import { Stack, Typography } from '@mui/material';

import { TranscriptionEntry } from './types';

export const TranscriptionITem: React.FC<TranscriptionEntry> = ({ id, text, timestamp }) => {
  return (
    <Stack
      component="li"
      direction="row"
      alignItems="flex-start"
      gap={1.5}
      sx={{
        ':hover': {
          '.copy-button': {
            opacity: 1,
          },
        },
      }}
    >
      <Typography variant="body2" component="span" color="primary" fontWeight={500}>
        {timestamp}
      </Typography>

      <Typography variant="body2" fontWeight={500} color="textSecondary">
        {text}
      </Typography>
    </Stack>
  );
};
