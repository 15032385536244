import { PatientAllergy, PatientMedicalCondition } from 'apiServices';

export enum PATIENT_TABS {
  PROFILE = 'Profile',
  MEDICAL = 'Medical',
  SUMMARY = 'Summary',
  CONSULTATIONS = 'Consultations',
  CONCERNS = 'Concerns',
  FORMS = 'Forms',
}

export type EditPatientFormProps = {
  refs?: ((node?: Element) => void)[];
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
  allergies: PatientAllergy[];
  medicalConditions: PatientMedicalCondition[];
};
