import { useEffect, useState } from 'react';

import { Card, Grid2, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { PatientAllergy, PatientMedicalCondition } from 'apiServices';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFDatePicker } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { CustomTableHeader, TableNoData } from 'components/Table';
import { LABELS } from 'constants/strings';
import { usePatientEnums } from 'hooks';
import { useMedicalRecordTableState } from 'pages/Patients/pages/PatientView/TabContentPages/Medical';
import {
  MedicalRecordRow,
  AddEditMedicalRecordTableRow,
  ALLERGIES_MEDICAL_RECORD_TABLE_HEAD,
} from 'pages/Patients/pages/PatientView/TabContentPages/Medical/components';
import { usePatientAllergies } from 'pages/Patients/pages/PatientView/usePatientAllergies';
import { usePatientMedicalConditions } from 'pages/Patients/pages/PatientView/usePatientMedicalConditions';
import { JSONField } from 'types';

import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';
import { PREVIOUS_AESTHETIC_TREATMENTS_OPTIONS, SKIN_TYPE_OPTIONS, SUN_EXPOSURE_OPTIONS } from '../../options';

type PatientInformationProps = {
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
  patientAllergies: PatientAllergy[];
  patientMedicalConditions: PatientMedicalCondition[];
  patientQuestionnaire: JSONField;
};

const SKIN_TYPE_ICON_MAPPING: Record<string, React.JSX.Element> = {
  type_1: <Icon type="skinType1Icon" />,
  type_2: <Icon type="skinType2Icon" />,
  type_3: <Icon type="skinType3Icon" />,
  type_4: <Icon type="skinType4Icon" />,
  type_5: <Icon type="skinType5Icon" />,
  type_6: <Icon type="skinType6Icon" />,
};

export const PatientInformation: React.FC<PatientInformationProps> = ({
  patientAllergies,
  fetchPatient,
  patientId,
  patientMedicalConditions,
  patientQuestionnaire,
}) => {
  const { control } = useFormContext<QuestionnaireFormSchema>();

  const {
    fetchPatientGenderEnums,
    fetchPatientSmokingEnums,
    fetchPatientEthnicitiesEnums,
    patientEthnicitiesEnumsState,
    patientSmokingEnumsState,
    patientGenderEnumsState,
  } = usePatientEnums();

  useEffect(() => {
    fetchPatientGenderEnums();
    fetchPatientSmokingEnums();
    fetchPatientEthnicitiesEnums();
  }, []);

  const {
    allergies: allergiesOptions,
    anchor,
    openPopoverHandler,
    closePopoverHandler,
    onDeletePatientAllergyHandler,
    isActionPending,
    actionState,
    onCreateAllergyHandler,
    dialogStates,
    getCloseDialogHandler,
  } = usePatientAllergies({ patientId, fetchPatient, patientQuestionnaire });

  const {
    anchor: anchorMedicalConditions,
    closePopoverHandler: closePopoverMedicalConditionsHandler,
    medicalConditions: medicalConditionsOptions,
    openPopoverHandler: openPopoverMedicalConditionsHandler,
    onCreateMedicalConditonHandler,
    onDeletePatientMedicalConditionHandler,
    actionState: actionStateMedicalCondition,
    isActionPending: isActionPendingMedicalCondition,
    dialogStates: dialogStatesMedicalCondition,
    getCloseDialogHandler: closeDeleteDialogWindowMedicalCondition,
  } = usePatientMedicalConditions({ patientId, fetchPatient });

  const [selectedSkinType, setSelectedSkinType] = useState<string | null>(null);

  const {
    isAddMedicalRecord,
    selectedMedicalRecordId,
    onClickEditButtonHandler,
    onClickAddButtonHandler,
    onClickCloseButtonHandler,
    onClickDeleteButtonHandler,
  } = useMedicalRecordTableState();

  return (
    <>
      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('delete')}
        open={dialogStates.delete}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            allergy? This action cannot be undone.
          </Typography>
        }
        title="Delete Allergy"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete Allergy"
        onClickApproveButtonHandler={onDeletePatientAllergyHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindowMedicalCondition('delete')}
        open={dialogStatesMedicalCondition.delete}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionStateMedicalCondition.name}
            </Typography>{' '}
            medical condition? This action cannot be undone.
          </Typography>
        }
        title="Delete Medical Condition"
        isApproveButtonDisabled={isActionPendingMedicalCondition}
        isApproveButtonLoading={isActionPendingMedicalCondition}
        approveButtonTitle="Delete Medical Condition"
        onClickApproveButtonHandler={onDeletePatientMedicalConditionHandler}
      />

      <FormSectionWrapper>
        <Grid2 spacing={2} container>
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFDatePicker isRequired control={control} registerName="birthDate" />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              options={patientEthnicitiesEnumsState.items}
              loading={patientEthnicitiesEnumsState.isLoading}
              label="Ethnicity"
              control={control}
              registerName="ethnicity"
              valueKey="value"
              labelKey="value"
            />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              label={LABELS.GENDER}
              control={control}
              registerName="genderIndentity"
              placeholder="Select Gender"
              options={patientGenderEnumsState.items}
              loading={patientGenderEnumsState.isLoading}
              valueKey="value"
              labelKey="value"
            />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              label="Previous aesthetic treatments"
              control={control}
              registerName="previousAestheticTreatments"
              placeholder="Select Previous aesthetic treatments"
              options={PREVIOUS_AESTHETIC_TREATMENTS_OPTIONS}
              valueKey="value"
              labelKey="value"
              freeSolo={false}
              multiple
            />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              label="Skin Type (Fitzpatrick scale)"
              control={control}
              registerName="skinType"
              options={SKIN_TYPE_OPTIONS}
              valueKey="value"
              labelKey="name"
              freeSolo={false}
              multiple={false}
              onChangeHandler={value => setSelectedSkinType(value?.value)}
              // inputProps={{
              //   InputProps: {
              //     startAdornment: SKIN_TYPE_ICON_MAPPING[selectedSkinType],
              //   },
              // }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    {SKIN_TYPE_ICON_MAPPING[option.value]}
                    {option.name}
                  </Stack>
                </li>
              )}
            />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              label="Sun Exposure"
              control={control}
              registerName="sunExposureHistory"
              options={SUN_EXPOSURE_OPTIONS}
              valueKey="value"
              labelKey="name"
            />
          </Grid2>

          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFAutocompleteField
              label="Smoking"
              control={control}
              registerName="smokingStatus"
              placeholder="Select Smoking Frequency"
              loading={patientSmokingEnumsState.isLoading}
              options={patientSmokingEnumsState.items}
              valueKey="value"
              labelKey="name"
            />
          </Grid2>

          <Grid2 size={12}>
            {/* Create a generic MedicalRecordsTableCard component. */}
            <Card>
              <Stack p={2} gap={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
                  Medical Conditions
                </Typography>

                <MDButton
                  onClick={onClickAddButtonHandler}
                  size="small"
                  variant="secondary"
                  startIcon={<Icon type="add" />}
                >
                  Add Medical Condition
                </MDButton>
              </Stack>
              <TableContainer>
                <Table size="medium">
                  <CustomTableHeader headLabel={ALLERGIES_MEDICAL_RECORD_TABLE_HEAD} />

                  <TableBody>
                    {isAddMedicalRecord && (
                      <AddEditMedicalRecordTableRow
                        options={patientMedicalConditions}
                        onSaveHandler={async () => {}}
                        onCancelHandler={onClickCloseButtonHandler}
                        getOptionLabel={option => option?.medical_condition?.medical_condition_icd10_code}
                      />
                    )}

                    {medicalConditionsOptions?.map(medicalCondition =>
                      selectedMedicalRecordId === medicalCondition?.id ? (
                        <AddEditMedicalRecordTableRow
                          options={medicalConditionsOptions}
                          option={medicalCondition}
                          note={medicalCondition?.name}
                          onSaveHandler={async () => {}}
                          onCancelHandler={onClickCloseButtonHandler}
                          getOptionLabel={option => option?.name}
                        />
                      ) : (
                        <MedicalRecordRow
                          key={medicalCondition?.id}
                          id={medicalCondition?.id}
                          onClickEdit={onClickEditButtonHandler}
                          onClickDelete={onClickDeleteButtonHandler}
                          name="Medical Condition"
                          createdAt="2021-10-10"
                        />
                      ),
                    )}
                    {!isAddMedicalRecord && <TableNoData isNotFound={!patientMedicalConditions?.length} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid2>

          <Grid2 size={12}>
            <Card>
              <Stack p={2} gap={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
                  Allergies
                </Typography>

                <MDButton
                  onClick={onClickAddButtonHandler}
                  size="small"
                  variant="secondary"
                  startIcon={<Icon type="add" />}
                >
                  Add Allergy
                </MDButton>
              </Stack>
              <TableContainer>
                <Table size="medium">
                  <CustomTableHeader headLabel={ALLERGIES_MEDICAL_RECORD_TABLE_HEAD} />

                  <TableBody>
                    {isAddMedicalRecord && (
                      <AddEditMedicalRecordTableRow
                        options={allergiesOptions}
                        onSaveHandler={async () => {}}
                        onCancelHandler={onClickCloseButtonHandler}
                        getOptionLabel={option => option?.name}
                      />
                    )}

                    {patientAllergies?.map(allergy =>
                      selectedMedicalRecordId === allergy?.id ? (
                        <AddEditMedicalRecordTableRow
                          options={patientAllergies}
                          option={allergy}
                          note={allergy?.allergy?.note}
                          onSaveHandler={async () => {}}
                          onCancelHandler={onClickCloseButtonHandler}
                          getOptionLabel={option => option?.allergy?.allergy_icd10_code}
                        />
                      ) : (
                        <MedicalRecordRow
                          key={allergy?.id}
                          id={allergy?.id}
                          onClickEdit={onClickEditButtonHandler}
                          onClickDelete={onClickDeleteButtonHandler}
                          name="Allergy"
                          createdAt="2021-10-10"
                        />
                      ),
                    )}
                    {!isAddMedicalRecord && <TableNoData isNotFound={!patientAllergies?.length} />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid2>
        </Grid2>
      </FormSectionWrapper>
    </>
  );
};
