import { FC, memo, useState } from 'react';

import { FormControlLabel, Stack, Switch } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { TagCategoryTagInitialFilters } from 'pages/TagManagement/types';

type CategoryTagsFilterPopoverContentProps = {
  appliedFilters: TagCategoryTagInitialFilters;
  onApplyFilters: (newFilters: TagCategoryTagInitialFilters) => void;
};

export const CategoryTagsFilterPopoverContent: FC<CategoryTagsFilterPopoverContentProps> = memo(
  ({ appliedFilters, onApplyFilters }) => {
    const [filters, setFilters] = useState(appliedFilters);

    const onClickResetFiltersButtonHandler = () => setFilters({ isShowDeactivated: false });

    const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

    const isApplyButtonDisabled = appliedFilters.isShowDeactivated === filters.isShowDeactivated;

    const isResetButtonDisabled = filters.isShowDeactivated === false;

    const onChangeDeactivatedSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isShowDeactivated: checked }));
    };

    return (
      <Stack position="relative">
        <Stack height={1} spacing={2} py={1} alignItems="flex-start">
          <FormControlLabel
            onChange={onChangeDeactivatedSwitchHandler}
            checked={filters.isShowDeactivated}
            control={<Switch />}
            label="Show deactivated"
            labelPlacement="start"
          />
        </Stack>

        <FilterMenuPopoverButtons
          isClearButtonDisabled={isResetButtonDisabled}
          onClickClearButtonHandler={onClickResetFiltersButtonHandler}
          isApplyButtonDisabled={isApplyButtonDisabled}
          onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
        />
      </Stack>
    );
  },
);
