import { PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useRouter, useStorageListener, useUserProfile } from 'hooks';
import { AppStore } from 'store';
import { CognitoUser } from 'store/slices';

export const PublicRouteGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { expertProfile, userRoles, cognitoUser, setCognitoProfile } = useUserProfile();

  const isAuthenticated = userRoles.isRoleAdmin ? !!cognitoUser : cognitoUser && !!expertProfile;

  const { navigate } = useRouter();

  const updateCognitoProfile = (cognitoUser: CognitoUser) => {
    if (cognitoUser?.userId) {
      setCognitoProfile(cognitoUser);
      navigate(ROUTES.patients, { replace: true });
    }
  };

  useStorageListener<{ state: AppStore }>('store', parsedNewState => {
    if (isAuthenticated) updateCognitoProfile(parsedNewState?.state?.cognitoUser);
  });

  if (isAuthenticated) return <Navigate to={ROUTES.patients} replace />;

  return <>{children}</>;
};
