import Stack from '@mui/material/Stack';

import { FormActions } from 'components/Form';

import { ConcernDetails } from './ConcernDetails';
import { ConcernOverview } from './ConcernOverview';

export const GeneralTabContent: React.FC = () => {
  return (
    <Stack component="form">
      <Stack width={1} direction={{ xs: 'column-reverse', md: 'row' }}>
        <ConcernOverview />
        <ConcernDetails />
      </Stack>

      <FormActions isDisabled={false} isLoading={false} isDirtyForm={true} />
    </Stack>
  );
};
