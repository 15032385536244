import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import { CreateGLServicePayload, GLService, GLServicesSearchParams, UpdateGLServicePayload } from './types';

const BASE_SERVICES_API_URL = `${BASE_DICTIONARY_API_URL}/services`;

export const getGLServices = async (params: GLServicesSearchParams) =>
  await apiClient.get<ListOfItems<GLService>>(BASE_SERVICES_API_URL, { params });

export const createGLService = async (payload: CreateGLServicePayload) =>
  await apiClient.post<GLService>(BASE_SERVICES_API_URL, payload);

export const getGLService = async (id: string) => await apiClient.get<GLService>(`${BASE_SERVICES_API_URL}/${id}`);

export const updateGLService = async (id: string, payload: UpdateGLServicePayload) =>
  await apiClient.patch<GLService>(`${BASE_SERVICES_API_URL}/${id}`, payload);

export const deleteGLService = async (id: string) => await apiClient.delete(`${BASE_SERVICES_API_URL}/${id}`);

export const deactivateGLService = async (id: string) =>
  await apiClient.delete<GLService>(`${BASE_SERVICES_API_URL}/${id}/deactivate`);

export const activateGLService = async (id: string) =>
  await apiClient.patch<GLService>(`${BASE_SERVICES_API_URL}/${id}/activate`);
