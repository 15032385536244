import {
  CSSObject,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  IconButtonProps,
  Theme,
  alpha,
} from '@mui/material';

import borders from '../base/borders';
import { palette } from '../base/palette';

const COLORS: Exclude<IconButtonProps['color'], 'inherit' | 'default'>[] = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

const rootStyle = (ownerState: IconButtonProps) => {
  const { size, variant, color } = ownerState;
  const isRounded = variant === 'rounded';
  const isCircle = variant === 'circle';

  const baseStyles: CSSObject = {
    ...((isRounded || isCircle) && {
      svg: {
        width: 16,
        height: 16,
        ...(size === 'large' && {
          width: 20,
          height: 20,
        }),
      },
    }),
  };

  const sizeStyles: CSSObject = {
    ...(size === 'small' && {
      ...((isCircle || isRounded) && {
        width: 24,
        height: 24,
      }),
    }),
    ...(size === 'medium' && {
      ...(isCircle && { width: 32, height: 32 }),
      ...(isRounded && { width: 28, height: 28 }),
    }),
    ...(size === 'large' && {
      ...(isCircle && { width: 40, height: 40 }),
      ...(isRounded && { width: 32, height: 32 }),
    }),
  };

  const variantStyles: CSSObject = {
    ...(isRounded && {
      border: borders.borderWidth[0],
      padding: '4px',
      backgroundColor: 'transparent',
      borderRadius: borders.borderRadius[6],
      color: palette.secondary.darker,
      '&:hover': {
        backgroundColor: palette.background.surfaceMedium,
      },
      '&:disabled': {
        backgroundColor: palette.disabledColor.disabledSoft,
        color: palette.disabledColor.disabledMedium,
        borderColor: palette.disabledColor.disabledMedium,
      },
      '& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius: borders.borderRadius[6] },
    }),
    ...(isCircle && {
      borderRadius: borders.borderRadius.full,
      backgroundColor: palette.background.default,
      border: `${borders.borderWidth[1]} solid ${palette.secondary.dark}`,
      color: palette.secondary.dark,
      '&:hover': {
        backgroundColor: palette.background.surfaceSoft,
      },

      '&:disabled': {
        backgroundColor: palette.disabledColor.disabledSoft,
        color: palette.disabledColor.disabledMedium,
        borderColor: palette.disabledColor.disabledMedium,
      },
    }),
  };

  const colorStyle = COLORS.map(currentColor => {
    const colorValue = palette[currentColor]?.main || palette.primary.main;
    return {
      ...(color === currentColor && {
        ...(isRounded && {
          color: colorValue,
          '&:hover': {
            backgroundColor: alpha(colorValue, 0.04),
          },
        }),
        ...(isCircle && {
          backgroundColor: alpha(colorValue, 0.04),
          color: colorValue,
          borderColor: colorValue,
          '&:hover': {
            backgroundColor: alpha(colorValue, 0.1),
          },
        }),
      }),
    };
  });

  return [baseStyles, sizeStyles, variantStyles, colorStyle];
};

const iconButton: {
  defaultProps?: ComponentsProps['MuiIconButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIconButton'];
  variants?: ComponentsVariants<Theme>['MuiIconButton'];
} = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: ({ ownerState }) => rootStyle(ownerState),
  },
};

export default iconButton;
