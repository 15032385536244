import { StateCreator } from 'zustand';

import { PromptViewInitialState, PromptViewSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: PromptViewInitialState = {
  prompt: null,
  evaluationHistory: [],
};

export const PromptViewSlice: StateCreator<
  PromptViewSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PromptViewSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setPrompt: prompt => {
      set(state => {
        state.prompt = prompt;
      });
    },
    setEvaluationHistory: evaluationHistory => {
      set(state => {
        state.evaluationHistory = evaluationHistory;
      });
    },
  };
};
