import { FC } from 'react';

import { CardContent, CardHeader, Stack } from '@mui/material';

import { UserEmailForm } from './components';

export const UserProfile: FC = () => {
  return (
    <Stack sx={{ minHeight: 250, height: 1 }}>
      <CardHeader title="Profile" />
      <CardContent sx={{ height: 1 }}>
        <UserEmailForm />
      </CardContent>
    </Stack>
  );
};
