import { PatientAllergy, PatientMedicalCondition } from 'apiServices';
import { TableHeaderConfig } from 'types';

const BASE_MEDICAL_RECORD_TABLE_HEAD: TableHeaderConfig<unknown>[] = [
  { id: 'notes', label: 'Notes', disableSorting: true },
  { id: 'createdAt', label: 'Date Added', disableSorting: true },
  { id: 'actions', label: 'Actions', disableSorting: true },
];

export const ALLERGIES_MEDICAL_RECORD_TABLE_HEAD: TableHeaderConfig<PatientAllergy>[] = [
  { id: 'allergy', label: 'Allergy', disableSorting: true },
  { id: 'icd10Code', label: 'ICD-10', disableSorting: true },
  ...BASE_MEDICAL_RECORD_TABLE_HEAD,
];

export const MEDICAL_CONDITIONS_MEDICAL_RECORD_TABLE_HEAD: TableHeaderConfig<PatientMedicalCondition>[] = [
  { id: 'medicalCondition', label: 'Medical Condition', disableSorting: true },
  { id: 'icd10Code', label: 'ICD-10', disableSorting: true },
  ...BASE_MEDICAL_RECORD_TABLE_HEAD,
];
