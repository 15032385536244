import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FetchMFAPreferenceOutput, fetchMFAPreference, updateMFAPreference } from 'aws-amplify/auth';
import { Resolver, useForm } from 'react-hook-form';

import { ToastType, notice } from 'components/ToastNotification';
import { useCognitoAuthContext } from 'context';
import { useBoolean } from 'hooks/useBoolean';
import { setupTOTPHandler } from 'lib/AWS_Cognito/helpers';
import { awsCognitoErrorHandler } from 'utils/errorHanders';
import { TOTP_MFA_verification_code_validation_schema, TOT_MFA_verification_code_schema } from 'utils/formValidation';

export const useMFAprovidersState = () => {
  const { verifyTOTP } = useCognitoAuthContext();

  const [isMFASettingsLoading, setIsMFASettingsLoading] = useState(true);

  const [MFAdata, setMFAdata] = useState<null | FetchMFAPreferenceOutput>(null);

  const isTOTPEnabled = MFAdata?.enabled?.includes('TOTP') || false;

  const getMFAsettings = async () => {
    const MFAsettings = await fetchMFAPreference();

    setMFAdata(MFAsettings);
  };

  const onGetMFASettingsaHandler = async () => {
    try {
      await getMFAsettings();

      setIsMFASettingsLoading(false);
    } catch (error) {
      setIsMFASettingsLoading(false);
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to get MFA settings, please try again!' });
    }
  };

  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TOT_MFA_verification_code_schema>({
    resolver: yupResolver(TOTP_MFA_verification_code_validation_schema) as Resolver<TOT_MFA_verification_code_schema>,
    mode: 'onTouched',
    defaultValues: {
      TOTPverificationCode: '',
    },
  });

  useEffect(() => {
    onGetMFASettingsaHandler();
  }, []);

  const onVerifyTOTPcodeHandler = handleSubmit(async ({ TOTPverificationCode }) => {
    try {
      await verifyTOTP(TOTPverificationCode);

      await updateMFAPreference({ totp: 'PREFERRED' });

      await getMFAsettings();

      reset();
      onCloseConfrimTOTPcodeDialog();

      notice(ToastType.SUCCESS, 'Successfully enabled TOTP MFA!');
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to verify TOTP code' });
    }
  });

  const [isScanQRcodeDialogPopen, onOpenScanQRcodeDialog, onCloseScanQRcodeDialog] = useBoolean();

  useEffect(() => {
    if (isScanQRcodeDialogPopen) onGetQRcodeHandler();
  }, [isScanQRcodeDialogPopen]);

  const [isGeneratingQRcode, setIsGeneratingQRcode] = useState(true);

  const [TOTP_QrCode_URL, setTOTP_QrCode_URL] = useState('');

  const onGetQRcodeHandler = async () => {
    try {
      const URL = await setupTOTPHandler();

      setTOTP_QrCode_URL(URL.href);

      setIsGeneratingQRcode(false);
    } catch (error) {
      setIsGeneratingQRcode(false);
      console.error(error);
      notice(ToastType.ERROR, 'Failed to generate QR code, please try again');
    }
  };

  const [isConfrimTOTPcodeDialogOpen, onOpenConfrimTOTPcodeDialog, onCloseConfrimTOTPcodeDialog] = useBoolean();

  const onClickNextButtonHandler = () => {
    onCloseScanQRcodeDialog();
    onOpenConfrimTOTPcodeDialog();
  };

  const [isDisableTOTPdialogOpen, onOpenDisableTOTPdialog, onCloseDisableTOTPdialog] = useBoolean(false);

  const onSwitchTOTPhandler = async () => {
    if (isTOTPEnabled) {
      onOpenDisableTOTPdialog();
      return;
    }
    onOpenScanQRcodeDialog();
  };

  const [isDisablingTOTP, setIsDisablingTOTP] = useState(false);

  const onClickDisableTOTPButtonHandler = async () => {
    try {
      setIsDisablingTOTP(true);

      await updateMFAPreference({ totp: 'DISABLED' });

      await getMFAsettings();

      notice(ToastType.SUCCESS, 'Successfully disabled TOTP MFA!');
      onCloseDisableTOTPdialog();

      setIsDisablingTOTP(false);
    } catch (error) {
      setIsDisablingTOTP(false);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to disable TOTP MFA, please try again!' });
    }
  };

  return {
    onClickDisableTOTPButtonHandler,
    isDisablingTOTP,
    onSwitchTOTPhandler,
    isDisableTOTPdialogOpen,
    onCloseDisableTOTPdialog,
    onVerifyTOTPcodeHandler,
    TOTP_QrCode_URL,
    isMFASettingsLoading,
    control,
    isValid,
    isSubmitting,
    isTOTPEnabled,
    onClickNextButtonHandler,
    isGeneratingQRcode,
    onCloseConfrimTOTPcodeDialog,
    isConfrimTOTPcodeDialogOpen,
    onCloseScanQRcodeDialog,
    isScanQRcodeDialogPopen,
  };
};
