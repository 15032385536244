import { FC, memo, useMemo } from 'react';

import { ArrowForwardIosRounded, MenuOpenRounded, MenuRounded } from '@mui/icons-material';
import { Box, IconButton, Link, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Icon } from 'components/Icon';
import { ROUTES } from 'constants/routes';
import { useUserProfile } from 'hooks';

import { BREADCRUMBS_ROUTES, getBreadcrumbsConfig } from './breadcrumbs.config';
import { BreadcrumbsProps } from './types';

export const Breadcrumbs: FC<BreadcrumbsProps> = memo(({ isTabletScreen, isOpenMobileNavBar, onOpenSideNav }) => {
  const userRole = useUserProfile().userRole;

  const excludePaths = useMemo(() => getBreadcrumbsConfig(userRole), [userRole]);

  const breadcrumbs = useBreadcrumbs(BREADCRUMBS_ROUTES, { excludePaths });

  return (
    <Stack
      width={1}
      direction="row"
      spacing={0.5}
      alignItems="center"
      justifyContent="space-between"
      bgcolor={theme => theme.palette.common.white}
    >
      <Stack direction="row" spacing={1} alignItems="center" sx={{ minWidth: 0, flex: 1 }}>
        <Icon sx={{ color: theme => theme.palette.action.disabled }} type="page" />
        <MuiBreadcrumbs
          sx={{
            textTransform: 'capitalize',
            minWidth: 0,
            flex: 1,
            '& .MuiBreadcrumbs-ol': {
              flexWrap: 'nowrap',
              minWidth: 0,
            },
            '& .MuiBreadcrumbs-li': {
              display: 'flex',
              whiteSpace: 'nowrap',

              '&:last-child': {
                flex: '1 1 auto',
                minWidth: 0,
                overflow: 'hidden',
              },
            },
            '& .MuiBreadcrumbs-separator': { mx: '4px' },
          }}
          separator={<ArrowForwardIosRounded />}
        >
          {breadcrumbs.map(({ match, breadcrumb }, index) =>
            index !== breadcrumbs.length - 1 ? (
              <Link
                key={match.pathname}
                color="secondary.darker"
                to={match.pathname === '/' ? ROUTES.patients : match.pathname}
                component={ReactRouterLink}
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
                noWrap
              >
                {breadcrumb}
              </Link>
            ) : (
              <Typography
                key={match.pathname}
                color="primary"
                variant="caption"
                component="p"
                noWrap
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {breadcrumb}
              </Typography>
            ),
          )}
        </MuiBreadcrumbs>
      </Stack>
      <Box display={isTabletScreen ? 'flex' : 'none'}>
        <IconButton size="medium" onClick={onOpenSideNav}>
          {isOpenMobileNavBar ? <MenuOpenRounded /> : <MenuRounded />}
        </IconButton>
      </Box>
    </Stack>
  );
});
