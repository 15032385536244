import { PatientSortFieldName } from 'apiServices';
import { LABELS } from 'constants/strings';
import { TableHeaderConfig } from 'types';

export const PATIENTS_TABLE_HEAD: TableHeaderConfig<PatientSortFieldName>[] = [
  { id: 'avatar', disableSorting: true },
  { id: 'last_name', label: LABELS.LAST_NAME },
  { id: 'first_name', label: LABELS.FIRST_NAME },
  { id: 'middleName', label: LABELS.MIDDLE_NAME, disableSorting: true },
  { id: 'birthDate', label: 'Date of Birth (Age)', disableSorting: true },
  { id: 'email', label: LABELS.EMAIL, disableSorting: true },
  { id: 'phone', label: 'Phone', disableSorting: true },
];
