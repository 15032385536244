import { FC } from 'react';

import { Tab } from '@mui/material';

import { Icon } from 'components/Icon';
import { StyledTabsWrapper } from 'components/MDTabs';

export type TableTabsProps = {
  tabValue: number;
  handleSetTabValue: (_: React.SyntheticEvent<Element, Event>, newValue: number) => void;
};

export const TableTabs: FC<TableTabsProps> = ({ tabValue, handleSetTabValue }) => {
  return (
    <StyledTabsWrapper styleVariant="pill" value={tabValue} onChange={handleSetTabValue}>
      <Tab
        icon={
          <Icon
            type="linkedBuilding"
            sx={{
              '& svg': {
                width: 20,
                height: 20,
              },
            }}
          />
        }
        label="Services"
      />
      <Tab
        icon={
          <Icon
            type="linkedMedical"
            sx={{
              '& svg': {
                width: 20,
                height: 20,
              },
            }}
          />
        }
        label="Products"
      />
    </StyledTabsWrapper>
  );
};
