import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const { primary, disabledColor, text, secondary } = palette;
const { size } = typography;
const { borderWidth } = borders;

const input = {
  styleOverrides: {
    root: {
      fontSize: size.bodyMedium,
      color: text.primary,
      fontWeight: 500,

      '&.Mui-disabled': {
        backgroundColor: disabledColor.disabledSoft,
        '&:before': {
          borderColor: disabledColor.disabledMedium,
          borderBottomStyle: 'solid',
        },
      },

      '&:hover:not(.Mui-disabled, .Mui-focused, .Mui-error):before': {
        borderWidth: borderWidth[1],
        borderColor: secondary.dark,
      },

      '&:before': {
        borderColor: secondary.main,
        borderWidth: borderWidth[1],
      },

      '&:after': {
        borderColor: primary.main,
      },

      '&.MuiInput-underline.MuiInputBase-adornedStart': {
        paddingLeft: 12,
      },
      '&::placeholder': {
        color: 'red !important',
        opacity: 1,
      },
    },

    input: {
      padding: '9px 12px',
      textOverflow: 'ellipsis',

      '&:disabled': {
        color: disabledColor.disabledMedium,
        WebkitTextFillColor: disabledColor.disabledMedium,
      },

      '&::placeholder': {
        color: secondary.dark,
        WebkitTextFillColor: secondary.dark,
        opacity: 1,
        '&::-moz-placeholder': {
          color: secondary.dark,
        },
        '&:-ms-input-placeholder': {
          color: secondary.dark,
        },
      },
    },

    inputSizeSmall: {
      fontSize: size.bodySmall,
      padding: '5px 12px',
    },

    multiline: {
      padding: 0,
    },
  },
};

export default input;
