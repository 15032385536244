import { MenuList } from '@mui/material';

import { MenuPopover } from '../MenuPopover';
import { PopoverMenuItem } from '../PopoverMenuItem';
import { ActionMenuListProps } from './types';

export const ActionMenuList: React.FC<ActionMenuListProps> = ({ menuItems = [], onClose, ...menuPopoverProps }) => {
  return (
    <MenuPopover
      disabledArrow
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={{
        mt: 0.5,
      }}
      paperSx={{
        minWidth: 160,
      }}
      onClose={onClose}
      {...menuPopoverProps}
    >
      <MenuList>
        {menuItems?.map(({ icon, title, onClick }) => {
          const onClickHandler = (e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            onClose(e, 'backdropClick');
            onClick(e);
          };

          return (
            <PopoverMenuItem
              key={title}
              icon={icon}
              title={title}
              onClick={onClickHandler}
              sx={{
                svg: {
                  width: 16,
                  height: 16,
                },
              }}
            />
          );
        })}
      </MenuList>
    </MenuPopover>
  );
};
