import * as yup from 'yup';

export type UpdatePracticeSummaryFormSchema = {
  practice_summary?: string;
};

export const updatePracticeSummaryValidationSchema: yup.ObjectSchema<UpdatePracticeSummaryFormSchema> = yup
  .object()
  .shape({
    practice_summary: yup.string().optional().label('Practice Summary'),
  })
  .required();
