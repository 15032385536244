import { FC } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import { DialogTitle, Divider, IconButton, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

import { DialogHeaderProps } from '../types';

export const DialogHeader: FC<DialogHeaderProps> = ({ isHideDivider, sx, onClickCancelButtonHandler, title, icon }) => {
  return (
    <>
      <Stack
        sx={{ px: { xs: 2, sm: 2.5 }, py: 1.5, ...sx }}
        direction="row"
        gap={0.5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          {icon && icon}
          <DialogTitle sx={{ flexShrink: 1, p: 0, fontWeight: 500, fontSize: { xs: 16, sm: 20 } }}>{title}</DialogTitle>
        </Stack>
        {onClickCancelButtonHandler && (
          <MDButton variant="tertiary" size="small" isIconOnly onClick={onClickCancelButtonHandler}>
            <Icon
              type="closeIcon"
              sx={{
                svg: {
                  width: 12,
                  height: 12,
                },
              }}
            />
          </MDButton>
        )}
      </Stack>
      {!isHideDivider && <Divider />}
    </>
  );
};
