import { Dialog, Stack, Table, TableBody, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { MDInput } from 'components/MDInput';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';
import { FiltersMenuContent, ProductsServicesListTableRow, TableTabs, TableTabsProps } from 'pages/Catalog';
import { PRODUCTS_SERVICES_TABLE_HEADERS } from 'pages/Catalog/config';

import { AddServiceDialogWindowForm } from './AddServiceDialogWindowForm';
import { useGLServicesState } from './useGLServices.state';

export const GlobalLibraryServices: React.FC<TableTabsProps> = ({ handleSetTabValue, tabValue }) => {
  const {
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    closeAddServiceDialog,
    isAddServiceDialogOpen,
    openAddServiceDialog,
    onGetServicesHandler,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onDeleteServiceHandler,
    onCopyItemHandler,
    categoryName,
    handleChangeCategoryName,
    isRolePracticeUser,
    dialogStates,
    getCloseDialogHandler,
  } = useGLServicesState();

  return (
    <PageWrapper>
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchValueHandler,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        filterButtonShown={isRoleAdmin}
        actionButtonProps={
          isRoleAdmin && {
            buttonTitle: 'Add service',
            onClick: openAddServiceDialog,
          }
        }
        isFiltersApplied={isFiltersApplied}
        tabs={<TableTabs tabValue={tabValue} handleSetTabValue={handleSetTabValue} />}
      />

      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <CustomTableHeader
            headLabel={PRODUCTS_SERVICES_TABLE_HEADERS}
            sx={{
              'th:first-of-type': { pl: '16px !important' },
            }}
          />

          <TableBody>
            {isLoading ? (
              <TableSkeleton cellsAmount={3} />
            ) : (
              items.map(el => (
                <ProductsServicesListTableRow
                  key={el?.id}
                  onClickActivationOptionHandler={onClickActionOptionHandler}
                  description={el?.description}
                  title={el?.title}
                  id={el?.id}
                  isActive={el?.is_active}
                  isThreeDotsButton={isRoleAdmin}
                  isViewOnly={isRolePracticeUser}
                  library="gl"
                  type="service"
                />
              ))
            )}
            <TableNoData isNotFound={!isLoading && !items?.length} />
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddServiceDialogOpen}
        onClose={closeAddServiceDialog}
        fullWidth
        slotProps={{
          paper: {
            sx: {
              maxWidth: 750,
            },
          },
        }}
      >
        <AddServiceDialogWindowForm
          onCloseDialogHandler={closeAddServiceDialog}
          onGetServicesHandler={onGetServicesHandler}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('copy')}
        open={dialogStates.copy}
        description={
          <>
            <Typography textAlign="center" variant="body2">
              Are you sure you want to copy{' '}
              <Typography variant="body2" component="span" fontWeight={600}>
                {actionState.name}
              </Typography>{' '}
              to practice library?
            </Typography>
            <MDInput label="Name" value={categoryName} onChange={handleChangeCategoryName} />
          </>
        }
        title="Copying"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Copy"
        onClickApproveButtonHandler={onCopyItemHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('delete')}
        open={dialogStates.delete}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}?
            </Typography>
          </Typography>
        }
        title="Deleting service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete service"
        onClickApproveButtonHandler={onDeleteServiceHandler}
      />

      <BaseDialogWindow
        open={dialogStates.deactivate}
        onClickCancelButtonHandler={getCloseDialogHandler('deactivate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              service?
            </Typography>
          </Stack>
        }
        title="Inactivate service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate service"
        onClickApproveButtonHandler={onDeactivateServiceHandler}
      />

      <BaseDialogWindow
        open={dialogStates.activate}
        onClickCancelButtonHandler={getCloseDialogHandler('activate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              service?
            </Typography>
          </Stack>
        }
        title="Activate service"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate service"
        onClickApproveButtonHandler={onActivateServiceHandler}
      />

      {isRoleAdmin && (
        <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
          <FiltersMenuContent
            onResetFilters={() => {}}
            appliedFilters={appliedFilters}
            onApplyFilters={onApplyFilters}
          />
        </PopoverWrapper>
      )}
    </PageWrapper>
  );
};
