import * as yup from 'yup';

import { UpdatePatientPayload } from 'apiServices';

export type EditMedicalFormSchema = Pick<
  UpdatePatientPayload,
  'smoking' | 'alcohol_consumption' | 'pain_tolerance' | 'biological_sex' | 'blood_type'
>;

export const editMedicalValidationSchema: yup.ObjectSchema<EditMedicalFormSchema> = yup
  .object()
  .shape({
    smoking: yup.string().optional().label('Smoking').nullable(),
    alcohol_consumption: yup.string().optional().label('Alcohol Consumption').nullable(),
    pain_tolerance: yup.string().optional().label('Pain Tolerance').nullable(),
    blood_type: yup.string().optional().label('Blood Type').nullable(),
    biological_sex: yup.string().optional().label('Biological Sex').nullable(),
  })
  .required();
