import { memo } from 'react';

import { FileWithPath } from 'react-dropzone';
import { Area } from 'react-easy-crop/types';

import { WHITESPACE_REGEX } from 'constants/regex';

export const getElementById = <OptionType, KeyType extends keyof OptionType>(
  currentElementId: OptionType[KeyType] | null | undefined,
  valueKey: KeyType,
  options: readonly OptionType[],
): OptionType | null => {
  if (!currentElementId) return null;

  return options.find(option => option[valueKey] === currentElementId) || null;
};

export const getFilterApplyButtonStatus = <TFilters>({
  filterEntries,
  appliedFilters,
}: {
  filterEntries: [string, TFilters[keyof TFilters]][];
  appliedFilters: TFilters;
}): boolean => {
  return filterEntries.every(([key, value]) => {
    const appliedFilterValue = appliedFilters[key as keyof TFilters];

    if (Array.isArray(appliedFilterValue) && Array.isArray(value)) {
      if (appliedFilterValue.length !== value.length) return false;

      return appliedFilterValue.every(item => value.some(val => JSON.stringify(val) === JSON.stringify(item)));
    }

    if (typeof appliedFilterValue === 'object') {
      const currentFilterItemValues = Object.values(value || {});
      const appliedFilterItemValues = Object.values(appliedFilterValue || {});
      return appliedFilterItemValues?.[0] === currentFilterItemValues?.[0];
    }

    return appliedFilterValue === value;
  });
};

export const getFilterResetButtonStatus = <TFilters extends Record<string, any>>({
  filterEntries,
  defaultFilters,
}: {
  filterEntries: [string, TFilters[keyof TFilters]][];
  defaultFilters: Partial<TFilters>;
}): boolean => {
  return filterEntries.every(([key, value]) => {
    const defaultValue = defaultFilters[key];

    if (Array.isArray(value)) {
      return value.length === (Array.isArray(defaultValue) ? defaultValue.length : 0);
    }

    if (typeof value === 'object' && value !== null) {
      const currentFilterValues = Object.values(value || {});
      const defaultFilterValues = Object.values(defaultValue || {});

      return JSON.stringify(currentFilterValues) === JSON.stringify(defaultFilterValues);
    }

    return value === defaultValue;
  });
};

export const genericMemo: <T>(component: T) => T = memo;

export const getInitials = (name: string) => {
  if (!name) return '';

  const words = name.trim().split(WHITESPACE_REGEX);

  let initials = words?.[0]?.[0];

  if (words.length > 1) initials += words.at(-1)?.[0];

  return initials?.toUpperCase();
};

export const formatCountdownTime = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');

  return `${paddedMinutes}:${paddedSeconds}`;
};

export function hideEmail(email: string): string {
  const [localPart, domain] = email.split('@');

  if (localPart.length <= 1) return email;

  const hiddenLocalPart = localPart.substring(0, 2) + '*'.repeat(6);

  return `${hiddenLocalPart}@${domain}`;
}

export const checkIsFilterApplied = <TInitial extends { [key: string]: any }, TApplied extends { [key: string]: any }>(
  initialFilter: TInitial,
  appliedFilter: TApplied,
): boolean => {
  const filterEntries = Object.entries(initialFilter);
  for (let index = 0; index < filterEntries.length; index++) {
    const key = filterEntries[index]?.[0];
    if (Boolean(initialFilter[key]) !== Boolean(appliedFilter[key])) return true;
  }

  return false;
};

type GenerateFormDataProps = {
  formDataImageFileName: string;
  file: FileWithPath;
  croppedAreaPixels: Area;
};

export const generateImageFormData = ({
  formDataImageFileName,
  file,
  croppedAreaPixels,
}: GenerateFormDataProps): FormData => {
  if (!file) throw new Error('File is missing!');

  const formData = new FormData();

  formData.append(formDataImageFileName, file);
  formData.append('crop_width', Math.round(croppedAreaPixels.width).toString());
  formData.append('crop_height', Math.round(croppedAreaPixels.height).toString());
  formData.append('crop_x', Math.round(croppedAreaPixels.x).toString());
  formData.append('crop_y', Math.round(croppedAreaPixels.y).toString());

  return formData;
};

export function resolveFilterCondition(
  firstOption: boolean,
  secondOption: boolean,
  defaultValue: null | boolean = null,
) {
  if (firstOption === secondOption) return defaultValue;

  if (firstOption) return true;

  return false;
}

export const formatDuration = (seconds: number) => {
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);
  return `${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
};
