import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CardContent, CardHeader, Divider, Grid2, Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { getGLService, updateGLService } from 'apiServices';
import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import { useRouter, useUserProfile } from 'hooks';
import { ProfileSkeleton } from 'pages/Catalog';
import { CreateCatalogGLItemFormSchema, createCatalogGLItemValidationSchema } from 'pages/Catalog/config';
import { useAppStore } from 'store';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

export const GlobalLibraryServiceProfile: FC = () => {
  const { params } = useRouter();

  const serviceId = params?.id;

  const {
    userRoles: { isRoleAdmin },
  } = useUserProfile();

  const { GLService, setGLService } = useAppStore(
    store => ({
      GLService: store.GLService,
      setGLService: store.setGLService,
    }),
    shallow,
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting, errors },
  } = useForm<CreateCatalogGLItemFormSchema>({
    resolver: yupResolver(createCatalogGLItemValidationSchema) as Resolver<CreateCatalogGLItemFormSchema>,
    mode: 'onTouched',
    values: { title: GLService?.title, description: GLService?.description || '' },
  });

  const onFormSubmitHandler = handleSubmit(async ({ title, description }) => {
    try {
      const { data } = await updateGLService(serviceId, { title, ...(description && { description }) });

      notice(ToastType.SUCCESS, 'Service has been successfully edited!');

      setGLService(data);
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  const onGetGLServiceHandler = async () => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGLService(serviceId);

      setGLService(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get service, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetGLServiceHandler();

    return () => {
      setGLService(null);
    };
  }, []);

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <Box component="section">
      <Stack component="form" onSubmit={onFormSubmitHandler}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <CardHeader
              title="Edit service"
              slotProps={{
                title: {
                  sx: {
                    fontSize: 20,
                    fontWeight: 700,
                  },
                },
              }}
              sx={{ pb: 0 }}
            />
            <Divider sx={{ my: 2 }} />

            <CardContent sx={{ pt: 0 }}>
              {isLoading ? (
                <ProfileSkeleton />
              ) : (
                <Stack spacing={2}>
                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="title"
                    label="Name"
                    required
                    placeholder="Enter name"
                    disabled={!isRoleAdmin}
                  />

                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="description"
                    label="Description"
                    placeholder="Enter description"
                    multiline
                    minRows={5}
                    maxRows={8}
                    disabled={!isRoleAdmin}
                  />
                </Stack>
              )}
            </CardContent>
          </Grid2>

          {!isLoading && (
            <Grid2
              size={12}
              sx={{
                '.MuiPaper-root': {
                  boxShadow: 'none',
                  border: 'none',
                },
              }}
            >
              <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
            </Grid2>
          )}
        </Grid2>
      </Stack>
    </Box>
  );
};
