import { BASE_PRACTICE_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import { CreatePracticePayload, GetPracticesParams, Practice, PracticeListItem, UpdatePracticePayload } from './types';

export const getPractices = async (params: GetPracticesParams) =>
  await apiClient.get<ListOfItems<PracticeListItem>>(BASE_PRACTICE_API_URL, { params });

export const createPractice = async (payload: CreatePracticePayload) =>
  await apiClient.post<Practice>(BASE_PRACTICE_API_URL, payload);

export const getPractice = async (practiceId: string) =>
  await apiClient.get<Practice>(`${BASE_PRACTICE_API_URL}/${practiceId}`);

export const updatePractice = async (practiceId: string, payload: UpdatePracticePayload) =>
  await apiClient.patch<Practice>(`${BASE_PRACTICE_API_URL}/${practiceId}`, payload);

export const deletePractice = async (practiceId: string) =>
  await apiClient.delete(`${BASE_PRACTICE_API_URL}/${practiceId}`);
