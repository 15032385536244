import { FC } from 'react';

import { Card } from '@mui/material';

import { getActivateMenuItem } from 'components/MenuPopover';
import {
  EntityHeaderActions,
  EntityHeaderDetails,
  EntityHeaderMainWrapper,
  EntityHeaderRoot,
} from 'components/Widgets';

export const Header: FC = () => {
  return (
    <EntityHeaderRoot>
      <EntityHeaderActions
        activeStatusProps={{ isActive: true }}
        actionMenuItems={[getActivateMenuItem(true, () => {})]}
      />
      <EntityHeaderMainWrapper>
        <EntityHeaderDetails title="Workflow Builder" publishStutusProps={{ isPublished: true }} />
      </EntityHeaderMainWrapper>
    </EntityHeaderRoot>
  );
};
