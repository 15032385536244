import { Box, Grid2 } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { SideNavMenu, SideNavMenuItem } from 'components/SideNavMenu';
import { ROUTES } from 'constants/routes';
import { getBorderStyles } from 'styles';

const sidenavItems: SideNavMenuItem[] = [
  { icon: 'userIcon', label: 'profile', href: ROUTES.profile },
  { icon: 'shieldCheckIcon', label: 'security', href: ROUTES.profileSecurity },
];

export const ProfileLayout: React.FC = () => {
  return (
    <Box component="section" display="flex" flexDirection="column" flex={1}>
      <Grid2 container height={{ xs: 'auto', md: 1 }}>
        <Grid2
          size={{
            xs: 12,
            md: 2,
          }}
        >
          <SideNavMenu sidenavItems={sidenavItems} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 10,
          }}
          sx={theme => getBorderStyles(theme)}
        >
          <Outlet />
        </Grid2>
      </Grid2>
    </Box>
  );
};
