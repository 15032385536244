import { memo } from 'react';

import { Box, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';

type WebViewTableToolbarProps = {
  placeholder: string;
  value: string;
  onChangeInputHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFilterButtonHandler: () => void;
};

export const WebViewTableToolbar: React.FC<WebViewTableToolbarProps> = memo(
  ({ onChangeInputHandler, placeholder, value, onClickFilterButtonHandler }) => {
    return (
      <Box py={1.5} px={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
          <MDInput
            slotProps={{
              input: {
                startAdornment: (
                  <Icon
                    type="searchIcon"
                    sx={{
                      color: 'secondary.main',
                    }}
                  />
                ),
              },
            }}
            sx={{
              '.MuiInputBase-root': {
                height: 46,
              },
            }}
            fullWidth
            size="medium"
            variant="outlined"
            value={value}
            onChange={onChangeInputHandler}
            placeholder={placeholder}
          />

          <MDButton variant="secondary" isIconOnly onClick={onClickFilterButtonHandler} sx={{ minWidth: 49 }}>
            <Icon
              type="filter"
              sx={{
                alignItems: 'center',
                svg: {
                  path: {
                    strokeWidth: 1.5,
                  },
                  width: 20,
                  height: 20,
                },
              }}
            />
          </MDButton>
        </Stack>
      </Box>
    );
  },
);
