import { palette } from '../base/palette';
import typography from '../base/typography';

const { secondary } = palette;
const { size } = typography;

const breadcrumbs = {
  styleOverrides: {
    separator: {
      fontSize: size.bodyMedium,
      color: secondary.darker,
    },
  },
};

export default breadcrumbs;
