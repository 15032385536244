import { CSSObject, ChipProps, alpha } from '@mui/material';

import borders from '../base/borders';
import { palette } from '../base/palette';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
    linkedContent: true;
  }
}

const COLORS: Exclude<ChipProps['color'], 'default'>[] = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

const getDefaultSoftStyles = (ownerState: ChipProps): CSSObject => ({
  backgroundColor: palette.background.surfaceSoft,
  color: palette.text.primary,
  border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
  ...(ownerState.clickable && {
    ':hover': {
      backgroundColor: palette.background.surfaceMedium,
    },
  }),
});

const getSoftColorStyles = (ownerState: ChipProps): CSSObject => {
  if (ownerState.color === 'default' || !ownerState.color) return getDefaultSoftStyles(ownerState);

  if (!COLORS.includes(ownerState.color)) return {};

  const colorValue = palette[ownerState.color]?.main || palette.primary.main;

  return {
    backgroundColor: alpha(colorValue, 0.1),
    color: colorValue,
    border: `${borders.borderWidth[1]} solid ${alpha(colorValue, 0.75)}`,
    ...(ownerState.clickable && {
      ':hover': { backgroundColor: alpha(colorValue, 0.2) },
    }),
  };
};

const getLinkedContentStyles = (): CSSObject => ({
  backgroundColor: 'transparent',
  border: 'none',
});

const rootStyle = (ownerState: ChipProps): CSSObject[] => {
  const isSoft = ownerState.variant === 'soft' || ownerState.variant === 'linkedContent';
  const isLinked = ownerState.variant === 'linkedContent';

  return [
    {
      borderWidth: borders.borderWidth[1],
      fontSize: 14,
      fontWeight: 500,
    },
    isSoft ? getSoftColorStyles(ownerState) : {},
    isLinked ? getLinkedContentStyles() : {},
  ];
};

const chip = {
  styleOverrides: {
    root: ({ ownerState }: { ownerState: ChipProps }) => rootStyle(ownerState),
  },
};

export default chip;
