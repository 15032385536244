import { Box, Divider, Grid2, Typography } from '@mui/material';

import {
  createGLBAalbum,
  create_gl_ba_album_pair,
  uploadGLBAalbumCover,
  upload_image_gl_ba_album_pair,
} from 'apiServices';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyContent } from 'components/EmptyState';
import { PopoverWrapper } from 'components/MenuPopover';
import { TableToolbar } from 'components/Table';
import { PageWrapper } from 'layouts';
import {
  ActivateDeactivateDialogWindow,
  AddAlbumDialogWindow,
  AddBeforeAfterImagesDialogWindow,
  BACardItem,
  BACardItemSkeleton,
  FiltersMenuContent,
} from 'pages/BeforeAfter/components';

import { useGLBeforeAfterListState } from './useGLBeforeAfterList.state';

export const GlobalLibraryBeforeAfterList: React.FC = () => {
  const {
    openAddAlbumDialogWindow,
    closeAddAlbumDialogWindow,
    b_a_gl_data,
    isLoading,
    isFetchMoreLoading,
    lastAlbumCardRef,
    onChangeSearchInputValue,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    appliedFilters,
    onApplyFilters,
    openFilterMenu,
    onDeactivateAlbumHandler,
    onActivateAlbumHandler,
    dialogStates,
    getCloseDialogHandler,
    actionState,
    isActionPending,
    isRoleAdmin,
    getMenuItems,
    onCardClickHandler,
    isOpenAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    isOpenAddAlbumDialogWindow,
    albumId,
    onGetBeforeAfterAlbumsHandler,
    fetchAlbumsHandler,
    onDeleteAlbumHandler,
  } = useGLBeforeAfterListState();

  return (
    <PageWrapper component="div">
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        filterButtonShown={isRoleAdmin}
        actionButtonProps={
          isRoleAdmin && {
            buttonTitle: 'Add album',
            onClick: openAddAlbumDialogWindow,
          }
        }
        isFiltersApplied={false}
      />
      <Divider />

      <Box p={2}>
        <Grid2 container spacing={2} position="relative" height={1}>
          {isLoading ? (
            <BACardItemSkeleton />
          ) : (
            b_a_gl_data.items.map((album, index) => (
              <Grid2
                key={album?.id}
                size={{
                  xs: 12,
                  sm: 6,
                  lg: 3,
                }}
              >
                <BACardItem
                  isActive={album?.is_active}
                  title={album?.name}
                  description={album?.description}
                  coverThumbUrl={album?.cover_thumb_url}
                  onCardClickHandler={onCardClickHandler}
                  pairsCount={album?.pairs_count}
                  id={album?.id}
                  lastAlbumCardRef={b_a_gl_data.items.length - 1 === index ? lastAlbumCardRef : null}
                  getMenuItems={getMenuItems}
                />
              </Grid2>
            ))
          )}
          {!isLoading && !b_a_gl_data.total && (
            <Grid2 size={12}>
              <EmptyContent />
            </Grid2>
          )}

          {isFetchMoreLoading && <BACardItemSkeleton />}
        </Grid2>
      </Box>

      <AddAlbumDialogWindow
        isOpenAddAllbumDialogWindow={isOpenAddAlbumDialogWindow}
        closeAddAlbumDialogWindow={closeAddAlbumDialogWindow}
        uploadAlbumCoverAPIrequest={uploadGLBAalbumCover}
        onFetchAlbumsHandler={onGetBeforeAfterAlbumsHandler}
        createAlbumAPIrequest={createGLBAalbum}
      />

      {isOpenAddAlbumImagesDialogWindow && (
        <AddBeforeAfterImagesDialogWindow
          isOpenAddAllbumDialogWindow={isOpenAddAlbumImagesDialogWindow}
          closeAddAlbumDialogWindow={closeAddAlbumImagesDialogWindow}
          onFetchAlbumsHandler={fetchAlbumsHandler}
          uploadImageAPIrequest={upload_image_gl_ba_album_pair}
          createBAimagePairAPIrequest={create_gl_ba_album_pair}
          albumId={albumId}
        />
      )}

      <ActivateDeactivateDialogWindow
        title="Album"
        actionType={actionState.actionType}
        name={actionState.name}
        isOpen={actionState.actionType === 'activate' ? dialogStates.activate : dialogStates.deactivate}
        isActionPending={isActionPending}
        onClose={
          actionState.actionType === 'activate'
            ? getCloseDialogHandler('activate')
            : getCloseDialogHandler('deactivate')
        }
        onApprove={actionState.actionType === 'activate' ? onActivateAlbumHandler : onDeactivateAlbumHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('delete')}
        open={dialogStates.delete}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            album? This action cannot be undone.
          </Typography>
        }
        title="Delete Album"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete Album"
        onClickApproveButtonHandler={onDeleteAlbumHandler}
      />

      {isRoleAdmin && (
        <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
          <FiltersMenuContent
            appliedFilters={appliedFilters}
            onApplyFilters={onApplyFilters}
            isHideShowDeactived={!isRoleAdmin}
          />
        </PopoverWrapper>
      )}
    </PageWrapper>
  );
};
