import { memo } from 'react';

import { Dialog, DialogContent, Divider, Grid2 } from '@mui/material';

import { DialogHeader } from 'components/BaseDialogWindow';

import { ITEMS } from '../config';
import { GridContentItem } from './GridContentItem';
import { ContentTypePickerDialogWindowProps } from './types';
import './index.css';

export const ContentTypePickerDialogWindow: React.FC<ContentTypePickerDialogWindowProps> = memo(
  ({ open, onClose, onSelectContentTypeHandler, ...other }) => {
    const onClickCloseButtonHandler = (e: React.MouseEvent<HTMLElement>) => onClose(e, 'backdropClick');

    return (
      <Dialog
        fullWidth
        open={open}
        onClose={onClose}
        slotProps={{
          paper: {
            sx: { maxWidth: 780 },
          },
        }}
        {...other}
      >
        <DialogHeader
          sx={{ px: 3, py: 1.5 }}
          onClickCancelButtonHandler={onClickCloseButtonHandler}
          title="Select Content Type"
          isHideDivider
        />
        <Divider />

        <DialogContent>
          <Grid2 container spacing={1.5}>
            {ITEMS.map(({ label, type }) => (
              <GridContentItem
                key={type}
                onSelectContentTypeHandler={onSelectContentTypeHandler}
                label={label}
                type={type}
              />
            ))}
          </Grid2>
        </DialogContent>
      </Dialog>
    );
  },
);
