import { useCallback, useState } from 'react';

import { FieldValues, useController } from 'react-hook-form';

import { MDInput, MDInputProps } from 'components/MDInput';
import { NON_DIGIT_REGEX } from 'constants/regex';

import { RHFCommonProps } from './types';

type RHFTextFieldProps<TField extends FieldValues> = MDInputProps &
  Pick<RHFCommonProps<TField>, 'control' | 'registerName'>;

const formatSSN = (value: string) => {
  if (!value) return '';

  const raw = value.replace(NON_DIGIT_REGEX, '');

  if (raw.length <= 3) return raw;
  if (raw.length <= 5) return `${raw.slice(0, 3)}-${raw.slice(3)}`;
  return `${raw.slice(0, 3)}-${raw.slice(3, 5)}-${raw.slice(5, 9)}`;
};

const getRawSSN = (value: string) => value?.replace(NON_DIGIT_REGEX, '');

export const RHFssnTextField = <TField extends FieldValues>(
  props: RHFTextFieldProps<TField> & {
    ssnValue: string;
  },
) => {
  const {
    registerName,
    label = 'Social Security Number',
    size = 'medium',
    required,
    placeholder = 'XXX-XX-XXXX',
    ssnValue,
    control,
    ...rest
  } = props;

  const [displayValue, setDisplayValue] = useState(formatSSN(ssnValue));

  const {
    field,
    fieldState: { error },
  } = useController({ name: registerName, control });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = getRawSSN(e.target.value) || null;
      const formattedValue = formatSSN(rawValue);

      setDisplayValue(formattedValue);

      e.target.value = rawValue;

      field.onChange(e);
    },
    [registerName],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const rawValue = getRawSSN(e.target.value) || null;
      const formattedValue = formatSSN(rawValue);

      setDisplayValue(formattedValue);

      e.target.value = rawValue;

      field.onBlur();
    },
    [registerName],
  );

  return (
    <MDInput
      {...field}
      label={label}
      required={required}
      size={size}
      value={displayValue}
      onBlur={handleBlur}
      onChange={handleChange}
      error={!!error?.message}
      helperText={error?.message}
      slotProps={{
        htmlInput: {
          maxLength: 11,
        },
      }}
      placeholder={placeholder}
      {...rest}
    />
  );
};
