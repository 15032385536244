import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { RHFTextField } from 'components/HookForm';
import { MDButton } from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { VERIFY_TOTP_CODE_TITLES } from 'constants/strings';
import { BackButton, IllustrationLayout } from 'layouts';
import { AUTH_STEPS, VerifyTOTPcodeStep } from 'pages/SignIn';

import { RecentlyLoggedInUserItem } from './RecentlyLoggedInUserItem';
import { useRecentlyLoggedInUsersSignInState } from './useRecentlyLoggedInUsersSignIn.state';

export const RecentlyLoggedInUsersSignIn: FC = () => {
  const {
    register,
    authStep,
    errors,
    isValid,
    isSubmitting,
    resetAuthStep,
    onFormSubmitHandler,
    getCognitoCurrentUser,
    getExpertProfileHandler,
    selectedRecentlyLoggedInUser,
    removeRecentlyLoggedInUser,
  } = useRecentlyLoggedInUsersSignInState();

  if (!selectedRecentlyLoggedInUser) return null;

  return (
    <>
      {authStep === AUTH_STEPS.SIGN_IN && (
        <IllustrationLayout title="Sign In" backButton={<BackButton title="Back" />}>
          <Box component="form" onSubmit={onFormSubmitHandler}>
            <Stack spacing={2}>
              <Box
                sx={{
                  borderRadius: theme => theme.borders.borderRadius[8],
                  p: 1.5,
                  bgcolor: theme => theme.palette.background.surfaceSoft,
                }}
              >
                <Stack gap={1}>
                  <RecentlyLoggedInUserItem email={selectedRecentlyLoggedInUser?.email} />
                </Stack>
              </Box>

              <RHFTextField
                register={register}
                registerName="password"
                registerErrors={errors?.password?.message}
                type="password"
                label="Password"
                placeholder="Enter your password"
                fullWidth
                isPasswordField
              />

              <MDButton
                type="submit"
                fullWidth
                color="primary"
                disabled={isSubmitting || !isValid}
                isLoading={isSubmitting}
              >
                Sign In
              </MDButton>

              <Box display="flex" justifyContent="flex-end">
                <Typography
                  component={Link}
                  to={ROUTES.forgotPassword}
                  variant="caption"
                  sx={{
                    color: 'primary.light',
                    ':hover': { opacity: 0.75 },
                  }}
                  fontWeight="500"
                >
                  Forgot Password?
                </Typography>
              </Box>
            </Stack>
          </Box>
        </IllustrationLayout>
      )}
      {authStep === AUTH_STEPS.VERIFY_TOTP_CODE && (
        <IllustrationLayout
          title={VERIFY_TOTP_CODE_TITLES.title}
          description={VERIFY_TOTP_CODE_TITLES.description}
          backButton={<BackButton title={VERIFY_TOTP_CODE_TITLES.backButton} onClick={resetAuthStep} />}
        >
          <VerifyTOTPcodeStep
            getExpertProfileHandler={getExpertProfileHandler}
            getCognitoCurrentUser={getCognitoCurrentUser}
            onSuccessfulConfirmSignInHandler={removeRecentlyLoggedInUser}
          />
        </IllustrationLayout>
      )}
    </>
  );
};
