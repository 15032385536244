import { BackdropBlursTypes } from 'types/mui-theme';

const generateBlurValue = (blurValue: number): string => `blur(${blurValue}px)`;

export const backdropBlurs: BackdropBlursTypes = {
  sm: generateBlurValue(4),
  md: generateBlurValue(8),
  lg: generateBlurValue(16),
  xl: generateBlurValue(40),
};
