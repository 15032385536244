import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import { updatePatient } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useRouter, useScrollSections } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { EditMedicalFormSchema, editMedicalValidationSchema } from './components';
import { ALLERGIES_ID, GENERAL_ID, LIFESTYLE_ID, MEDICAL_CONDITIONS_ID } from './constants';
import { usePatientStore } from '../../usePatientStore';

const SCROLL_SECTIONS = [
  { id: GENERAL_ID, label: 'General', priority: 1 },
  { id: LIFESTYLE_ID, label: 'Lifestyles', priority: 2 },
  { id: ALLERGIES_ID, label: 'Allegries', priority: 3 },
  { id: MEDICAL_CONDITIONS_ID, label: 'Medical Conditions', priority: 4 },
] as const;

export const useMedicalState = () => {
  const { params } = useRouter();

  const patientId = params?.id;

  const { fetchPatient, patient } = usePatientStore();

  const formMethods = useForm<EditMedicalFormSchema>({
    resolver: yupResolver(editMedicalValidationSchema),
    mode: 'onTouched',
    values: {
      smoking: patient?.smoking,
      alcohol_consumption: patient?.alcohol_consumption,
      biological_sex: patient?.biological_sex,
      blood_type: patient?.blood_type,
      pain_tolerance: patient?.pain_tolerance,
    },
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    setError,
    handleSubmit,
    reset,
  } = formMethods;

  const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;

  const onFormSubmitHandler = handleSubmit(
    async ({ alcohol_consumption, biological_sex, blood_type, pain_tolerance, smoking }) => {
      try {
        await updatePatient(patientId, {
          ...(alcohol_consumption && { alcohol_consumption }),
          ...(biological_sex && { biological_sex }),
          ...(blood_type && { blood_type }),
          ...(pain_tolerance && { pain_tolerance }),
          ...(smoking && { smoking }),
        });

        await fetchPatient(patientId);

        notice(ToastType.SUCCESS, 'Patient profile has been successfully updated!');
        reset();
      } catch (error) {
        formErrorHandler({
          error,
          config: { formError: { setError: setError } },
          customErrorMessage: 'Failed to update patient profile, please try again!',
        });
      }
    },
  );

  const { generateSideNavItems, activeSection, onUpdateActiveSection } = useScrollSections({
    sections: SCROLL_SECTIONS,
  });

  const { ref: generalRef, entry: generalEntry } = useInView({
    initialInView: true,
    onChange: onUpdateActiveSection,
  });

  const { ref: lifestyleRef, entry: lifestyleEntry } = useInView({
    onChange: onUpdateActiveSection,
  });

  const { ref: allergiesRef, entry: allergiesEntry } = useInView({
    onChange: onUpdateActiveSection,
  });

  const { ref: medicalConditionsRef, entry: medicalConditionsEntry } = useInView({
    onChange: onUpdateActiveSection,
  });

  const sectionEntries = {
    [GENERAL_ID]: generalEntry,
    [LIFESTYLE_ID]: lifestyleEntry,
    [ALLERGIES_ID]: allergiesEntry,
    [MEDICAL_CONDITIONS_ID]: medicalConditionsEntry,
  };

  const sidenavItems = useMemo(
    () => generateSideNavItems(sectionEntries),
    [generalEntry, lifestyleEntry, allergiesEntry, medicalConditionsEntry],
  );

  return {
    medicalConditionsRef,
    allergiesRef,
    formMethods,
    generalRef,
    activeSection,
    lifestyleRef,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    patientId,
    fetchPatient,
    isDirty,
    patient,
    sidenavItems,
  };
};
