import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { size } = typography;
const { text, secondary } = palette;
const { borderWidth } = borders;

const dialogContent = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.bodyLarge,
      color: text.primary,
    },

    dividers: {
      borderTop: `${borderWidth[1]} solid ${secondary.main}`,
      borderBottom: `${borderWidth[1]} solid ${secondary.main}`,
    },
  },
};

export default dialogContent;
