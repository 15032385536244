import Stack from '@mui/material/Stack';

import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ScrollableSideNavMenu } from 'components/SideNavMenu';

import { PatientDemographicsContent, PatientContactContent } from './components';
import { useProfileState } from './useProfile.state';

export const Profile: React.FC = () => {
  const {
    formMethods,
    activeSection,
    contactRef,
    demographicsRef,
    sidenavItems,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    isDirty,
  } = useProfileState();

  return (
    <Stack height={1} width={1} direction={{ xs: 'column', md: 'row' }}>
      <ScrollableSideNavMenu activeSectionId={activeSection} sidenavItems={sidenavItems} />

      <FormProvider isBorders formProps={{ height: 1 }} methods={formMethods} onSubmit={onFormSubmitHandler}>
        <PatientDemographicsContent cardRef={demographicsRef} />

        <PatientContactContent cardRef={contactRef} />

        <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
      </FormProvider>
    </Stack>
  );
};
