import { FC } from 'react';

import { Chip, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { dateToCustomFormat } from 'utils/helpers';

import { DetailItem } from '../../../../../components';

export const RelatedVisitDetailsSection: FC = () => {
  const formattedDate = dateToCustomFormat('12-20-2024');

  return (
    <Stack>
      <DetailItem icon={<Icon type="calendar" />} label="Date" value={formattedDate} />

      <DetailItem icon={<Icon type="userIcon" />} label="Provider" value="Dr. Michael Lee" />

      <DetailItem
        icon={<Icon type="boltIcon" />}
        label="Severity"
        value={<Chip variant="soft" label="Severe" color="warning" />}
      />

      <DetailItem
        icon={<Icon type="flagIcon" />}
        label="Priority"
        value={
          <Stack alignItems="center" direction="row" gap={1} color="error.main">
            <Icon
              type="flagIcon"
              sx={{
                color: 'error.main',
                svg: {
                  fill: theme => theme.palette.error.main,
                },
              }}
            />
            <Typography variant="button" fontWeight={500}>
              Primary
            </Typography>
          </Stack>
        }
      />

      <DetailItem icon={<Icon type="userSpeakIcon" />} label="Visit Type" value="In Person" />

      <DetailItem
        icon={<Icon type="stethoscopeIcon" />}
        label="Interaction type"
        value="Routine Check-Up/Physical Exam"
      />

      <DetailItem icon={<Icon type="settingsIcon" />} label="Workflow" value="Follow-up Visit" />
    </Stack>
  );
};
