import { ListItemButton, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICON, SIDE_NAVIGATION } from 'constants/layout-config';

import { INavItemProps } from './navItem.types';

type TStyledItemProps = Omit<INavItemProps, 'item'> & {
  caption?: boolean;
  disabled?: boolean;
};

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'caption' && prop !== 'isNavMini',
})<TStyledItemProps>(({ active, disabled, depth, caption, theme, isNavMini }) => {
  const subItem = depth !== 1;

  const activeStyle = {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.surfaceMedium,
  };

  const activeSubStyle = {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  };

  return {
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    height: SIDE_NAVIGATION.H_DASHBOARD_ITEM,
    ...(isNavMini && {
      justifyContent: 'center',
    }),
    ...(subItem && {
      height: SIDE_NAVIGATION.H_DASHBOARD_SUB_ITEM,
      ...(depth > 2 && {
        paddingLeft: theme.spacing(depth),
      }),
      ...(caption && {
        height: SIDE_NAVIGATION.H_DASHBOARD_ITEM,
      }),
    }),
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeStyle,
      },
    }),
    ...(subItem &&
      active && {
        ...activeSubStyle,
      }),
    ...(disabled && {
      '&.Mui-disabled': {
        opacity: 0.64,
      },
    }),
  };
});

export const StyledIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isNavMini',
})<StyledDotIconProps>(({ active, theme, isNavMini }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: ICON.NAV_ITEM,
  height: ICON.NAV_ITEM,
  svg: {
    width: ICON.NAV_ITEM,
    height: ICON.NAV_ITEM,
  },
  minWidth: 'auto',
  marginRight: '10px',
  ...(active && {
    svg: {
      color: theme.palette.primary.main,
    },
  }),
  ...(isNavMini && {
    marginRight: 0,
  }),
}));

type StyledDotIconProps = {
  active?: boolean;
  isNavMini?: boolean;
};

export const StyledDotIcon = styled('span', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isNavMini',
})<StyledDotIconProps>(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: 'scale(2)',
    backgroundColor: theme.palette.primary.main,
  }),
}));
