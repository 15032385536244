import { Grid2 } from '@mui/material';

import { FeedbackContent, OptionsContent, PromptContent } from './components';
import { useTestingTabContentState } from './useTestingTabContent.state';

export const TestingTabContent: React.FC = () => {
  const {
    isLoadingPromptText,
    userPromptText,
    promptResponse,
    isFeedback,
    getUserPromptText,
    executePromptHandler,
    submitFeedbackHandler,
  } = useTestingTabContentState();

  return (
    <Grid2 container flex={{ md: 0, lg: 1 }} flexWrap={{ xs: 'wrap-reverse', lg: 'wrap' }}>
      <Grid2
        size={{
          xs: 12,
          lg: 8,
        }}
        flex={1}
        sx={({ breakpoints, borders, palette }) => ({
          borderRight: `${borders.borderWidth[1]} solid ${palette.secondary.main}`,
          [breakpoints.down('lg')]: {
            borderRight: 0,
            borderTop: `${borders.borderWidth[1]} solid ${palette.secondary.main}`,
          },
        })}
      >
        <PromptContent
          isLoadingPromptText={isLoadingPromptText}
          promptText={promptResponse?.result || userPromptText?.text}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          lg: 4,
        }}
      >
        {isFeedback ? (
          <FeedbackContent submitFeedbackHandler={submitFeedbackHandler} />
        ) : (
          <OptionsContent
            getUserPromptText={getUserPromptText}
            executePromptHandler={executePromptHandler}
            isLoadingPromptText={isLoadingPromptText}
          />
        )}
      </Grid2>
    </Grid2>
  );
};
