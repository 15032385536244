import { Box, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';

import { StylesSx } from './TableToolbar.styles';
import { ButtonProps, TableFiltersPanelProps } from './types';

const ActionButton = ({ buttonTitle, sx, ...props }: ButtonProps) => (
  <MDButton startIcon={<Icon type="add" />} sx={{ ...StylesSx.actionButton, ...sx }} {...props}>
    {buttonTitle}
  </MDButton>
);

const renderButtonSection = (actionButtonProps: ButtonProps, secondaryActionButtonProps: ButtonProps) => (
  <>
    {(actionButtonProps || secondaryActionButtonProps) && (
      <Stack direction="row" alignItems="center" gap={1}>
        {actionButtonProps && <ActionButton {...actionButtonProps} />}
        {secondaryActionButtonProps && <ActionButton {...secondaryActionButtonProps} />}
      </Stack>
    )}
  </>
);

export const TableToolbar: React.FC<TableFiltersPanelProps> = ({
  onOpenFilterMenuHandler,
  actionButtonProps,
  secondaryActionButtonProps,
  searchInputProps,
  isFiltersApplied,
  tabs,
  ...boxProps
}) => {
  return (
    <Box py={1.5} {...boxProps}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 2, sm: 1 }}
      >
        <MDInput
          fullWidth
          slotProps={{
            input: {
              startAdornment: <Icon type="searchIcon" />,
            },
          }}
          sx={{ ...StylesSx.searchInput, ...searchInputProps?.sx }}
          fieldWrapperProps={{
            maxWidth: 300,
          }}
          {...searchInputProps}
        />

        {tabs && (
          <Stack direction="row" alignItems="center">
            {tabs}
          </Stack>
        )}

        <Stack direction="row" alignItems="center" spacing={1}>
          {onOpenFilterMenuHandler && (
            <MDButton variant="secondary" isIconOnly sx={StylesSx.filterButton} onClick={onOpenFilterMenuHandler}>
              <Icon type="filter" sx={StylesSx.filterIcon} />
              {isFiltersApplied && <Box component="span" ml={0.5} sx={StylesSx.appliedFilterDot} />}
            </MDButton>
          )}

          {renderButtonSection(actionButtonProps, secondaryActionButtonProps)}
        </Stack>
      </Stack>
    </Box>
  );
};
