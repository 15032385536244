import { InputAdornment, TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-material-ui';

import { Icon } from 'components/Icon';
import { MDInput, MDInputPhoneRoot } from 'components/MDInput';
import { LABELS } from 'constants/strings';
import { genericMemo } from 'utils/helpers';

import 'react-phone-input-material-ui/lib/bootstrap.css';

type RHFPhoneTextFieldProps<TField extends FieldValues> = Omit<PhoneInputProps, 'component'> & {
  control: Control<TField>;
  registerOptions?: RegisterOptions<TField, any>;
  registerName: Path<TField>;
  registerErrors: FieldErrors<TField> | Merge<FieldError, FieldErrorsImpl>;
  isPasswordField?: boolean;
  MUITextFieldProps?: TextFieldProps;
};

export const RHFPhoneTextField = genericMemo(
  <TField extends FieldValues>({
    value,
    label = LABELS.PHONE,
    onChange,
    country = 'us',
    // disabled,
    MUITextFieldProps,
    control,
    registerErrors,
    registerName,
    registerOptions,
    placeholder = 'Enter Phone Number',
    ...rest
  }: RHFPhoneTextFieldProps<TField>): React.JSX.Element => {
    const errorMessage = registerName.includes('.')
      ? registerErrors?.[registerName.split('.')?.[1]]?.message
      : registerErrors?.[registerName]?.message;

    return (
      <MDInputPhoneRoot isDisabled={rest.disabled} isLabel={!!label}>
        <Controller
          control={control}
          name={registerName}
          render={({ field, fieldState: { error } }) => {
            return (
              <ReactPhoneInput
                label={label}
                placeholder={placeholder}
                {...field}
                country={country}
                value={field.value || `+1`}
                preferredCountries={['us']}
                inputProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                  label,
                  helperText: errorMessage,
                  error: !!error,
                  ...MUITextFieldProps,
                }}
                enableSearch={false}
                disabled={field.disabled}
                component={props => (
                  <MDInput
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon type="phoneIcon" />
                          </InputAdornment>
                        ),
                      },
                    }}
                    {...props}
                  />
                )}
                countryCodeEditable={false}
                disableDropdown={true}
                {...rest}
              />
            );
          }}
        />
      </MDInputPhoneRoot>
    );
  },
);
