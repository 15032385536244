import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { MDChip } from 'components/MDChip';
import { ThreeDotsTableCell } from 'components/Table';
import { usePopover, useRouter } from 'hooks';

type ConsultationTableRowProps = {
  id: string;
};

export const ConsultationTableRow: FC<ConsultationTableRowProps> = memo(({ id }) => {
  const { navigate, params } = useRouter();

  const onClickRowHandler = () => {
    if (!params?.id) return;

    navigate(`/patients/${params.id}/consultation/${id}`);
  };

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleClosePopover();
  };

  const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    handleOpenPopover(event);
  };

  const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();

    handleClosePopover();
  };

  return (
    <TableRow onClick={onClickRowHandler}>
      <TableCell>April 12, 2024 4:15-4:30PM</TableCell>

      <TableCell>
        <MDChip size="small" variant="soft" color="primary" isSquare label="On-going" />
      </TableCell>

      <TableCell>Dr. Sarah Johnson</TableCell>

      <TableCell>Initial Consultation</TableCell>

      <ThreeDotsTableCell
        open={openPopover}
        onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
        onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
        onClose={onCloseThreeDotsMenuHandler}
        menuItems={[]}
      />
    </TableRow>
  );
});
