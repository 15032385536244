import { apiClient } from 'lib/api';
import { ListOfItems, ImportToPLPayload } from 'types';

import { CreatePLServicePayload, PLService, PLServicesSearchParams, UpdatePLServicePayload } from './types';

const BASE_URL = '/practice/services';

export const getPLServices = async (params: PLServicesSearchParams) =>
  await apiClient.get<ListOfItems<PLService>>(BASE_URL, { params });

export const createPLService = async (payload: CreatePLServicePayload) =>
  await apiClient.post<PLService>(BASE_URL, payload);

export const importToPLService = async (payload: ImportToPLPayload) =>
  await apiClient.post<PLService>(`${BASE_URL}/import`, payload);

export const getPLService = async (serviceId: string) => await apiClient.get<PLService>(`${BASE_URL}/${serviceId}`);

export const updatePLService = async (id: string, payload: UpdatePLServicePayload) =>
  await apiClient.patch<PLService>(`${BASE_URL}/${id}`, payload);

// export const deactivatePLService = async (id: string) =>
//   await apiClient.delete<PLService>(`${BASE_URL}/${id}/deactivate`);

// export const activatePLService = async (id: string) => await apiClient.patch<PLService>(`${BASE_URL}/${id}/activate`);

export const deletePLService = async (serviceId: string) => await apiClient.delete(`${BASE_URL}/${serviceId}`);
