import { AxiosError } from 'axios';

import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES } from 'constants/strings';

import { APIerror, ErrorLocation } from './types';

type BaseBackendErrorHandlerConfig = {
  customErrorHandler?: (error: unknown) => void;
  customErrorMessage?: string;
};

const ERROR_LOCATION: ErrorLocation[] = ['body', 'query', 'path'];

export const backendErrorHandler = ({ error, config }: { error: unknown; config?: BaseBackendErrorHandlerConfig }) => {
  const { customErrorHandler, customErrorMessage } = config || {};

  console.error(error);

  if (!(error instanceof AxiosError)) return notice(ToastType.ERROR, ERROR_MESSAGES.smt_went_wrong);

  const errorResponse = error?.response?.data as APIerror;

  if (!errorResponse || !(typeof errorResponse === 'object' && 'detail' in errorResponse)) {
    notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
    return customErrorHandler?.(error);
  }

  if (error?.response?.status === 422 && Array.isArray(errorResponse.detail)) {
    errorResponse.detail.forEach(err => {
      const errorLocation = err?.loc?.[0];
      const isValidLocation = ERROR_LOCATION.includes(errorLocation);

      if (isValidLocation) return notice(ToastType.ERROR, err.msg || '');
    });
    return customErrorHandler?.(error);
  }

  if (typeof errorResponse.detail === 'string') {
    notice(ToastType.ERROR, errorResponse.detail);
    return customErrorHandler?.(error);
  } else {
    notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
    return customErrorHandler?.(error);
  }
};
