import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { size, fontWeightRegular } = typography;

const formControlLabel = {
  defaultProps: {
    sx: {
      textTransform: 'capitalize',
    },
  },
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginRight: 0,
      minHeight: pxToRem(24),
      marginBottom: pxToRem(2),
    },

    labelPlacementStart: {
      '.MuiFormControlLabel-label': {
        marginRight: 6,
      },
    },

    labelPlacementEnd: {
      '.MuiFormControlLabel-label': {
        marginLeft: 6,
      },
    },

    label: {
      display: 'inline-block',
      fontSize: size.bodyMedium,
      fontWeight: fontWeightRegular,
      lineHeight: 1,
    },
  },
};

export default formControlLabel;
