import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { useRouter } from 'hooks';
import { dateToCustomFormat } from 'utils/helpers';

type FormsTableRowProps = {
  title: string;
  provider: string;
  lastUpdatedAt: string;
  id: string;
};

export const FormsTableRow: FC<FormsTableRowProps> = memo(({ lastUpdatedAt, provider, title, id }) => {
  const { navigate, params } = useRouter();

  const onClickRowHandler = () => {
    if (!params?.id) return;

    navigate(`/patients/${params.id}/forms/${id}`);
  };

  const formattedLastUpdatedAt = dateToCustomFormat(lastUpdatedAt);

  return (
    <TableRow onClick={onClickRowHandler}>
      <TableCell>{title}</TableCell>

      <TableCell>{provider}</TableCell>

      <TableCell>{formattedLastUpdatedAt}</TableCell>
    </TableRow>
  );
});
