import Skeleton from '@mui/material/Skeleton';

export const SideNavMenuSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton component="li" variant="rounded" sx={{ minHeight: 25, width: 1 }} />

      <Skeleton component="li" variant="rounded" sx={{ minHeight: 25, width: '70%' }} />

      <Skeleton component="li" variant="rounded" sx={{ minHeight: 25, width: '50%' }} />
    </>
  );
};
