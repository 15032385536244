import { FC } from 'react';

import { Box, Link, Stack, Typography } from '@mui/material';

const LINKS = [
  { title: 'Terms & Conditions', href: 'https://www.aesthetics360.com/terms-and-conditions' },
  { title: 'Privacy Policy', href: 'https://www.aesthetics360.com/privacy-policy' },
  { title: 'Cookies Policy', href: 'https://www.aesthetics360.com/cookie-policy' },
];

export const Footer: FC = () => {
  const year = new Date().getFullYear();
  return (
    <Box
      width={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      sx={{ textAlign: 'center' }}
    >
      <Stack direction="row">
        {LINKS.map((link, index) => (
          <Stack key={link.title} direction="row" alignItems="center">
            <Box
              fontWeight="fontWeightRegular"
              fontSize="size.bodySmall"
              component={Link}
              target="_blank"
              href={link.href}
              sx={{
                ':hover': {
                  opacity: 0.75,
                },
              }}
            >
              {link.title}
            </Box>
            {index !== LINKS.length - 1 && (
              <Box
                component="span"
                sx={{ mx: 1.5, width: 5, height: 5, bgcolor: theme => theme.palette.text.primary, borderRadius: '50%' }}
              />
            )}
          </Stack>
        ))}
      </Stack>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        width={1}
        sx={{ fontSize: 14 }}
      >
        <Typography variant="caption" fontWeight={400} fontSize={10} color="text.secondary">
          &copy; {year} Aesthetics360, LLC. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
