import { FC, memo, useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';

import { uploadPatientAvatar, deletePatientAvatar } from 'apiServices';
import { Icon } from 'components/Icon';
import { MDTabsProps } from 'components/MDTabs';
import { EnitityHeaderTabsSkeleton, EntityHeader, StickyTabs } from 'components/Widgets';
import { useUserProfile } from 'hooks';

import { PATIENT_TABS } from '../types';

export const PATIENT_PROFILE_TABS = [
  PATIENT_TABS.PROFILE,
  PATIENT_TABS.MEDICAL,
  PATIENT_TABS.SUMMARY,
  PATIENT_TABS.CONCERNS,
  PATIENT_TABS.CONSULTATIONS,
  PATIENT_TABS.FORMS,
];

export const WEB_VIEW_PATIENT_PROFILE_TABS = [
  PATIENT_PROFILE_TABS[0],
  PATIENT_PROFILE_TABS[2],
  PATIENT_PROFILE_TABS[4],
];

type PatientProfileHeaderProps = Pick<MDTabsProps, 'value' | 'handleSetTabValue'> & {
  isWebView: boolean;
  isLoading: boolean;
  patientId: string;
  patientName: string;
  patientInfo: string;
  patientPhotoUrl: string;
  patientAvatarUrl: string;
  fetchPatient: (patientId: string) => Promise<void>;
};

export const PatientProfileHeader: FC<PatientProfileHeaderProps> = memo(
  ({
    isWebView,
    patientId,
    patientName,
    handleSetTabValue,
    value,
    isLoading,
    patientInfo,
    patientPhotoUrl,
    patientAvatarUrl,
    fetchPatient,
  }) => {
    const {
      userRoles: { isRoleAdmin, isRolePracticeAdmin },
      userId,
    } = useUserProfile();

    const isCanEditAvatar = !isWebView && (isRoleAdmin || isRolePracticeAdmin || userId === patientId);

    const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);

    const onUploadAvatarHandler = useCallback(
      async (formData: FormData, croppedImageSrc: string) => {
        setCroppedImageSrc(croppedImageSrc);
        await uploadPatientAvatar(patientId, formData);
        await fetchPatient(patientId);
      },
      [patientId],
    );

    const onDeleteAvatarHandler = async () => {
      await deletePatientAvatar(patientId);

      croppedImageSrc && setCroppedImageSrc(null);
      await fetchPatient(patientId);
    };

    const avatarSrc = croppedImageSrc || patientAvatarUrl || '';

    return (
      <>
        {isWebView && isLoading && (
          <EnitityHeaderTabsSkeleton isWebView tabsAmount={WEB_VIEW_PATIENT_PROFILE_TABS.length} />
        )}

        {isWebView && !isLoading && (
          <StickyTabs
            isWebView
            tabsData={WEB_VIEW_PATIENT_PROFILE_TABS}
            value={value}
            handleSetTabValue={handleSetTabValue}
          />
        )}

        {(isWebView && value === 0) || !isWebView ? (
          <EntityHeader
            isLoading={isLoading}
            skeletonProps={
              !isWebView && {
                isBackButton: true,
                tabsAmount: 4,
              }
            }
            title={patientName}
            subtitle={
              <>
                <Icon
                  type="calendar"
                  sx={{
                    color: theme => theme.palette.text.secondary,
                  }}
                />

                <Typography variant="body2" color="textSecondary">
                  {patientInfo}
                </Typography>
              </>
            }
            id={patientId}
            avatarProps={{
              src: avatarSrc,
              initialPhotoUrl: patientPhotoUrl || '',
              onCreateFileHandler: onUploadAvatarHandler,
              onDeleteFileHandler: onDeleteAvatarHandler,
              isDisabled: !isCanEditAvatar,
            }}
            tabs={
              !isWebView && {
                tabsData: PATIENT_PROFILE_TABS,
                value,
                handleSetTabValue,
              }
            }
            isWebView={isWebView}
          />
        ) : null}
      </>
    );
  },
);
