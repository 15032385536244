import { AutocompleteRenderOptionState, Box, Checkbox } from '@mui/material';

import { UserRolesEnum } from 'apiServices';
import { MDUserRoleChip } from 'components/MDChip';

export const UserRoleRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement> & { key: string },
  option: UserRolesEnum,
  ownerState: AutocompleteRenderOptionState,
) => {
  const { key, ...otherProps } = props;
  return (
    <Box component="li" key={key} {...otherProps} gap={1}>
      <Checkbox checked={ownerState.selected} />
      <MDUserRoleChip size="small" userRole={option.value} />
    </Box>
  );
};
