import { FC } from 'react';

import { Stack } from '@mui/material';

import { FeedbackCollectionResponse } from 'apiServices';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyContent } from 'components/EmptyState';
import { Scrollbar } from 'components/ScrollBar';

import { EvaluationHistoryCard, EvaluationHistoryCardSkeleton } from './components';
import { useEvaluationHistoryTabContentState } from './useEvaluationHistoryTabContent.state';

export const EvaluationHistoryTabContent: FC = () => {
  const {
    evaluationHistory,
    isLoading,
    handleDeleteEvaluationItem,
    isActionPending,
    dialogStates,
    getCloseDialogHandler,
    onClickActionOptionHandler,
  } = useEvaluationHistoryTabContentState();

  const MOCKED_EVALUATION_HISTORY: FeedbackCollectionResponse[] = [
    {
      account_id: 'acc_12345',
      accuracy_stars: 4,
      ai_model_name: 'GPT-4',
      coherence_stars: 5,
      comment: 'The response was very clear and helpful.',
      created_at: '2025-03-19T10:00:00Z',
      id: 1,
      input_data: 'Patient reported severe headaches.',
      model_response: 'Consider a CT scan to rule out neurological issues.',
      patient_id: 'pat_67890',
      practice_id: 'prac_54321',
      preferred_output: null,
      prompt_id: 101,
      relevancy_stars: 4,
      used_parameters: ['headache', 'severity: high'],
      cognito_username: 'user1@example.com',
    },
    {
      account_id: null,
      accuracy_stars: 3,
      ai_model_name: 'BERT',
      coherence_stars: 3,
      comment: null,
      created_at: '2025-03-18T15:30:00Z',
      id: 2,
      input_data: 'Patient has a history of diabetes.',
      model_response: 'Monitor blood sugar levels and adjust insulin dosage.',
      patient_id: 'pat_12345',
      practice_id: null,
      preferred_output: 'Recommend a follow-up with an endocrinologist.',
      prompt_id: 102,
      relevancy_stars: 2,
      used_parameters: null,
      cognito_username: 'user2@example.com',
    },
  ];

  return (
    <>
      <Stack flex={1} sx={{ position: 'relative', overflowY: 'auto' }}>
        <Stack
          sx={{
            position: 'absolute',
            width: 1,
            height: 1,
            top: 0,
            left: 0,
            overflow: 'hidden',
            borderRadius: ({ borders }) => borders.borderRadius[8],
          }}
        >
          {isLoading ? (
            <Scrollbar
              sx={{
                height: 1,
              }}
            >
              <EvaluationHistoryCardSkeleton />
              <EvaluationHistoryCardSkeleton />
              <EvaluationHistoryCardSkeleton isDividerVisible={false} />
            </Scrollbar>
          ) : MOCKED_EVALUATION_HISTORY?.length ? (
            <Scrollbar
              sx={{
                height: 1,
              }}
            >
              {MOCKED_EVALUATION_HISTORY?.map(history => (
                <EvaluationHistoryCard
                  key={history?.id}
                  {...history}
                  onClickActionOptionHandler={onClickActionOptionHandler}
                  lastCardRef={null}
                />
              ))}
            </Scrollbar>
          ) : (
            <EmptyContent justifyContent="center" />
          )}
        </Stack>
      </Stack>

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('delete')}
        open={dialogStates.delete}
        description="Are you sure you want to delete prompt? This action cannot be undone."
        title="Delete Prompt"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete Prompt"
        onClickApproveButtonHandler={handleDeleteEvaluationItem}
      />
    </>
  );
};
