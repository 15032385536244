import { FC, memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { GLServicesSearchParams, createGLService } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import {
  CreateCatalogGLItemFormSchema,
  createCatalogGLItemDefaultValues,
  createCatalogGLItemValidationSchema,
} from 'pages/Catalog/config';
import { formErrorHandler } from 'utils/errorHanders';

type AddServiceDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetServicesHandler: (params: GLServicesSearchParams) => Promise<void>;
};

export const AddServiceDialogWindowForm: FC<AddServiceDialogWindowFormProps> = memo(
  ({ onGetServicesHandler, onCloseDialogHandler }) => {
    const {
      register,
      handleSubmit,
      setError,
      formState: { isValid, isSubmitting, errors },
    } = useForm<CreateCatalogGLItemFormSchema>({
      resolver: yupResolver(createCatalogGLItemValidationSchema) as Resolver<CreateCatalogGLItemFormSchema>,
      mode: 'onTouched',
      defaultValues: createCatalogGLItemDefaultValues,
    });

    const onFormSubmitHandler = handleSubmit(async ({ title, description }) => {
      try {
        await createGLService({ title, ...(description && { description }) });

        onCloseDialogHandler();
        notice(ToastType.SUCCESS, 'Service has been successfully created!');

        await onGetServicesHandler({ page: 1 });
      } catch (error) {
        formErrorHandler({ error, config: { formError: { setError } } });
      }
    });

    const isSubmitButtonDisabled = !isValid || isSubmitting;

    return (
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Service', onClickCancelButtonHandler: onCloseDialogHandler }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
          cancelButtonProps: { children: 'Cancel' },
        }}
      >
        <Scrollbar>
          <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
            <Stack
              gap={2}
              sx={{
                '.MuiStack-root': {
                  width: 1,
                },
              }}
            >
              <RHFTextField
                register={register}
                registerName="title"
                registerErrors={errors?.title?.message}
                fullWidth
                required
                label="Name"
                placeholder="Enter name"
              />

              <RHFTextField
                register={register}
                registerName="description"
                registerErrors={errors?.description?.message}
                fullWidth
                multiline
                maxRows={8}
                minRows={6}
                placeholder="Enter description"
                label="Description"
              />
            </Stack>
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    );
  },
);
