import * as yup from 'yup';

import { UpdateExpertPayload } from 'apiServices';
import { LABELS } from 'constants/strings';
import { USER_ROLES } from 'types/enums';
import { Shape } from 'types/RHF-types';
import { EmailValidationSchema, PhoneValidationSchema } from 'utils/formValidation';

export type EditUserFormSchema = UpdateExpertPayload;

export const editUserValidationSchema = yup
  .object()
  .shape<Shape<EditUserFormSchema>>({
    role: yup.string<USER_ROLES>().required().label('User role'),
    first_name: yup.string().required().label(LABELS.FIRST_NAME),
    middle_name: yup.string().optional().label(LABELS.MIDDLE_NAME).nullable(),
    last_name: yup.string().required().label(LABELS.LAST_NAME),
    position: yup.string().optional().label(LABELS.POSITION).nullable(),
    title: yup.string().optional().label(LABELS.TITLE).nullable(),
    phone: PhoneValidationSchema,
    email: EmailValidationSchema,
  })
  .required();
