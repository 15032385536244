import { FC, memo, useEffect } from 'react';

import { Card, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { CustomTableHeader, TableNoData } from 'components/Table';
import { useDictionary } from 'hooks/useDictionary';
import { SectionWrapper } from 'layouts';

import { MedicalConditionsContentProps } from './types';
import { MEDICAL_CONDITIONS_ID } from '../constants';
import { useMedicalRecordTableState } from '../useMedicalRecordTableState';
import { AddEditMedicalRecordTableRow } from './AddEditMedicalRecordTableRow';
import { MedicalRecordRow } from './MedicalRecordRow';
import { ALLERGIES_MEDICAL_RECORD_TABLE_HEAD } from './table.config';

export const MedicalConditionsContent: FC<MedicalConditionsContentProps> = memo(
  ({ patientId, contentRef, patientMedicalConditions, fetchPatient }) => {
    const { fetchMedicalConditions, medicalConditions } = useDictionary();

    useEffect(() => {
      fetchMedicalConditions({ page: 1, size: 100 });
    }, []);

    const {
      isAddMedicalRecord,
      selectedMedicalRecordId,
      onClickEditButtonHandler,
      onClickAddButtonHandler,
      onClickCloseButtonHandler,
      onClickDeleteButtonHandler,
    } = useMedicalRecordTableState();

    return (
      <SectionWrapper ref={contentRef} id={MEDICAL_CONDITIONS_ID}>
        <Card>
          <Stack p={2} gap={1} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
              Medical Conditions
            </Typography>

            <MDButton
              onClick={onClickAddButtonHandler}
              size="small"
              variant="secondary"
              startIcon={<Icon type="add" />}
            >
              Add Medical Condition
            </MDButton>
          </Stack>
          <TableContainer>
            <Table size="medium">
              <CustomTableHeader headLabel={ALLERGIES_MEDICAL_RECORD_TABLE_HEAD} />

              <TableBody>
                {isAddMedicalRecord && (
                  <AddEditMedicalRecordTableRow
                    options={patientMedicalConditions}
                    onSaveHandler={async () => {}}
                    onCancelHandler={onClickCloseButtonHandler}
                    getOptionLabel={option => option?.medical_condition?.medical_condition_icd10_code}
                  />
                )}

                {medicalConditions?.map(medicalCondition =>
                  selectedMedicalRecordId === medicalCondition?.id ? (
                    <AddEditMedicalRecordTableRow
                      options={medicalConditions}
                      option={medicalCondition}
                      note={medicalCondition?.name}
                      onSaveHandler={async () => {}}
                      onCancelHandler={onClickCloseButtonHandler}
                      getOptionLabel={option => option?.name}
                    />
                  ) : (
                    <MedicalRecordRow
                      key={medicalCondition?.id}
                      id={medicalCondition?.id}
                      onClickEdit={onClickEditButtonHandler}
                      onClickDelete={onClickDeleteButtonHandler}
                      name="Medical Condition"
                      createdAt="2021-10-10"
                    />
                  ),
                )}
                {!isAddMedicalRecord && <TableNoData isNotFound={!patientMedicalConditions?.length} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </SectionWrapper>
    );
  },
);
