import borders from '../base/borders';
import { boxShadows } from '../base/boxShadows';
import pxToRem from '../functions/pxToRem';

const { borderRadius } = borders;

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: 'transparent',
      boxShadow: boxShadows['shadow-sm'],
      padding: pxToRem(8),
      borderRadius: borderRadius[8],
    },
  },
};

export default popover;
