import { TableHeaderConfig } from 'types';

export const RELATED_VISITS_TABLE_HEADERS: TableHeaderConfig[] = [
  { id: 'date', label: 'Date' },
  { id: 'provider', label: 'Provider' },
  { id: 'severity', label: 'Severity' },
  { id: 'priority', label: 'Priority' },
  { id: 'expressedAddressed', label: 'Expressed/Addressed' },
  { id: 'assessmentNotes', label: 'Assessment Notes' },
  { id: 'actions' },
];
