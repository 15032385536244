import * as yup from 'yup';

import { AIModelVersionPostRequest } from 'apiServices/ml/types';

export const AIModelVersionValidationSchema = yup.object().shape({
  version: yup.string().required('Version is required').max(50, 'Version must be at most 50 characters'),
  full_name: yup.string().required('Full name is required').max(100, 'Full name must be at most 100 characters'),
  api_endpoint: yup.string().url('API endpoint must be a valid URL').required('API endpoint is required'),
  release_date: yup.string().required('Release date is required'),

  configuration: yup
    .object()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional(),

  notes: yup.string().max(500, 'Notes must be at most 500 characters').optional(),
});

export const defaultAIModelVersionValues: AIModelVersionPostRequest = {
  ai_model_id: '',
  version: '1.0.0',
  full_name: 'AI Model Name 1.0.0',
  api_endpoint: 'https://example.com/api/v1',
  configuration: null,
  release_date: new Date().toDateString(),
  notes: '',
};
