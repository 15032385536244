import Stack from '@mui/material/Stack';

import { EmptyStateWithAction } from 'components/EmptyState';
import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { SectionWrapper } from 'layouts';

import { usePatientSummaryState } from './usePatientSummary.state';

export const PatientSummary: React.FC = () => {
  const { isWebView, patient, isSubmitButtonDisabled, isDirty, errors, isSubmitting, onFormSubmitHandler, register } =
    usePatientSummaryState();

  return (
    <SectionWrapper height={1} width={1}>
      {!patient?.patient_summary && isWebView ? (
        <EmptyStateWithAction
          title="No Summary Yet"
          subtitle="Add Summary in the web app to describe the patient’s condition"
          iconType="page"
        />
      ) : (
        <Stack
          component="form"
          onSubmit={e => {
            e.preventDefault();
            onFormSubmitHandler(e);
          }}
          width={1}
          height={1}
        >
          <RHFTextField
            register={register}
            registerName="patient_summary"
            registerErrors={errors.patient_summary?.message}
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            label="Patient Summary"
            placeholder="Enter Patient Summary"
          />
          <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
        </Stack>
      )}
    </SectionWrapper>
  );
};
