import { useState } from 'react';

import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { FieldValues } from 'react-hook-form';

import { MDInput, MDInputProps } from 'components/MDInput';

import { RHFCommonProps } from './types';

type RHFTextFieldProps<TField extends FieldValues> = MDInputProps &
  RHFCommonProps<TField> & {
    isPasswordField?: boolean;
  };

export const RHFTextField = <TField extends FieldValues>(props: RHFTextFieldProps<TField>) => {
  const {
    registerErrors,
    registerName,
    register,
    registerOptions,
    label,
    size = 'medium',
    isPasswordField,
    type,
    required,
    ...rest
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);

  const onClickHidePasswordIconHandler = () => setIsShowPassword(!isShowPassword);

  const fieldType = isPasswordField && !isShowPassword ? 'password' : isPasswordField && isShowPassword ? 'text' : type;

  return (
    <MDInput
      {...register(registerName, registerOptions)}
      label={label}
      required={required}
      size={size}
      error={!!registerErrors}
      helperText={String(registerErrors || '')}
      type={fieldType}
      slotProps={{
        input: {
          ...(isPasswordField && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={onClickHidePasswordIconHandler} edge="end">
                  {isShowPassword ? (
                    <VisibilityOutlined fontSize="medium" />
                  ) : (
                    <VisibilityOffOutlined fontSize="medium" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }),
        },
      }}
      {...rest}
    />
  );
};
