import { useRef, useState, useEffect, useCallback } from 'react';

import { Box, Collapse, Divider, Stack, useTheme } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';

import { PageWrapper } from 'layouts';
import { formatDuration } from 'utils/helpers';

import audioSample from 'assets/audio/sample-15s.mp3';

import { TranscriptionList } from './components';
import { AudioPlayerControls } from './components/Transcription/AudioPlayerControls';

const DEFAULT_CURRENT_TIME = '00:00';

export const TranscriptTabContent: React.FC = () => {
  const { palette } = useTheme();

  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(DEFAULT_CURRENT_TIME);

  useEffect(() => {
    if (!waveformRef.current) return;

    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: palette.secondary.main,
      progressColor: palette.primary.main,
      cursorColor: 'transparent',
      width: '100%',
      barWidth: 2,
      barGap: 4,
      barHeight: 2,
      barRadius: 2,
      interact: true,
      dragToSeek: true,
      height: 54,
    });

    wavesurfer.current?.load(audioSample);

    wavesurfer.current?.on('audioprocess', () => {
      setCurrentTime(formatDuration(wavesurfer.current?.getCurrentTime() || 0));
    });

    wavesurfer.current?.on('finish', () => {
      setIsPlaying(false);
      setCurrentTime(DEFAULT_CURRENT_TIME);
    });

    return () => {
      if (wavesurfer.current) wavesurfer.current?.destroy();
    };
  }, []);

  const togglePlayPause = useCallback(() => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setIsPlaying(prev => !prev);
    }
  }, [wavesurfer]);

  const [isShowTranscript, setIsShowTranscript] = useState(false);

  const onClickTranscript = useCallback(() => setIsShowTranscript(prev => !prev), []);

  return (
    <PageWrapper alignItems="center" component="div" py={3}>
      <Stack maxWidth={600} width={1} alignItems="center" gap={2}>
        <Stack width={1}>
          <Box
            ref={waveformRef}
            sx={{
              cursor: 'pointer',
            }}
          />

          <Divider sx={{ my: 1.5 }} />

          <AudioPlayerControls
            onClickTranscript={onClickTranscript}
            isShowTranscript={isShowTranscript}
            isPlaying={isPlaying}
            currentTime={currentTime}
            onTogglePlayPause={togglePlayPause}
          />
        </Stack>
        <Collapse in={isShowTranscript} unmountOnExit>
          <TranscriptionList />
        </Collapse>
      </Stack>
    </PageWrapper>
  );
};
