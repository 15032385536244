import * as yup from 'yup';

import { emailRegExp } from 'constants/regex';
import { LABELS } from 'constants/strings';

export type SignInFormSchema = Partial<{
  email: string;
  password: string;
}>;

export const firebaseSignInFormValidationSchema: yup.ObjectSchema<SignInFormSchema> = yup
  .object({
    email: yup
      .string()
      .required('Email field is required')
      .matches(emailRegExp, 'Please enter a valid email')
      .label(LABELS.EMAIL),
    password: yup.string().required().label('Password'),
  })
  .required();

export const defaultValues: SignInFormSchema = { email: '', password: '' };
