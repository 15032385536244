import axios from 'axios';

import { getAuthSession } from 'lib/AWS_Cognito/helpers';

const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL}/services`;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 50_000,
});

axiosInstance.interceptors.request.use(
  async config => {
    const cognitoSession = await getAuthSession();
    const accessToken = cognitoSession?.tokens?.accessToken?.toString();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export { axiosInstance as apiClient };
