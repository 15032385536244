import { Box, Card, Dialog, Divider, Stack, Table, TableBody, TableContainer } from '@mui/material';

import { getGlobalLibraryTag } from 'apiServices';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { FormActions } from 'components/Form';
import { MDInput, MDInputSkeleton } from 'components/MDInput';
import { PopoverWrapper, getActivateMenuItem, getPublishMenuItem } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, CustomTablePagination, TableNoData, TableSkeleton, TableToolbar } from 'components/Table';
import { EntityHeader } from 'components/Widgets';
import { SectionWrapper } from 'layouts';
import {
  CategoryTagsFilterPopoverContent,
  CreateTagMenuContent,
  Dialogs,
  EditTagMenuContent,
  TAG_CATEGORY_TAGS_TABLE_HEADER,
  TagsTableRow,
} from 'pages/TagManagement';

import { usePLtagCategoryProfile } from './usePLtagCategoryProfile.state';

export const PracticeLibraryTagCategoryProfile: React.FC = () => {
  const {
    onChangeSearchInputValue,
    searchValue,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    appliedFilters,
    onApplyFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    actionState,
    dialogStates,
    getCloseDialogHandler,
    isActionPending,
    onClickActionOptionHandler,
    onActivateTagHandler,
    onDeactivateTagHandler,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    isEditTagMenuOpen,
    closeEditTagMenu,
    onClickEditOptionMenuHandler,
    selectedTagId,
    isFiltersApplied,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
    tagCategoryActionState,
    isTagCategoryPublished,
    isTagCategoryActive,
    onActivateTagCategoryHandler,
    onDeactivateTagCategoryHandler,
    onPublishTagCategoryHandler,
    onUnpublishTagCategoryHandler,
    isDirty,
    category,
    isViewOnly,
  } = usePLtagCategoryProfile();

  return (
    <section>
      <EntityHeader
        isLoading={isLoading}
        skeletonProps={{
          isAvatar: true,
          isActiveStatus: true,
          isActionButton: true,
        }}
        title={category?.name}
        publishStutusProps={{
          isPublished: category?.is_published,
        }}
        activeStatusProps={{
          isActive: category?.is_active,
        }}
        actionMenuItems={
          !isViewOnly && [
            getPublishMenuItem(
              isTagCategoryPublished,
              isTagCategoryPublished
                ? tagCategoryActionState.getOpenDialogHandler('unpublish')
                : tagCategoryActionState.getOpenDialogHandler('publish'),
            ),
            getActivateMenuItem(
              isTagCategoryActive,
              isTagCategoryActive
                ? tagCategoryActionState.getOpenDialogHandler('deactivate')
                : tagCategoryActionState.getOpenDialogHandler('activate'),
            ),
          ]
        }
      />

      <Box component="form">
        <SectionWrapper
          width={1}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          sx={{ '.MuiStack-root': { width: 1 } }}
        >
          {isLoading ? (
            <MDInputSkeleton />
          ) : (
            <MDInput disabled={isViewOnly} required label="Name" placeholder="Enter name" {...register('name')} />
          )}
        </SectionWrapper>
        <Divider />

        <SectionWrapper>
          <Card sx={{ boxShadow: 'none', overflow: 'hidden' }}>
            <TableToolbar
              searchInputProps={{
                value: searchValue,
                placeholder: 'Search by Name',
                onChange: onChangeSearchInputValue,
              }}
              actionButtonProps={!isViewOnly && { buttonTitle: 'Add Tag', onClick: openAddTagMenu }}
              onOpenFilterMenuHandler={openFilterMenu}
              isFiltersApplied={isFiltersApplied}
              px={1.5}
            />

            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ width: 1, minWidth: 500 }}>
                  {!isLoadingTags && (
                    <colgroup>
                      <Box component="col" width={1} />
                      <Box component="col" width="20%" />
                    </colgroup>
                  )}
                  <CustomTableHeader
                    headLabel={TAG_CATEGORY_TAGS_TABLE_HEADER.slice(0, isViewOnly ? 1 : 2)}
                    order={order}
                    orderBy={orderBy}
                    onSort={onSort}
                  />

                  <TableBody>
                    {isLoadingTags ? (
                      <TableSkeleton cellsAmount={isViewOnly ? 1 : 2} />
                    ) : (
                      tags?.map((tag, index) => (
                        <TagsTableRow
                          key={tag?.id}
                          tagName={tag?.name}
                          index={index}
                          isActive={tag?.is_active}
                          id={tag?.id}
                          onClickEditOptionMenuHandler={onClickEditOptionMenuHandler}
                          onClickActivationOptionHandler={onClickActionOptionHandler}
                          isViewOnly={isViewOnly}
                        />
                      ))
                    )}
                    <TableNoData isNotFound={!isLoadingTags && !tags.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <CustomTablePagination
              count={total}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Card>
        </SectionWrapper>

        <FormActions
          isDirtyForm={isDirty}
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onFormSubmitHandler={onFormSubmitHandler}
        />
      </Box>

      <BaseDialogWindow
        open={tagCategoryActionState.dialogStates.deactivate}
        onClickCancelButtonHandler={tagCategoryActionState.getCloseDialogHandler('deactivate')}
        description="Are you sure you want to inactivate tag category?"
        title="Inactivate Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Inactivate Tag Category"
        onClickApproveButtonHandler={onDeactivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.dialogStates.activate}
        onClickCancelButtonHandler={tagCategoryActionState.getCloseDialogHandler('activate')}
        description="Are you sure you want to activate tag category?"
        title="Activate Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Activate Tag Category"
        onClickApproveButtonHandler={onActivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.dialogStates.publish}
        onClickCancelButtonHandler={tagCategoryActionState.getCloseDialogHandler('publish')}
        description="Are you sure you want to publish tag category?"
        title="Publish Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Publish Tag Category"
        onClickApproveButtonHandler={onPublishTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.dialogStates.unpublish}
        onClickCancelButtonHandler={tagCategoryActionState.getCloseDialogHandler('unpublish')}
        description="Are you sure you want to unpublish tag category?"
        title="Unpublish Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Unpublish Tag Category"
        onClickApproveButtonHandler={onUnpublishTagCategoryHandler}
      />

      <Dialogs
        isDeactivateDialogOpen={dialogStates.deactivate}
        closeDeactivateDialog={getCloseDialogHandler('deactivate')}
        isActivateDialogOpen={dialogStates.activate}
        closeActivateDialog={getCloseDialogHandler('activate')}
        isLoading={isActionPending}
        name={actionState.name}
        onActivateTagHandler={onActivateTagHandler}
        onDeactivateTagHandler={onDeactivateTagHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by Tags"
        handleClosePopover={closeFiltersMenu}
        variant="filter"
      >
        <CategoryTagsFilterPopoverContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>

      <Dialog open={isAddTagMenuOpen} onClose={closeAddTagMenu} fullWidth>
        <CreateTagMenuContent createTagHandler={createTagHandler} closeMenuHandler={closeAddTagMenu} />
      </Dialog>

      <Dialog open={isEditTagMenuOpen} onClose={closeEditTagMenu} fullWidth>
        <EditTagMenuContent
          getItemFc={getGlobalLibraryTag}
          editTagHandler={editTagHandler}
          closeMenuHandler={closeEditTagMenu}
          tagId={selectedTagId}
        />
      </Dialog>
    </section>
  );
};
