import { TableContainer, TableContainerProps } from '@mui/material';

import { Scrollbar } from 'components/ScrollBar';

export const CustomTableContainer: React.FC<TableContainerProps> = ({ children, sx, ...props }) => {
  return (
    <TableContainer
      sx={{
        height: 1,
        ...sx,
      }}
      {...props}
    >
      <Scrollbar>{children}</Scrollbar>
    </TableContainer>
  );
};
