import { useResponsive } from 'hooks';
import { useTabs } from 'hooks/useTabs';

export enum PATIENT_CONSULTATION_PROFILE_TABS {
  DETAILS = 'Details',
  TRANSCRIPT = 'Transcript',
  RELATED_CONCERNS = 'Related Concerns',
}
export const PATIENT_CONCERN_PROFILE_TAB_VALUES: PATIENT_CONSULTATION_PROFILE_TABS[] = [
  PATIENT_CONSULTATION_PROFILE_TABS.DETAILS,
  PATIENT_CONSULTATION_PROFILE_TABS.TRANSCRIPT,
  PATIENT_CONSULTATION_PROFILE_TABS.RELATED_CONCERNS,
];

export const usePatientConsultationProfileState = () => {
  const { tabValue, handleSetTabValue } = useTabs();

  const isWebView = useResponsive('down', 431);

  return {
    tabValue,
    handleSetTabValue,
    isWebView,
  };
};
