import { FC, SVGProps } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as ThreeDfilledIcon } from 'assets/icons/3d_filled_icon.svg';
import { ReactComponent as add } from 'assets/icons/add_icon.svg';
import { ReactComponent as aiFilledIcon } from 'assets/icons/ai_filled_icon.svg';
import { ReactComponent as arrowLeftIcon } from 'assets/icons/arrow_left_icon.svg';
import { ReactComponent as beforeAfterBoldIcon } from 'assets/icons/before_after_bold_icon.svg';
import { ReactComponent as beforeAfterFilled } from 'assets/icons/before_after_filled_icon.svg';
import { ReactComponent as beforeAfterGroupedWorkflowFilledIcon } from 'assets/icons/before_after_grouped_workflow_filled_icon.svg';
import { ReactComponent as beforeAfterWorkflowFilledIcon } from 'assets/icons/before_after_workflow_filled_icon.svg';
import { ReactComponent as beforeAfterWorkflowFilledWhiteBgIcon } from 'assets/icons/before_after_workflow_filled_white_bg_icon.svg';
import { ReactComponent as bmiIcon } from 'assets/icons/bmi_icon.svg';
import { ReactComponent as boltIcon } from 'assets/icons/bolt_icon.svg';
import { ReactComponent as calendar } from 'assets/icons/calendar_icon.svg';
import { ReactComponent as catalog } from 'assets/icons/catalog_icon.svg';
import { ReactComponent as chatRoundedDotsIcon } from 'assets/icons/chat_round_dots_icon.svg';
import { ReactComponent as checkmarkIcon } from 'assets/icons/checkmark_icon.svg';
import { ReactComponent as chestSizeIcon } from 'assets/icons/chest_size_icon.svg';
import { ReactComponent as closeIcon } from 'assets/icons/close_icon.svg';
import { ReactComponent as closeSquareIcon } from 'assets/icons/close_square_icon.svg';
import { ReactComponent as checked } from 'assets/icons/components/checkbox/checked.svg';
import { ReactComponent as unChecked } from 'assets/icons/components/checkbox/unchecked.svg';
import { ReactComponent as filter } from 'assets/icons/components/filter.svg';
import { ReactComponent as home } from 'assets/icons/components/home.svg';
import { ReactComponent as page } from 'assets/icons/components/page.svg';
import { ReactComponent as practices } from 'assets/icons/components/practices.svg';
import { ReactComponent as radioChecked } from 'assets/icons/components/radio/checked.svg';
import { ReactComponent as radioUnChecked } from 'assets/icons/components/radio/unchecked.svg';
import { ReactComponent as tag_managment } from 'assets/icons/components/tag_managment.svg';
import { ReactComponent as toastError } from 'assets/icons/components/toast/toast_error.svg';
import { ReactComponent as toastSuccess } from 'assets/icons/components/toast/toast_success.svg';
import { ReactComponent as toastWarning } from 'assets/icons/components/toast/toast_warning.svg';
import { ReactComponent as users } from 'assets/icons/components/users.svg';
import { ReactComponent as consultationStatusIcon } from 'assets/icons/consultation_status_icon.svg';
import { ReactComponent as copy } from 'assets/icons/copy_icon.svg';
import { ReactComponent as dragAndDropIcon } from 'assets/icons/drag_and_drop_icon.svg';
import { ReactComponent as dropFileIcon } from 'assets/icons/drop_file_icon.svg';
import { ReactComponent as editPen } from 'assets/icons/edit_pen_icon.svg';
import { ReactComponent as email } from 'assets/icons/email_icon.svg';
import { ReactComponent as eyeClosed } from 'assets/icons/eye_closed_icon.svg';
import { ReactComponent as eyeOpened } from 'assets/icons/eye_open_icon.svg';
import { ReactComponent as flagIcon } from 'assets/icons/flag_icon.svg';
import { ReactComponent as folderFilledIcon } from 'assets/icons/folder_filled_icon.svg';
import { ReactComponent as forwardIcon } from 'assets/icons/forward_icon.svg';
import { ReactComponent as galleryFilledIcon } from 'assets/icons/gallery_filled_icon.svg';
import { ReactComponent as galleryIcon } from 'assets/icons/gallery_icon.svg';
import { ReactComponent as gridViewIcon } from 'assets/icons/grid_view_icon.svg';
import { ReactComponent as groupAmountIcon } from 'assets/icons/group_amount_icon.svg';
import { ReactComponent as groupIcon } from 'assets/icons/group_icon.svg';
import { ReactComponent as heightIcon } from 'assets/icons/height_icon.svg';
import { ReactComponent as hipSizeIcon } from 'assets/icons/hip_size.svg';
import { ReactComponent as leftArmSizeIcon } from 'assets/icons/left_arm_size.svg';
import { ReactComponent as leftThighSizeIcon } from 'assets/icons/left_thigh_size.svg';
import { ReactComponent as linkFilledIcon } from 'assets/icons/link_filled_icon.svg';
import { ReactComponent as linkedBuilding } from 'assets/icons/linked/linked_building.svg';
import { ReactComponent as linkedContentBeforeAfter } from 'assets/icons/linked/linked_content_before_after.svg';
import { ReactComponent as linkedContentImage } from 'assets/icons/linked/linked_content_image.svg';
import { ReactComponent as linkedContentPage } from 'assets/icons/linked/linked_content_page.svg';
import { ReactComponent as linkedContentText } from 'assets/icons/linked/linked_content_text.svg';
import { ReactComponent as linkedContentVideo } from 'assets/icons/linked/linked_content_video.svg';
import { ReactComponent as linkedMedical } from 'assets/icons/linked/linked_medical.svg';
import { ReactComponent as listViewIcon } from 'assets/icons/list_view_icon.svg';
import { ReactComponent as lockIcon } from 'assets/icons/lock_icon.svg';
import { ReactComponent as menuHorizontalDotsIcon } from 'assets/icons/menu_horizontal_dots_icon.svg';
import { ReactComponent as moveIcon } from 'assets/icons/move_icon.svg';
import { ReactComponent as pageFilledIcon } from 'assets/icons/page_filled_icon.svg';
import { ReactComponent as patientsIcon } from 'assets/icons/patients_icon.svg';
import { ReactComponent as pauseFilledIcon } from 'assets/icons/pause_filled_icon.svg';
import { ReactComponent as phoneIcon } from 'assets/icons/phone_icon.svg';
import { ReactComponent as playFilledIcon } from 'assets/icons/play_filled_icon.svg';
import { ReactComponent as playVideoIcon } from 'assets/icons/play_video_icon.svg';
import { ReactComponent as questionnaireFilledIcon } from 'assets/icons/questionnaire_filled_icon.svg';
import { ReactComponent as refreshIcon } from 'assets/icons/refresh_icon.svg';
import { ReactComponent as rightArmSizeIcon } from 'assets/icons/right_arm_size.svg';
import { ReactComponent as rightThighSizeIcon } from 'assets/icons/right_thigh_size.svg';
import { ReactComponent as rocketIcon } from 'assets/icons/rocket_icon.svg';
import { ReactComponent as searchIcon } from 'assets/icons/search_icon.svg';
import { ReactComponent as settingsIcon } from 'assets/icons/settings_icon.svg';
import { ReactComponent as shieldCheckIcon } from 'assets/icons/shield_check_icon.svg';
import { ReactComponent as sidebarClose } from 'assets/icons/sidebar_close.svg';
import { ReactComponent as skinType1Icon } from 'assets/icons/skin/skin_complexion_type1_icon.svg';
import { ReactComponent as skinType2Icon } from 'assets/icons/skin/skin_complexion_type2_icon.svg';
import { ReactComponent as skinType3Icon } from 'assets/icons/skin/skin_complexion_type3_icon.svg';
import { ReactComponent as skinType4Icon } from 'assets/icons/skin/skin_complexion_type4_icon.svg';
import { ReactComponent as skinType5Icon } from 'assets/icons/skin/skin_complexion_type5_icon.svg';
import { ReactComponent as skinType6Icon } from 'assets/icons/skin/skin_complexion_type6_icon.svg';
import { ReactComponent as starsIcon } from 'assets/icons/stars_icon.svg';
import { ReactComponent as startRecordingIcon } from 'assets/icons/start_recording_icon.svg';
import { ReactComponent as stethoscopeIcon } from 'assets/icons/stethoscope_icon.svg';
import { ReactComponent as textFilledIcon } from 'assets/icons/text_filled_icon.svg';
import { ReactComponent as trashBinIcon2 } from 'assets/icons/trash bin2_icon.svg';
import { ReactComponent as trashBin } from 'assets/icons/trash_bin_icon.svg';
import { ReactComponent as uploadIcon } from 'assets/icons/upload_icon.svg';
import { ReactComponent as userIcon } from 'assets/icons/user_icon.svg';
import { ReactComponent as userSpeakIcon } from 'assets/icons/user_speak_icon.svg';
import { ReactComponent as videoFilledIcon } from 'assets/icons/video_filled_icon.svg';
import { ReactComponent as waistIcon } from 'assets/icons/waist_icon.svg';
import { ReactComponent as website } from 'assets/icons/website_icon.svg';
import { ReactComponent as weightIcon } from 'assets/icons/weight_icon.svg';
import { ReactComponent as workflowIcon } from 'assets/icons/workflow_icon.svg';

const ICONS = {
  startRecordingIcon,
  menuHorizontalDotsIcon,
  skinType1Icon,
  skinType2Icon,
  skinType3Icon,
  skinType4Icon,
  skinType5Icon,
  skinType6Icon,
  playFilledIcon,
  pauseFilledIcon,
  consultationStatusIcon,
  shieldCheckIcon,
  beforeAfterWorkflowFilledWhiteBgIcon,
  beforeAfterGroupedWorkflowFilledIcon,
  trashBinIcon2,
  listViewIcon,
  gridViewIcon,
  ThreeDfilledIcon,
  aiFilledIcon,
  beforeAfterWorkflowFilledIcon,
  folderFilledIcon,
  galleryFilledIcon,
  linkFilledIcon,
  pageFilledIcon,
  textFilledIcon,
  videoFilledIcon,
  questionnaireFilledIcon,
  workflowIcon,
  dragAndDropIcon,
  userIcon,
  userSpeakIcon,
  stethoscopeIcon,
  settingsIcon,
  flagIcon,
  lockIcon,
  rocketIcon,
  searchIcon,
  checkmarkIcon,
  boltIcon,
  chatRoundedDotsIcon,
  forwardIcon,
  arrowLeftIcon,
  phoneIcon,
  groupAmountIcon,
  closeSquareIcon,
  groupIcon,
  closeIcon,
  starsIcon,
  linkedContentText,
  moveIcon,
  refreshIcon,
  dropFileIcon,
  galleryIcon,
  uploadIcon,
  eyeOpened,
  editPen,
  add,
  trashBin,
  copy,
  eyeClosed,
  beforeAfterFilled,
  beforeAfterBoldIcon,
  email,
  website,
  filter,
  catalog,
  home,
  page,
  patientsIcon,
  practices,
  tag_managment,
  users,
  checked,
  unChecked,
  radioUnChecked,
  radioChecked,
  toastWarning,
  toastError,
  toastSuccess,
  linkedMedical,
  linkedContentVideo,
  linkedContentPage,
  linkedContentBeforeAfter,
  linkedContentImage,
  linkedBuilding,
  calendar,
  sidebarClose,
  weightIcon,
  heightIcon,
  bmiIcon,
  chestSizeIcon,
  waistIcon,
  hipSizeIcon,
  rightArmSizeIcon,
  leftArmSizeIcon,
  rightThighSizeIcon,
  leftThighSizeIcon,
  playVideoIcon,
};

export type TIconType = keyof typeof ICONS;

interface IIconProps extends SVGProps<SVGSVGElement> {
  type: TIconType;
  sx?: SxProps<Theme>;
}

export const Icon: FC<IIconProps> = props => {
  const { type, sx, ...other } = props;
  const NewIcon = ICONS[type];

  return (
    <Box component="span" display="flex" sx={sx}>
      <NewIcon {...other} />
    </Box>
  );
};
