import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { text, background } = palette;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
  styleOverrides: {
    root: {
      width: '100%',
      minHeight: 'unset',
      padding: `${pxToRem(4.8)} ${pxToRem(8)}`,
      borderRadius: borderRadius[0],
      fontSize: size.bodyMedium,
      color: text.primary,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        backgroundColor: background.surfaceMedium,
      },
    },
  },
};

export default menuItem;
