import { FC, memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { CircularWithValueLabel } from './CircularWithValueLabel';
import { BaseDialogWindow } from '../../BaseDialogWindow/BaseDialogWindow';
import { TimeoutSessionDialogWindowProps } from '../../BaseDialogWindow/types';

export const TimeoutSessionDialogWindow: FC<TimeoutSessionDialogWindowProps> = memo(
  ({
    isLoading,
    open,
    isFinished,
    onClickApproveButtonHandler,
    onClickCancelButtonHandler,
    startTimerValue,
    timerValue,
  }) => {
    return (
      <BaseDialogWindow
        open={open}
        description={
          <Stack sx={{ fontWeight: 400 }} spacing={1.5}>
            <Typography variant="body2" textAlign="center">
              For your security, we log you out automatically when you have not been active for a certain period of
              time.
            </Typography>
            {!isFinished && (
              <Box>
                <CircularWithValueLabel timerValue={timerValue} startTimerValue={startTimerValue} />
              </Box>
            )}
          </Stack>
        }
        title="You will be logged out soon"
        approveButtonTitle="Stay logged in"
        cancelButtonTitle="Logout now"
        onClickCancelButtonHandler={onClickCancelButtonHandler}
        onClickApproveButtonHandler={onClickApproveButtonHandler}
        isApproveButtonDisabled={isLoading}
        isApproveButtonLoading={isLoading}
      />
    );
  },
);
