import { Stack, SxProps, Theme } from '@mui/material';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { Controller, FieldValues } from 'react-hook-form';

import { BaseDatePicker } from 'components/BaseDatePicker';
import { genericMemo } from 'utils/helpers';

import { RHFCommonProps } from './types';

type RHFTimePickerProps<TField extends FieldValues> = RHFCommonProps<TField> & {
  placeholder?: string;
  onChangeDateHandler?: (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
  isDisablePast?: boolean;
  isDisableFuture?: boolean;
  isRequired?: boolean;
  label?: string;
  sx?: SxProps<Theme>;
};

export const RHFDatePicker = genericMemo(<TField extends FieldValues>(props: RHFTimePickerProps<TField>) => {
  const {
    control,
    registerName: name,
    isRequired,
    onChangeDateHandler,
    isDisableFuture = true,
    isDisablePast = false,
    label = 'Date of Birth',
    placeholder = 'Select Date of Birth',
    sx,
  } = props;

  return (
    <Stack width={1} sx={sx}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const onChangeHandler = (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
            if (newValue && !context.validationError) {
              error?.message && control.setError(name, { message: '' });
              field.onChange(newValue);
              return;
            }
            if (context.validationError === 'invalidDate') {
              control.setError(name, {
                message: 'Enter a valid time format',
              });
            }

            if (context.validationError === 'disableFuture') {
              control.setError(name, {
                message: 'Future date is not valid',
              });
            }
            onChangeDateHandler?.(newValue, context);
          };

          const onClickClearButtonHandler = () => {
            field.onChange(null);
            field.onBlur();
          };

          return (
            <BaseDatePicker
              label={label}
              isError={!!error?.message}
              value={!field.value ? null : new Date(field.value || '')}
              onClickClearButtonHandler={onClickClearButtonHandler}
              onChange={onChangeHandler}
              disablePast={isDisablePast}
              disableFuture={isDisableFuture}
              disabled={field.disabled}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'medium',
                  error: !!error?.message,
                  helperText: error?.message,
                  label: label,
                  placeholder: placeholder,
                  required: isRequired,
                  onBlur: field.onBlur,
                },
              }}
            />
          );
        }}
      />
    </Stack>
  );
});
