import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid2,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { DropZone } from 'components/DropZone';
import { MDAutocomplete } from 'components/MDAutocomplete';
import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { MDTabs } from 'components/MDTabs';
import { CustomTableHeader } from 'components/Table';
import { useTabs } from 'hooks';
import { SectionWrapper } from 'layouts';

import {
  defaultMaxTokens,
  defaultStopSequences,
  defaultTemperature,
  defaultTopK,
  defaultTopP,
  evaluationMetricsMap,
} from './constants';
import { ModelCostTableRow } from './ModelCostTableRow';
import TransitionsModal from './prompt_modal';
import { MODELS_COST_TABLE_HEAD } from './table.config';

export const LLM: React.FC = () => {
  const [output1, setOutput1] = useState('');

  const onChangeOutput1 = (e: React.ChangeEvent<HTMLInputElement>) => setOutput1(e.target.value);

  const [output2, setOutput2] = useState('');

  const onChangeOutput2 = (e: React.ChangeEvent<HTMLInputElement>) => setOutput2(e.target.value);

  const eveluationMetrics = useMemo(
    () => Object.entries(evaluationMetricsMap).map(([key, value]) => ({ key, value })),
    [],
  );

  const { tabValue, handleSetTabValue } = useTabs();

  const [contextData, setContextData] = useState([{ variable: '', value: '' }]);

  const [temperature, setTemperature] = useState(defaultTemperature);
  const [top_p, setTopP] = useState(defaultTopP);
  const [top_k, setTopK] = useState(defaultTopK);
  const [inputValue, setInputValue] = useState('');
  const [max_tokens, setMaxTokens] = useState(defaultMaxTokens);

  const [showDialog, setshowDialog] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [stopSequences, setStopSequences] = useState(defaultStopSequences);

  const StopSequences = () => {
    const handleAdd = () => {
      if (inputValue.trim() && !stopSequences.includes(inputValue)) {
        setStopSequences([...stopSequences, inputValue]);
        setInputValue('');
      }
    };

    const handleDelete = (sequenceToDelete: unknown) => {
      setStopSequences(stopSequences.filter(seq => seq !== sequenceToDelete));
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          label="Stop sequences"
          variant="outlined"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          size="small"
        />
        <Button variant="contained" onClick={handleAdd}>
          Add
        </Button>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            marginTop: '20px',
            marginLeft: '10px',
          }}
        >
          {stopSequences?.map(sequence => (
            <Chip key={sequence} label={sequence} onDelete={() => handleDelete(sequence)} color="primary" />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <SectionWrapper>
      <Stack gap={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
          <Stack gap={2} width={1}>
            <Typography variant="subtitle1" fontWeight={500}>
              Model 1
            </Typography>
            <MDAutocomplete
              options={[]}
              inputProps={{ label: 'Model 1', placeholder: 'Select Model 1' }}
              valueKey=""
              labelKey=""
            />
            <MDInput
              fullWidth
              multiline
              minRows={8}
              maxRows={24}
              label="Model 1 output"
              placeholder="Enter Model 1 output"
              value={output1}
              onChange={onChangeOutput1}
            />
          </Stack>

          <Stack gap={2} width={1}>
            <Typography variant="subtitle1" fontWeight={500}>
              Model 2
            </Typography>
            <MDAutocomplete
              options={[]}
              inputProps={{ label: 'Model 2', placeholder: 'Select Model 2' }}
              valueKey=""
              labelKey=""
            />
            <MDInput
              fullWidth
              multiline
              minRows={8}
              maxRows={24}
              label="Model 2 output"
              placeholder="Enter Model 2 output"
              value={output2}
              onChange={onChangeOutput2}
            />
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Box
            display="flex"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            maxWidth={{ xs: 1, sm: 400 }}
            width={1}
            mt={1}
          >
            <MDTabs
              allowScrollButtonsMobile
              value={tabValue}
              tabsData={['Prompt', 'Context', 'Generation Configuration']}
              handleSetTabValue={handleSetTabValue}
            />
          </Box>

          {tabValue === 0 && (
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} gap={2} width={1}>
              <Stack width={1} gap={1.5}>
                <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
                  <MDAutocomplete
                    options={[]}
                    inputProps={{ label: 'Model 1', placeholder: 'Select Model 1' }}
                    valueKey=""
                    labelKey=""
                    fullWidth
                  />

                  <MDInput label="Prompt ID" fullWidth />
                </Stack>
                <MDInput fullWidth multiline minRows={4} label="Template" />
              </Stack>
              <Stack gap={1} width={1} maxWidth={200}>
                <MDButton variant="secondary" size="small">
                  Reset
                </MDButton>
                <MDButton variant="secondary" size="small">
                  Enter
                </MDButton>

                <TransitionsModal
                  region=""
                  template=""
                  contextData={contextData}
                  temperature={temperature}
                  top_p={top_p}
                  top_k={top_k}
                  max_tokens={max_tokens}
                  stop_sequences={stopSequences}
                  showDialog={setshowDialog}
                />
              </Stack>
            </Stack>
          )}

          {tabValue === 1 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Variable</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contextData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          variant="standard"
                          fullWidth
                          value={row.variable}
                          onChange={e => {
                            const newData = [...contextData];
                            newData[index].variable = e.target.value;
                            setContextData(newData);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          variant="standard"
                          value={row.value}
                          onChange={e => {
                            const newData = [...contextData];
                            newData[index].value = e.target.value;
                            setContextData(newData);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack alignItems="flex-end">
                <MDButton
                  variant="contained"
                  component="span"
                  color="primary"
                  sx={{ m: 1 }}
                  onClick={() => setContextData([...contextData, { variable: '', value: '' }])}
                >
                  Add Row
                </MDButton>
              </Stack>
            </TableContainer>
          )}

          {tabValue === 2 && (
            <Box
              sx={{
                borderRadius: 1,
                padding: '20px',
                border: '1px solid #000',
              }}
            >
              <Grid2 container spacing={1}>
                <Grid2 size={3}>
                  <TextField
                    required
                    onChange={e => setTemperature(Number(e.target.value))}
                    id="outlined-required"
                    label="Temperature"
                    type="number"
                    defaultValue={temperature}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <TextField
                    required
                    type="number"
                    onChange={e => setTopP(Number(e.target.value))}
                    id="outlined-required"
                    label="Top p"
                    defaultValue={top_p}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <TextField
                    required
                    type="number"
                    onChange={e => setTopK(Number(e.target.value))}
                    id="outlined-required"
                    label="Top K"
                    defaultValue={top_k}
                  />
                </Grid2>
                <Grid2 size={3}>
                  <TextField
                    required
                    id="outlined-required"
                    type="number"
                    onChange={e => setMaxTokens(Number(e.target.value))}
                    label="Max Tokens"
                    defaultValue={max_tokens}
                  />
                </Grid2>

                <Grid2 size={8}>{StopSequences()}</Grid2>
              </Grid2>
            </Box>
          )}
        </Stack>

        <Stack gap={2}>
          <Typography variant="subtitle1" fontWeight={500}>
            Model Cost Analysis
          </Typography>
          <TableContainer>
            <Table size="medium" sx={{ minWidth: 500 }}>
              <CustomTableHeader headLabel={MODELS_COST_TABLE_HEAD} />
              <TableBody>
                <ModelCostTableRow />
                <ModelCostTableRow />
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack gap={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={1}>
            <Typography variant="subtitle1" fontWeight={500}>
              Batch Generation
            </Typography>

            <MDButton variant="secondary">Start Generation</MDButton>
          </Stack>

          <Stack gap={1.5}>
            <Stack direction="row" gap={1.5} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox checked={isChecked} onChange={e => setIsChecked(e.target.checked)} color="primary" />
                }
                label="Use Knowledge Base"
              />

              <MDInput
                size="small"
                fullWidth
                disabled={!isChecked}
                placeholder="Knowledge Base ID"
                sx={{ marginLeft: 2, align: 'right' }}
              />

              <MDInput size="small" fullWidth placeholder="Prompt Template ID" sx={{ marginLeft: 0, align: 'left' }} />
            </Stack>

            <Stack direction="row" gap={1.5} alignItems="center">
              <MDInput fullWidth label="S3 Bucket Name" />

              <MDInput fullWidth label="Data file" />
            </Stack>

            <MDAutocomplete
              options={[]}
              inputProps={{ label: 'Model 1', placeholder: 'Select Model 1' }}
              valueKey=""
              labelKey=""
            />

            <DropZone onUploadFileHandler={files => console.log(files)} buttonTitle="Select File" />
          </Stack>
        </Stack>

        <Stack gap={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={1}>
            <Typography variant="subtitle1" fontWeight={500}>
              Evaluation
            </Typography>
            <MDButton variant="secondary">Start Evaluation</MDButton>
          </Stack>

          <Stack gap={2}>
            <MDAutocomplete
              options={[]}
              inputProps={{ label: 'Model', placeholder: 'Select Model' }}
              valueKey=""
              labelKey=""
            />

            <MDInput label="Evaluation File Location" placeholder="Enter Evaluation File Location" />

            <MDAutocomplete
              options={eveluationMetrics}
              multiple
              inputProps={{ label: 'Evaluation Metrics', placeholder: 'Select Evaluation Metrics' }}
              valueKey="key"
              labelKey="value"
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionWrapper>
  );
};
