import { Fragment } from 'react';

import { AutocompleteFreeSoloValueMapping, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { MDInput } from 'components/MDInput';

import { MDAutocompleteProps } from './types';

export const MDAutocomplete = <
  OptionType,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  inputProps,
  inputType,
  loading,
  renderOption,
  options,
  getOptionLabel,
  lastElementRef,
  freeSolo,
  onInputChange,
  filterOptions,
  labelKey,
  valueKey,
  customIsOptionEqualToValue,
  sx,
  ...rest
}: MDAutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>) => {
  const isOptionEqualToValue =
    customIsOptionEqualToValue ?? ((option, value) => option?.[valueKey] === value?.[valueKey]);

  const getOptionLabelHandler = labelKey
    ? (option: OptionType | AutocompleteFreeSoloValueMapping<FreeSolo>) =>
        typeof option === 'string' ? option : String(option?.[labelKey])
    : getOptionLabel;

  return (
    <Autocomplete
      renderInput={params => (
        <MDInput
          isLoading={loading}
          {...params}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          fullWidth
          {...inputProps}
        />
      )}
      loading={loading}
      getOptionLabel={getOptionLabelHandler}
      options={options}
      freeSolo={freeSolo}
      onInputChange={onInputChange}
      filterOptions={!freeSolo && onInputChange ? x => x : filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      sx={{ width: 1, ...sx }}
      renderOption={
        renderOption
          ? renderOption
          : ({ key, ...props }, option, state) => {
              const isLastItem = lastElementRef && state.index === options.length - 1;

              return (
                <Fragment key={key}>
                  <li {...props} ref={isLastItem ? lastElementRef : null}>
                    {getOptionLabelHandler?.(option)}
                  </li>
                  {loading && isLastItem && (
                    <Box {...props} key="loading" component="li" sx={{ cursor: 'initial', pointerEvents: 'none' }}>
                      Loading...
                    </Box>
                  )}
                </Fragment>
              );
            }
      }
      {...rest}
    />
  );
};
