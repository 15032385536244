import { MoreHorizOutlined } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { ActiveStatusChip } from 'components/MDChip';
import { ActionMenuList } from 'components/MenuPopover';
import { usePopover, useRouter } from 'hooks';

import { EntityHeaderActionsProps } from '../types';

export const EntityHeaderActions: React.FC<EntityHeaderActionsProps> = ({ actionMenuItems, activeStatusProps }) => {
  const { back } = useRouter();

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const isActionMenuItems = !!actionMenuItems?.length;

  return (
    <>
      <Stack
        mb={1.5}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        gap={1}
        alignItems="center"
      >
        <MDButton
          onClick={back}
          variant="ghost"
          size="small"
          sx={{ p: '6px' }}
          startIcon={<Icon type="arrowLeftIcon" sx={{ mb: '1px' }} />}
        >
          Back
        </MDButton>

        <Stack
          gap={1}
          sx={theme => ({
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'row',
            },
          })}
          alignItems="center"
        >
          {activeStatusProps && <ActiveStatusChip {...activeStatusProps} />}

          {isActionMenuItems && activeStatusProps && (
            <Divider sx={{ mx: 0.5, height: 18, alignSelf: 'center' }} flexItem orientation="vertical" />
          )}

          {isActionMenuItems ? (
            <MDButton size="small" variant="secondary" isIconOnly onClick={handleOpenPopover}>
              <MoreHorizOutlined fontSize="medium" />
            </MDButton>
          ) : null}
        </Stack>
      </Stack>

      <ActionMenuList onClose={handleClosePopover} open={openPopover} menuItems={actionMenuItems} />
    </>
  );
};
