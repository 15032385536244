import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import { GetMedicalCondtitionsItemsParams, MedicalCondition } from './types';

const API_SUB_URL = 'medical_conditions';

export const getMedicalConditions = async (params: GetMedicalCondtitionsItemsParams) =>
  await apiClient.get<ListOfItems<MedicalCondition>>(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}`, { params });

export const getMedicalConditionById = async (id: string) =>
  await apiClient.get<MedicalCondition>(`${BASE_DICTIONARY_API_URL}/${API_SUB_URL}/${id}`);
