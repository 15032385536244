import { DatePicker } from '@mui/x-date-pickers';

import { Icon } from 'components/Icon';
import { MDInput } from 'components/MDInput';

import { BaseDatePickerProps } from './types';

export const BaseDatePicker: React.FC<BaseDatePickerProps> = ({
  isError,
  value,
  slotProps,
  sx,
  onChange,
  onClickClearButtonHandler,
  onErrorHandler,
  ...rest
}) => {
  return (
    <DatePicker
      {...rest}
      onError={onErrorHandler}
      slots={{ textField: MDInput, openPickerIcon: () => <Icon type="calendar" /> }}
      slotProps={{
        clearButton: { sx: { p: '6px' } },
        openPickerButton: { sx: { p: '6px', mr: '-5px' } },
        field: { clearable: true, onClear: onClickClearButtonHandler },
        textField: {
          error: false,
          size: 'small',
          variant: 'standard',
        },
        ...slotProps,
      }}
      sx={sx}
      value={value}
      onChange={onChange}
    />
  );
};
