import { BorderTypes } from 'types/mui-theme';

const borders: BorderTypes = {
  borderWidth: {
    0: 0,
    1: '1px',
    1.25: '1.25px',
    2: '2px',
    4: '4px',
    8: '8px',
  },

  borderRadius: {
    0: '0px',
    2: '2px',
    4: '4px',
    6: '6px',
    8: '8px',
    10: '10px',
    12: '12px',
    16: '16px',
    24: '24px',
    32: '32px',
    full: '50%',
  },
};

export default borders;
