import { Box, Grid2 } from '@mui/material';

import { create_gl_ba_album_pair, upload_image_gl_ba_album_pair } from 'apiServices';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { getActivateMenuItem } from 'components/MenuPopover';
import { EntityHeader } from 'components/Widgets';
import { ROUTES } from 'constants/routes';
import {
  ActivateDeactivateDialogWindow,
  AddBeforeAfterImagesDialogWindow,
  AlbumProfileFormSection,
  BACardItem,
  BeforeAfterPhotos,
} from 'pages/BeforeAfter';

import beforeAfterAlbumFallback from 'assets/icons/before_after_album_fallback.svg';

import { useGLBeforeAfterAlbumProfileState } from './useGLBeforeAfterAlbumProfile.state';

export const GlobalLibraryBAProfile: React.FC = () => {
  const {
    b_a_gl_album,
    isLoading,
    isActionPending,
    formMethods,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    b_a_gl_album_pairs,
    isAlbumPairLoading,
    ref,
    isFetchMoreLoading,
    onDeleteAvatarHandler,
    onUploadAvatarHandler,
    onFormSubmitHandler,
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
    getMenuItems,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    albumId,
    fetchAlbumPairs,
    albumPairActionMethods,
    deleteAlbumPairHandler,
    isRoleAdmin,
    onActivateDeactivateAlbumPairHandler,
    dialogStates,
    getOpenDialogHandler,
    getCloseDialogHandler,
  } = useGLBeforeAfterAlbumProfileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
      }}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <EntityHeader
            isLoading={isLoading}
            skeletonProps={{
              avatarVariant: 'rounded',
              isActiveStatus: isRoleAdmin,
              isActionButton: isRoleAdmin,
            }}
            title={b_a_gl_album?.name}
            subtitle={b_a_gl_album?.description}
            activeStatusProps={
              isRoleAdmin && {
                isActive: b_a_gl_album?.is_active,
              }
            }
            actionMenuItems={
              isRoleAdmin
                ? [
                    getActivateMenuItem(
                      b_a_gl_album?.is_active,
                      b_a_gl_album?.is_active ? getOpenDialogHandler('deactivate') : getOpenDialogHandler('activate'),
                    ),
                  ]
                : []
            }
            avatarProps={{
              src: b_a_gl_album?.cover_thumb_url,
              fallbackSrc: beforeAfterAlbumFallback,
              initialPhotoUrl: b_a_gl_album?.cover_url,
              onCreateFileHandler: onUploadAvatarHandler,
              onDeleteFileHandler: onDeleteAvatarHandler,
              formDataImageName: 'cover_image',
              variant: 'rounded',
              cropShape: 'rect',
              isDisabled: !isRoleAdmin,
            }}
          />

          <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
            <AlbumProfileFormSection isLoading={isLoading} isViewOnly={!isRoleAdmin} />
            <FormActions
              isDisabled={formMethods.formState.isSubmitting}
              isLoading={formMethods.formState.isSubmitting}
              onFormSubmitHandler={onFormSubmitHandler}
              isDirtyForm={formMethods.formState.isDirty}
              backRoute={ROUTES.beforeAfter}
              sx={{
                borderRadius: 0,
                ...(formMethods.formState.isDirty && {
                  borderBottom: ({ borders, palette }) => `${borders.borderWidth[1]} solid ${palette.secondary.main}`,
                }),
              }}
            />
          </FormProvider>

          <BeforeAfterPhotos
            data={b_a_gl_album_pairs.items}
            totalItems={b_a_gl_album_pairs.total}
            isLoading={isAlbumPairLoading}
            isLoadingNext={isFetchMoreLoading}
            checkedImagePairIdSize={checkedIds.size}
            onResetImagePairChekedIds={onResetChekedIds}
            openAddAlbumImagesDialogWindow={isRoleAdmin && openAddAlbumImagesDialogWindow}
            albumPairActionMethods={albumPairActionMethods}
            deleteAlbumPairHandler={deleteAlbumPairHandler}
            deactiveActivateAlbumPairHandler={onActivateDeactivateAlbumPairHandler}
            renderItem={(item, index) => {
              return (
                <BACardItem
                  id={item?.id}
                  title={item?.name}
                  coverRightThumbURL={item?.image_right_thumb_url}
                  coverLeftThumbURL={item?.image_left_thumb_url}
                  isActive={item?.is_active}
                  onChangeCheckboxHandler={isRoleAdmin && onCheckItemHandler}
                  checkedIds={checkedIds}
                  getMenuItems={getMenuItems}
                  // TODO: click on card
                  onCardClickHandler={() => {}}
                  lastAlbumCardRef={b_a_gl_album_pairs.items.length - 1 === index ? ref : null}
                />
              );
            }}
          />
        </Grid2>
      </Grid2>

      {isOpenAddAlbumImagesDialogWindow && (
        <AddBeforeAfterImagesDialogWindow
          isOpenAddAllbumDialogWindow={isOpenAddAlbumImagesDialogWindow}
          closeAddAlbumDialogWindow={closeAddAlbumImagesDialogWindow}
          onFetchAlbumsHandler={fetchAlbumPairs}
          uploadImageAPIrequest={upload_image_gl_ba_album_pair}
          createBAimagePairAPIrequest={create_gl_ba_album_pair}
          albumId={albumId}
        />
      )}

      <ActivateDeactivateDialogWindow
        actionType={dialogStates.deactivate ? 'deactivate' : 'activate'}
        name={b_a_gl_album?.name}
        isOpen={dialogStates.deactivate || dialogStates.activate}
        isActionPending={isActionPending}
        onClose={getCloseDialogHandler(dialogStates.deactivate ? 'deactivate' : 'activate')}
        onApprove={dialogStates.deactivate ? onDeactivateAlbumHandler : onActivateAlbumHandler}
        title="Album"
      />
    </Box>
  );
};
