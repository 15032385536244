import { useMemo } from 'react';

import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Divider, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { useCheckedIDs } from 'hooks';
import { StepContentItem } from 'pages/Workflows/types';

import workflow_image1 from 'assets/illustrations/workflow_image1.png';
import workflow_image2 from 'assets/illustrations/workflow_image2.png';
import workflow_image3 from 'assets/illustrations/workflow_image3.png';

import { ContentType } from '../ContentTypePickerDialogWindow';
import { AccordionContentItem } from './AccordionContentItem';
import { ContentItem } from './ContentItem';
import { DialogFooter } from './DialogFooter';
import { ContentPickerDialogWindowContentProps } from './types';

const MOCKED_IMAGES_CONTENT_ITEMS: StepContentItem[] = [
  { id: '1', title: 'Non-Surgical Facelift', previewURL: workflow_image1, type: 'image' },
  { id: '2', title: 'Skin Tightening', previewURL: workflow_image2, type: 'image' },
  {
    id: '3',
    title: 'Botox Treatment',
    previewURL: workflow_image3,
    type: 'image',
  },
];

const MOCKED_BA_CONTENT_ITEMS: StepContentItem[] = [
  {
    id: '1',
    title: 'Facial Rejuvenation',
    previewURL: workflow_image3,
    type: 'beforeAfter',
    content: [
      {
        id: '1_1',
        title: 'Facial Rejuvenation Content',
        previewURL: workflow_image1,
        type: 'beforeAfter',
      },
      {
        id: '2_1',
        title: 'Facial Rejuvenation Content',
        previewURL: workflow_image2,
        type: 'beforeAfter',
      },
    ],
  },
  {
    id: '2',
    title: 'Eyelid Surgery Transformation',
    previewURL: workflow_image3,
    type: 'beforeAfter',
    content: [
      {
        id: '1_2',
        title: 'Eyelid Surgery Transformation Content',
        previewURL: workflow_image1,
        type: 'beforeAfter',
      },
      {
        id: '2_2',
        title: 'Eyelid Surgery Transformation Content',
        previewURL: workflow_image2,
        type: 'beforeAfter',
      },
    ],
  },
];

const MOCKED_CONTENT_ITEMS: Partial<Record<ContentType, StepContentItem[]>> = {
  image: MOCKED_IMAGES_CONTENT_ITEMS,
  beforeAfter: MOCKED_BA_CONTENT_ITEMS,
};

const DIALOG_TITLE: Partial<Record<ContentType, string>> = {
  image: 'Image',
  beforeAfter: 'Before & After',
};

const getSelectedItems = (
  contentType: ContentType,
  items: StepContentItem[],
  checkedIds: Set<string>,
): StepContentItem[] => {
  if (!items) return [];

  switch (contentType) {
    case 'beforeAfter':
      return items
        .flatMap(item => item.content?.map(nestedItem => ({ ...nestedItem, parentId: item.id })) || [])
        .filter(item => checkedIds.has(item.id));
    case 'image':
      return items.filter(item => checkedIds.has(item.id));
    default:
      return [];
  }
};

export const ContentPickerDialogWindowContent: React.FC<ContentPickerDialogWindowContentProps> = ({
  onClose,
  dialogTitle,
  selectedContentType,
  onAddContentToStepHandler,
}) => {
  const onClickCloseButtonHandler = (e: React.MouseEvent<HTMLElement>) => onClose(e, 'backdropClick');

  const { onCheckAllIdsHandler, checkedIdsSize, isNoIdChecked, checkedIds, onCheckItemHandler, onResetChekedIds } =
    useCheckedIDs();

  const totalItems = useMemo(() => {
    const items = MOCKED_CONTENT_ITEMS[selectedContentType] || [];
    return selectedContentType === 'beforeAfter' ? items.flatMap(item => item.content || []).length : items.length;
  }, [selectedContentType]);

  const onClickApplyButtonHandler = async () => {
    if (isNoIdChecked) return;

    const items = MOCKED_CONTENT_ITEMS[selectedContentType] || [];
    const selectedItems = getSelectedItems(selectedContentType, items, checkedIds);

    onAddContentToStepHandler(selectedItems);

    onResetChekedIds();
    onClose({}, 'backdropClick');
  };

  return (
    <>
      <Stack
        sx={{ px: { xs: 2, sm: 2.5 }, py: 1.5 }}
        direction="row"
        gap={0.5}
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogTitle sx={{ flexShrink: 1, p: 0, fontWeight: 500, fontSize: { xs: 16, sm: 20 } }}>
          {dialogTitle || DIALOG_TITLE[selectedContentType]}
        </DialogTitle>

        <Typography variant="body2" color="text.secondary" fontWeight={500} lineHeight={1}>
          {isNoIdChecked ? (
            'No items selected'
          ) : (
            <>
              <Typography component="span" variant="caption" fontSize="inherit" color="primary.main">
                {checkedIdsSize}
              </Typography>{' '}
              of{' '}
              <Typography component="span" variant="caption" fontSize="inherit" color="primary.main">
                {totalItems}
              </Typography>{' '}
              items selected
            </>
          )}
        </Typography>

        <MDButton isIconOnly variant="tertiary" size="small" onClick={onClickCloseButtonHandler}>
          <Icon
            type="closeIcon"
            sx={{
              svg: {
                width: 12,
                height: 12,
              },
            }}
          />
        </MDButton>
      </Stack>
      <Divider />

      <DialogContent sx={{ p: 0 }}>
        <Box component="ul">
          {MOCKED_CONTENT_ITEMS[selectedContentType]?.map(item => {
            switch (item.type) {
              case 'beforeAfter':
                return (
                  <AccordionContentItem
                    key={item.id}
                    onCheckItemHandler={onCheckItemHandler}
                    id={item.id}
                    title={item.title}
                    previewURL={item.previewURL}
                    content={item?.content}
                    checkedIds={checkedIds}
                    onCheckAllItemsHandler={onCheckAllIdsHandler}
                    type={item.type}
                  />
                );

              default:
                return (
                  <ContentItem
                    key={item.id}
                    onCheckItemHandler={onCheckItemHandler}
                    id={item.id}
                    title={item.title}
                    previewURL={item.previewURL}
                    isChecked={checkedIds.has(item.id)}
                    type={item.type}
                  />
                );
            }
          })}
        </Box>
      </DialogContent>

      <DialogFooter
        onClickApplyButtonHandler={onClickApplyButtonHandler}
        onClickCloseButtonHandler={onClickCloseButtonHandler}
        onResetSelectedItems={onResetChekedIds}
        isApplyButtonDisabled={isNoIdChecked}
      />
    </>
  );
};

export const ContentPickerDialogWindow: React.FC<ContentPickerDialogWindowContentProps & DialogProps> = ({
  open,
  onClose,
  selectedContentType,
  onAddContentToStepHandler,
  ...other
}) => (
  <Dialog fullWidth open={open} onClose={onClose} maxWidth="md" {...other}>
    <ContentPickerDialogWindowContent
      onAddContentToStepHandler={onAddContentToStepHandler}
      onClose={onClose}
      selectedContentType={selectedContentType}
    />
  </Dialog>
);
