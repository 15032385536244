import { useEffect, useState } from 'react';

import { getPromptVersions } from 'apiServices/ml/prompt-version.api';
import { PromptVersionItemResponse } from 'apiServices/ml/types';
import { ToastType, notice } from 'components/ToastNotification';

export const usePromptVersions = (promptId: string) => {
  const [promptVersions, setPromptVersions] = useState<PromptVersionItemResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPromptVersions = async () => {
      if (!promptId) return;

      setIsLoading(true);
      notice(ToastType.INFO, 'Loading prompt versions...');

      try {
        const response = await getPromptVersions(promptId);
        setPromptVersions(response.data);
        notice(ToastType.SUCCESS, 'Prompt versions loaded successfully');
      } catch (err) {
        setError('Failed to fetch prompt versions');
        notice(ToastType.ERROR, 'Failed to fetch prompt versions');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromptVersions();
  }, [promptId]);

  return { promptVersions, isLoading, error };
};
