import { Dialog, Table, TableBody } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { Icon } from 'components/Icon';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { EMPTY_SCREEN_MESSAGES } from 'constants/strings';
import { PageWrapper } from 'layouts';

import { AddPracticeDialogWindowContent, FiltersMenuContent, PracticesTableRow } from './components';
import { PRACTICES_SERVICES_TABLE_HEAD } from './table.config';
import { usePracticesState } from './usePractices.state';

export const Practices: React.FC = () => {
  const {
    isPending,
    practices,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    onApplyFilters,
    isAddPracticeDialogOpen,
    openAddPracticeDialog,
    closeAddPracticeDialog,
    onGetPracticesHandler,
    onChangeSearchValueHandler,
    setResetFilters,
    isEmptyState,
    isFilteredEmptyState,
  } = usePracticesState();

  return (
    <PageWrapper height={1}>
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by name, website, contacts...',
          onChange: onChangeSearchValueHandler,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={{ onClick: openAddPracticeDialog, buttonTitle: 'Add practice' }}
      />
      {isEmptyState ? (
        <EmptyStateWithAction
          title="No Practices Yet"
          subtitle="Click below to add a new one."
          iconType="practices"
          actionButtonProps={{
            title: 'Add Practice',
            onClickHandler: openAddPracticeDialog,
            buttonProps: { startIcon: <Icon type="add" /> },
          }}
        />
      ) : isFilteredEmptyState ? (
        <EmptyStateWithAction
          title={EMPTY_SCREEN_MESSAGES.title}
          subtitle={EMPTY_SCREEN_MESSAGES.subtitle}
          iconType="practices"
        />
      ) : (
        <>
          <CustomTableContainer>
            <Table size="medium" sx={{ minWidth: 800 }}>
              <CustomTableHeader headLabel={PRACTICES_SERVICES_TABLE_HEAD} />
              <TableBody>
                {isPending ? (
                  <TableSkeleton cellsAmount={PRACTICES_SERVICES_TABLE_HEAD.length} />
                ) : (
                  practices.map(practice => (
                    <PracticesTableRow
                      key={practice?.id}
                      id={practice?.id}
                      name={practice?.name}
                      created_at={practice?.created_at}
                      updated_at={practice?.updated_at}
                      status={practice?.status}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>

          <CustomTablePagination
            count={total}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePageHandler}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </>
      )}

      <PopoverWrapper
        open={isFiltersMenuOpen}
        handleClosePopover={closeFiltersMenu}
        title="Filter by Practices"
        variant="filter"
      >
        <FiltersMenuContent
          appliedFilters={appliedFilters}
          setAppliedFilters={onApplyFilters}
          setResetFilters={setResetFilters}
        />
      </PopoverWrapper>

      <Dialog open={isAddPracticeDialogOpen} onClose={closeAddPracticeDialog} fullWidth>
        <AddPracticeDialogWindowContent fetchItems={onGetPracticesHandler} closeMenuHandler={closeAddPracticeDialog} />
      </Dialog>
    </PageWrapper>
  );
};
