import { ButtonProps, ComponentsOverrides, Theme } from '@mui/material';

import borders from 'styles/theme/base/borders';
import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { fontWeightSemiBold, size } = typography;
const { borderRadius } = borders;

const FONT_SIZE: Record<ButtonProps['size'], number> = {
  large: size.bodyLarge,
  medium: size.bodyMedium,
  small: size.bodySmall,
};

export const BUTTON_HEIGHT: Record<ButtonProps['size'], number> = {
  large: 52,
  medium: 46,
  small: 38,
};

const root: ComponentsOverrides<Theme>['MuiButton']['root'] = ({ ownerState, theme: { palette } }) => {
  return {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: FONT_SIZE[ownerState.size],
    height: BUTTON_HEIGHT[ownerState.size],
    minWidth: 38,
    fontWeight: fontWeightSemiBold,
    borderRadius: borderRadius[8],
    lineHeight: 'inherit',
    textAlign: 'center',
    userSelect: 'none',
    backgroundSize: '150% !important',
    backgroundPositionX: '25% !important',
    transition: 'all 150ms ease-in',
    textTransform: 'capitalize',
    '&:disabled': {
      pointerEvents: 'none',
      background: palette.disabledColor.disabledSoft,
      color: palette.disabledColor.disabledMedium,
      border: 'none',
    },

    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      svg: {
        width: 18,
        height: 18,
      },
    },

    '& .material-icons': {
      fontSize: pxToRem(15),
      marginTop: pxToRem(-2),
    },
  };
};

export default root;
