import { FC } from 'react';

import { Stack, Typography, alpha } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

type ActionsBarProps = {
  onClickGroupButtonHandler?: () => void;
  itemsAmount: number;
  onClickUnselectButtonHandler?: () => void;
};

export const ActionsBar: FC<ActionsBarProps> = ({
  itemsAmount,
  onClickGroupButtonHandler,
  onClickUnselectButtonHandler,
}) => {
  return (
    <Stack
      sx={{
        position: 'sticky',
        bottom: 10,
        width: 1,
        ml: 2,
        zIndex: -1,
        opacity: 0,
        height: 0,
        transition: ({ transitions }) =>
          transitions.create('all', { duration: '0.15s', easing: transitions.easing.easeInOut }),
        ...(itemsAmount && {
          opacity: 1,
          height: 'auto',
          mt: 1,
          zIndex: theme => theme.zIndex.fab,
        }),
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        sx={{
          bgcolor: theme => theme.palette.primary.main,
          boxShadow: theme => theme.boxShadows['shadow-sm'],
          color: theme => theme.palette.common.white,
          borderRadius: theme => theme.borders.borderRadius[12],
          maxWidth: 750,
          width: 1,
          transition: ({ transitions }) =>
            transitions.create('all', { duration: '0.15s', easing: transitions.easing.easeInOut }),
          ...(itemsAmount && {
            height: 1,
            px: 1.5,
            py: { xs: 1.5, sm: 2 },
          }),
        }}
      >
        <Stack
          width={1}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          gap={{ xs: 1, sm: 2 }}
          justifyContent="space-between"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" gap={1}>
            <Typography fontSize={14} fontWeight={600} variant="body2">
              {itemsAmount} selected
            </Typography>

            <MDButton
              sx={{
                color: theme => theme.palette.common.white,
                ':hover': {
                  background: theme => alpha(theme.palette.common.white, 0.2),
                },
              }}
              startIcon={<Icon type="closeSquareIcon" />}
              variant="ghost"
              onClick={onClickUnselectButtonHandler}
            >
              Unselect all
            </MDButton>
          </Stack>

          <MDButton
            sx={{
              color: theme => theme.palette.common.white,
              ':hover': {
                background: theme => alpha(theme.palette.common.white, 0.2),
              },
            }}
            startIcon={<Icon type="groupIcon" />}
            variant="ghost"
            onClick={onClickGroupButtonHandler}
          >
            Group
          </MDButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
