import { useCallback, useEffect, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { ROUTES } from 'constants/routes';
import { useCognitoAuthContext } from 'context';
import { useBoolean, useCountdown, useRouter, useUserProfile } from 'hooks';

import { TimeoutSessionDialogWindow } from './TimeoutSessionDialogWindow';

const DEFAULT_IDLE_TIMEOUT_VALUE = 900000; // 15 minutes

const SESSION_SECONDS_TO_LOGOUT = 60;

const exemptedRoutes: ROUTES[] = [ROUTES.setupAccount];

export const TimeoutSession: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { signOutCognito } = useCognitoAuthContext();
  const { cognitoUser } = useUserProfile();
  const { pathname } = useRouter();

  const [isTimeoutSessionDialogWindowOpen, openTimeoutSessionDialogWindow, closeTimeoutSessionDialogWindow] =
    useBoolean(false);

  const { resetCountdown, countdown, startCountdown, isRunning, stopCountdown, isFinished } = useCountdown({
    seconds: SESSION_SECONDS_TO_LOGOUT,
  });

  const onIdle = useCallback(() => {
    openTimeoutSessionDialogWindow();
    startCountdown();
  }, [openTimeoutSessionDialogWindow]);

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout: DEFAULT_IDLE_TIMEOUT_VALUE,
    throttle: 250,
    crossTab: true,
    name: 'authSessionTimeout',
    startManually: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (exemptedRoutes.includes(pathname as ROUTES) || !cognitoUser) {
      pause();
      return;
    }

    if (cognitoUser) start();
  }, [cognitoUser, pathname]);

  useEffect(() => {
    if (isFinished && !isRunning) {
      closeTimeoutSessionDialogWindow();
      pause();
      resetCountdown();
      signOutCognito();
    }
  }, [isFinished]);

  const [isLoading, setIsLoading] = useState(false);

  const onClickLogoutButton = useCallback(async () => {
    setIsLoading(true);

    const isLoggedOut = await signOutCognito();

    setIsLoading(false);

    if (isLoggedOut) {
      closeTimeoutSessionDialogWindow();
      pause();
      resetCountdown();
    }
  }, [closeTimeoutSessionDialogWindow, pause, stopCountdown, signOutCognito]);

  const onClickStayLoggedInButton = useCallback(() => {
    closeTimeoutSessionDialogWindow();
    start();
    resetCountdown();
  }, [start, closeTimeoutSessionDialogWindow, stopCountdown]);

  return (
    <>
      {children}
      <TimeoutSessionDialogWindow
        onClickApproveButtonHandler={onClickStayLoggedInButton}
        onClickCancelButtonHandler={onClickLogoutButton}
        open={isTimeoutSessionDialogWindowOpen}
        isFinished={isFinished}
        timerValue={countdown}
        startTimerValue={SESSION_SECONDS_TO_LOGOUT}
        isLoading={isLoading}
      />
    </>
  );
};
