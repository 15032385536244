import { useCallback, useState } from 'react';

import { ActionType } from 'types';

export type ActionDialogManagementReturnType = {
  dialogStates: Record<ActionType, boolean>;
  onClickActionOptionHandler: (actionStateData: ActionDataInitialState) => void;
  actionState: ActionDataInitialState;
  isActionPending: boolean;
  setIsActionPending: React.Dispatch<React.SetStateAction<boolean>>;
  closeDialog: (type: ActionType) => void;
  openDialog: (type: ActionType) => void;
  getCloseDialogHandler: (type: ActionType) => (event?: React.MouseEvent<HTMLElement>) => void;
  getOpenDialogHandler: (type: ActionType) => (event?: React.MouseEvent<HTMLElement>) => void;
};

export type ActionDataInitialState = {
  id: string;
  name: string;
  actionType: ActionType | null;
};

const ActionDataInitialStateValue: ActionDataInitialState = {
  actionType: null,
  id: '',
  name: '',
};

export const useActionDialogManagement = (): ActionDialogManagementReturnType => {
  const [isActionPending, setIsActionPending] = useState(false);

  const [actionState, setActionState] = useState(ActionDataInitialStateValue);

  const [dialogStates, setDialogStates] = useState<Record<ActionType, boolean>>({
    activate: false,
    deactivate: false,
    delete: false,
    copy: false,
    publish: false,
    unpublish: false,
  });

  const openDialog = useCallback((type: ActionType) => setDialogStates(prev => ({ ...prev, [type]: true })), []);
  const closeDialog = useCallback((type: ActionType) => setDialogStates(prev => ({ ...prev, [type]: false })), []);

  const getCloseDialogHandler = useCallback(
    (type: ActionType) => () => setDialogStates(prev => ({ ...prev, [type]: false })),
    [],
  );

  const getOpenDialogHandler = useCallback(
    (type: ActionType) => () => setDialogStates(prev => ({ ...prev, [type]: true })),
    [],
  );

  const onClickActionOptionHandler = useCallback((actionStateData: ActionDataInitialState) => {
    setActionState(actionStateData);
    if (actionStateData.actionType) {
      openDialog(actionStateData.actionType);
    }
  }, []);

  return {
    dialogStates,
    onClickActionOptionHandler,
    actionState,
    isActionPending,
    setIsActionPending,
    closeDialog,
    openDialog,
    getCloseDialogHandler,
    getOpenDialogHandler,
  };
};
