import { palette } from 'styles/theme/base/palette';

const { background, text } = palette;

const stepIcon = {
  styleOverrides: {
    root: {
      color: text.secondary,
      width: 26,
      height: 26,
      '.MuiStepIcon-text': {
        fill: text.primary,
        fontWeight: 600,
      },

      '&.Mui-active': {
        '.MuiStepIcon-text': {
          fill: background.default,
        },
      },
    },
  },
};

export default stepIcon;
