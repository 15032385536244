import { useState } from 'react';

import {
  PatientEnum,
  PatientEnums,
  getPatientBiologicalSexesEnums,
  getPatientBloodTypesEnums,
  getPatientEnums,
  getPatientEthnicitiesEnums,
  getPatientGenderEnums,
  getPatientPainTolerancesEnums,
  getPatientPersonTitlesEnums,
  getPatientAlcoholConsumptionsEnums,
  getPatientSmokingEnums,
} from 'apiServices';
import { OptionsInitialState } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';

export type PatientEnumsInitialState<T> = Omit<OptionsInitialState<T>, 'totalPages'>;

const createInitialState = <T>(): PatientEnumsInitialState<T> => ({ isLoading: true, items: [] });

const createFetchHook =
  <T>(
    fetchFunction: () => Promise<{ data: T[] }>,
    stateSetter: React.Dispatch<React.SetStateAction<PatientEnumsInitialState<T>>>,
    errorMessage: string,
    isLoading: boolean,
  ) =>
  async () => {
    !isLoading && stateSetter(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await fetchFunction();
      stateSetter(prev => ({ ...prev, totalPages: null, items: data }));
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: errorMessage } });
    } finally {
      stateSetter(prev => ({ ...prev, isLoading: false }));
    }
  };

export const usePatientEnums = () => {
  const [patientEnumsState, setPatientEnumsState] = useState<{ isLoading: boolean; items: PatientEnums | null }>({
    isLoading: true,
    items: null,
  });

  const fetchAllPatientEnums = async () => {
    setPatientEnumsState(prev => ({ ...prev, isLoading: true }));
    try {
      const { data } = await getPatientEnums();
      setPatientEnumsState(prev => ({ ...prev, items: data }));
    } catch (err) {
      backendErrorHandler({
        error: err,
        config: { customErrorMessage: 'Failed to get patient enums, please try again!' },
      });
    } finally {
      setPatientEnumsState(prev => ({ ...prev, isLoading: false }));
    }
  };

  const [patientGenderEnumsState, setPatientGenderEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientGenderEnums = createFetchHook(
    getPatientGenderEnums,
    setPatientGenderEnumsState,
    'Failed to get patient gender, please try again!',
    patientGenderEnumsState.isLoading,
  );

  const [patientBioSexesEnumsState, setPatientBioSexesEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientBioSexesEnums = createFetchHook(
    getPatientBiologicalSexesEnums,
    setPatientBioSexesEnumsState,
    'Failed to get patient biological sexes, please try again!',
    patientBioSexesEnumsState.isLoading,
  );

  const [patientEthnicitiesEnumsState, setPatientEthnicitiesEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientEthnicitiesEnums = createFetchHook(
    getPatientEthnicitiesEnums,
    setPatientEthnicitiesEnumsState,
    'Failed to get patient ethnicities, please try again!',
    patientEthnicitiesEnumsState.isLoading,
  );

  const [patientPainTolerancesEnumsState, setPatientPainTolerancesEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientPainTolerancesEnums = createFetchHook(
    getPatientPainTolerancesEnums,
    setPatientPainTolerancesEnumsState,
    'Failed to get patient pain tolerances, please try again!',
    patientPainTolerancesEnumsState.isLoading,
  );

  const [patientPersonTitlesEnumsState, setPatientPersonTitlesEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientPersonTitlesEnums = createFetchHook(
    getPatientPersonTitlesEnums,
    setPatientPersonTitlesEnumsState,
    'Failed to get patient person titles, please try again!',
    patientPersonTitlesEnumsState.isLoading,
  );

  const [patientBloodTypesEnumsState, setPatientBloodTypesEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientBloodTypesEnums = createFetchHook(
    getPatientBloodTypesEnums,
    setPatientBloodTypesEnumsState,
    'Failed to get patient blood types, please try again!',
    patientBloodTypesEnumsState.isLoading,
  );

  const [patientSmokingEnumsState, setPatientSmokingEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientSmokingEnums = createFetchHook(
    getPatientSmokingEnums,
    setPatientSmokingEnumsState,
    'Failed to get patient smoking statuses, please try again!',
    patientSmokingEnumsState.isLoading,
  );

  const [patientAlcoholConsumptionEnumsState, setPatientAlcoholConsumptionEnumsState] =
    useState<PatientEnumsInitialState<PatientEnum>>(createInitialState());

  const fetchPatientAlcoholConsumptionsEnums = createFetchHook(
    getPatientAlcoholConsumptionsEnums,
    setPatientAlcoholConsumptionEnumsState,
    'Failed to get patient alcohol consumption statuses, please try again!',
    patientAlcoholConsumptionEnumsState.isLoading,
  );

  return {
    fetchAllPatientEnums,
    patientEnumsState,
    fetchPatientGenderEnums,
    patientGenderEnumsState,
    fetchPatientBioSexesEnums,
    patientBioSexesEnumsState,
    fetchPatientEthnicitiesEnums,
    patientEthnicitiesEnumsState,
    fetchPatientPainTolerancesEnums,
    patientPainTolerancesEnumsState,
    fetchPatientPersonTitlesEnums,
    patientPersonTitlesEnumsState,
    fetchPatientBloodTypesEnums,
    patientBloodTypesEnumsState,
    fetchPatientSmokingEnums,
    patientSmokingEnumsState,
    fetchPatientAlcoholConsumptionsEnums,
    patientAlcoholConsumptionEnumsState,
  };
};
