import { FC, memo } from 'react';

import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Box, Divider, Stack, SxProps, Theme } from '@mui/material';

import { DiscardChangesDialogWindow } from 'components/BaseDialogWindow';
import { MDButton } from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { useDiscardChangesDialogWindow } from 'hooks';

type FormActionsProps = {
  isDisabled: boolean;
  isLoading: boolean;
  onFormSubmitHandler?: (e?: React.BaseSyntheticEvent<object, any, any>) => Promise<void>;
  onCancelHandler?: () => void;
  isDirtyForm: boolean;
  backRoute?: ROUTES;
  sx?: SxProps<Theme>;
};

export const FormActions: FC<FormActionsProps> = memo(
  ({ sx, isDisabled, isLoading, onFormSubmitHandler, onCancelHandler, isDirtyForm, backRoute }) => {
    const {
      closeDiscardDialogWindow,
      isOpenDiscardDialogWindow,
      onClickCancelButtonHandler,
      onClickDiscardButtonHandler,
    } = useDiscardChangesDialogWindow({ backRoute, isDirty: isDirtyForm, onCancelHandler });

    return (
      <>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            bgcolor: theme => theme.palette.background.default,
            borderRadius: ({ borders }) => `0 0 ${borders.borderRadius[12]} ${borders.borderRadius[12]}`,
            ...(isDirtyForm ? {} : { maxHeight: 0, overflow: 'hidden' }),
            transition: theme =>
              theme.transitions.create('all', { duration: '0.25s', easing: theme.transitions.easing.easeInOut }),
            ...sx,
          }}
        >
          <Divider />
          <Box p={2} sx={{ background: 'none' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="flex-end" spacing={2}>
              <MDButton
                startIcon={<CloseRounded fontSize="medium" />}
                variant="secondary"
                onClick={onClickCancelButtonHandler}
              >
                Cancel
              </MDButton>
              <MDButton
                startIcon={<CheckRounded fontSize="medium" />}
                type="submit"
                onClick={onFormSubmitHandler}
                disabled={isDisabled}
                isLoading={isLoading}
              >
                Save
              </MDButton>
            </Stack>
          </Box>
        </Box>

        <DiscardChangesDialogWindow
          open={isOpenDiscardDialogWindow}
          onClickCancelButtonHandler={onClickDiscardButtonHandler}
          onClickApproveButtonHandler={closeDiscardDialogWindow}
        />
      </>
    );
  },
);
