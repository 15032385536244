import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const { secondary, background } = palette;
const { borderWidth, borderRadius } = borders;

const card: {
  defaultProps?: ComponentsProps['MuiCard'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCard'];
  variants?: ComponentsVariants<Theme>['MuiCard'];
} = {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: 0,
      wordWrap: 'break-word',
      backgroundColor: background.default,
      backgroundClip: 'border-box',
      border: `${borderWidth[1]} solid ${background.surfaceStrong}`,
      borderRadius: borderRadius[12],
      boxShadow: 'none',
      overflow: 'visible',
    },
  },
};

export default card;
