type UseEmptyTableStateProps = {
  isPending: boolean;
  total: number;
  isFiltersApplied: boolean;
  debouncedSearchValue: string;
};

export const useEmptyTableState = ({
  isPending,
  total,
  isFiltersApplied,
  debouncedSearchValue,
}: UseEmptyTableStateProps) => {
  const isNoPatients = !isPending && !total;

  const isEmptyState = isNoPatients && !isFiltersApplied && !debouncedSearchValue;
  const isFilteredEmptyState = isNoPatients && (isFiltersApplied || debouncedSearchValue);

  return { isEmptyState, isFilteredEmptyState };
};
