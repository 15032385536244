import { Divider, Skeleton, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFTextField } from 'components/HookForm';
import { SectionWrapper } from 'layouts';

import { CreateBAalbumFormSchema } from '../CreateAlbumDialogWindow/form.config';

type AlbumProfileFormSectionProps = { isLoading?: boolean; isViewOnly?: boolean };

export const AlbumProfileFormSection: React.FC<AlbumProfileFormSectionProps> = ({ isLoading, isViewOnly }) => {
  const {
    register,
    formState: { errors, isDirty },
  } = useFormContext<CreateBAalbumFormSchema>();

  return (
    <>
      <SectionWrapper>
        <Stack spacing={3} maxWidth={700}>
          {isLoading ? (
            <>
              <Skeleton variant="rounded" width="100%" height={40} />
              <Skeleton variant="rounded" width="100%" height={100} />
            </>
          ) : (
            <>
              <RHFTextField
                register={register}
                registerErrors={errors?.name?.message}
                registerName="name"
                label="Name"
                required
                placeholder="Enter name"
                disabled={isViewOnly}
              />

              <RHFTextField
                register={register}
                registerErrors={errors?.description?.message}
                registerName="description"
                label="Description"
                placeholder="Enter description"
                multiline
                minRows={5}
                maxRows={8}
                disabled={isViewOnly}
              />
            </>
          )}
        </Stack>
      </SectionWrapper>
      <Divider
        sx={{
          ...(isDirty && {
            display: 'none',
          }),
        }}
      />
    </>
  );
};
