import { useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';

import {
  GLServicesSearchParams,
  deleteGLProduct,
  getGLProducts,
  importToPLProduct,
  updatePLService,
} from 'apiServices';
import { useUserProfile } from 'hooks/useUserProfile';
import { CATALOG_PRODUCT_MESSAGES } from 'pages/Catalog/constants';
import { useCatalogTableState } from 'pages/Catalog/useCatalogTable.state';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

export const useGLProductsState = () => {
  const {
    GLProducts: { items, total },
    setGLProducts,
  } = useAppStore(
    store => ({
      GLProducts: store.catalogGLData.GLProducts,
      setGLProducts: store.setGLProducts,
    }),
    shallow,
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeUser },
    practiceId,
  } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const onGetProductsHandler = async (params?: GLServicesSearchParams) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGLProducts({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        page,
        ...params,
      });

      setGLProducts({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (params?.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get products, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    debouncedSearchValue,
    setPage,
    resetPageHandler,
    appliedFilters,
    rowsPerPage,
    page,
    ...catalogTableStateMethods
  } = useCatalogTableState({
    fetchItems: onGetProductsHandler,
    updateItem: updatePLService,
    deleteItem: deleteGLProduct,
    copyItem: importToPLProduct,
    practiceId,
    messages: {
      activationError: CATALOG_PRODUCT_MESSAGES.activateFailed,
      activationSuccess: CATALOG_PRODUCT_MESSAGES.activateSuccess,
      deactivationError: CATALOG_PRODUCT_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_PRODUCT_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_PRODUCT_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_PRODUCT_MESSAGES.deleteSuccess,
      copyError: CATALOG_PRODUCT_MESSAGES.copyFailed,
      copySuccess: CATALOG_PRODUCT_MESSAGES.copySuccess,
    },
  });

  useEffect(() => {
    onGetProductsHandler({});
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    items,
    isLoading,
    total,
    rowsPerPage,
    appliedFilters,
    onGetProductsHandler,
    isRoleAdmin,
    isRolePracticeUser,
    page,
    ...catalogTableStateMethods,
  };
};
