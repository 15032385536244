import { Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';

import { ComponentType, SideNavMenuItemProps } from './types';

export const SideNavMenuItem = <C extends ComponentType = 'li'>({
  isActive,
  isDisabled,
  label,
  icon,
  sx,
  component = 'li' as C,
  ...rest
}: SideNavMenuItemProps<C>) => {
  return (
    <Stack
      component={component}
      height={42}
      direction="row"
      spacing={{ xs: 1.5, sm: 1 }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 42,
        backgroundColor: theme => (isActive ? theme.palette.info.main : 'inherit'),
        borderRadius: theme => theme.borders.borderRadius[8],
        color: ({ palette }) => (isActive ? palette.text.primary : palette.text.secondary),
        padding: 1.5,
        transition: ({ transitions }) =>
          transitions.create('background-color', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shorter,
          }),
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'info.main',
        },
        ...(isDisabled && {
          pointerEvents: 'none',
          backgroundColor: 'info.light',
        }),
        ...sx,
      }}
      {...rest}
    >
      {icon && (
        <Icon
          type={icon}
          sx={{
            color: 'inherit',
            svg: {
              width: 16,
              height: 16,
            },
          }}
        />
      )}
      <Typography variant="button" fontWeight={500} color="inherit" textTransform="capitalize" noWrap>
        {label}
      </Typography>
    </Stack>
  );
};
