import * as yup from 'yup';

import { UpdatePatientPayload } from 'apiServices';

export type EditPatientSummaryFormSchema = Pick<UpdatePatientPayload, 'patient_summary'>;

export const editPatientSummaryValidationSchema = yup
  .object()
  .shape({
    patient_summary: yup.string().optional().label('Patient Summary'),
  })
  .required();
