import { memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { MDInput } from 'components/MDInput';
import { notice, ToastType } from 'components/ToastNotification';
import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { DATE_INPUT_PLACEHOLDER } from 'constants/strings';
import { SectionWrapper } from 'layouts';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import { UpdatePracticeProfileFormSchema, updatePracticeProfileValidationSchema } from './validation';
import { ProfileProps } from '../types';

export const Profile: React.FC<ProfileProps> = memo(
  ({ isViewOnly, name, website, created_at, updateAndFetchPractice, updated_at }) => {
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm<UpdatePracticeProfileFormSchema>({
      resolver: yupResolver(updatePracticeProfileValidationSchema),
      mode: 'onTouched',
      values: {
        name: name || '',
        website: website || '',
      },
    });

    const onFormSubmitHandler = handleSubmit(async ({ name, website }) => {
      try {
        await updateAndFetchPractice({
          name,
          ...(website && { website }),
        });

        notice(ToastType.SUCCESS, 'Practice profile has been successfully edited!');
      } catch (error) {
        console.error(error);
        formErrorHandler({
          error,
          config: { formError: { setError } },
          customErrorMessage: 'Failed to edit practice profile, please try again!',
        });
      }
    });

    const fomattedCreatedAt = dateToCustomFormat(created_at, MM_DD_YYYY_DATE_FORMAT);
    const fomattedUpdatedAt = dateToCustomFormat(updated_at, MM_DD_YYYY_DATE_FORMAT);

    const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting;

    return (
      <SectionWrapper>
        <Stack width={1}>
          <Typography fontWeight={500} mb={3}>
            Profile
          </Typography>
          <Stack width={1} gap={3} component="form" onSubmit={onFormSubmitHandler}>
            <Stack direction={{ xs: 'column', sm: 'row' }} width={1} gap={3}>
              <RHFTextField
                register={register}
                registerName="name"
                registerErrors={errors?.name?.message}
                fullWidth
                placeholder="Practice name"
                disabled={isViewOnly}
              />

              <RHFTextField
                register={register}
                registerName="website"
                registerErrors={errors.website?.message}
                fullWidth
                placeholder="Website"
                disabled={isViewOnly}
                slotProps={{
                  input: {
                    startAdornment: <Icon type="website" />,
                  },
                }}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} width={1} gap={3}>
              <MDInput
                fullWidth
                label="Creation Date"
                disabled
                value={fomattedCreatedAt}
                placeholder={DATE_INPUT_PLACEHOLDER}
              />
              <MDInput
                fullWidth
                label="Last Updated"
                disabled
                value={fomattedUpdatedAt}
                placeholder={DATE_INPUT_PLACEHOLDER}
              />
            </Stack>

            <FormActions isDirtyForm={isDirty} isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} />
          </Stack>
        </Stack>
      </SectionWrapper>
    );
  },
);
