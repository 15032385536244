import { Stack, Typography } from '@mui/material';

import { Icon, TIconType } from 'components/Icon';
import { MDButton, MDButtonProps } from 'components/MDButton';

type ActionButtonProps = {
  title: string;
  onClickHandler: () => void | Promise<void>;
  buttonProps?: Omit<MDButtonProps, 'children' | 'onClick'>;
};

type EmptyStateWithActionProps = {
  title: string;
  actionButtonProps?: ActionButtonProps;
  subtitle?: React.ReactNode;
  iconType?: TIconType;
};

export const EmptyStateWithAction: React.FC<EmptyStateWithActionProps> = ({
  iconType,
  title,
  subtitle,
  actionButtonProps,
}) => {
  return (
    <Stack width={1} flex={1} alignItems="center" justifyContent="center" position="relative">
      {iconType && (
        <Icon
          sx={{
            mb: 1,
            color: 'secondary.main',
            svg: {
              width: 34,
              height: 34,
            },
          }}
          type={iconType}
        />
      )}
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Stack>
          <Typography variant="subtitle2" fontWeight={600} textAlign="center">
            {title}
          </Typography>
          {typeof subtitle !== 'string'
            ? subtitle
            : subtitle && (
                <Typography variant="body1" fontWeight={400} textAlign="center">
                  {subtitle}
                </Typography>
              )}
        </Stack>

        {actionButtonProps && (
          <MDButton
            variant="secondary"
            onClick={actionButtonProps?.onClickHandler}
            startIcon={<Icon type="refreshIcon" />}
            disabled={actionButtonProps?.buttonProps?.isLoading}
            {...actionButtonProps?.buttonProps}
          >
            {actionButtonProps.title}
          </MDButton>
        )}
      </Stack>
    </Stack>
  );
};
