import * as yup from 'yup';

import { UpdatePracticePayload } from 'apiServices';

export type UpdatePracticeProfileFormSchema = Pick<UpdatePracticePayload, 'name' | 'website'>;

export const updatePracticeProfileValidationSchema = yup
  .object()
  .shape({
    name: yup.string().required().label('Practice Name'),
    website: yup.string().optional().label('Website'),
  })
  .required();
