import { memo } from 'react';

import { Card, Grid2, Stack, Typography, alpha } from '@mui/material';

import { Icon } from 'components/Icon';

import { PREVIEW_IMAGE_TYPE_PLACEHOLDER } from '../config';
import { GridContentItemProps } from './types';

export const GridContentItem: React.FC<GridContentItemProps> = memo(({ label, type, onSelectContentTypeHandler }) => {
  const onCardClickHandler = () => {
    if (type === 'image' || type === 'beforeAfter') onSelectContentTypeHandler(type);
  };

  return (
    <Grid2
      size={{
        xs: 6,
        sm: 6,
        md: 'auto',
      }}
      key={label}
    >
      <Card
        onClick={onCardClickHandler}
        sx={({ palette }) => {
          return {
            width: { xs: 1, md: 140 },
            height: 100,
            textAlign: 'center',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 1.5,
            boxShadow: 'none',
            ':hover': {
              borderColor: alpha(palette.primary.main, 0.25),
              bgcolor: alpha(palette.primary.main, 0.1),
              color: 'primary.main',
            },
            svg: { width: 38, height: 38, color: 'primary.main' },
          };
        }}
      >
        <Stack alignItems="center" gap={0.5}>
          <Icon type={PREVIEW_IMAGE_TYPE_PLACEHOLDER[type]} />
          <Typography variant="body2" fontSize={14} fontWeight={400}>
            {label}
          </Typography>
        </Stack>
      </Card>
    </Grid2>
  );
});
