import { Theme } from '@mui/material';

export const listItemButtonStyles = (isChecked: boolean) => ({
  ...(isChecked && {
    bgcolor: 'background.surfaceSoft',
  }),
  ':hover': {
    cursor: 'pointer',
    bgcolor: 'background.surfaceSoft',
  },
});

export const labelStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 1,
  gap: 1,
  py: 1.5,
  px: { xs: 1, sm: 2 },
  ':hover': {
    cursor: 'pointer',
  },
};

export const lazyImageStyles = {
  width: 36,
  height: 36,
  borderRadius: (theme: Theme) => theme.borders.borderRadius[8],
};
