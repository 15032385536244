import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { RHFDatePicker, RHFTextField } from 'components/HookForm';
import { FormMenuPopoverButton, MenuPopover } from 'components/MenuPopover';
import { MenuPopoverProps } from 'components/MenuPopover/types';

import { AddTreatmentValidationSchema, addTreatmentValidationSchema, defaultTreatmentValues } from '../../form.config';

type AddTreatmentFormContentProps = Pick<MenuPopoverProps, 'onClose'> & {
  unit: 'units' | 'mL';
};

const AddTreatmentFormContent: React.FC<AddTreatmentFormContentProps> = ({ onClose, unit }) => {
  const {
    control,
    register,
    formState: { isValid, isSubmitting, errors },
  } = useForm<AddTreatmentValidationSchema>({
    mode: 'onTouched',
    resolver: yupResolver(addTreatmentValidationSchema),
    defaultValues: defaultTreatmentValues,
  });

  const onFormSubmitHandler = async () => {};

  return (
    <Stack component="form" onSubmit={onFormSubmitHandler} p={1.5} pb={0} gap={2}>
      <RHFTextField
        label="Areas treated"
        placeholder="Enter Areas Treated"
        register={register}
        registerName="areasTreated"
        registerErrors={errors.areasTreated?.message}
      />

      <RHFDatePicker
        label="Last Treatment Date"
        placeholder="Enter Last Treatment Date"
        control={control}
        registerName="lastTreatmentDate"
      />

      <RHFTextField
        label="Product used"
        placeholder="Enter Product(s) Used"
        register={register}
        registerName="productUsed"
        registerErrors={errors.productUsed?.message}
      />

      <RHFTextField
        label={`Dosage (if known) ${unit}`}
        placeholder="Enter Dosage"
        register={register}
        registerName="dosage"
        type="number"
        registerErrors={errors.dosage?.message}
      />

      <FormMenuPopoverButton isLoading={isSubmitting} disabled={isSubmitting || !isValid} />
    </Stack>
  );
};

type AddTreatmentFormProps = MenuPopoverProps & AddTreatmentFormContentProps;

export const AddTreatmentForm: React.FC<AddTreatmentFormProps> = ({ open, onClose, unit, ...restPopoverProps }) => (
  <MenuPopover
    paperSx={{ ml: 0, width: { xs: open?.clientWidth ?? 'auto', md: 1 }, maxWidth: { xs: 'none', md: 550 } }}
    open={open}
    onClose={onClose}
    {...restPopoverProps}
  >
    <AddTreatmentFormContent onClose={onClose} unit={unit} />
  </MenuPopover>
);
