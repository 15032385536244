import Stack from '@mui/material/Stack';

import { LibraryTabbedPanel } from 'components/Widgets';

import { TABS } from './config';
import { GlobalLibrary } from './pages';
import { PracticeLibrary } from './pages';
import { useCatalogState } from './useCatalog.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibrary />;

    default:
      return <GlobalLibrary />;
  }
}

export const Catalog: React.FC = () => {
  const { handleSetTabValue, tabValue } = useCatalogState();

  return (
    <Stack flex={1}>
      <LibraryTabbedPanel value={tabValue} tabsData={TABS} handleSetTabValue={handleSetTabValue} />
      {renderTabValueContent(tabValue)}
    </Stack>
  );
};
