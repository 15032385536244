import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { GetPatientsParams, createPatient } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFDatePicker, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { LABELS } from 'constants/strings';
import { useOptions, useUserProfile } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import { CreatePatientFormSchema, createPatientValidationSchema, patientFormDefaultValues } from './form.config';

type AddPatientDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  isPracticeField?: boolean;
  onGetPatientHandler: (params?: GetPatientsParams) => Promise<void>;
  resetPageHandler: VoidFunction;
};

export const AddPatientDialogWindowForm: React.FC<AddPatientDialogWindowFormProps> = ({
  onGetPatientHandler,
  isPracticeField,
  onCloseDialogHandler,
  resetPageHandler,
}) => {
  const {
    userRoles: { isRoleAdmin },
    expertProfile,
  } = useUserProfile();

  const {
    handleSubmit,
    setError,
    register,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CreatePatientFormSchema>({
    resolver: yupResolver(createPatientValidationSchema) as Resolver<CreatePatientFormSchema>,
    mode: 'onTouched',
    defaultValues: {
      ...patientFormDefaultValues,
      ...(!isRoleAdmin && {
        practiceId: expertProfile?.practice?.id || '',
      }),
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    const { patient_summary, first_name, last_name, middle_name, email, phone, birth_date, practice_id } = formData;

    try {
      await createPatient({
        first_name,
        last_name,
        middle_name: middle_name || null,
        birth_date: dateToCustomFormat(birth_date),
        email: email || null,
        phone: phone ? `+${phone}` : null,
        patient_summary: patient_summary || null,
        practice_id,
      });

      resetPageHandler();

      notice(ToastType.SUCCESS, 'Patient has been successfully created!');
      onCloseDialogHandler();

      await onGetPatientHandler({ page: 1 });
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const { debouncedPracticeSearchValue, practicesState, onChangePracticeInputValueHandler, fetchPractices } =
    useOptions({});

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{ title: 'Add Patient', onClickCancelButtonHandler: onCloseDialogHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
      }}
    >
      <Scrollbar sx={{ maxHeight: 380, height: 1 }}>
        <Stack spacing={2} height={1} width={1} pb={1}>
          <RHFTextField
            register={register}
            registerName="first_name"
            registerErrors={errors?.first_name?.message}
            fullWidth
            required
            label={LABELS.FIRST_NAME}
            placeholder="Enter First Name"
          />

          <RHFTextField
            register={register}
            registerName="middle_name"
            registerErrors={errors?.middle_name?.message}
            fullWidth
            label={LABELS.MIDDLE_NAME}
            placeholder="Enter Middle Name"
          />

          <RHFTextField
            register={register}
            registerName="last_name"
            registerErrors={errors?.last_name?.message}
            fullWidth
            label={LABELS.LAST_NAME}
            required
            placeholder="Enter Last Name"
          />

          <RHFDatePicker
            isRequired
            control={control}
            registerName="birth_date"
            isDisableFuture
            sx={{
              '.MuiTypography-root': {
                textTransform: 'none',
              },
            }}
          />

          <RHFPhoneTextField
            control={control}
            registerErrors={errors}
            registerName="phone"
            MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
          />

          <RHFTextField
            register={register}
            registerName="email"
            registerErrors={errors?.email?.message}
            fullWidth
            label={LABELS.EMAIL}
            placeholder="Enter Email"
          />

          <RHFTextField
            register={register}
            registerName="patient_summary"
            registerErrors={errors?.patient_summary?.message}
            fullWidth
            multiline
            maxRows={5}
            label="Patient Summary"
            placeholder="Enter Patient Summary"
          />

          {isPracticeField && (
            <InfiniteScrollWrapper
              searchQuery={debouncedPracticeSearchValue}
              totalPages={practicesState.totalPages}
              fetchItemsHandler={fetchPractices}
            >
              {({ ref }) => (
                <RHFAutocompleteField
                  control={control}
                  registerName="practice_id"
                  isRequired
                  label={LABELS.PRACTICE}
                  placeholder="Select Practice"
                  options={practicesState.items}
                  onInputChange={onChangePracticeInputValueHandler}
                  loading={practicesState.isLoading}
                  valueKey="id"
                  labelKey="name"
                  lastElementRef={ref}
                />
              )}
            </InfiniteScrollWrapper>
          )}
        </Stack>
      </Scrollbar>
    </FormDialogWindowContent>
  );
};
