import { StateCreator } from 'zustand';

import { getPatient } from 'apiServices';
import { backendErrorHandler } from 'utils/errorHanders';

import { InitialState, PatientsSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: InitialState = {
  patients: {
    page: null,
    patients: [],
    total: null,
    size: null,
    pages: null,
  },
  patient: null,
  patientBreadcrumb: null,
};

export const PatientsSlice: StateCreator<
  PatientsSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PatientsSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setPatients: patients => {
      set(state => {
        state.patients = patients;
      });
    },
    setPatient: patient => {
      set(state => {
        state.patient = patient;
      });
    },
    fetchPatient: async id => {
      try {
        const { data } = await getPatient(id, { includePhoto: true });
        set(state => {
          state.patient = data;

          state.patientBreadcrumb = {
            id: data.id,
            name: `${data?.first_name} ${data?.middle_name || ''} ${data?.last_name}`,
          };
        });
      } catch (error) {
        console.error(error);
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get patient, please try again!' } });
      }
    },
  };
};
