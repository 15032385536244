import { FC, memo, useCallback, useState } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Card, CardContent, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { useBoolean } from 'hooks';

import { Step, StepContentItem } from '../types';
import { ContentPickerDialogWindow } from './ContentPickerDialogWindow';
import { ContentType, ContentTypePickerDialogWindow } from './ContentTypePickerDialogWindow';
import { StepContent } from './StepContent';

type StepCardProps = Step & {
  phaseId: string;
  onAddContentToStepHandler: (phaseId: string, stepId: string, stepContent: StepContentItem[]) => void;
};

export const StepCard: FC<StepCardProps> = memo(({ phaseId, onAddContentToStepHandler, content, id, name }) => {
  const [isAddContentDialogWindowOpen, onOpenAddContentDialogWindow, onCloseAddContentDialogWindow] = useBoolean();

  const [isSelectContentDialogWindowOpen, onOpenSelectContentDialogWindow, onCloseSelectContentDialogWindow] =
    useBoolean();

  const [selectedContentType, setSelectedContentType] = useState<ContentType | null>(null);

  const onSelectContentTypeHandler = useCallback((contentType: ContentType) => {
    onOpenSelectContentDialogWindow();
    setSelectedContentType(contentType);
  }, []);

  const onAddContentToStepHandlerAndCloseContentTypePicker = useCallback((stepContent: StepContentItem[]) => {
    onAddContentToStepHandler(phaseId, id, stepContent);
    onCloseAddContentDialogWindow();
  }, []);

  return (
    <>
      <Card sx={{ boxShadow: 'none', bgcolor: 'background.surfaceSoft' }}>
        <CardContent
          sx={{
            p: { xs: 2, lg: 3 },
            '&:last-child': {
              paddingBottom: { xs: 2, lg: 3 },
            },
          }}
        >
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" mb={2.5}>
            <Stack direction="row" gap={0.5} alignItems="center">
              <Icon type="dragAndDropIcon" />
              <Typography variant="body2" fontWeight={500} lineHeight={1}>
                {name}
              </Typography>
            </Stack>

            <MDButton size="small" isIconOnly variant="secondary">
              <MoreHorizOutlined fontSize="medium" />
            </MDButton>
          </Stack>

          {content.length ? (
            <StepContent stepContent={content} onClickAddContentHandler={onOpenAddContentDialogWindow} />
          ) : (
            <Stack justifyContent="center" alignItems="center">
              <MDButton variant="ghost" onClick={onOpenAddContentDialogWindow} startIcon={<Icon type="add" />}>
                Add Content
              </MDButton>
            </Stack>
          )}
        </CardContent>
      </Card>

      <ContentTypePickerDialogWindow
        open={isAddContentDialogWindowOpen}
        onClose={onCloseAddContentDialogWindow}
        onSelectContentTypeHandler={onSelectContentTypeHandler}
        hidden={isSelectContentDialogWindowOpen}
      />

      <ContentPickerDialogWindow
        open={isSelectContentDialogWindowOpen}
        onClose={onCloseSelectContentDialogWindow}
        selectedContentType={selectedContentType}
        onAddContentToStepHandler={onAddContentToStepHandlerAndCloseContentTypePicker}
      />
    </>
  );
});
