import { Stack, StackProps, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { TypeColor } from 'types';

type PriorityType = 'primary' | 'secondary' | 'tertiary' | 'future';

const PriorityIndicatorColors: Record<PriorityType, Extract<TypeColor, 'error' | 'success' | 'primary' | 'info'>> = {
  primary: 'error',
  tertiary: 'success',
  future: 'primary',
  secondary: 'info',
};

type PriorityIndicatorProps = StackProps & {
  priorityType: PriorityType;
  label: string;
};

export const PriorityIndicator: React.FC<PriorityIndicatorProps> = ({ priorityType, label, ...stackProps }) => {
  const color = PriorityIndicatorColors[priorityType] || 'error';

  return (
    <Stack direction="row" gap={0.5} alignItems="center" {...stackProps}>
      <Icon
        type="flagIcon"
        sx={{
          color: `${color}.main`,
          svg: {
            width: 14,
            height: 14,
            fill: theme => theme.palette[color].main,
          },
        }}
      />
      <Typography sx={{ color: theme => theme.palette[color].main }} variant="body2" fontWeight={500} fontSize={14}>
        {label}
      </Typography>
    </Stack>
  );
};
