import { memo } from 'react';

import { CheckRounded } from '@mui/icons-material';
import { IconButton, ListItemButton, ListItemText } from '@mui/material';

import { Icon } from 'components/Icon';
import { useCopyToClipboard } from 'hooks';

type CollapseFieldItemProps = {
  field: string;
  model: string;
  insertTagHandler?: (tag: string) => void;
};

export const CollapseFieldItem = memo(({ field, model, insertTagHandler }: CollapseFieldItemProps) => {
  const { copyToClipboard, isCopied } = useCopyToClipboard({});

  const tag = `${model?.toLowerCase()}.${field}`;

  const onClickCopyHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(tag);
  };

  const onClickItemButtonHandler = () => insertTagHandler?.(tag);

  return (
    <ListItemButton sx={{ px: 1.5 }} onClick={onClickItemButtonHandler}>
      <ListItemText
        sx={{ wordWrap: 'break-word' }}
        primary={field}
        primaryTypographyProps={{ fontSize: 14, fontWeight: 400, color: 'text.secondary' }}
      />
      <IconButton size="small" onClick={onClickCopyHandler}>
        {isCopied ? (
          <CheckRounded color="success" sx={{ fontSize: '16px !important' }} />
        ) : (
          <Icon type="copy" sx={{ svg: { height: 16, width: 16 } }} />
        )}
      </IconButton>
    </ListItemButton>
  );
});
