import { memo } from 'react';

import Stack from '@mui/material/Stack';

import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { ScrollableSideNavMenu } from 'components/SideNavMenu';
import { ROUTES } from 'constants/routes';

import { AllergiesContent, GeneralContent, LifestyleContent, MedicalConditionsContent } from './components';
import { useMedicalState } from './useMedical.state';

export const Medical: React.FC = memo(() => {
  const {
    formMethods,
    activeSection,
    allergiesRef,
    generalRef,
    lifestyleRef,
    medicalConditionsRef,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    patientId,
    fetchPatient,
    isDirty,
    patient,
    sidenavItems,
  } = useMedicalState();

  return (
    <Stack direction={{ xs: 'column', md: 'row' }}>
      <ScrollableSideNavMenu activeSectionId={activeSection} sidenavItems={sidenavItems} />

      <FormProvider isBorders methods={formMethods} onSubmit={onFormSubmitHandler}>
        <GeneralContent contentRef={generalRef} />

        <LifestyleContent contentRef={lifestyleRef} />

        <AllergiesContent
          patientId={patientId}
          fetchPatient={fetchPatient}
          patientAllergies={patient?.allergies}
          contentRef={allergiesRef}
        />

        <MedicalConditionsContent
          patientId={patientId}
          fetchPatient={fetchPatient}
          patientMedicalConditions={patient?.medical_conditions}
          contentRef={medicalConditionsRef}
        />

        <FormActions
          isDisabled={isSubmitButtonDisabled}
          isLoading={isSubmitting}
          onFormSubmitHandler={onFormSubmitHandler}
          isDirtyForm={isDirty}
          backRoute={ROUTES.patients}
        />
      </FormProvider>
    </Stack>
  );
});
