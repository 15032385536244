import { FC } from 'react';

import { IllustrationLayout } from 'layouts';

import { AlreadyVerifiedEmail, CompletedStep, SetupCredentials } from './components';
import { STEPS_FLOW } from './types';
import { useSignUpState } from './useSignUp.state';

export const SetupAccount: FC = () => {
  const { activeStep, handleNextStep } = useSignUpState();

  return (
    <IllustrationLayout authMode="signUp">
      {activeStep === STEPS_FLOW.SETUP_CREDENTIALS && <SetupCredentials handleNextStep={handleNextStep} />}

      {activeStep === STEPS_FLOW.COMPLETED && <CompletedStep />}

      {activeStep === STEPS_FLOW.EMAIL_ALREADY_VERIFIED && <AlreadyVerifiedEmail />}
    </IllustrationLayout>
  );
};
