import { FC } from 'react';

import { SearchOutlined } from '@mui/icons-material';
import { Box, Stack, StackProps, Typography } from '@mui/material';

type IEmptyContentProps = StackProps;

export const EmptyContent: FC<IEmptyContentProps> = props => {
  const { sx, ...other } = props;
  return (
    <Stack
      spacing={2}
      textAlign="center"
      sx={{
        height: 1,
        textAlign: 'center',
        p: theme => theme.spacing(5, 2),
        ...sx,
      }}
      {...other}
    >
      <Stack spacing={1.5} justifyContent="center" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            boxShadow: theme => theme.boxShadows['shadow-md'],
            bgcolor: theme => theme.palette.background.default,
            width: 42,
            height: 42,
            border: theme => `1px solid ${theme.palette.background.surfaceSoft}`,
            borderRadius: theme => theme.borders.borderRadius[8],
          }}
        >
          <SearchOutlined fontSize="medium" />
        </Box>
        <Stack spacing={1}>
          <Typography variant="h6" fontSize="size.bodyLarge" fontWeight="fontWeightBold">
            No results found
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
