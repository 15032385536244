import { FC, memo, useState } from 'react';

import { LogoutOutlined, MoreHorizRounded } from '@mui/icons-material';
import { IconButton, MenuList, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { MDAvatar } from 'components/MDAvatar';
import { MenuPopover, PopoverMenuItem } from 'components/MenuPopover';
import { ROUTES } from 'constants/routes';
import { useCognitoAuthContext } from 'context';
import { useBoolean, usePopover, useUserProfile } from 'hooks';

import { createTransition } from './helpers';

type NavAccountProps = {
  isNavMini: boolean;
};

export const NavAccount: FC<NavAccountProps> = memo(({ isNavMini }) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const [isLogoutWindow, openLogoutWindow, closeLogOutWindow] = useBoolean(false);

  const navigate = useNavigate();

  const { signOutCognito } = useCognitoAuthContext();

  const {
    cognitoUser,
    expertProfile,
    userRoles: { isRoleAdmin },
  } = useUserProfile();

  const onClickProfileButtonHandler = () => {
    navigate(ROUTES.profile);
    handleClosePopover();
  };

  const onClickLogoutMenuItemHandler = () => {
    openLogoutWindow();
    handleClosePopover();
  };

  const [isLoading, setIsLoading] = useState(false);

  const onClickLogoutHandler = async () => {
    setIsLoading(true);

    await signOutCognito();
    closeLogOutWindow();

    setIsLoading(false);
  };

  return (
    <Stack
      pt={1}
      direction={isNavMini ? 'column' : 'row'}
      spacing={0.5}
      justifyContent={isNavMini ? 'center' : 'space-between'}
      alignItems="center"
    >
      <Tooltip title={isNavMini ? cognitoUser?.username : ''} placement="right">
        <Stack direction="row" minWidth={0} spacing={isNavMini ? 0 : 0.75} alignItems="center">
          <MDAvatar
            avatarSize="sm"
            name={cognitoUser?.username}
            src={!isRoleAdmin ? expertProfile?.avatar_url : null}
          />

          <Typography
            variant="caption"
            fontWeight={400}
            fontSize={14}
            noWrap
            sx={{
              transition: theme => createTransition(theme, 'all'),
              ...(isNavMini && {
                overflow: 'hidden',
                flexGrow: 0,
                display: 'none',
              }),
            }}
          >
            {cognitoUser?.username}
          </Typography>
        </Stack>
      </Tooltip>

      <IconButton size="small" onClick={handleOpenPopover}>
        <MoreHorizRounded />
      </IconButton>

      <MenuPopover
        open={openPopover}
        arrow="left-bottom"
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disabledArrow
      >
        <MenuList>
          <PopoverMenuItem title="Profile" onClick={onClickProfileButtonHandler} />

          <PopoverMenuItem
            title="Logout"
            icon={<LogoutOutlined fontSize="small" />}
            onClick={onClickLogoutMenuItemHandler}
            sx={{
              ':hover': {
                backgroundColor: theme => theme.palette.error.main,
                color: theme => theme.palette.common.white,
              },
            }}
          />
        </MenuList>
      </MenuPopover>

      <BaseDialogWindow
        open={isLogoutWindow}
        title="Logout"
        onClickCancelButtonHandler={closeLogOutWindow}
        description="Are you sure you want to log out?"
        cancelButtonTitle="Back"
        approveButtonTitle="Log out"
        onClickApproveButtonHandler={onClickLogoutHandler}
        isApproveButtonLoading={isLoading}
        isApproveButtonDisabled={isLoading}
        approveButtonProps={{
          sx: {
            minWidth: 100,
          },
        }}
        sx={{
          '.MuiDialogActions-root > .MuiStack-root': {
            alignItems: 'stretch',
          },
        }}
      />
    </Stack>
  );
});
