import { useCallback, useEffect } from 'react';

import { createPatientMedicalCondition, deletePatientMedicalCondition } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useDictionary, usePopover } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

type UsePatientMedicalCondtionsProps = {
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
};

// TODO: refactor this hook
export const usePatientMedicalConditions = ({ patientId, fetchPatient }: UsePatientMedicalCondtionsProps) => {
  const { fetchMedicalConditions, medicalConditions, isMedicalConditionsLoading } = useDictionary();

  useEffect(() => {
    fetchMedicalConditions();
  }, []);

  const [anchor, openPopoverHandler, closePopoverHandler] = usePopover();

  const {
    dialogStates,
    getCloseDialogHandler,
    closeDialog,
    actionState,
    isActionPending,
    setIsActionPending,
    onClickActionOptionHandler,
  } = useActionDialogManagement();

  const onDeletePatientMedicalConditionHandler = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      if (!actionState.id) return;

      setIsActionPending(true);

      try {
        await deletePatientMedicalCondition(patientId, actionState.id);

        closeDialog('delete');
        notice(ToastType.SUCCESS, 'Medical condition has been successfully removed!');
      } catch (error) {
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove medical condition, please try again!' },
        });
      } finally {
        setIsActionPending(false);
      }
    },

    [patientId],
  );

  const onCreateMedicalConditonHandler = useCallback(
    async (medical_condition_icd10_code: string) => {
      try {
        await createPatientMedicalCondition(patientId, { medical_condition_icd10_code, symptoms: 'Symptoms' });

        notice(ToastType.SUCCESS, `The allergy has been successfully added!`);

        await fetchPatient(patientId);
      } catch (error) {
        backendErrorHandler({
          error,
          config: {
            customErrorMessage: `Failed to add medical condition, please try again!`,
          },
        });
      }
    },
    [patientId],
  );

  return {
    fetchMedicalConditions,
    medicalConditions,
    isMedicalConditionsLoading,
    anchor,
    openPopoverHandler,
    closePopoverHandler,
    onDeletePatientMedicalConditionHandler,
    onCreateMedicalConditonHandler,
    onClickActionOptionHandler,
    isActionPending,
    actionState,
    dialogStates,
    getCloseDialogHandler,
  };
};
