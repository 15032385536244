import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import pxToRem from '../functions/pxToRem';

const svgIcon: {
  defaultProps?: ComponentsProps['MuiSvgIcon'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSvgIcon'];
  variants?: ComponentsVariants<Theme>['MuiSvgIcon'];
} = {
  defaultProps: {
    fontSize: 'inherit',
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: 'inherit !important',
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};

export default svgIcon;
