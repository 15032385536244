import { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { updatePatient } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useResponsive } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { EditPatientSummaryFormSchema, editPatientSummaryValidationSchema } from './validation';
import { usePatientStore } from '../../usePatientStore';

export const usePatientSummaryState = () => {
  const { fetchPatient, patient } = usePatientStore();

  const isWebView = useResponsive('down', 431);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<EditPatientSummaryFormSchema>({
    resolver: yupResolver(editPatientSummaryValidationSchema),
    mode: 'onTouched',
    values: {
      patient_summary: patient?.patient_summary || '',
    },
  });

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting;

  const onFormSubmitHandler = handleSubmit(async ({ patient_summary }) => {
    try {
      await updatePatient(patient?.id, {
        ...(patient_summary && { patient_summary }),
      });

      notice(ToastType.SUCCESS, 'Patient summary has been successfully updated!');
      await fetchPatient(patient?.id);
    } catch (error) {
      formErrorHandler({
        error,
        config: {
          formError: { setError },
        },
        customErrorMessage: 'Failed to update patient summary, please try again later!',
      });
    }
  });

  return {
    isWebView,
    patient,
    isSubmitButtonDisabled,
    isDirty,
    errors,
    register,
    onFormSubmitHandler,
    isSubmitting,
  };
};
