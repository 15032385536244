import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { MDTabs } from 'components/MDTabs';

import { TABS } from './config';
import { EvaluationHistoryTabContent } from './EvaluationHistoryTabContent';
import { LLM } from './LLM';
import { TestingTabContent } from './TestingTabContent';
import { UpdatePromptTabContent, UpdatePromptTabContentProps } from './UpdatePromptTabContent';
import { usePromptViewState } from './usePromptView.state';

const renderTabContent = (tabValue: number, isRoleAItester: boolean, updateTabProps: UpdatePromptTabContentProps) => {
  if (isRoleAItester) return tabValue === 0 ? <TestingTabContent /> : <EvaluationHistoryTabContent />;

  switch (tabValue) {
    case 0:
      return <UpdatePromptTabContent {...updateTabProps} />;
    case 1:
      return <TestingTabContent />;
    case 2:
      return <EvaluationHistoryTabContent />;
    default:
      return null;
  }
};

export const PromptView: React.FC = () => {
  const { handleViewTabValue, viewTabValue, handleSetTabValue, tabValue, back, prompt, isLoading, isRoleAItester } =
    usePromptViewState();

  const visibleTabs = isRoleAItester ? TABS.slice(1) : TABS;

  return (
    <Box component="section" flex={1} display="flex" flexDirection="column" width={1}>
      <Box
        display="flex"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        maxWidth={{ xs: 1, sm: 400 }}
        width={1}
        mt={1}
      >
        <MDTabs
          allowScrollButtonsMobile
          value={viewTabValue}
          tabsData={['A360', 'LLM']}
          handleSetTabValue={handleViewTabValue}
        />
      </Box>
      {viewTabValue === 1 ? (
        <LLM />
      ) : (
        <>
          <Stack>
            <Stack px={2} pt={1.5} alignItems="flex-start" justifyContent="flex-start">
              <MDButton onClick={back} variant="ghost" sx={{ px: 0.5 }} startIcon={<Icon type="arrowLeftIcon" />}>
                Back
              </MDButton>

              {isLoading ? (
                <Skeleton variant="rounded" height={24} width="60%" />
              ) : (
                <Typography
                  noWrap
                  textAlign={{ xs: 'center', sm: 'start' }}
                  fontSize={{ xs: 14, md: 16, lg: 18 }}
                  fontWeight={500}
                  sx={{
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    whiteSpace: 'normal',
                  }}
                >
                  {prompt?.name}
                </Typography>
              )}
              <Box
                display="flex"
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                maxWidth={{ xs: 1, sm: 400 }}
                width={1}
                mt={1}
              >
                <MDTabs
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  value={tabValue}
                  tabsData={visibleTabs}
                  handleSetTabValue={handleSetTabValue}
                />
              </Box>
            </Stack>
            <Divider sx={{ mt: '-1px' }} />
          </Stack>

          {renderTabContent(tabValue, isRoleAItester, { isLoading, prompt })}
        </>
      )}
    </Box>
  );
};
