import { useEffect, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import { GLServicesSearchParams, deletePLProduct, getPLProducts, updatePLProduct } from 'apiServices';
import { useUserProfile } from 'hooks';
import { CATALOG_PRODUCT_MESSAGES } from 'pages/Catalog/constants';
import { useCatalogTableState } from 'pages/Catalog/useCatalogTable.state';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

export const usePLProductsState = () => {
  const {
    PLProducts: { items, total },
    setPLProducts,
  } = useAppStore(
    store => ({
      PLProducts: store.catalogPLData.PLProducts,
      setPLProducts: store.setPLProducts,
    }),
    shallow,
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeUser },
  } = useUserProfile();

  const [isPending, startTransition] = useTransition();

  const onGetProductsHandler = async (params?: GLServicesSearchParams) =>
    startTransition(async () => {
      try {
        const { data } = await getPLProducts({
          ...(debouncedSearchValue && { search: debouncedSearchValue }),
          is_active: !appliedFilters.isShowDeactivated,
          size: rowsPerPage,
          page,
          ...params,
        });

        setPLProducts({
          items: data.items,
          page: data.page,
          pages: data.pages,
          size: data.size,
          total: data.total,
        });
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get products, please try again!' } });
      }
    });

  const { debouncedSearchValue, appliedFilters, rowsPerPage, setPage, page, ...catalogTableStateMethods } =
    useCatalogTableState({
      fetchItems: onGetProductsHandler,
      updateItem: updatePLProduct,
      deleteItem: deletePLProduct,
      messages: {
        activationError: CATALOG_PRODUCT_MESSAGES.activateFailed,
        activationSuccess: CATALOG_PRODUCT_MESSAGES.activateSuccess,
        deactivationError: CATALOG_PRODUCT_MESSAGES.inactivateFailed,
        deactivationSuccess: CATALOG_PRODUCT_MESSAGES.inactivateSuccess,
        deletionError: CATALOG_PRODUCT_MESSAGES.deleteFailed,
        deletionSuccess: CATALOG_PRODUCT_MESSAGES.deleteSuccess,
      },
    });

  useEffect(() => {
    onGetProductsHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    items,
    isLoading: isPending,
    total,
    page,
    onGetProductsHandler,
    isRoleAdmin,
    isRolePracticeUser,
    rowsPerPage,
    appliedFilters,
    ...catalogTableStateMethods,
  };
};
