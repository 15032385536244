import { FC, memo, useEffect } from 'react';

import { Card, Divider, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { CustomTableHeader, TableNoData } from 'components/Table';
import { useDictionary } from 'hooks/useDictionary';
import { SectionWrapper } from 'layouts';

import { ALLERGIES_ID } from '../../constants';
import { useMedicalRecordTableState } from '../../useMedicalRecordTableState';
import { AddEditMedicalRecordTableRow } from '../AddEditMedicalRecordTableRow';
import { MedicalRecordRow } from '../MedicalRecordRow';
import { ALLERGIES_MEDICAL_RECORD_TABLE_HEAD } from '../table.config';
import { AllergiesContentProps } from '../types';

export const AllergiesContent: FC<AllergiesContentProps> = memo(
  ({ patientId, contentRef, patientAllergies, fetchPatient }) => {
    const { fetchAllergies, allergies } = useDictionary();

    useEffect(() => {
      fetchAllergies({ page: 1, size: 100 });
    }, []);

    const {
      isAddMedicalRecord,
      selectedMedicalRecordId,
      onClickEditButtonHandler,
      onClickAddButtonHandler,
      onClickCloseButtonHandler,
      onClickDeleteButtonHandler,
    } = useMedicalRecordTableState();

    return (
      <>
        <SectionWrapper ref={contentRef} id={ALLERGIES_ID}>
          <Card>
            <Stack p={2} gap={1} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
                Allergies
              </Typography>

              <MDButton
                onClick={onClickAddButtonHandler}
                size="small"
                variant="secondary"
                startIcon={<Icon type="add" />}
              >
                Add Allergy
              </MDButton>
            </Stack>
            <TableContainer>
              <Table size="medium">
                <CustomTableHeader headLabel={ALLERGIES_MEDICAL_RECORD_TABLE_HEAD} />

                <TableBody>
                  {isAddMedicalRecord && (
                    <AddEditMedicalRecordTableRow
                      options={allergies}
                      onSaveHandler={async () => {}}
                      onCancelHandler={onClickCloseButtonHandler}
                      getOptionLabel={option => option?.name}
                    />
                  )}

                  {patientAllergies?.map(allergy =>
                    selectedMedicalRecordId === allergy?.id ? (
                      <AddEditMedicalRecordTableRow
                        options={patientAllergies}
                        option={allergy}
                        note={allergy?.allergy?.note}
                        onSaveHandler={async () => {}}
                        onCancelHandler={onClickCloseButtonHandler}
                        getOptionLabel={option => option?.allergy?.allergy_icd10_code}
                      />
                    ) : (
                      <MedicalRecordRow
                        key={allergy?.id}
                        id={allergy?.id}
                        onClickEdit={onClickEditButtonHandler}
                        onClickDelete={onClickDeleteButtonHandler}
                        name="Allergy"
                        createdAt="2021-10-10"
                      />
                    ),
                  )}
                  {!isAddMedicalRecord && <TableNoData isNotFound={!patientAllergies?.length} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </SectionWrapper>
        <Divider />
      </>
    );
  },
);
