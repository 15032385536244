import { FC, memo } from 'react';

import { Box, Card, CardContent, Chip, Stack } from '@mui/material';
import { FileWithPath } from 'react-dropzone';

import { DropZone } from 'components/DropZone';
import { Icon } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';
import { MDButton } from 'components/MDButton';
import { UploadFileButton } from 'components/UploadFileButton';
import { ACCEPT_DROPZONE_IMAGE_FILES } from 'constants/fileExtensions';
import rgba from 'styles/theme/functions/rgba';

type UploadImageType = 'before' | 'after';

type UploadImageCardProps = {
  previewURL: string;
  onClickRemoveImageHandler: VoidFunction;
  onUploadAnotherFileHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDropFileHandler: (file: readonly FileWithPath[]) => void;
  isDisabled?: boolean;
  type: UploadImageType;
};

export const UploadImageCard: FC<UploadImageCardProps> = memo(
  ({ previewURL, onUploadAnotherFileHandler, onClickRemoveImageHandler, onDropFileHandler, isDisabled, type }) => {
    const label = type === 'before' ? 'Before' : 'After';

    const renderImageAndActions = (isImageLoaded: boolean) => {
      if (isImageLoaded) {
        return (
          <>
            <Chip
              sx={{
                bgcolor: theme => theme.palette.common.white,
                position: 'absolute',
                top: 10,
                left: '50%',
                transform: 'translateX(-50%)',
              }}
              variant="soft"
              color="default"
              label={label}
            />
            <Stack
              spacing={1}
              className="action-buttons"
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 0,
                right: 0,
                px: 2,
                width: 'auto',
                display: 'none',
              }}
            >
              <UploadFileButton
                startIcon={<Icon type="uploadIcon" />}
                fullWidth
                onChangeHandler={onUploadAnotherFileHandler}
              >
                Upload another
              </UploadFileButton>
              <MDButton fullWidth onClick={onClickRemoveImageHandler}>
                Remove
              </MDButton>
            </Stack>
          </>
        );
      }
      return null;
    };

    return (
      <Card
        sx={{
          overflow: 'hidden',
          width: 1,
          boxShadow: 'none',
          height: 362,
          ...(isDisabled && {
            ':before': {
              content: '""',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              background: theme => rgba(theme.palette.common.white, 0.5),
              zIndex: theme => theme.zIndex.speedDial,
              borderRadius: theme => theme.borders.borderRadius[10],
            },
          }),
        }}
      >
        {previewURL ? (
          <Stack
            height={1}
            sx={{
              width: 'auto',
              ':hover': {
                '& .action-buttons': {
                  display: 'block',
                },
              },
            }}
          >
            <LazyImage src={previewURL} alt="before & after image">
              {({ isImageLoaded }) => renderImageAndActions(isImageLoaded)}
            </LazyImage>
          </Stack>
        ) : (
          <CardContent
            sx={{
              height: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 3,
              pt: 3,
              ':last-child': { pb: 3 },
            }}
          >
            <DropZone
              buttonTitle="Upload image"
              onUploadFileHandler={onDropFileHandler}
              uploadButtonProps={{ startIcon: <Icon type="uploadIcon" /> }}
              dropzoneChildren={
                <Box display="flex" justifyContent="center" mb={2.5}>
                  <Chip variant="soft" color="default" label={label} />
                </Box>
              }
              options={{ accept: ACCEPT_DROPZONE_IMAGE_FILES }}
            />
          </CardContent>
        )}
      </Card>
    );
  },
);
