import { AutocompleteProps, CSSObject, ComponentsOverrides, ComponentsProps, Theme, alpha } from '@mui/material';

import { Icon } from 'components/Icon';
import borders from 'styles/theme/base/borders';
import { boxShadows } from 'styles/theme/base/boxShadows';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { size } = typography;
const { disabledColor, primary, text, info, background } = palette;
const { borderRadius, borderWidth } = borders;

const rootStyle = (ownerState: AutocompleteProps<any, any, any, any, 'div'> & Record<string, unknown>) => {
  const defaultStyles: CSSObject = {
    borderRadius: borderRadius[8],
    '& fieldset': {
      borderWidth: borderWidth[1],
    },

    '& .Mui-disabled': {
      backgroundColor: disabledColor.disabledSoft,
    },

    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, &.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot':
      {
        paddingRight: 42,
      },

    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      paddingRight: 64,
    },

    '& .MuiAutocomplete-inputRoot .MuiInput-input': {
      padding: 0,
    },

    '& .MuiInput-underline': {
      paddingLeft: 12,
    },

    ...(ownerState.size === 'medium' && {
      '& .MuiInputBase-root': {
        height: 42,
        fontSize: 14,
      },
    }),
    ...(ownerState.size === 'small' && {
      '& .MuiInputBase-root': {
        height: 38,
        paddingLeft: 8,
        fontSize: 12,
      },
      '& .MuiInputBase-sizeSmall': {
        paddingLeft: '8px !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
      },
    }),
    ...(ownerState.multiple && {
      '& .MuiInputBase-root': {
        height: 'unset',
        ...(ownerState.size === 'medium' && {
          minHeight: 42,
        }),
        ...(ownerState.size === 'small' && {
          minHeight: 38,
        }),
      },
    }),
  };

  return [defaultStyles];
};

const autocomplete: {
  defaultProps?: ComponentsProps['MuiAutocomplete'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAutocomplete'];
} = {
  defaultProps: {
    ChipProps: {
      deleteIcon: <Icon type="closeIcon" />,
    },
    popupIcon: <Icon type="arrowLeftIcon" sx={{ svg: { width: 20, height: 20 }, transform: 'rotate(-90deg)' }} />,
  },
  styleOverrides: {
    root: ({ ownerState }) => rootStyle(ownerState),

    popper: {
      boxShadow: boxShadows['shadow-md'],
      padding: `${pxToRem(4)} ${pxToRem(6)}`,
      fontSize: size.bodyMedium,
      color: text.primary,
      textAlign: 'left',
      backgroundColor: `${background.default} !important`,
      borderRadius: borderRadius[8],
      border: `${borders.borderWidth[1]} solid ${background.surfaceStrong}`,
      marginTop: '3px !important',
    },

    popupIndicator: {
      transition: 'all 0.3s',
      marginRight: 0,
    },

    paper: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    listbox: {
      paddingTop: pxToRem(4),
      paddingBottom: pxToRem(4),
      '& .MuiAutocomplete-option[aria-selected="true"], .Mui-focused': {
        color: `${text.primary}`,
      },
    },

    option: {
      padding: `${pxToRem(8)} ${pxToRem(12)} !important`,
      borderRadius: borderRadius[4],
      fontSize: size.bodyMedium,
      color: text.primary,
      ':first-of-type': {
        marginTop: 0,
      },
      marginTop: 3,
      transition: 'background-color 300ms ease, color 300ms ease',

      '&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
        color: text.primary,
      },
    },

    noOptions: {
      fontSize: size.bodyMedium,
      color: text.primary,
      padding: pxToRem(4),
    },

    groupLabel: {
      color: text.primary,
    },

    loading: {
      fontSize: size.bodyMedium,
      color: text.primary,
    },

    tag: {
      alignItems: 'center',
      height: 28,
      padding: 6,
      backgroundColor: info.main,
      color: primary.main,
      borderRadius: borderRadius[8],
      maxWidth: 'calc(100% - 20%)',
      border: 0,
      ':hover': {
        backgroundColor: alpha(info.main, 0.55),
      },

      '& .MuiChip-label': {
        fontSize: size.bodySmall,
        textTransform: 'capitalize',
        lineHeight: 'initial',
        alignSelf: 'center',
        padding: '0 4px 0 0',
      },

      '& .MuiChip-deleteIcon': {
        color: primary.main,
        margin: 0,
        '&:hover': {
          color: alpha(primary.main, 0.9),
        },
      },
      '&.Mui-disabled': {
        border: `${borderWidth[1]} solid ${disabledColor.disabledMedium}`,
        color: disabledColor.disabledMedium,
        '& .MuiChip-deleteIcon': {
          color: disabledColor.disabledMedium,
        },
      },
    },
    endAdornment: {
      right: 12,
    },
  },
};

export default autocomplete;
