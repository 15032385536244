import { useCallback, useState } from 'react';

import { SortDirection } from 'types';

import { useDebounce } from './useDebounce';
import { usePopover } from './usePopover';

export type UseTableProps<TSortPropertyName, TFilters = undefined> = {
  defaultOrder?: SortDirection;
  defaultOrderBy?: TSortPropertyName;
  defaultSelected?: string[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
  defaultFilters?: TFilters;
};

const PAGINATION_SETTINGS = {
  page: 1,
  itemsPerPage: 20,
};

export const useTable = <TSortPropertyName, TFilters = undefined>(
  props?: UseTableProps<TSortPropertyName, TFilters>,
) => {
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const [orderBy, setOrderBy] = useState<TSortPropertyName | null>(props?.defaultOrderBy || null);

  const [order, setOrder] = useState<SortDirection>(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || PAGINATION_SETTINGS.page);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || PAGINATION_SETTINGS.itemsPerPage);

  const [selected, setSelected] = useState<string[]>(props?.defaultSelected || []);

  const onSort = useCallback(
    (id: TSortPropertyName) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id) {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
      }
    },
    [order, orderBy],
  );

  const onSelectRow = useCallback(
    (id: string) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const onSelectAllRows = useCallback((checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage + 1);
  }, []);

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  const resetPageHandler = () => {
    if (page !== 1) setPage(1);
  };

  const onChangeSearchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    resetPageHandler();
  };

  const [isFiltersApplied, setIsFiltersApplied] = useState(false);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isResetFilters, setIsResetFilters] = useState(false);

  const setResetFilters = useCallback(() => setIsResetFilters(true), []);

  const [appliedFilters, setAppliedFilters] = useState<TFilters>(props?.defaultFilters || ({} as TFilters));

  const onApplyFilters = (filterData: TFilters) => {
    if (isResetFilters) {
      setIsResetFilters(false);
      setIsFiltersApplied(false);
    } else {
      setIsFiltersApplied(true);
    }

    setAppliedFilters(filterData);

    resetPageHandler();

    closeFiltersMenu();
  };

  return {
    order,
    page,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,
    searchValue,
    onChangeSearchValueHandler,
    debouncedSearchValue,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    onApplyFilters,
    isFiltersApplied,
    appliedFilters,
    setResetFilters,
    resetPageHandler,
  };
};
