import borders from '../base/borders';
import pxToRem from '../functions/pxToRem';

const { borderRadius } = borders;

const linearProgress = {
  styleOverrides: {
    root: {
      height: pxToRem(6),
      borderRadius: borderRadius[4],
      overflow: 'visible',
    },

    bar: {
      height: pxToRem(6),
      borderRadius: borderRadius[2],
      transform: `translate(0, 0) !important`,
      transition: 'width 0.6s ease !important',
    },
  },
};

export default linearProgress;
