import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { RHFTextField } from 'components/HookForm';
import { MDButton } from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { LABELS, VERIFY_TOTP_CODE_TITLES } from 'constants/strings';
import { BackButton, IllustrationLayout } from 'layouts';

import { VerifyTOTPcodeStep } from './components';
import { useSignInState } from './hooks/useSignIn.state';
import { AUTH_STEPS } from './types';

export const SignIn: React.FC = () => {
  const {
    errors,
    isSubmitButtonDisabled,
    isSubmitting,
    onFormSubmitHandler,
    register,
    authStep,
    getCognitoCurrentUser,
    getExpertProfileHandler,
    isRememberMe,
    onChangeRememberMeHandler,
  } = useSignInState();

  return (
    <>
      {authStep === AUTH_STEPS.SIGN_IN && (
        <IllustrationLayout title="Sign In" description="Please enter your credentials to log in">
          <form onSubmit={onFormSubmitHandler}>
            <Stack spacing={2.5}>
              <Stack spacing={2.5}>
                <RHFTextField
                  registerName="email"
                  registerErrors={errors?.email?.message}
                  type="email"
                  label={LABELS.EMAIL}
                  placeholder="Enter your email"
                  fullWidth
                  register={register}
                />

                <RHFTextField
                  register={register}
                  registerName="password"
                  registerErrors={errors?.password?.message}
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  fullWidth
                  isPasswordField
                />
              </Stack>

              <MDButton type="submit" fullWidth disabled={isSubmitButtonDisabled} isLoading={isSubmitting}>
                Sign In
              </MDButton>

              <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} alignItems="center" justifyContent="space-between">
                <FormControlLabel
                  onChange={onChangeRememberMeHandler}
                  checked={isRememberMe}
                  sx={{
                    mb: 0,
                  }}
                  control={<Checkbox />}
                  disabled={isSubmitting}
                  label="Remember Me"
                />
                <Typography
                  component={Link}
                  to={ROUTES.forgotPassword}
                  variant="caption"
                  sx={{
                    ':hover': { opacity: 0.75 },
                  }}
                  color="primary.dark"
                  fontWeight="fontWeightMedium"
                >
                  Forgot Password?
                </Typography>
              </Stack>
            </Stack>
          </form>
        </IllustrationLayout>
      )}

      {authStep === AUTH_STEPS.VERIFY_TOTP_CODE && (
        <IllustrationLayout
          title={VERIFY_TOTP_CODE_TITLES.title}
          description={VERIFY_TOTP_CODE_TITLES.description}
          backButton={<BackButton title={VERIFY_TOTP_CODE_TITLES.backButton} navigateTo={ROUTES.signIn} />}
        >
          <VerifyTOTPcodeStep
            getExpertProfileHandler={getExpertProfileHandler}
            getCognitoCurrentUser={getCognitoCurrentUser}
          />
        </IllustrationLayout>
      )}
    </>
  );
};
