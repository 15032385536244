import { Table, TableBody } from '@mui/material';

import { getActivateMenuItem } from 'components/MenuPopover';
import { CustomTableContainer, CustomTableHeader, CustomTablePagination, TableSkeleton } from 'components/Table';
import { useBoolean } from 'hooks';
import { PageWrapper } from 'layouts';

import { RelatedConcernsTableRow } from './components';
import {
  EditRelatedConcernSideMenuContent,
  RelatedConcernSideMenu,
  ViewRelatedConcernSideMenuContent,
} from './components/SideMenu';
import { CONCERNS_TABLE_HEADERS } from '../../PatientView/TabContentPages/Concerns/constants';

export const RelatedConcernsTabContent: React.FC = () => {
  const isLoading = false;

  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const [isEditMenuOpen, openEditMenu, closeEditMenu] = useBoolean();

  return (
    <PageWrapper pt={3} component="div">
      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <CustomTableHeader headLabel={CONCERNS_TABLE_HEADERS} onClickAddColumnButtonHandler={() => {}} />

          <TableBody>
            {isLoading ? (
              <TableSkeleton cellsAmount={3} />
            ) : (
              <RelatedConcernsTableRow id="123" onClickTableRow={openDialog} onClickEditMenuItem={openEditMenu} />
            )}
            {/* TODO */}
            {/* <TableNoData isNotFound={!isLoading} /> */}
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={0}
        page={1 - 1}
        rowsPerPage={20}
        onPageChange={(event, page) => {}}
        onRowsPerPageChange={event => {}}
      />

      <RelatedConcernSideMenu
        menuItems={[getActivateMenuItem(true, () => {})]}
        open={isDialogOpen}
        onClose={closeDialog}
        onClickCloseButton={closeDialog}
      >
        <ViewRelatedConcernSideMenuContent />
      </RelatedConcernSideMenu>

      <RelatedConcernSideMenu
        menuItems={[getActivateMenuItem(false, () => {})]}
        open={isEditMenuOpen}
        onClose={closeEditMenu}
        onClickCloseButton={closeEditMenu}
      >
        <EditRelatedConcernSideMenuContent />
      </RelatedConcernSideMenu>
    </PageWrapper>
  );
};
