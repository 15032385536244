import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { CreatePromptPayload, GetPromptsSearchParams, createPrompt } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { TagEditor } from 'pages/PromptLibrary/components';
import { formErrorHandler } from 'utils/errorHanders';

import { createPromptValidationSchema, defaultPromptValues } from './form.config';

type AddPromptDialogWindowFormProps = {
  closeDialogHandler: () => void;
  fetchPrompts: (params: GetPromptsSearchParams) => Promise<void>;
};

export const AddPromptDialogWindowForm: FC<AddPromptDialogWindowFormProps> = ({ closeDialogHandler, fetchPrompts }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    control,
  } = useForm<CreatePromptPayload>({
    mode: 'onTouched',
    resolver: yupResolver(createPromptValidationSchema) as Resolver<CreatePromptPayload>,
    defaultValues: defaultPromptValues,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      await createPrompt(formData);
      notice(ToastType.SUCCESS, 'Prompt has been successfully created!');
      closeDialogHandler();

      await fetchPrompts({ page: 1 });
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to create prompt, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{ title: 'Add Prompt', onClickCancelButtonHandler: closeDialogHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
      }}
    >
      <Scrollbar sx={{ height: 1, maxHeight: 380 }}>
        <Stack spacing={2}>
          <RHFTextField
            register={register}
            registerName="name"
            registerErrors={errors.name?.message}
            fullWidth
            placeholder="Enter Prompt Name"
            label="Prompt Name"
            required
          />

          <TagEditor isRequired control={control} name="text" placeholder="Enter Text" label="Text" />

          <RHFTextField
            register={register}
            registerName="comment"
            registerErrors={errors.comment?.message}
            fullWidth
            placeholder="Enter Comment"
            label="Comment"
            multiline
            minRows={3}
            maxRows={6}
          />
        </Stack>
      </Scrollbar>
    </FormDialogWindowContent>
  );
};
