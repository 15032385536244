import { useCallback, useState } from 'react';

import { useBoolean } from 'hooks';

export const useMedicalRecordTableState = () => {
  const [isAddMedicalRecord, setIsAddMedicalRecord] = useState(false);

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [selectedMedicalRecordId, setSelectedMedicalRecordId] = useState(null);

  const clearMedicalRecordState = useCallback(() => {
    setIsAddMedicalRecord(false);
    setSelectedMedicalRecordId(null);
  }, []);

  const onClickEditButtonHandler = useCallback((id: string) => {
    setIsAddMedicalRecord(false);
    setSelectedMedicalRecordId(id);
  }, []);

  const onClickAddButtonHandler = useCallback(() => setIsAddMedicalRecord(true), []);

  const onClickCloseButtonHandler = useCallback(() => setIsAddMedicalRecord(false), []);

  const onClickDeleteButtonHandler = useCallback((id: string) => {
    setSelectedMedicalRecordId(id);
    openDeleteDialogWindow();
  }, []);

  return {
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    isAddMedicalRecord,
    setIsAddMedicalRecord,
    selectedMedicalRecordId,
    setSelectedMedicalRecordId,
    onClickEditButtonHandler,
    onClickAddButtonHandler,
    onClickCloseButtonHandler,
    onClickDeleteButtonHandler,
    clearMedicalRecordState,
  };
};
