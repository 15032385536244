import { StateCreator } from 'zustand';

import { CatalogProductProfileInitialState, CatalogProductProfileSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: CatalogProductProfileInitialState = {
  GLProduct: null,
  PLProduct: null,
};

export const CatalogProductProfile: StateCreator<
  CatalogProductProfileSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  CatalogProductProfileSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setGLProduct: data => {
      set(state => {
        state.GLProduct = data;
      });
    },
    setPLProduct: data => {
      set(state => {
        state.PLProduct = data;
      });
    },
  };
};
