export const ERROR_MESSAGES = {
  smt_went_wrong: 'Something went wrong, please try again!',
  unsupportedFileFormat: `The file you've uploaded is not supported. Please upload a file in one of the following formats:`,
};

export const UNSUPPORTED_FILE_TYPE_TITLE = 'Unsupported file type';

export const VERIFY_TOTP_CODE_TITLES = {
  title: 'Verification code',
  description: 'Enter the 6-digit verification code from your TOTP application',
  backButton: 'Back to Login',
};

export const LIBRARIES_NAMES = {
  global: 'Global Library',
  practice: 'Practice Library',
};

export const TOTP_APP_NAME = 'Aesthetics360';

export const DATE_INPUT_PLACEHOLDER = 'MM/DD/YYYY';

export const LABELS = {
  FIRST_NAME: 'First Name',
  MIDDLE_NAME: 'Middle Name',
  LAST_NAME: 'Last Name',
  PAIN_TOLERANCE: 'Pain Tolerance',
  BLOOD_TYPE: 'Blood Type',
  EMAIL: 'Email',
  PRACTICE: 'Practice',
  DATE_OF_BIRTH: 'Date of Birth',
  ACTIONS: 'Actions',
  PHONE: 'Phone Number',
  POSITION: 'Position',
  TITLE: 'Title',
  WEBSITE: 'Website',
  GENDER: 'Gender',
  ETHNICITY: 'Ethnicity',
  SSN: 'Social Security Number',
  OCCUPATION: 'Occupation',
};

export const EMPTY_SCREEN_MESSAGES = {
  title: 'No Results',
  subtitle: 'Try refining your search',
};
