import { JSX } from 'react';

import { Card, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, TableNoData } from 'components/Table';
import { useRecordTable } from 'hooks';
import { TableHeaderConfig } from 'types';

type RecordItem = {
  id: string;
};

type RenderTableRowProps<TItem extends RecordItem> = {
  item: TItem;
  onClickEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

type RenderAddEditTableRowProps<TItem extends RecordItem> = {
  item?: TItem;
  onClickCancelButtonHandler: () => void;
};

type RecordTableProps<TItem extends RecordItem> = {
  title: string;
  items: TItem[];
  headLabels: TableHeaderConfig<TItem>[];
  addButtonTitle: string;
  renderTableRow: (props: RenderTableRowProps<TItem>) => JSX.Element;
  renderAddEditTableRow: (props: RenderAddEditTableRowProps<TItem>) => JSX.Element;
};

export const RecordTable = <TItems extends RecordItem>({
  title,
  items,
  headLabels,
  addButtonTitle,
  renderAddEditTableRow,
  renderTableRow,
}: RecordTableProps<TItems>) => {
  const {
    isAddRecord,
    selectedRecordId,
    onClickEditButtonHandler,
    onClickAddButtonHandler,
    onClickCloseButtonHandler,
    onClickDeleteButtonHandler,
  } = useRecordTable();

  return (
    <Card>
      <Stack p={2} gap={1} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
          {title}
        </Typography>
        <MDButton
          onClick={onClickAddButtonHandler}
          size="small"
          variant="secondary"
          startIcon={<Icon type="add" />}
          disabled={isAddRecord || selectedRecordId}
        >
          {addButtonTitle}
        </MDButton>
      </Stack>

      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 500 }}>
            <CustomTableHeader headLabel={headLabels} />
            <TableBody>
              {isAddRecord && renderAddEditTableRow({ onClickCancelButtonHandler: onClickCloseButtonHandler })}

              {items?.map(item =>
                selectedRecordId === item?.id
                  ? renderAddEditTableRow({ item, onClickCancelButtonHandler: onClickCloseButtonHandler })
                  : renderTableRow({
                      item,
                      onClickEdit: onClickEditButtonHandler,
                      onClickDelete: onClickDeleteButtonHandler,
                    }),
              )}

              {!isAddRecord && !selectedRecordId && <TableNoData isNotFound={!items?.length} />}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
};
