import { memo } from 'react';

import { ListWrapper } from './ListWrapper';
import { SideNavMenuItem } from './SideNavMenuItem';
import { ScrollableSideNavMenuProps } from './types';

export const ScrollableSideNavMenu: React.FC<ScrollableSideNavMenuProps> = memo(
  ({ sidenavItems, sx, activeSectionId, ...restProps }) => {
    const renderSidenavItems = sidenavItems.map(({ id, onClick, icon, label }) => {
      const isActive = id === activeSectionId;

      return <SideNavMenuItem key={label} label={label} isActive={isActive} icon={icon} onClick={onClick} />;
    });

    return (
      <ListWrapper sx={{ display: 'none', ...sx }} {...restProps}>
        {renderSidenavItems}
      </ListWrapper>
    );
  },
);
