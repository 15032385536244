import { shallow } from 'zustand/shallow';

import { useTabs } from 'hooks/useTabs';
import { useAppStore } from 'store';

export const useCatalogState = () => {
  const { catalogItemsType, setCatalogItemsType, setCatalogActiveLibrary, catalogActiveLibrary } = useAppStore(
    store => ({
      catalogActiveLibrary: store.catalogActiveLibrary,
      setCatalogActiveLibrary: store.setCatalogActiveLibrary,
      catalogItemsType: store.catalogItemsType,
      setCatalogItemsType: store.setCatalogItemsType,
    }),
    shallow,
  );

  const { setTabValue, tabValue } = useTabs(catalogActiveLibrary === 'global' ? 0 : 1);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setCatalogActiveLibrary(newValue === 0 ? 'global' : 'practice');
    catalogItemsType !== 'services' && setCatalogItemsType('services');
  };

  return { handleSetTabValue, tabValue };
};
