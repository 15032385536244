import {
  Box,
  Card,
  Divider,
  Grid2,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';

import { BaseDialogWindow, ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { getActivateMenuItem } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { ScrollableSideNavMenu } from 'components/SideNavMenu';
import { EntityHeader } from 'components/Widgets';
import { SectionWrapper } from 'layouts';
import { TagsTableRow, UploadImageCard } from 'pages/BeforeAfter';
import { ActivateDeactivateDialogWindow } from 'pages/BeforeAfter/components/ActivateDeactivateDialog';
import { SECTION_IDS } from 'pages/BeforeAfter/config';
import { getBorderStyles } from 'styles';

import beforeAfterAlbumFallback from 'assets/icons/before_after_album_fallback.svg';

import { usePLBeforeAfterAlbumPhotosProfileState } from './usePLBeforeAfterAlbumPhotosProfile.state';

export const PracticeLibraryBAphotos: React.FC = () => {
  const {
    ba_pl_image_pair,
    photoRef,
    infoRef,
    tagsRef,
    activeSection,
    isLoading,
    errors,
    onFormSubmitHandler,
    register,
    isSubmitButtonDisabled,
    isDirty,
    isSubmitting,
    sideNavItems,
    isActionPending,
    onDeleteBeforeImageHandler,
    onUploadDropzoneFileHandler,
    previewURL,
    onClickRemoveBeforeImageHandler,
    onCreateBeforeImageHandler,
    isCropFileDialogOpen,
    onCropCompleteHandler,
    onFileInputChangeHandler,
    onActivateDeactivateAlbumPairHandler,
    imagePosition,
    onDeleteAfterImageHandler,
    isImageUplaoding,
    onClickRemoveAfterImageHandler,
    onAfterFileInputChangeHandler,
    onUploadAfterDropzoneFileHandler,
    isAfterCropFileDialogOpen,
    afterPreviewURL,
    onCreateAfterImageHandler,
    onAfterCropCompleteHandler,
    isOpenDeleteImageDialogWindow,
    closeDeleteImageDialogWindow,
    isDeletingImage,
    onCloseCropDialogHandler,
    isViewOnly,
    dialogStates,
    getOpenDialogHandler,
    getCloseDialogHandler,
  } = usePLBeforeAfterAlbumPhotosProfileState();

  return (
    <Box component="section">
      <Grid2 container>
        <Grid2 size={12}>
          <EntityHeader
            isLoading={isLoading}
            skeletonProps={{
              avatarVariant: 'rounded',
              isActiveStatus: true,
              isActionButton: true,
            }}
            title={ba_pl_image_pair?.name}
            subtitle={ba_pl_image_pair?.description}
            activeStatusProps={{
              isActive: ba_pl_image_pair?.is_active,
            }}
            actionMenuItems={[
              getActivateMenuItem(
                ba_pl_image_pair?.is_active,
                ba_pl_image_pair?.is_active ? getOpenDialogHandler('deactivate') : getOpenDialogHandler('activate'),
              ),
            ]}
            avatarProps={{
              // TODO: uncomment when BE will be ready
              // src: albumPair?.album?.coverUrl,
              // initialPhotoUrl: b_a_gl_album?.coverUrl,
              fallbackSrc: beforeAfterAlbumFallback,
              onCreateFileHandler: async () => {},
              onDeleteFileHandler: async () => {},
              formDataImageName: 'cover_image',
              variant: 'rounded',
              cropShape: 'rect',
              isDisabled: true,
            }}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 0,
            md: 2,
          }}
        >
          <ScrollableSideNavMenu isLoading={isLoading} activeSectionId={activeSection} sidenavItems={sideNavItems} />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 10,
          }}
          sx={theme => getBorderStyles(theme, true)}
        >
          <Stack width={1}>
            <Stack width={1} ref={photoRef} id={SECTION_IDS.PHOTO}>
              <SectionWrapper width={1} direction={{ xs: 'column', sm: 'row' }} gap={1.5}>
                {isLoading ? (
                  <>
                    <Skeleton variant="rounded" width="100%" height={300} />
                    <Skeleton variant="rounded" width="100%" height={300} />
                  </>
                ) : (
                  <>
                    <UploadImageCard
                      previewURL={
                        ba_pl_image_pair?.image_left_thumb_url && !previewURL
                          ? ba_pl_image_pair?.image_left_thumb_url
                          : previewURL
                      }
                      onClickRemoveImageHandler={onClickRemoveBeforeImageHandler}
                      onUploadAnotherFileHandler={onFileInputChangeHandler}
                      type="before"
                      onDropFileHandler={onUploadDropzoneFileHandler}
                      isDisabled={isViewOnly}
                    />

                    <UploadImageCard
                      previewURL={
                        ba_pl_image_pair?.image_right_thumb_url && !afterPreviewURL
                          ? ba_pl_image_pair?.image_right_thumb_url
                          : afterPreviewURL
                      }
                      onClickRemoveImageHandler={onClickRemoveAfterImageHandler}
                      onUploadAnotherFileHandler={onAfterFileInputChangeHandler}
                      type="after"
                      onDropFileHandler={onUploadAfterDropzoneFileHandler}
                      isDisabled={isViewOnly}
                    />
                  </>
                )}
              </SectionWrapper>
              <Divider flexItem />
            </Stack>

            <Stack ref={infoRef} id={SECTION_IDS.INFO}>
              <SectionWrapper component="form" onSubmit={onFormSubmitHandler} spacing={2.5} maxWidth={700}>
                {isLoading ? (
                  <>
                    <Skeleton variant="rounded" width="100%" height={40} />
                    <Skeleton variant="rounded" width="100%" height={100} />
                  </>
                ) : (
                  <>
                    <RHFTextField
                      label="Name"
                      register={register}
                      registerName="name"
                      registerErrors={errors?.name?.message}
                      placeholder="Enter name"
                      disabled={isViewOnly}
                    />

                    <RHFTextField
                      label="Description"
                      register={register}
                      registerName="description"
                      registerErrors={errors?.description?.message}
                      multiline
                      minRows={5}
                      maxRows={8}
                      placeholder="Enter description"
                      disabled={isViewOnly}
                    />
                  </>
                )}

                {/* TODO: when BE will be ready */}
                {/* <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Product 1' }]}
                      inputProps={{ label: 'Products' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    />

                    <MDAutocomplete
                      multiple
                      options={[{ id: '1', name: 'Service 1' }]}
                      inputProps={{ label: 'Services' }}
                      getOptionLabel={option => {
                        if (typeof option !== 'string') return option.name;
                      }}
                    /> */}
              </SectionWrapper>
              {/* TODO: when tags will be ready */}
              <Divider />
            </Stack>

            {/* TODO: when BE will be ready */}
            <SectionWrapper ref={tagsRef} id={SECTION_IDS.TAGS} spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between">
                <Typography>Tags (5/44)</Typography>

                <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                  <MDButton variant="secondary" isIconOnly>
                    <Icon
                      type="filter"
                      sx={{
                        alignItems: 'center',
                        svg: {
                          path: {
                            strokeWidth: 1.5,
                          },
                          width: 18,
                          height: 18,
                        },
                      }}
                    />
                  </MDButton>
                  <MDButton startIcon={<Icon type="starsIcon" />}>AI assistant</MDButton>
                </Stack>
              </Stack>

              <Card sx={{ overflow: 'hidden' }}>
                <TableContainer>
                  <Scrollbar>
                    <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                      <TableBody>
                        <TagsTableRow />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
              </Card>

              <Card sx={{ overflow: 'hidden' }}>
                <TableContainer>
                  <Scrollbar>
                    <Table size="medium" sx={{ minWidth: 600, th: { width: 200 } }}>
                      <TableBody>
                        <TagsTableRow />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
              </Card>
            </SectionWrapper>

            <FormActions
              isDisabled={isSubmitButtonDisabled}
              isLoading={isSubmitting}
              onFormSubmitHandler={onFormSubmitHandler}
              isDirtyForm={isDirty}
            />
          </Stack>
        </Grid2>
      </Grid2>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteImageDialogWindow}
        open={isOpenDeleteImageDialogWindow}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {imagePosition === 'left' ? 'before' : 'after'}
            </Typography>{' '}
            image?
          </Typography>
        }
        title="Delete Image"
        isApproveButtonDisabled={isDeletingImage}
        isApproveButtonLoading={isDeletingImage}
        approveButtonTitle="Delete Image"
        onClickApproveButtonHandler={imagePosition === 'left' ? onDeleteBeforeImageHandler : onDeleteAfterImageHandler}
      />

      {isCropFileDialogOpen && (
        <ZoomCropDialogWindow
          imageURL={previewURL}
          open={isCropFileDialogOpen}
          onClickCloseButtonHandler={onCloseCropDialogHandler}
          onUploadNewFileHandler={onFileInputChangeHandler}
          onSaveFileHandler={onCreateBeforeImageHandler}
          onCropCompleteHandler={onCropCompleteHandler}
          isLoading={isImageUplaoding}
          cropShape="rect"
        />
      )}

      {isAfterCropFileDialogOpen && (
        <ZoomCropDialogWindow
          imageURL={afterPreviewURL}
          open={isAfterCropFileDialogOpen}
          onClickCloseButtonHandler={onCloseCropDialogHandler}
          onUploadNewFileHandler={onAfterFileInputChangeHandler}
          onSaveFileHandler={onCreateAfterImageHandler}
          onCropCompleteHandler={onAfterCropCompleteHandler}
          isLoading={isImageUplaoding}
          cropShape="rect"
        />
      )}

      <ActivateDeactivateDialogWindow
        title="Before & After Photos"
        descriptionSuffix="the before & after photos later."
        actionType={dialogStates.activate ? 'deactivate' : 'activate'}
        name={ba_pl_image_pair?.name}
        isOpen={dialogStates.activate || dialogStates.deactivate}
        isActionPending={isActionPending}
        onClose={getCloseDialogHandler(dialogStates.activate ? 'deactivate' : 'activate')}
        onApprove={onActivateDeactivateAlbumPairHandler}
      />
    </Box>
  );
};
