import { useCallback, useEffect } from 'react';

import { addPatientAllergy, deletePatientAllergy } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useDictionary, usePopover } from 'hooks';
import { QuestionnaireFormSchema } from 'pages/Patients/pages/PatientFormProfile/QuestionnaireForm/form.config';
import { QuestionnaireField } from 'pages/Patients/pages/PatientFormProfile/QuestionnaireForm/types';
import { JSONField } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';

type UsePatientAllergiesProps = {
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
  patientQuestionnaire?: JSONField;
};
// TODO: refactor this hook
export const usePatientAllergies = ({ patientQuestionnaire, patientId, fetchPatient }: UsePatientAllergiesProps) => {
  const { fetchAllergies, allergies, isAllergiesLoading } = useDictionary();

  useEffect(() => {
    fetchAllergies();
  }, []);

  const [anchor, openPopoverHandler, closePopoverHandler] = usePopover();

  const {
    actionState,
    isActionPending,
    setIsActionPending,
    onClickActionOptionHandler,
    dialogStates,
    getCloseDialogHandler,
    closeDialog,
  } = useActionDialogManagement();

  const onDeletePatientAllergyHandler = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      if (!actionState.id) return;

      setIsActionPending(true);
      try {
        await deletePatientAllergy(patientId, actionState.id);

        // const questionnaire = patientRAG.questionnaireForm as QuestionnaireField;

        // const updatedAllergies = questionnaire?.allergies?.filter(allergy => allergy. !== actionState.id) || [];

        // await updatePatientCard(patientId, {
        //   rag: {
        //     ...patient?.patientCard?.rag,
        //     allergies: updatedAllergies,
        //   },
        // });

        closeDialog('delete');

        notice(ToastType.SUCCESS, 'Allergy has been successfully removed!');

        // await fetchPatient(patientId);
      } catch (error) {
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to remove allergy, please try again!' },
        });
      } finally {
        setIsActionPending(false);
      }
    },
    [patientId],
  );

  const onCreateAllergyHandler = useCallback(
    async (allergy_icd10_code: string) => {
      try {
        await addPatientAllergy(patientId, { allergy_icd10_code });

        notice(ToastType.SUCCESS, `The allergy has been successfully added!`);

        await fetchPatient(patientId);
      } catch (error) {
        backendErrorHandler({
          error,
          config: {
            customErrorMessage: `Failed to add allergy, please try again!`,
          },
        });
      }
    },
    [patientId],
  );

  return {
    fetchAllergies,
    allergies,
    isAllergiesLoading,
    anchor,
    openPopoverHandler,
    closePopoverHandler,
    onDeletePatientAllergyHandler,
    onCreateAllergyHandler,
    onClickActionOptionHandler,
    isActionPending,
    actionState,
    dialogStates,
    getCloseDialogHandler,
  };
};
