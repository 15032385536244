import { useCallback, useEffect, useRef, useState } from 'react';

const STORAGE_KEY = 'countdown_end_time';

export const persistTimer = {
  save: (endTime: number, key: string = STORAGE_KEY) => {
    localStorage.setItem(key, endTime.toString());
  },
  getRemainingTime: (key: string = STORAGE_KEY) => {
    const savedEndTime = localStorage.getItem(key);
    if (!savedEndTime) return null;

    const timeLeft = Math.max(0, Math.floor((+savedEndTime - Date.now()) / 1000));
    return timeLeft > 0 ? timeLeft : null;
  },
  clear: (key: string = STORAGE_KEY) => {
    localStorage.removeItem(key);
  },
};

type UseCountdownProps = {
  seconds: number;
  isPersist?: boolean;
  storageKey?: string;
};

export const useCountdown = ({ seconds, isPersist, storageKey }: UseCountdownProps) => {
  const [countdown, setCountdown] = useState<number>(seconds);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isPersist) {
      const remainingTime = persistTimer.getRemainingTime(storageKey);
      if (remainingTime !== null) {
        setCountdown(remainingTime);
        setIsRunning(true);
      }
    }

    return () => persistTimer.clear(storageKey);
  }, [isPersist, storageKey]);

  const startCountdown = useCallback(() => {
    if (isPersist) {
      const endTime = Date.now() + seconds * 1000;
      persistTimer.save(endTime, storageKey);
    }

    setCountdown(seconds);
    setIsRunning(true);
  }, [isPersist, storageKey, seconds]);

  const stopCountdown = useCallback(() => {
    if (isPersist) persistTimer.clear(storageKey);

    if (intervalRef.current) clearInterval(intervalRef.current);

    setIsRunning(false);
  }, [isPersist, storageKey]);

  const resetCountdown = useCallback(() => {
    if (isPersist) persistTimer.clear(storageKey);

    if (intervalRef.current) clearInterval(intervalRef.current);

    setCountdown(seconds);
    setIsRunning(false);
  }, [isPersist, seconds, storageKey]);

  useEffect(() => {
    if (!isRunning) return;

    intervalRef.current = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          stopCountdown();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isRunning]);

  const isFinished = countdown === 0 && !isRunning;

  return {
    countdown,
    isRunning,
    isFinished,
    startCountdown,
    stopCountdown,
    resetCountdown,
  };
};
