import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Divider } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { updatePatient } from 'apiServices';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { MDTabs } from 'components/MDTabs';
import { ToastType, notice } from 'components/ToastNotification';
import { useTabs } from 'hooks';
import { useAppStore } from 'store';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import {
  AdditionalInformation,
  LifeStyleFactors,
  PatientConcernsAndGoals,
  PatientInformation,
  PhysicalExamination,
  TreatmentHistory,
} from './components/Form';
import { QuestionnaireFormSchema, defaultValues, questionnaireFormValidationSchema } from './form.config';

const TABS = [
  'Patient Information',
  'Patient Concerns and Goals',
  'Lifestyle Factors',
  'Physical Examination',
  'Treatment History',
  'Additional Information',
];

export const QuestionnaireConsultationForm: React.FC = () => {
  const { tabValue, handleSetTabValue } = useTabs();

  const { patient, fetchPatient } = useAppStore(
    store => ({ patient: store.patient, fetchPatient: store.fetchPatient }),
    shallow,
  );

  //TODO add fetch patient to useEffect logic if there is no patient data

  const patientRAG = patient?.questionnaire as QuestionnaireFormSchema;

  const methods = useForm<QuestionnaireFormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(questionnaireFormValidationSchema) as Resolver<QuestionnaireFormSchema>,
    defaultValues,
    values: {
      birthDate: patient?.birth_date,
      genderIndentity: patient?.gender_identity,
      ethnicity: patient?.ethnicity,
      ocupation: patient?.occupation,
      previousAestheticTreatments: patientRAG?.previousAestheticTreatments || [],
      skinType: patientRAG?.skinType,
      sunExposureHistory: patientRAG?.sunExposureHistory,
      smokingStatus: patient?.smoking,
      primaryConcerns: patientRAG?.primaryConcerns || [],
      secondaryConcerns: patientRAG?.secondaryConcerns || [],
      desiredOutcome: patientRAG?.desiredOutcome,
      budgetConstraints: patientRAG?.budgetConstraints,
      facialExpressions: patientRAG?.facialExpressions || [],
      otherFacialExpressions: patientRAG?.otherFacialExpressions,
      sleepHabits: patientRAG?.sleepHabits || [],
      recentWeightChanges: patientRAG?.recentWeightChanges,
      weightChangeAmount: patientRAG?.weightChangeAmount ?? null,
      hormonalStatus: patientRAG?.hormonalStatus,
      facialScanPerformed: patientRAG?.facialScanPerformed,
      skinElasticity: patientRAG?.skinElasticity,
      facialMuscleStrength: patientRAG?.facialMuscleStrength,
      facialMuscleAsymmetryDetails: patientRAG?.facialMuscleAsymmetryDetails,
      boneStructure: patientRAG?.boneStructure,
      anatomicalNotes: patientRAG?.anatomicalNotes,
      previousBotulinumToxinTreatments: patientRAG?.previousBotulinumToxinTreatments,
      botulinumToxinAreasTreated: patientRAG?.botulinumToxinAreasTreated,
      botulinumToxinLastTreatmentDate: patientRAG?.botulinumToxinLastTreatmentDate,
      botulinumToxinProductUsed: patientRAG?.botulinumToxinProductUsed,
      botulinumToxinDosage: patientRAG?.botulinumToxinDosage ?? null,
      previousDermalFillerTreatments: patientRAG?.previousDermalFillerTreatments,
      dermalFillerAreasTreated: patientRAG?.dermalFillerAreasTreated,
      dermalFillerLastTreatmentDate: patientRAG?.dermalFillerLastTreatmentDate,
      dermalFillerProductsUsed: patientRAG?.dermalFillerProductsUsed,
      dermalFillerVolumeInjected: patientRAG?.dermalFillerVolumeInjected ?? null,
      patientSatisfaction: patientRAG?.patientSatisfaction,
      additionalInformation: patientRAG?.additionalInformation,
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, isDirty },
  } = methods;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const questionnaireForm: QuestionnaireFormSchema = {
        birthDate: dateToCustomFormat(formData.birthDate),
        genderIndentity: formData.genderIndentity,
        ethnicity: formData.ethnicity,
        previousAestheticTreatments: formData.previousAestheticTreatments || [],
        skinType: formData.skinType,
        sunExposureHistory: formData.sunExposureHistory,
        smokingStatus: formData.smokingStatus,

        primaryConcerns: formData.primaryConcerns || [],
        secondaryConcerns: formData.secondaryConcerns || [],
        desiredOutcome: formData.desiredOutcome,
        budgetConstraints: formData.budgetConstraints,

        ocupation: formData.ocupation,
        facialExpressions: formData.facialExpressions || [],
        otherFacialExpressions: formData.otherFacialExpressions,
        sleepHabits: formData.sleepHabits || [],
        recentWeightChanges: formData.recentWeightChanges,
        weightChangeAmount: formData.weightChangeAmount ?? null,
        hormonalStatus: formData.hormonalStatus,

        facialScanPerformed: formData.facialScanPerformed,
        skinElasticity: formData.skinElasticity,
        facialMuscleStrength: formData.facialMuscleStrength,
        facialMuscleAsymmetryDetails: formData.facialMuscleAsymmetryDetails,
        boneStructure: formData.boneStructure,
        anatomicalNotes: formData.anatomicalNotes,

        previousBotulinumToxinTreatments: formData.previousBotulinumToxinTreatments,
        botulinumToxinAreasTreated: formData.botulinumToxinAreasTreated,
        botulinumToxinLastTreatmentDate: dateToCustomFormat(formData.botulinumToxinLastTreatmentDate),
        botulinumToxinProductUsed: formData.botulinumToxinProductUsed,
        botulinumToxinDosage: formData.botulinumToxinDosage ?? null,

        previousDermalFillerTreatments: formData.previousDermalFillerTreatments,
        dermalFillerAreasTreated: formData.dermalFillerAreasTreated,
        dermalFillerLastTreatmentDate: dateToCustomFormat(formData.dermalFillerLastTreatmentDate),
        dermalFillerProductsUsed: formData.dermalFillerProductsUsed,
        dermalFillerVolumeInjected: formData.dermalFillerVolumeInjected ?? null,

        patientSatisfaction: formData.patientSatisfaction,
        additionalInformation: formData.additionalInformation,
      };

      await updatePatient(patient?.id, {
        birth_date: dateToCustomFormat(formData.birthDate),
        ethnicity: formData.ethnicity,
        gender_identity: formData.genderIndentity,
        occupation: formData.ocupation,
        questionnaire: questionnaireForm,
      }),
        notice(ToastType.SUCCESS, 'Questionnaire form has been successfully updated!');
    } catch (error) {
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to save questionnaire form, please try again later',
        config: { formError: { setError } },
      });
    }
  });

  const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;

  return (
    <FormProvider onSubmit={onFormSubmitHandler} methods={methods}>
      <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }} width={1} px={1}>
        <MDTabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={tabValue}
          tabsData={TABS}
          handleSetTabValue={handleSetTabValue}
        />
      </Box>
      <Divider sx={{ mt: '-1px' }} />
      {tabValue === 0 && (
        <PatientInformation
          fetchPatient={fetchPatient}
          patientAllergies={patient?.allergies}
          patientId={patient?.id}
          patientMedicalConditions={patient?.medical_conditions}
          patientQuestionnaire={patient?.questionnaire}
        />
      )}

      {tabValue === 1 && <PatientConcernsAndGoals />}
      {tabValue === 2 && <LifeStyleFactors />}
      {tabValue === 3 && <PhysicalExamination />}
      {tabValue === 4 && <TreatmentHistory />}
      {tabValue === 5 && <AdditionalInformation />}

      <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
    </FormProvider>
  );
};
