import { FC, memo } from 'react';

import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { LABELS } from 'constants/strings';
import { SectionWrapper } from 'layouts';

import { ProfileContactCardProps } from './types';
import { EditPatientFormSchema } from './validation';
import { CONTACT_ID } from '../../constants';

export const PatientContactContent: FC<ProfileContactCardProps> = memo(({ cardRef }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EditPatientFormSchema>();

  return (
    <SectionWrapper ref={cardRef} id={CONTACT_ID}>
      <Typography variant="subtitle2" fontWeight={500}>
        Contanct
      </Typography>

      <Stack sx={{ width: { xs: 1, lg: '66%' } }} mt={1.5} spacing={2}>
        <RHFPhoneTextField
          control={control}
          registerErrors={errors}
          registerName="phone"
          MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
        />

        <RHFTextField
          label={LABELS.EMAIL}
          register={register}
          registerName="email"
          registerErrors={errors.email?.message}
          fullWidth
          placeholder="Enter Email"
        />
      </Stack>
    </SectionWrapper>
  );
});
