import { FC, memo, useEffect } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField, RHFDatePicker, RHFssnTextField, RHFTextField } from 'components/HookForm';
import { LABELS } from 'constants/strings';
import { usePatientEnums } from 'hooks';
import { SectionWrapper } from 'layouts';

import { PatientDemographicsContentProps } from './types';
import { EditPatientFormSchema } from './validation';
import { DEMOGRAPHICS_ID } from '../../constants';

export const PatientDemographicsContent: FC<PatientDemographicsContentProps> = memo(({ cardRef }) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext<EditPatientFormSchema>();

  const {
    patientEthnicitiesEnumsState,
    patientGenderEnumsState,
    patientPersonTitlesEnumsState,
    fetchPatientPersonTitlesEnums,
    fetchPatientEthnicitiesEnums,
    fetchPatientGenderEnums,
  } = usePatientEnums();

  useEffect(() => {
    fetchPatientPersonTitlesEnums();
    fetchPatientEthnicitiesEnums();
    fetchPatientGenderEnums();
  }, []);

  return (
    <>
      <SectionWrapper ref={cardRef} id={DEMOGRAPHICS_ID}>
        <Stack>
          <Typography variant="subtitle2" fontWeight={500}>
            Demographics
          </Typography>

          <Box mt={1.5}>
            <Stack gap={2}>
              <Stack direction={{ xs: 'column', lg: 'row' }} gap={2} width={1}>
                <RHFTextField
                  label={LABELS.FIRST_NAME}
                  required
                  register={register}
                  registerName="first_name"
                  registerErrors={errors.first_name?.message}
                  fullWidth
                  placeholder="Enter First Name"
                />

                <RHFTextField
                  label={LABELS.MIDDLE_NAME}
                  register={register}
                  registerName="middle_name"
                  registerErrors={errors.middle_name?.message}
                  fullWidth
                  placeholder="Enter Middle Name"
                />

                <RHFTextField
                  label={LABELS.LAST_NAME}
                  required
                  register={register}
                  registerName="last_name"
                  registerErrors={errors.last_name?.message}
                  fullWidth
                  placeholder="Enter Last Name"
                />
              </Stack>

              <Stack direction={{ xs: 'column', lg: 'row' }} gap={2} width={1}>
                <RHFDatePicker
                  control={control}
                  registerName="birth_date"
                  isDisableFuture
                  sx={{
                    '.MuiTypography-root': {
                      textTransform: 'none',
                    },
                  }}
                />

                <RHFssnTextField fullWidth registerName="ssn" control={control} ssnValue={watch('ssn')} />

                <RHFAutocompleteField
                  label={LABELS.TITLE}
                  control={control}
                  registerName="title"
                  placeholder="Select Title"
                  options={patientPersonTitlesEnumsState.items}
                  loading={patientPersonTitlesEnumsState.isLoading}
                  valueKey="name"
                  labelKey="value"
                />
              </Stack>

              <Stack direction={{ xs: 'column', lg: 'row' }} gap={2} width={1}>
                <RHFAutocompleteField
                  label={LABELS.GENDER}
                  control={control}
                  registerName="gender_identity"
                  placeholder="Select Gender"
                  options={patientGenderEnumsState.items}
                  loading={patientGenderEnumsState.isLoading}
                  valueKey="name"
                  labelKey="value"
                />

                <RHFAutocompleteField
                  label={LABELS.ETHNICITY}
                  control={control}
                  registerName="ethnicity"
                  placeholder="Select Ethnicity"
                  options={patientEthnicitiesEnumsState.items}
                  loading={patientEthnicitiesEnumsState.isLoading}
                  valueKey="name"
                  labelKey="value"
                />

                <RHFTextField
                  label={LABELS.OCCUPATION}
                  register={register}
                  registerName="occupation"
                  registerErrors={errors.occupation?.message}
                  fullWidth
                  placeholder="Enter Occupation"
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </SectionWrapper>
      <Divider />
    </>
  );
});
