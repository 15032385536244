import { FC, memo } from 'react';

import { Box, Skeleton, SxProps, Theme } from '@mui/material';
import { useInView } from 'react-intersection-observer';

import { UseImageStatusProps, useImageStatus } from 'hooks';

type LazyImageProps = {
  src: string;
  alt: string;
  fallbackImage?: string;
  imageSX?: SxProps<Theme>;
  imageContainerSX?: SxProps<Theme>;
  rootMargin?: string;
  useImageStatusProps?: UseImageStatusProps;
  children?: (props: { isImageLoaded: boolean }) => React.ReactNode;
};

export const LazyImage: FC<LazyImageProps> = memo(
  ({ children, src, alt, fallbackImage, imageSX, imageContainerSX, rootMargin = '50px 0px', useImageStatusProps }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      rootMargin,
    });

    const { isImageLoading, isImageLoaded, isImageLoadingError } = useImageStatus({
      src: src || fallbackImage,
      isShouldStartLoading: inView,
      ...useImageStatusProps,
    });

    return (
      <Box
        ref={ref}
        data-inview={inView}
        sx={{
          width: 1,
          height: 1,
          position: 'relative',
          ...imageContainerSX,
        }}
      >
        {inView && (
          <>
            <Skeleton
              variant="rounded"
              sx={{ width: '100%', height: '100%', display: isImageLoading ? 'block' : 'none' }}
            />

            <Box
              component="img"
              alt={alt}
              src={src || fallbackImage}
              loading="lazy"
              sx={{
                width: 1,
                height: 1,
                display: 'none',
                objectFit: 'cover',
                borderRadius: 'inherit',
                ...((isImageLoaded || (isImageLoadingError && fallbackImage)) && {
                  display: 'block',
                }),
                ...imageSX,
              }}
            />
          </>
        )}

        {children?.({ isImageLoaded })}
      </Box>
    );
  },
);
