import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { notice, ToastType } from 'components/ToastNotification';
import { SectionWrapper } from 'layouts';
import { formErrorHandler } from 'utils/errorHanders';

import { UpdatePracticeSummaryFormSchema, updatePracticeSummaryValidationSchema } from './validation';
import { SummaryProps } from '../types';

export const Summary: React.FC<SummaryProps> = ({ practice_summary, updateAndFetchPractice, isViewOnly }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<UpdatePracticeSummaryFormSchema>({
    resolver: yupResolver(updatePracticeSummaryValidationSchema),
    mode: 'onTouched',
    values: {
      practice_summary: practice_summary?.data || '',
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ practice_summary }) => {
    try {
      await updateAndFetchPractice({
        practice_summary: { data: practice_summary },
      });

      notice(ToastType.SUCCESS, 'Practice summary has been successfully edited!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to edit practice summary, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isDirty || !isValid || isSubmitting;

  return (
    <SectionWrapper component="form" onSubmit={onFormSubmitHandler}>
      <Typography fontWeight={500} mb={3}>
        Summary
      </Typography>

      <RHFTextField
        register={register}
        registerName="practice_summary"
        registerErrors={errors?.practice_summary?.message}
        fullWidth
        placeholder="Enter Summary"
        maxRows={8}
        multiline
        disabled={isViewOnly}
      />

      <FormActions isDirtyForm={isDirty} isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} />
    </SectionWrapper>
  );
};
