import { Dialog, Table, TableBody } from '@mui/material';

import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';

import { AddConcernDialogWindowForm, ConcernsTableRow, FiltersMenuContent } from './components';
import { CONCERNS_TABLE_HEADERS } from './constants';
import { useConcernsState } from './useConcerns.state';

export const Concerns: React.FC = () => {
  const isLoading = false;

  const {
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    page,
    total,
    rowsPerPage,
    onChangePageHandler,
    onChangeRowsPerPage,
    isOpenAddConcernDialogWindow,
    closeAddConcernDialogWindow,
    openAddConcernDialogWindow,
  } = useConcernsState();

  return (
    <PageWrapper component="div">
      <TableToolbar
        onOpenFilterMenuHandler={openFilterMenu}
        searchInputProps={{
          placeholder: 'Type to Search',
        }}
        actionButtonProps={{
          buttonTitle: 'Add Concern',
          onClick: openAddConcernDialogWindow,
        }}
      />

      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <CustomTableHeader headLabel={CONCERNS_TABLE_HEADERS} onClickAddColumnButtonHandler={() => {}} />

          <TableBody>
            {isLoading ? <TableSkeleton cellsAmount={3} /> : <ConcernsTableRow id="123" />}
            <TableNoData isNotFound={!isLoading && !1} />
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePageHandler}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        handleClosePopover={closeFiltersMenu}
        title="Filter by Concerns"
        variant="filter"
      >
        <FiltersMenuContent appliedFilters={{ isShowDeactivated: false }} onApplyFilters={() => {}} />
      </PopoverWrapper>

      <Dialog
        open={isOpenAddConcernDialogWindow}
        onClose={closeAddConcernDialogWindow}
        fullWidth
        slotProps={{
          paper: {
            sx: { maxWidth: 750 },
          },
        }}
      >
        <AddConcernDialogWindowForm onCloseDialogHandler={closeAddConcernDialogWindow} />
      </Dialog>
    </PageWrapper>
  );
};
