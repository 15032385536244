import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { PublishStatusChip } from 'components/MDChip';
import { getActivateMenuItem, getPublishMenuItem } from 'components/MenuPopover';
import { ThreeDotsTableCell } from 'components/Table';
import { ROUTES } from 'constants/routes';
import { ActionDialogManagementReturnType, usePopover, useRouter } from 'hooks';
import { LibraryType } from 'types';

import { Phase } from '../types';

type WorkflowTableRowProps = {
  name: string;
  id: string;
  isPublished: boolean;
  isActive: boolean;
  onClickActivationOptionHandler: ActionDialogManagementReturnType['onClickActionOptionHandler'];
  phase: Phase[];
  libraryType: LibraryType;
};

export const WorkflowTableRow: FC<WorkflowTableRowProps> = memo(
  ({ onClickActivationOptionHandler, libraryType, isActive, id, name, isPublished, phase }) => {
    const { navigate } = useRouter();

    const onClickTableRowHandler = () => navigate(`/workflows/${libraryType}/${id}`, { state: { phase } });

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleOpenPopover(event);
    };

    const onClickActivationButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name,
        actionType: isActive ? 'deactivate' : 'activate',
      });
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name,
        actionType: 'delete',
      });
    };

    const onClickPublishUnpublishButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name,
        actionType: isPublished ? 'unpublish' : 'publish',
      });
    };

    return (
      <TableRow
        onClick={onClickTableRowHandler}
        sx={
          {
            // TODO: create a custom table row component
            // ...(!isActive && { opacity: '40%' }),
          }
        }
      >
        <TableCell>{name}</TableCell>

        <TableCell>
          <PublishStatusChip isPublished={isPublished} />
        </TableCell>

        <ThreeDotsTableCell
          open={openPopover}
          tableCellSX={{ textAlign: 'center' }}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          onClose={onCloseThreeDotsMenuHandler}
          onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
          menuItems={[
            getActivateMenuItem(isActive, onClickActivationButtonHandler),
            getPublishMenuItem(isPublished, onClickPublishUnpublishButtonHandler),
          ]}
        />
      </TableRow>
    );
  },
);
