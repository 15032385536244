import { FC } from 'react';

import { Box, Skeleton } from '@mui/material';

export const ActiveStatusChipSkeleton: FC = () => {
  return (
    <Box
      sx={{
        height: 30,
        width: 80,
      }}
      alignSelf={{ xs: 'center', sm: 'auto' }}
    >
      <Skeleton
        sx={{
          borderRadius: theme => theme.borders.borderRadius[12],
          transform: 'scale(1, 1)',
          display: 'flex',
          height: 1,
          width: 1,
        }}
      />
    </Box>
  );
};
