import { stepConnectorClasses } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const { background, primary } = palette;
const { borderWidth } = borders;

const stepConnector = {
  styleOverrides: {
    root: {
      transition: 'all 200ms linear',
      borderColor: background.surfaceSoft,
      [`&.${stepConnectorClasses.completed}, &.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: primary.main,
        },
      },
    },

    line: {
      borderWidth: borderWidth[2],
      borderColor: background.surfaceMedium,
    },
  },
};

export default stepConnector;
