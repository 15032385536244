import { memo } from 'react';

import { Card, CardContent, Stack } from '@mui/material';

import { TranscriptionActionsToolbar } from './TranscriptionActionsToolbar';
import { TranscriptionITem } from './TranscriptionITem';
import { TranscriptionEntry } from './types';

const transcriptionData: TranscriptionEntry[] = [
  {
    id: 1,
    timestamp: '0:15',
    text: 'Hello! How are you today? What brings you in?',
  },
  {
    id: 2,
    timestamp: '1:15',
    text: "Hi, Doctor. I'm doing well, thank you. I wanted to talk about the wrinkles on my forehead and the dark circles under my eyes. I've been thinking about treatments to improve them.",
  },
  {
    id: 3,
    timestamp: '1:20',
    text: "Sure, those are common concerns. Are there any other areas that you're worried about?",
  },
];

type TranscriptionListProps = {
  transcriptionData: TranscriptionEntry[];
};

export const TranscriptionList: React.FC = memo(() => {
  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        <TranscriptionActionsToolbar onClickEditButtonHandler={() => {}} />
        <Stack component="ul" gap={1}>
          {transcriptionData.map(entry => (
            <TranscriptionITem key={entry.id} {...entry} />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
});
