import { StateCreator } from 'zustand';

import { BeforeAfterAlbumProfileInitialState, BeforeAfterAlbumProfileSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: BeforeAfterAlbumProfileInitialState = {
  b_a_gl_album: null,
  b_a_gl_album_pairs: { page: null, items: [], total: null, size: null, pages: null },
  b_a_pl_album: null,
  b_a_pl_album_pairs: { page: null, items: [], total: null, size: null, pages: null },
};

export const BeforeAfterAlbumProfile: StateCreator<
  BeforeAfterAlbumProfileSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  BeforeAfterAlbumProfileSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    set_b_a_album: data => {
      set(state => {
        state.b_a_gl_album = data;
      });
    },
    set_b_a_album_pairs: data => {
      set(state => {
        state.b_a_gl_album_pairs = data;
      });
    },
    set_b_a_pl_album: data => {
      set(state => {
        state.b_a_pl_album = data;
      });
    },
    set_b_a_pl_album_pairs: data => {
      set(state => {
        state.b_a_pl_album_pairs = data;
      });
    },
    set_next_b_a_album_pairs: data => {
      set(state => {
        state.b_a_gl_album_pairs = {
          ...data,
          items: [...state.b_a_gl_album_pairs.items, ...data.items],
        };
      });
    },
    set_next_b_a_pl_album_pairs: data => {
      set(state => {
        state.b_a_pl_album_pairs = {
          ...data,
          items: [...state.b_a_pl_album_pairs.items, ...data.items],
        };
      });
    },
  };
};
