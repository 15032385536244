import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

const appBar: {
  defaultProps?: ComponentsProps['MuiAppBar'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAppBar'];
  variants?: ComponentsVariants<Theme>['MuiAppBar'];
} = {
  defaultProps: {
    color: 'transparent',
  },

  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },
};

export default appBar;
