import { palette } from 'styles/theme/base/palette';

const { text } = palette;

const formLabel = {
  styleOverrides: {
    root: {
      color: text.primary,
    },
  },
};

export default formLabel;
