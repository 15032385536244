import { ButtonTypeMap } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonRoot } from './MDButtonRoot';
import { MDButtonProps } from './types';

export const MDButton = <RootComponent extends React.ElementType = ButtonTypeMap['defaultComponent']>({
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  isCircular,
  isIconOnly,
  children,
  isLoading,
  startIcon,
  endIcon,
  component,
  ...rest
}: MDButtonProps<RootComponent>) => {
  return (
    <ButtonRoot
      {...rest}
      color={color}
      variant={variant}
      size={size}
      component={component as RootComponent}
      ownerState={{ isIconOnly, isCircular }}
      startIcon={isLoading ? null : startIcon}
      endIcon={isLoading ? null : endIcon}
    >
      {isLoading ? (
        <CircularProgress
          size={20}
          color="primary"
          sx={{
            svg: {
              width: 20,
              height: 20,
            },
          }}
        />
      ) : (
        children
      )}
    </ButtonRoot>
  );
};
