import { Dialog, Stack, Table, TableBody, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyStateWithAction } from 'components/EmptyState';
import { Icon } from 'components/Icon';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { EMPTY_SCREEN_MESSAGES } from 'constants/strings';
import { PageWrapper } from 'layouts';

import { AddUserDialogWindowForm, FiltersMenuContent, UserTableRow } from './components';
import { USERS_LIST_TABLE_HEAD } from './table.config';
import { useUsersState } from './useUsers.state';

export const UsersList: React.FC = () => {
  const {
    isPending,
    onGetUsersHandler,
    onChangeSearchValueHandler,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    items,
    onApplyFilters,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    selectedItem,
    isDeleting,
    onDeleteUserHandler,
    onClickActionOptionHandler,
    onActivateUserHandler,
    onDeactivateUserHandler,
    actionState,
    isActionPending,
    expertProfileId,
    isOpenAddUserDialogWindow,
    openAddUserDialogWindow,
    closeAddUserDialogWindow,
    getCloseDialogHandler,
    dialogStates,
    resetPageHandler,
    setResetFilters,
    isEmptyState,
    isFilteredEmptyState,
  } = useUsersState();

  return (
    <PageWrapper>
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by name, position, contacts...',
          onChange: onChangeSearchValueHandler,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={{ onClick: openAddUserDialogWindow, buttonTitle: 'Add user' }}
      />

      {isEmptyState ? (
        <EmptyStateWithAction
          title="No Users Yet"
          subtitle="Click below to add a new one."
          iconType="users"
          actionButtonProps={{
            title: 'Add User',
            onClickHandler: openAddUserDialogWindow,
            buttonProps: { startIcon: <Icon type="add" /> },
          }}
        />
      ) : isFilteredEmptyState ? (
        <EmptyStateWithAction
          title={EMPTY_SCREEN_MESSAGES.title}
          subtitle={EMPTY_SCREEN_MESSAGES.subtitle}
          iconType="users"
        />
      ) : (
        <>
          <CustomTableContainer>
            <Table size="medium" sx={{ minWidth: 800 }}>
              <CustomTableHeader headLabel={USERS_LIST_TABLE_HEAD} />

              <TableBody>
                {isPending ? (
                  <TableSkeleton cellsAmount={USERS_LIST_TABLE_HEAD.length} />
                ) : (
                  items.map(user => (
                    <UserTableRow
                      key={user?.id}
                      firstName={user?.first_name}
                      lastName={user?.last_name}
                      middleName={user?.middle_name}
                      email={user?.email}
                      userRole={user?.role}
                      createdAt={user?.created_at}
                      id={user?.id}
                      isActive={user?.is_active}
                      onClickActivationOptionHandler={onClickActionOptionHandler}
                      isDotsButton={expertProfileId !== user?.id}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>

          <CustomTablePagination
            count={total}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePageHandler}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </>
      )}

      <PopoverWrapper open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu} variant="filter">
        <FiltersMenuContent
          appliedFilters={appliedFilters}
          onResetFilters={setResetFilters}
          onApplyFilters={onApplyFilters}
        />
      </PopoverWrapper>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {selectedItem.name}?
            </Typography>
          </Typography>
        }
        title="Deleting user"
        approveButtonTitle="Delete user"
        isApproveButtonDisabled={isDeleting}
        isApproveButtonLoading={isDeleting}
        onClickApproveButtonHandler={onDeleteUserHandler}
      />

      <BaseDialogWindow
        open={dialogStates.deactivate}
        onClickCancelButtonHandler={getCloseDialogHandler('deactivate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              You are about to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              account. Please be aware that the user will no longer have access to the account. This action is
              reversible, you can reactivate the account at any time to restore access.
            </Typography>
          </Stack>
        }
        title="Inactivate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate user"
        onClickApproveButtonHandler={onDeactivateUserHandler}
      />

      <BaseDialogWindow
        open={dialogStates.activate}
        onClickCancelButtonHandler={getCloseDialogHandler('activate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              You are about to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              account. Please be aware that the user will regain access to their account.
            </Typography>
          </Stack>
        }
        title="Activate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate user"
        onClickApproveButtonHandler={onActivateUserHandler}
      />

      <Dialog
        open={isOpenAddUserDialogWindow}
        onClose={closeAddUserDialogWindow}
        fullWidth
        slotProps={{
          paper: {
            sx: {
              maxWidth: 750,
            },
          },
        }}
      >
        <AddUserDialogWindowForm
          onCloseDialogHandler={closeAddUserDialogWindow}
          onGetUsersHandler={onGetUsersHandler}
          resetPageHandler={resetPageHandler}
        />
      </Dialog>
    </PageWrapper>
  );
};
