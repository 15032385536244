import { Box, BoxProps, Divider } from '@mui/material';

import { MDTabs, MDTabsProps } from 'components/MDTabs';

export type StickyTabsProps = MDTabsProps & {
  isWebView?: boolean;
  tabWrapperProps?: BoxProps;
};

export const StickyTabs: React.FC<StickyTabsProps> = ({ tabWrapperProps, isWebView, ...restTabsProps }) => {
  return (
    <Box
      width={1}
      display="flex"
      sx={{
        ...(isWebView && {
          position: 'sticky',
          top: 0,
          zIndex: theme => theme.zIndex.appBar,
          backgroundColor: 'background.default',
        }),
      }}
    >
      <Box width={1} {...tabWrapperProps} mt={isWebView ? 0 : 1} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
        <MDTabs variant="scrollable" allowScrollButtonsMobile isFullWidth={isWebView} {...restTabsProps} />
        {isWebView && <Divider flexItem sx={{ mt: '-1px' }} />}
      </Box>
    </Box>
  );
};
