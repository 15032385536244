import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { boxShadows } from 'styles/theme/base/boxShadows';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const tablePagination: {
  defaultProps?: ComponentsProps['MuiTablePagination'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTablePagination'];
  variants?: ComponentsVariants<Theme>['MuiTablePagination'];
} = {
  defaultProps: {
    backIconButtonProps: {
      size: 'small' as const,
    },
    nextIconButtonProps: {
      size: 'small' as const,
    },
    slotProps: {
      select: {
        MenuProps: {
          sx: {
            '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
              boxShadow: boxShadows['shadow-sm'],
              border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
            },
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      },
    },
    SelectProps: {
      MenuProps: {
        MenuListProps: {
          sx: {
            '& .MuiMenuItem-root': {
              ...typography.body2,
            },
          },
        },
      },
    },
  },
  styleOverrides: {
    root: {
      borderTop: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      '& .MuiSelect-iconOpen': {
        transform: 'translateY(-50%) rotate(-90deg) !important',
      },
      '& .MuiInputBase-root': {
        marginRight: 8,
        order: -1,
        fontWeight: 500,
        borderRadius: borders.borderRadius[4],
        overflow: 'hidden',
        border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
        backgroundColor: palette.background.surfaceSoft,
        height: 28,
        justifyContent: 'space-between',
      },

      '& .MuiSelect-select.MuiTablePagination-select': {
        padding: '0 30px 0 8px !important',
        height: '28px !important',
      },
    },
    selectLabel: {
      fontWeight: 500,
      fontSize: 12,
      order: -1,
    },
    displayedRows: {
      fontWeight: 500,
      fontSize: 12,
    },
    toolbar: {
      minHeight: '48px !important',
      width: '100%',
    },
    actions: {
      marginRight: '8px',
    },
    selectRoot: {
      '.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
        padding: 0,
        boxShadow: boxShadows['shadow-md'],
        backgroundColor: 'red',
        border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      },
    },
    select: {
      MenuProps: {
        padding: 0,
        boxShadow: boxShadows['shadow-md'],
        backgroundColor: 'red',
        border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      },
      '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
        padding: 0,
        boxShadow: boxShadows['shadow-md'],
        backgroundColor: 'red',
        border: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      },
    },
    menuItem: {
      borderRadius: borders.borderRadius[0],
      padding: '8px 10px',
    },
  },
};

export default tablePagination;
