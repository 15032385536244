import { FC, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Stack } from '@mui/material';

import { DictionaryCityItem, DictionaryItem, PatientEnum } from 'apiServices';
import { MDInput } from 'components/MDInput';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { usePatientEnums } from 'hooks';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FilterInitialState, FiltersMenuContentProps } from '../types';
import { FilterInitialStateValue } from '../usePatients.state';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ appliedFilters, onApplyFilters, onResetFilters }) => {
  const [filters, setFilters] = useState<FilterInitialState>(appliedFilters);

  const {
    patientGenderEnumsState,
    patientEthnicitiesEnumsState,
    patientBioSexesEnumsState,
    fetchPatientGenderEnums,
    fetchPatientEthnicitiesEnums,
    fetchPatientBioSexesEnums,
  } = usePatientEnums();

  useEffect(() => {
    fetchPatientEthnicitiesEnums();
    fetchPatientGenderEnums();
    fetchPatientBioSexesEnums();
  }, []);

  const handleChange =
    (field: keyof FilterInitialState) =>
    (
      _: React.SyntheticEvent<Element, Event>,
      value: PatientEnum | DictionaryCityItem | DictionaryItem | null | number[],
    ) => {
      setFilters(prev => ({ ...prev, [field]: value }));
    };

  const onClickResetFiltersButtonHandler = () => {
    setFilters(FilterInitialStateValue);
    onResetFilters();
  };

  const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

  const filtersEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries: filtersEntries }),
    [filtersEntries],
  );

  const isResetButtonDisabled = useMemo(
    () =>
      getFilterResetButtonStatus({
        filterEntries: filtersEntries,
        defaultFilters: FilterInitialStateValue,
      }),
    [filtersEntries],
  );

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => Promise<void>;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
          fullWidth
        />
      )}
    />
  );
  return (
    <Stack position="relative">
      <Scrollbar style={{ maxHeight: 410 }}>
        <Stack spacing={2.5} mt={2}>
          {renderAutocomplete({
            value: filters.bioGenderValue,
            getOptionLabel: option => option.value,
            isLoading: patientBioSexesEnumsState.isLoading,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Bio gender',
            onChange: handleChange('bioGenderValue'),
            options: patientBioSexesEnumsState.items,
            placeholder: 'Select Bio Gender',
          })}

          {renderAutocomplete({
            value: filters.genderValue,
            getOptionLabel: option => option.value,
            isLoading: patientGenderEnumsState.isLoading,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Gender',
            onChange: handleChange('genderValue'),
            options: patientGenderEnumsState.items,
            placeholder: 'Select Gender',
          })}

          {renderAutocomplete({
            value: filters.ethnicGroupValue,
            getOptionLabel: option => option.value,
            isLoading: patientEthnicitiesEnumsState.isLoading,
            isOptionEqualToValue: (option, value) => option.name === value.name,
            label: 'Ethnicity',
            onChange: handleChange('ethnicGroupValue'),
            options: patientEthnicitiesEnumsState.items,
            placeholder: 'Select Ethnicity',
          })}
        </Stack>
      </Scrollbar>

      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
