import { CSSObject, Theme } from '@mui/material';

export const getBorderStyles = (theme: Theme, isScrollableSection?: boolean): CSSObject => ({
  borderLeft: 0,
  ...(!isScrollableSection && {
    borderTop: `${theme.borders.borderWidth[1]} solid ${theme.palette.background.surfaceStrong}`,
  }),
  [theme.breakpoints.up('md')]: {
    borderLeft: `${theme.borders.borderWidth[1]} solid ${theme.palette.background.surfaceStrong}`,
    borderTop: 0,
  },
});
