import { FC, useEffect, useState } from 'react';

import { Card, Grid2, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { deleteAIModelVersion } from 'apiServices/ml/ai-model-version.api';
import { MDButton } from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, CustomTablePagination, TableNoData, TableSkeleton } from 'components/Table';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';
import { useVersions } from 'hooks/useVersions';

export const AIModelVersions: FC = () => {
  const { navigate, state, params } = useRouter();
  const model = state.model;
  state.breadCrumbName = model?.name;

  const [localVersions, setLocalVersions] = useState([]);
  const { versions, isLoading, error } = useVersions(model.id);

  useEffect(() => {
    if (!isLoading && versions) {
      setLocalVersions(versions);
    }
  }, [isLoading, versions]);

  const handleDeleteVersionClick = async (version_id: string) => {
    try {
      await deleteAIModelVersion(version_id);
      setLocalVersions(prevVersions => prevVersions.filter(v => v.id !== version_id));
    } catch (error) {
      console.error('Error deleting version:', error);
    }
  };

  const handleUpdateVersionClick = (version_id: string) => {
    navigate(`/update-ai-models/${model?.name}/${version_id}/versions`, { state: state });
  };

  const handleAddVersionClick = () => {
    navigate(ROUTES.addAIModelVersion, { state: { breadCrumbName: 'Add AI Model', model: model } });
  };

  return (
    <section>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Card sx={{ borderRadius: 2, overflow: 'hidden', padding: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Model Versions for {params.model_name}</Typography>
              <MDButton variant="contained" color="primary" onClick={handleAddVersionClick}>
                Add Version
              </MDButton>
            </Stack>
            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 1000 }}>
                  <CustomTableHeader
                    headLabel={[
                      { id: 'versionNumber', label: 'Number' },
                      { id: 'description', label: 'Full name' },
                      { id: 'apiEndpoint', label: 'API Endpoint' },
                      { id: 'actions', label: 'Actions' },
                    ]}
                  />
                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={4} />
                    ) : error ? (
                      <TableRow>
                        <TableCell colSpan={4}>Error loading versions</TableCell>
                      </TableRow>
                    ) : localVersions.length ? (
                      localVersions.map(version => (
                        <TableRow key={version.id}>
                          <TableCell>{version.version}</TableCell>
                          <TableCell>{version.full_name}</TableCell>
                          <TableCell>{version.api_endpoint}</TableCell>
                          <TableCell>
                            <MDButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                state.version = version;
                                handleUpdateVersionClick(version.id);
                              }}
                            >
                              Update
                            </MDButton>
                            <MDButton
                              variant="outlined"
                              color="error"
                              size="small"
                              onClick={() => handleDeleteVersionClick(version.id)}
                              sx={{ ml: 1 }}
                            >
                              Delete
                            </MDButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableNoData isNotFound />
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <CustomTablePagination
              count={localVersions.length}
              page={0}
              rowsPerPage={10}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </Card>
        </Grid2>
      </Grid2>
    </section>
  );
};
