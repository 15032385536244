import { useEffect, useState } from 'react';

import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { useResponsive, useRouter, useTabs } from 'hooks';
import { calculateAge, dateToCustomFormat } from 'utils/helpers';

import { usePatientStore } from './usePatientStore';

export const usePatientViewState = () => {
  const { params } = useRouter();

  const { setPatient, fetchPatient, patient } = usePatientStore();

  const isWebView = useResponsive('down', 431);

  const [isLoading, setIsLoading] = useState(true);

  const onGetPatientHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchPatient(params?.id);
    setIsLoading(false);
  };

  useEffect(() => {
    onGetPatientHandler();

    return () => setPatient(null);
  }, []);

  const { handleSetTabValue, tabValue } = useTabs();

  const fullName = `${patient?.first_name} ${patient?.last_name}`;
  const fullNameWidthMiddleName = `${patient?.first_name} ${patient?.middle_name} ${patient?.last_name}`;

  const formatedBirthDate = dateToCustomFormat(patient?.birth_date, MM_DD_YYYY_DATE_FORMAT);
  const age = calculateAge(patient?.birth_date);

  const isNoPatient = !isLoading && !patient;

  return {
    isLoading,
    handleSetTabValue,
    tabValue,
    formatedBirthDate,
    fullName,
    fullNameWidthMiddleName,
    patient,
    age,
    isNoPatient,
    onGetPatientHandler,
    isWebView,
    fetchPatient,
  };
};
