import * as yup from 'yup';

import { Patient, UpdatePatientPayload } from 'apiServices';
import { LABELS } from 'constants/strings';
import { Shape } from 'types/RHF-types';

export type EditPatientFormSchema = UpdatePatientPayload;

export const editPatientValidationSchema = yup
  .object()
  .shape<Shape<Patient>>({
    first_name: yup.string().required().label(LABELS.FIRST_NAME),
    middle_name: yup.string().optional().label(LABELS.MIDDLE_NAME).nullable(),
    last_name: yup.string().required().label(LABELS.LAST_NAME),
    birth_date: yup.string().required().label(LABELS.DATE_OF_BIRTH),
    title: yup.string().optional().label(LABELS.TITLE).nullable(),
    ssn: yup.lazy((value: string) => {
      return value?.length
        ? yup.string().required().label(LABELS.SSN).min(9).max(9).trim().nullable()
        : yup.string().optional().label(LABELS.SSN).nullable().trim();
    }),
    occupation: yup.string().optional().label(LABELS.OCCUPATION).nullable(),
    gender_identity: yup.string().optional().label(LABELS.GENDER).nullable(),
    ethnicity: yup.string().optional().label(LABELS.ETHNICITY).nullable(),
  })
  .required();
