import Stack from '@mui/material/Stack';

import { LibraryTabbedPanel } from 'components/Widgets';

import { TABS } from './config';
import { GlobalLibraryTagList, PracticeLibraryTagList } from './pages';
import { useTagManagementState } from './useTagManagement.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibraryTagList />;

    default:
      return <PracticeLibraryTagList />;
  }
}

export const TagManagement: React.FC = () => {
  const { handleSetTabValue, tabValue } = useTagManagementState();

  return (
    <Stack component="section" flex={1}>
      <LibraryTabbedPanel value={tabValue} handleSetTabValue={handleSetTabValue} tabsData={TABS} />
      {renderTabValueContent(tabValue)}
    </Stack>
  );
};
