import { Box, Grid2, Stack, Typography } from '@mui/material';

import { AuthFlowBackgrounds } from 'types/enums';

import AuthLogo from 'assets/icons/aesthetics360_full_logo.svg';

import { Footer } from './components/Footer';
import { LayoutWrapperProps } from './types';

export const IllustrationLayout: React.FC<LayoutWrapperProps> = ({
  children,
  authMode = 'signIn',
  description,
  illustration = AuthFlowBackgrounds.SIGN_IN,
  title,
  backButton,
}) => {
  return (
    <Box width={1} height="100%" minHeight="100vh">
      <Grid2
        container
        sx={{
          backgroundColor: 'white.main',
        }}
      >
        <Grid2
          size={{
            xs: 12,
            lg: 6,
          }}
          sx={theme => ({
            mx: 'auto',
            position: 'relative',
            [theme.breakpoints.down(1200)]: {
              flexBasis: '100%',
              maxWidth: 1,
            },
          })}
        >
          <Box display="flex" flexDirection="column" justifyContent="center" minHeight="100vh">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 15,
                left: 15,
              }}
            >
              <Box component="img" src={AuthLogo} width={130} height={19} />
            </Stack>

            <Grid2
              container
              sx={{
                alignItems: 'center',
                pt: 7,
                flex: 1,
              }}
            >
              <Grid2
                size={{
                  xs: 11,
                  sm: 9,
                }}
                mx="auto"
              >
                {authMode === 'signIn' && (
                  <Box pt={3} py={3.5} px={3}>
                    <Stack spacing={3} alignItems="flex-start">
                      {backButton && backButton}
                      <Typography mb={1} variant="h2" fontSize={32}>
                        {title}
                      </Typography>
                    </Stack>

                    <Typography
                      mt={1}
                      display="flex"
                      lineHeight="20px"
                      variant="caption"
                      fontWeight="fontWeightRegular"
                      fontSize="size.bodyMedium"
                      color="text.secondary"
                    >
                      {description}
                    </Typography>
                  </Box>
                )}
                <Box p={3} pt={0} width={1}>
                  {children}
                </Box>
              </Grid2>
            </Grid2>

            <Box
              sx={{
                display: 'flex',
                width: 1,
                mb: 2,
              }}
            >
              <Footer />
            </Box>
          </Box>
        </Grid2>
        <Grid2
          size={{
            xs: 0,
            lg: 6,
          }}
          sx={theme => ({
            [theme.breakpoints.down(1200)]: {
              display: 'none',
            },
          })}
        >
          <Box py="10px" width={1} height={1} pr="10px">
            <Box height={1} width={1}>
              <Box
                display={{ xs: 'none', lg: 'flex' }}
                width={1}
                height={1}
                borderRadius="30px"
                sx={{ background: `url(${illustration}) no-repeat center center`, backgroundSize: 'cover' }}
              />
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};
