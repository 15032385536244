import { useState } from 'react';

import { updatePassword } from 'aws-amplify/auth';

import { ToastType, notice } from 'components/ToastNotification';
import { changePasswordValidationData } from 'components/ValidationChecklist';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);

      await updatePassword({
        oldPassword: passwordsState.currentPassword,
        newPassword: passwordsState.confirmPassword,
      });

      setPasswordsState(initialState);
      notice(ToastType.SUCCESS, 'Successfully changed password!');
    } catch (error: unknown) {
      console.error(error);
      if ((error as Error)?.message === 'Incorrect username or password.') {
        notice(ToastType.ERROR, 'Incorrect current password');
      } else {
        awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to change password, please try again!' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [passwordsState, setPasswordsState] = useState(initialState);

  const changePasswordsState = ({
    fieldName,
    value,
  }: {
    fieldName: keyof typeof initialState;
    value: string | boolean;
  }) =>
    setPasswordsState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));

  const onChangeCurrentPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'currentPassword', value: event.target.value });
  };

  const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'newPassword', value: event.target.value });
  };

  const onChangeConfirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    changePasswordsState({ fieldName: 'confirmPassword', value: event.target.value });
  };

  const { isValid, rules } = changePasswordValidationData(
    passwordsState.currentPassword,
    passwordsState.newPassword,
    passwordsState.confirmPassword,
  );

  return {
    isLoading,
    onFormSubmitHandler,
    passwordsState,
    onChangeCurrentPasswordHandler,
    onChangeNewPasswordHandler,
    onChangeConfirmPasswordHandler,
    isValid,
    rules,
  };
};
