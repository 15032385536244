import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import pxToRem from '../functions/pxToRem';

const icon: {
  defaultProps?: ComponentsProps['MuiIcon'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiIcon'];
  variants?: ComponentsVariants<Theme>['MuiIcon'];
} = {
  defaultProps: {
    baseClassName: 'material-icons-round',
    fontSize: 'inherit',
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: 'inherit !important',
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};

export default icon;
