import { Box, BoxProps, Divider } from '@mui/material';

import { MDTabs, MDTabsProps } from 'components/MDTabs';

type LibraryTabbedPanelProps = MDTabsProps & {
  wrapperProps?: BoxProps;
};

export const LibraryTabbedPanel: React.FC<LibraryTabbedPanelProps> = ({
  wrapperProps,
  tabsData,
  handleSetTabValue,
  value,
  ...restTabsProps
}) => {
  return (
    <>
      <Box px={3} pt={1} width={1} {...wrapperProps}>
        <MDTabs value={value} tabsData={tabsData} handleSetTabValue={handleSetTabValue} {...restTabsProps} />
      </Box>
      <Divider sx={{ mt: '-1px' }} />
    </>
  );
};
