import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import { Icon } from 'components/Icon';
import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const { borderRadius } = borders;

const checkbox: {
  defaultProps?: ComponentsProps['MuiCheckbox'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
  variants?: ComponentsVariants<Theme>['MuiCheckbox'];
} = {
  defaultProps: {
    disableRipple: true,
    icon: <Icon type="unChecked" />,
    checkedIcon: <Icon type="checked" />,
  },
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: borderRadius[4],
      color: palette.secondary.dark,

      ':hover': {
        color: palette.secondary.darker,
      },

      '&.Mui-checked': {
        color: palette.primary.main,
        ':hover': {
          color: palette.primary.dark,
        },
      },

      '&.Mui-disabled': {
        backgroundColor: palette.background.default,
        color: palette.disabledColor.disabledSoft,

        '&.Mui-checked': {
          backgroundColor: palette.disabledColor.disabledSoft,
          color: palette.disabledColor.disabledMedium,
        },
      },

      '& svg': {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: 20,
        height: 20,
        color: 'inherit',
      },
    },
  },
};

export default checkbox;
