import { useCallback, useState } from 'react';

import { useBoolean } from 'hooks';

export const useRecordTable = () => {
  const [isAddRecord, setIsAddRecord] = useState(false);

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const clearRecordState = useCallback(() => {
    setIsAddRecord(false);
    setSelectedRecordId(null);
  }, []);

  const onClickEditButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setIsAddRecord(false);
    setSelectedRecordId(event.currentTarget.id);
  }, []);

  const onClickAddButtonHandler = useCallback(() => setIsAddRecord(true), []);

  const onClickCloseButtonHandler = useCallback(() => {
    selectedRecordId ? setSelectedRecordId(null) : setIsAddRecord(false);
  }, [selectedRecordId]);

  const onClickDeleteButtonHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedRecordId(event.currentTarget.id);
    openDeleteDialogWindow();
  }, []);

  return {
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    isAddRecord,
    setIsAddRecord,
    selectedRecordId,
    setSelectedRecordId,
    onClickEditButtonHandler,
    onClickAddButtonHandler,
    onClickCloseButtonHandler,
    onClickDeleteButtonHandler,
    clearRecordState,
  };
};
