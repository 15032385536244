import { useState } from 'react';

import { CardContent, Divider, Stack } from '@mui/material';

import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';

import { CardItem } from '../types';

type AddItemCardProps<TItem extends CardItem> = {
  onCancelHandler: () => void;
  onSaveHandler: (item: TItem) => void;
  label?: string;
  placeholder?: string;
};

export const AddItemCard = <TItem extends CardItem>({
  onCancelHandler,
  onSaveHandler,
  label = 'Name',
  placeholder = 'Enter Name',
}: AddItemCardProps<TItem>) => {
  const [name, setName] = useState('');

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  const onClickSaveHandler = () => {
    onSaveHandler({ id: name, name } as TItem);
    onCancelHandler();
  };

  return (
    <>
      <CardContent
        sx={{
          p: { xs: 2, lg: 3 },
          '&:last-child': {
            paddingBottom: { xs: 2, lg: 3 },
          },
        }}
      >
        <Stack width={1} direction={{ xs: 'column-reverse', md: 'row' }} gap={3} alignItems="flex-end">
          <MDInput
            label={label}
            fullWidth
            value={name}
            onChange={onChangeName}
            placeholder={placeholder}
            fieldWrapperProps={{
              width: 1,
              sx: {
                '& label': {
                  fontSize: 14,
                },
              },
            }}
          />
          <Stack direction="row" justifyContent="flex-end" maxHeight={50} gap={1.5}>
            <MDButton variant="secondary" onClick={onCancelHandler}>
              Cancel
            </MDButton>
            <MDButton disabled={!name.length} onClick={onClickSaveHandler}>
              Save
            </MDButton>
          </Stack>
        </Stack>
      </CardContent>
      <Divider />
    </>
  );
};
