import { BASE_DICTIONARY_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import {
  CreateGlobalLibraryTagCategoryPayload,
  CreateGlobalLibraryTagPayload,
  GlobalLibrarySearchParams,
  GlobalLibraryTag,
  GlobalLibraryTagCategory,
  GlobalLibraryTagsSearchParams,
  UpdateGlobalLibraryTagCategoryPayload,
  UpdateGlobalLibraryTagPayload,
} from './types';

const BASE_TAG_CATEGORIES_API_URL = `${BASE_DICTIONARY_API_URL}/tag_categories`;
const BASE_TAGS_API_URL = `${BASE_DICTIONARY_API_URL}/tags`;

export const getGlobalLibraryTagCategories = async (params: GlobalLibrarySearchParams) =>
  await apiClient.get<ListOfItems<GlobalLibraryTagCategory>>(BASE_TAG_CATEGORIES_API_URL, {
    params,
    paramsSerializer: { indexes: null },
  });

export const createGlobalLibraryTagCategory = async (payload: CreateGlobalLibraryTagCategoryPayload) =>
  await apiClient.post<GlobalLibraryTagCategory>(BASE_TAG_CATEGORIES_API_URL, payload);

export const getGlobalLibraryTagCategory = async (categoryId: string) =>
  await apiClient.get<GlobalLibraryTagCategory>(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`);

export const updateGlobalLibraryTagCategory = async (
  categoryId: string,
  payload: UpdateGlobalLibraryTagCategoryPayload,
) => await apiClient.patch<GlobalLibraryTagCategory>(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`, payload);

export const deleteGlobalLibraryTagCategory = async (categoryId: string) =>
  await apiClient.delete(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`);

// Tags API
export const getGlobalLibraryTags = async (params: GlobalLibraryTagsSearchParams) =>
  await apiClient.get<ListOfItems<GlobalLibraryTag>>(BASE_TAGS_API_URL, {
    params,
    paramsSerializer: { indexes: null },
  });

export const createGlobalLibraryTag = async (payload: CreateGlobalLibraryTagPayload) =>
  await apiClient.post<GlobalLibraryTag>(BASE_TAGS_API_URL, payload);

export const getGlobalLibraryTag = async (tagId: string) =>
  await apiClient.get<GlobalLibraryTag>(`${BASE_TAGS_API_URL}/${tagId}`);

export const updateGlobalLibraryTag = async (tagId: string, payload: UpdateGlobalLibraryTagPayload) =>
  await apiClient.patch<GlobalLibraryTag>(`${BASE_TAGS_API_URL}/${tagId}`, payload);

export const deleteGlobalLibraryTag = async (tagId: string) => await apiClient.delete(`${BASE_TAGS_API_URL}/${tagId}`);
