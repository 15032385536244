import { FC, memo } from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { PatientListItem } from 'apiServices';
import { MDAvatar } from 'components/MDAvatar';
import { DATE_OF_BIRTH_DATE_FORMAT } from 'constants/dateFormats';
import { calculateAge, dateToCustomFormat } from 'utils/helpers';

type PatientsTableRowProps = Pick<
  PatientListItem,
  'birth_date' | 'id' | 'avatar_url' | 'first_name' | 'last_name' | 'middle_name' | 'phone' | 'email'
>;

export const PatientsTableRow: FC<PatientsTableRowProps> = memo(
  ({ birth_date, first_name, id, last_name, middle_name, avatar_url, email, phone }) => {
    const navigate = useNavigate();

    const onClickRowHandler = () => navigate(`/patients/${id}`);

    const age = calculateAge(birth_date);

    const formattedBirthData = dateToCustomFormat(birth_date, DATE_OF_BIRTH_DATE_FORMAT);

    return (
      <TableRow onClick={onClickRowHandler}>
        <TableCell>
          <MDAvatar
            isShowErrorNotification={false}
            isDisabled
            avatarSize="sm"
            src={avatar_url ?? null}
            alt="patient avatar"
            name={`${last_name} ${first_name}`}
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2">{last_name}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{first_name}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{middle_name ?? ''}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{`${formattedBirthData} (${age})`}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
            {email ?? ''}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2">{phone ?? ''}</Typography>
        </TableCell>
      </TableRow>
    );
  },
);
