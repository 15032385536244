import { Outlet } from 'react-router-dom';

import { Breadcrumbs, MobileBreadCrumbs } from 'components/Breadcrumbs';
import { NavVertical } from 'components/NavVertical';

import { ContentWrapper } from './ContentWrapper';
import { MainLayoutStyles as Styled } from './MainLayout.styles';
import { useMainLayoutState } from './useMainLayout.state';

export const MainLayout: React.FC = () => {
  const { isWebView, handleClose, handleOpen, isMobile, isNavMini, isOpenMobileNavBar, isTablet } =
    useMainLayoutState();

  return (
    <Styled.MainLayoutWrapper>
      <NavVertical isTabletScreen={isTablet} onCloseSideNav={handleClose} isOpenMobileNavBar={isOpenMobileNavBar} />
      <Styled.LayoutWrapper isNavMini={isNavMini} isTablet={isTablet}>
        {!isWebView && (
          <Styled.Header>
            {isMobile ? (
              <MobileBreadCrumbs
                isMobileScreen={isMobile}
                isOpenMobileNavBar={isOpenMobileNavBar}
                onOpenSideNav={handleOpen}
              />
            ) : (
              <Breadcrumbs
                isTabletScreen={isTablet}
                isOpenMobileNavBar={isOpenMobileNavBar}
                onOpenSideNav={handleOpen}
              />
            )}
          </Styled.Header>
        )}
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Styled.LayoutWrapper>
    </Styled.MainLayoutWrapper>
  );
};
