import { FC, memo, useEffect } from 'react';

import { Practice } from 'apiServices';
import { RecordTable } from 'components/Table';
import { usePatientEnums } from 'hooks';
import { SectionWrapper } from 'layouts';

import { CONTACT_RECORD_TABLE_HEAD, AddEditContactTableRow, PracticeContactTableRow } from './components';

type ContactsProps = Pick<Practice, 'contact_persons'> & {
  fetchPracticeProfile: () => Promise<void>;
  practiceId: string;
};

export const Contacts: FC<ContactsProps> = memo(({ contact_persons, fetchPracticeProfile, practiceId }) => {
  const { fetchPatientPersonTitlesEnums, patientPersonTitlesEnumsState } = usePatientEnums();

  useEffect(() => {
    fetchPatientPersonTitlesEnums();
  }, []);

  return (
    <SectionWrapper>
      <RecordTable
        title="Contacts"
        items={contact_persons}
        headLabels={CONTACT_RECORD_TABLE_HEAD}
        addButtonTitle="Add Address"
        renderAddEditTableRow={({ onClickCancelButtonHandler, item }) => (
          <AddEditContactTableRow
            key={item?.id}
            contact={item}
            onClickCancleButtonHandler={onClickCancelButtonHandler}
            fetchPracticeProfile={fetchPracticeProfile}
            practiceId={practiceId}
            patientPersonTitlesEnumsState={patientPersonTitlesEnumsState}
          />
        )}
        renderTableRow={({ item, onClickEdit }) => (
          <PracticeContactTableRow key={item?.id} onClickEdit={onClickEdit} {...item} />
        )}
      />
    </SectionWrapper>
  );
});
