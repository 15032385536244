import { TagListSortFieldName } from 'apiServices';
import { TableHeaderConfig } from 'types';
import { LIBRARY_TABS } from 'types/enums';

export const TABS: LIBRARY_TABS[] = [LIBRARY_TABS.GLOBAL_LIBRARY, LIBRARY_TABS.PRACTICE_LIBRARY];

export const WORKFLOW_TABLE_HEADERS: TableHeaderConfig<TagListSortFieldName>[] = [
  { id: 'workflowName', label: 'Workflow Name', disableSorting: true },
  { id: 'status', label: 'Status', disableSorting: true },
  { id: 'actions', disableSorting: true },
];
