import Stack from '@mui/material/Stack';

import { EntityHeaderActions, EntityHeaderRoot } from 'components/Widgets';

import { GeneralTabContent } from './GeneralTabContent';
import { RelatedVisitsTabContent } from './RelatedVisitsTabContent';
import { PATIENT_CONCERN_PROFILE_TAB_VALUES, usePatientConcernProfileState } from './usePatientConcernProfile.state';

const renderTabContent = (tabValue: number) => {
  switch (tabValue) {
    case 0:
      return <GeneralTabContent />;
    case 1:
      return <RelatedVisitsTabContent />;
    default:
      return null;
  }
};

export const PatientConcernProfile: React.FC = () => {
  const { handleSetTabValue, tabValue } = usePatientConcernProfileState();

  return (
    <Stack component="section">
      <EntityHeaderRoot
        tabs={{
          tabsData: PATIENT_CONCERN_PROFILE_TAB_VALUES,
          value: tabValue,
          handleSetTabValue,
          tabWrapperProps: { maxWidth: 400 },
        }}
      >
        <EntityHeaderActions activeStatusProps={{ isActive: true }} />
      </EntityHeaderRoot>

      {renderTabContent(tabValue)}
    </Stack>
  );
};
