import { FC, useEffect } from 'react';

import { KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded } from '@mui/icons-material';
import { Box, Drawer } from '@mui/material';

import { SIDE_NAVIGATION } from 'constants/layout-config';
import { useAppSettings } from 'hooks/useAppSettings';
import { useRouter } from 'hooks/useRouter';

import { createTransition } from './helpers';
import { StyledDrawer, StyledIconButton, StyledNavVertical } from './NavVertical.styles';
import { NavVerticalRenderContent } from './NavVerticalRenderContent';

type NavVerticalProps = {
  isOpenMobileNavBar: boolean;
  isTabletScreen: boolean;
  onCloseSideNav: VoidFunction;
};

export const NavVertical: FC<NavVerticalProps> = ({ isOpenMobileNavBar, isTabletScreen, onCloseSideNav }) => {
  const { pathname } = useRouter();

  const { onToggleLayout, isNavMini } = useAppSettings();

  useEffect(() => {
    if (isTabletScreen && isNavMini) onToggleLayout();
  }, [isTabletScreen]);

  useEffect(() => {
    if (isTabletScreen && isOpenMobileNavBar) onCloseSideNav();
  }, [pathname, isTabletScreen]);

  return (
    <>
      {isTabletScreen ? (
        <Box component="nav">
          <Drawer
            open={isOpenMobileNavBar}
            onClose={onCloseSideNav}
            variant="temporary"
            ModalProps={{
              keepMounted: false,
            }}
            PaperProps={{
              sx: {
                overflow: 'hidden',
                width: SIDE_NAVIGATION.W_DASHBOARD_TABLET,
                boxShadow: 'none',
              },
            }}
          >
            <StyledIconButton
              size="small"
              onClick={onCloseSideNav}
              sx={{ left: SIDE_NAVIGATION.W_DASHBOARD_TABLET - 16 }}
            >
              {isOpenMobileNavBar ? <KeyboardDoubleArrowLeftRounded /> : <KeyboardDoubleArrowRightRounded />}
            </StyledIconButton>

            <NavVerticalRenderContent isNavMini={isNavMini} />
          </Drawer>
        </Box>
      ) : (
        <StyledNavVertical isNavMini={isNavMini}>
          <StyledIconButton
            size="small"
            onClick={onToggleLayout}
            sx={{
              transition: theme => createTransition(theme, 'all'),
              left: (isNavMini ? SIDE_NAVIGATION.W_DASHBOARD_MINI : SIDE_NAVIGATION.W_DASHBOARD) - 16,
            }}
          >
            {isNavMini ? <KeyboardDoubleArrowRightRounded /> : <KeyboardDoubleArrowLeftRounded />}
          </StyledIconButton>

          <StyledDrawer
            open
            variant="permanent"
            ModalProps={{
              keepMounted: true,
            }}
            isNavMini={isNavMini}
          >
            <NavVerticalRenderContent isNavMini={isNavMini} />
          </StyledDrawer>
        </StyledNavVertical>
      )}
    </>
  );
};
