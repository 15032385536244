import { BASE_PATIENTS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';

import { Patient } from './patient';
import { CreatePatientMedicalCondition } from './types';

export const createPatientMedicalCondition = async (
  patientId: string,
  payload: CreatePatientMedicalCondition,
): Promise<void> => {
  await apiClient.put<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/medical_conditions`, payload);
};

export const deletePatientMedicalCondition = async (patientId: string, condition_id: string): Promise<void> => {
  await apiClient.delete(`${BASE_PATIENTS_API_URL}/${patientId}/medical_conditions/${condition_id}`);
};
