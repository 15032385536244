import { usePopover, useTable } from 'hooks';

export const useConsultationsState = () => {
  const total = 1;

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const { page, rowsPerPage, onChangeRowsPerPage, setPage, resetPageHandler } = useTable();

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
  };

  return {
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    total,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    setPage,
    resetPageHandler,
    onChangePageHandler,
  };
};
