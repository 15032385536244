import { shallow } from 'zustand/shallow';

import { useAppStore } from 'store';
import { USER_ROLES } from 'types/enums';

export const useUserProfile = () => {
  const { setCognitoProfile, cognitoUser, expertProfile, setUserProfile } = useAppStore(
    store => ({
      setUserProfile: store.setUserProfile,
      setCognitoProfile: store.setCognitoProfile,
      cognitoUser: store.cognitoUser,
      expertProfile: store.expertProfile,
    }),
    shallow,
  );

  const userRole = expertProfile?.role || cognitoUser?.roles?.[0];

  const userRoles = {
    isRoleAdmin: userRole === USER_ROLES.ROLE_ADMIN,
    isRolePracticeAdmin: userRole === USER_ROLES.ROLE_PRACTICE_ADMIN,
    isRolePracticeUser: [USER_ROLES.ROLE_PRACTICE_DOCTOR].includes(userRole),
    isRoleAItester: userRole === USER_ROLES.ROLE_AI_TESTER,
  };

  const userId = expertProfile?.id;
  const practiceId = expertProfile?.practice?.id;

  return {
    setCognitoProfile,
    cognitoUser,
    userId,
    userRoles,
    expertProfile,
    userRole,
    setUserProfile,
    practiceId,
  };
};
