import { FC } from 'react';

import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Box, DialogContent, Stack } from '@mui/material';

import { DialogActions, DialogHeader } from './components';
import { FormDialogWindowProps } from './types';

export const FormDialogWindowContent: FC<FormDialogWindowProps> = ({
  children,
  headerProps,
  actionProps,
  onFormSubmitHandler,
}) => {
  return (
    <Stack px={{ xs: 2, sm: 2.5, md: 3 }}>
      <DialogHeader sx={{ px: 0 }} {...headerProps} />
      <Box component="form" onSubmit={onFormSubmitHandler}>
        <DialogContent sx={{ px: 0 }}>{children}</DialogContent>

        <DialogActions
          isDivider={actionProps?.isDivider}
          approveButtonProps={{
            type: 'submit',
            startIcon: <CheckRounded fontSize="medium" />,
            children: 'Save',
            ...actionProps?.approveButtonProps,
          }}
          cancelButtonProps={{
            variant: 'secondary',
            startIcon: <CloseRounded sx={{ fontSize: '20px !important' }} />,
            onClick: headerProps.onClickCancelButtonHandler,
            children: 'Cancel',
            ...actionProps?.cancelButtonProps,
          }}
          sx={{ px: 0, py: '14px', ...actionProps?.sx }}
        />
      </Box>
    </Stack>
  );
};
