import { useCallback, useState } from 'react';

import { useAppSettings, useInterceptor, useResponsive } from 'hooks';

export const useMainLayoutState = () => {
  const isWebView = useResponsive('down', 431);

  const isMobile = useResponsive('down', 426);

  const isTablet = useResponsive('down', 'md');

  const [isOpenMobileNavBar, setIsOpenMobileNavBar] = useState(false);

  const handleOpen = useCallback(() => {
    setIsOpenMobileNavBar(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpenMobileNavBar(false);
  }, []);

  const { isNavMini } = useAppSettings();

  useInterceptor();

  return {
    isMobile,
    isNavMini,
    isTablet,
    isOpenMobileNavBar,
    handleOpen,
    handleClose,
    isWebView,
  };
};
