import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const tableHead = {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.surfaceSoft,
      borderTop: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      borderBottom: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      'tr:first-of-type': {
        cursor: 'inherit',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default tableHead;
