import { FC } from 'react';

import { ArrowBackIosNewRounded } from '@mui/icons-material';

import { MDButton, MDButtonProps } from 'components/MDButton';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

type BackButtonProps = MDButtonProps & {
  navigateTo?: ROUTES;
  title: string;
};

export const BackButton: FC<BackButtonProps> = ({ navigateTo, title, ...buttonProps }) => {
  const { back, navigate } = useRouter();

  const onClick = () => (navigateTo ? navigate(navigateTo) : back());

  return (
    <MDButton
      variant="ghost"
      sx={{ fontSize: 14, px: 0.5 }}
      startIcon={<ArrowBackIosNewRounded />}
      onClick={onClick}
      {...buttonProps}
    >
      {title}
    </MDButton>
  );
};
