import { FC, useEffect, useState } from 'react';

import { Card, Grid2, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { deleteAIModel } from 'apiServices/ml/ai-model.api';
import { MDButton } from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, CustomTablePagination, TableNoData, TableSkeleton } from 'components/Table';
import { ROUTES } from 'constants/routes';
import { useAIModels } from 'hooks/useAIModels';

import { AIModelTableRow } from './components/AIModelTableRow';
import { AI_MODEL_TABLE_HEADERS } from './table.config';

export const AIModelList: FC = () => {
  const navigate = useNavigate();

  const [localModels, setLocalModels] = useState([]);
  const { aiModels, isLoading, error } = useAIModels();

  useEffect(() => {
    if (!isLoading && aiModels) {
      setLocalModels(aiModels);
    }
  }, [isLoading, aiModels]);

  const handleDeleteModelClick = async (model_id: string) => {
    try {
      await deleteAIModel(model_id);
      setLocalModels(prevModels => prevModels.filter(model => model.id !== model_id));
    } catch (error) {
      console.error('Error deleting model:', error);
    }
  };

  const handleAddModelClick = () => {
    navigate(ROUTES.addAIModel);
  };

  return (
    <section>
      <Grid2 container spacing={2} justifyContent="center">
        <Grid2
          size={{
            xs: 12,
            md: 10,
            lg: 8,
          }}
        >
          <Card sx={{ borderRadius: 2, overflow: 'hidden', maxWidth: '1000px', mx: 'auto' }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" p={2}>
              <MDButton variant="contained" color="primary" onClick={handleAddModelClick}>
                Add AI Model
              </MDButton>
            </Stack>
            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 800 }}>
                  <CustomTableHeader headLabel={AI_MODEL_TABLE_HEADERS} />
                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={7} />
                    ) : error ? (
                      <TableRow>
                        <TableCell colSpan={6}>Error loading AI Models</TableCell>
                      </TableRow>
                    ) : localModels.length ? (
                      localModels.map(model => (
                        <AIModelTableRow
                          key={model.id}
                          model_id={model.id}
                          modelName={model.name}
                          parameters={model.parameters}
                          openDeleteDialogWindow={() => handleDeleteModelClick(model.id)}
                        />
                      ))
                    ) : (
                      <TableNoData isNotFound />
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <CustomTablePagination
              count={localModels.length}
              page={0}
              rowsPerPage={20}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
            />
          </Card>
        </Grid2>
      </Grid2>
    </section>
  );
};
