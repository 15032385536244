import { useState } from 'react';

import { Card, CardContent, Divider, Grid2, Stack, Tab } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { Icon, TIconType } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { StyledTabsWrapper } from 'components/MDTabs';
import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean, useTabs } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

import { GridViewItem } from './GridViewItem';
import { ListViewItem } from './ListViewItem';
import { StepContentItem } from '../../types';

type StepContentProps = {
  stepContent: StepContentItem[];
  onClickAddContentHandler: () => void;
};

const TABS_DATA: {
  iconType: TIconType;
  ariaLabel: string;
}[] = [
  {
    iconType: 'gridViewIcon',
    ariaLabel: 'Grid2 View',
  },
  {
    iconType: 'listViewIcon',
    ariaLabel: 'List View',
  },
];

export const StepContent: React.FC<StepContentProps> = ({ stepContent, onClickAddContentHandler }) => {
  const { handleSetTabValue, tabValue } = useTabs();

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [isDeleting, setIsDeleting] = useState(false);

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const onClickDeleteButtonHandler = (id: string) => {
    setSelectedItemId(id);
    openDeleteDialogWindow();
  };

  const handleConfirmDelete = async () => {
    if (!selectedItemId) return;

    setIsDeleting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSelectedItemId(null);
      closeDeleteDialogWindow();

      notice(ToastType.SUCCESS, 'Image has been deleted successfully');
    } catch (error) {
      backendErrorHandler({ error });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Card sx={{ boxShadow: 'none', width: 1 }}>
        <CardContent sx={{ p: 1.5, width: 1 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={1}>
            <StyledTabsWrapper
              onChange={handleSetTabValue}
              value={tabValue}
              styleVariant="pill"
              isIconOnly
              size="small"
              sx={{ height: 46, width: 92 }}
            >
              {TABS_DATA.map(({ iconType, ariaLabel }) => (
                <Tab key={ariaLabel} icon={<Icon type={iconType} />} aria-label={ariaLabel} />
              ))}
            </StyledTabsWrapper>

            <MDButton onClick={onClickAddContentHandler} variant="secondary" startIcon={<Icon type="add" />}>
              Add Content
            </MDButton>
          </Stack>
          <Divider sx={{ mt: 1, mb: 2 }} />

          {tabValue === 0 && (
            <Grid2 container spacing={1.5}>
              {stepContent.map(el => (
                <Grid2
                  key={el.id}
                  size={{
                    xs: 12,
                    sm: 6,
                    lg: 3,
                  }}
                >
                  <GridViewItem {...el} onClickDeleteButtonHandler={onClickDeleteButtonHandler} />
                </Grid2>
              ))}
            </Grid2>
          )}

          {tabValue === 1 && (
            <Stack component="ul">
              {stepContent.map(el => (
                <ListViewItem key={el.id} {...el} onClickDeleteButtonHandler={onClickDeleteButtonHandler} />
              ))}
            </Stack>
          )}
        </CardContent>
      </Card>

      <BaseDialogWindow
        title="Delete Image"
        description="Are you sure you want to delete this image?"
        open={isOpenDeleteDialogWindow}
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        onClickApproveButtonHandler={handleConfirmDelete}
        isApproveButtonDisabled={isDeleting}
        isApproveButtonLoading={isDeleting}
        approveButtonTitle="Delete"
      />
    </>
  );
};
