import { StateCreator } from 'zustand';

import { UserProfileInitialState, UserProfileSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: UserProfileInitialState = {
  cognitoUser: null,
  expertProfile: null,
};

export const UserProfileSlice: StateCreator<
  UserProfileSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  UserProfileSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setUserProfile: value => {
      set(state => {
        state.expertProfile = value;
      });
    },
    setCognitoProfile: value => {
      set(state => {
        state.cognitoUser = value;
      });
    },
  };
};
