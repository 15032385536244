import { CheckRounded, ReplayRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

type FilterMenuPopoverButtonsProps = {
  isApplyButtonDisabled: boolean;
  isClearButtonDisabled: boolean;
  onClickClearButtonHandler: () => void;
  onClickApplyButtonHandler: () => void;
  isLoading?: boolean;
};

export const FilterMenuPopoverButtons: React.FC<FilterMenuPopoverButtonsProps> = ({
  isApplyButtonDisabled,
  isClearButtonDisabled,
  isLoading,
  onClickApplyButtonHandler,
  onClickClearButtonHandler,
}) => {
  return (
    <Stack spacing={1.5} py={1} direction="row" sx={{ width: 1 }}>
      <MDButton
        onClick={onClickClearButtonHandler}
        disabled={isClearButtonDisabled}
        startIcon={<Icon type="refreshIcon" />}
        variant="secondary"
        size="small"
        fullWidth
      >
        Clear
      </MDButton>
      <MDButton
        onClick={onClickApplyButtonHandler}
        disabled={isApplyButtonDisabled}
        startIcon={<Icon type="checkmarkIcon" />}
        isLoading={isLoading}
        size="small"
        fullWidth
      >
        Apply
      </MDButton>
    </Stack>
  );
};
