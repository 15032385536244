import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const tableRow = {
  styleOverrides: {
    root: {
      borderBottom: `${borders.borderWidth[1]} solid ${palette.background.surfaceStrong}`,

      '&:hover': {
        cursor: 'pointer',
      },
      '&.Mui-selected': {
        backgroundColor: palette.background.surfaceSoft,
        '&:hover': {
          backgroundColor: palette.background.surfaceMedium,
        },
      },
    },
  },
};

export default tableRow;
