import { Stack } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { FormSkeleton } from 'components/Form';
import { EntityHeader } from 'components/Widgets';

import { Profile, Summary, Contacts } from './tabPages';
import { usePracticeProfileState } from './usePracticeProfile.state';

export const PRACTICE_PROFILE_TABS = ['Profile', 'Summary', 'Contacts'];

export const PracticeProfile: React.FC = () => {
  const {
    practice,
    isLoading,
    updateAndFetchPracticeHandler,
    onGetPracticeHandler,
    isNoPractice,
    handleSetTabValue,
    tabValue,
    isViewOnly,
    fetchPractice,
  } = usePracticeProfileState();

  return (
    <Stack component="section" flex={1} direction="column" flexDirection="column" width={1}>
      {isNoPractice ? (
        <EmptyStateWithAction
          title="Failed to get practice"
          subtitle="Please use the button below to try once again."
          actionButtonProps={{
            title: 'Get practice',
            onClickHandler: onGetPracticeHandler,
          }}
        />
      ) : (
        <>
          <EntityHeader
            isLoading={isLoading}
            skeletonProps={{ isActionButton: true, tabsAmount: PRACTICE_PROFILE_TABS.length }}
            title={practice?.name}
            tabs={{
              tabsData: PRACTICE_PROFILE_TABS,
              value: tabValue,
              handleSetTabValue,
            }}
          />

          {isLoading ? (
            <FormSkeleton rows={4} />
          ) : (
            <>
              {tabValue === 0 && (
                <Profile
                  updateAndFetchPractice={updateAndFetchPracticeHandler}
                  created_at={practice?.created_at}
                  updated_at={practice?.updated_at}
                  name={practice?.name}
                  website={practice?.website}
                  isViewOnly={isViewOnly}
                />
              )}
              {tabValue === 1 && (
                <Summary
                  isViewOnly={isViewOnly}
                  updateAndFetchPractice={updateAndFetchPracticeHandler}
                  practice_summary={practice?.practice_summary}
                />
              )}
              {tabValue === 2 && (
                <Contacts
                  contact_persons={practice?.contact_persons}
                  fetchPracticeProfile={fetchPractice}
                  practiceId={practice?.id}
                />
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};
