import { memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';
import { MDButton } from 'components/MDButton';
import rgba from 'styles/theme/functions/rgba';

import { LIST_OF_ITEMS_PREVIEW_IMAGE_TYPE_PLACEHOLDER } from '../config';
import { GridViewItemProps } from './types';

export const GridViewItem: React.FC<GridViewItemProps> = memo(
  ({ onClickDeleteButtonHandler, id, previewURL, title, type }) => {
    const handleDeleteButtonClick = () => onClickDeleteButtonHandler(id);

    return (
      <Stack
        width={1}
        spacing={1.5}
        sx={{
          ':hover': {
            cursor: 'move',
            '.delete-button': {
              opacity: 1,
            },
          },
        }}
      >
        <Box height={146} display="flex" position="relative" flexDirection="column">
          <LazyImage
            src={previewURL}
            alt="cover image"
            imageSX={{ borderRadius: theme => theme.borders.borderRadius[8] }}
          />
          <MDButton
            size="small"
            variant="secondary"
            className="delete-button"
            onClick={handleDeleteButtonClick}
            sx={{
              backgroundColor: 'common.white',
              minWidth: 'unset',
              width: 38,
              position: 'absolute',
              top: 10,
              right: 10,
              opacity: 0,
              transition: ({ transitions }) =>
                transitions.create('opacity', {
                  duration: transitions.duration.shortest,
                  easing: transitions.easing.easeIn,
                }),
              ':hover': {
                opacity: 1,
                backgroundColor: 'common.white',
              },
            }}
          >
            <Icon type="trashBinIcon2" />
          </MDButton>

          <Box
            sx={{
              borderRadius: '50%',
              width: 36,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backdropFilter: 'blur(20px)',
              position: 'absolute',
              bottom: 10,
              right: 10,
              bgcolor: theme => rgba(theme.palette.primary.main, 0.7),
              color: 'common.white',
            }}
          >
            <Icon type={LIST_OF_ITEMS_PREVIEW_IMAGE_TYPE_PLACEHOLDER[type]} />
          </Box>
        </Box>
        <Typography pl={0.5} variant="body2" color="text.secondary" fontWeight={500}>
          {title}
        </Typography>
      </Stack>
    );
  },
);
