import { Box, BoxProps } from '@mui/material';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

import { getBorderStyles } from 'styles';

type FormProviderProps<TMethods> = {
  children: React.ReactNode;
  methods: UseFormReturn<TMethods>;
  onSubmit?: VoidFunction;
  formProps?: BoxProps;
  isBorders?: boolean;
};

export function FormProvider<TMethods>({
  children,
  onSubmit,
  isBorders,
  methods,
  formProps,
}: FormProviderProps<TMethods>) {
  const { sx = {}, ...restFormProps } = formProps || {};

  return (
    <Form {...methods}>
      <Box
        width={1}
        component="form"
        onSubmit={onSubmit}
        sx={theme => ({
          ...(isBorders && getBorderStyles(theme, true)),
          ...formProps?.sx,
        })}
        {...restFormProps}
      >
        {children}
      </Box>
    </Form>
  );
}
