const tableContainer = {
  styleOverrides: {
    root: {
      'tr:last-child': {
        borderBottom: 0,
      },
      position: 'relative' as const,
    },
  },
};

export default tableContainer;
