import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useCognitoAuthContext } from 'context';
import { useAppStore } from 'store';
import { RecentlyLoggedInUser } from 'store/slices';
import { awsCognitoErrorHandler } from 'utils/errorHanders';

import { SignInFormSchema, defaultValues, firebaseSignInFormValidationSchema } from '../form.config';
import { AUTH_STEPS } from '../types';

export const useSignInState = () => {
  const { signInCognito, getExpertProfileHandler, getCognitoCurrentUser } = useCognitoAuthContext();

  const { recentlyLoggedInUsers, setRecentlyLoggedInUsers } = useAppStore(store => ({
    recentlyLoggedInUsers: store.recentlyLoggedInUsers,
    setRecentlyLoggedInUsers: store.setRecentlyLoggedInUsers,
  }));

  const [authStep, setAuthStep] = useState<AUTH_STEPS>(AUTH_STEPS.SIGN_IN);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm<SignInFormSchema>({
    mode: 'onTouched',
    resolver: yupResolver(firebaseSignInFormValidationSchema),
    defaultValues,
  });

  const setRecentlyLoggedInUsersHandler = (email: string) => {
    if (isRememberMe) {
      const recentlyLoggedInUsersWithNewUser = [
        ...(recentlyLoggedInUsers ?? []),
        { email, loginTimestamp: Date.now() },
      ];

      const uniqueUsersObj = recentlyLoggedInUsersWithNewUser.reduce(
        (acc, user) => {
          acc[user.email] = user;
          return acc;
        },
        {} as Record<string, RecentlyLoggedInUser>,
      );

      setRecentlyLoggedInUsers(Object.values(uniqueUsersObj));
    }
  };

  const onFormSubmitHandler = handleSubmit(async ({ email, password }) => {
    try {
      await signInCognito({
        email,
        password,
        onTOTPRequiedHandler: () => {
          setAuthStep(AUTH_STEPS.VERIFY_TOTP_CODE);
          reset();
        },
        onSuccessfulSignInHandler: () => setRecentlyLoggedInUsersHandler(email),
      });
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Something went wrong, please try again!' });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const [isRememberMe, setIsRememberMe] = useState(false);

  const onChangeRememberMeHandler = (_: React.SyntheticEvent, checked: boolean) => setIsRememberMe(checked);

  return {
    onFormSubmitHandler,
    register,
    errors,
    isSubmitButtonDisabled,
    isSubmitting,
    authStep,
    getCognitoCurrentUser,
    getExpertProfileHandler,
    onChangeRememberMeHandler,
    isRememberMe,
  };
};
