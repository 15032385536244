import { FC } from 'react';

import { useCatalogTableTabs } from 'pages/Catalog/useCatalogTableTabs';

import { GlobalLibraryProducts } from './Products';
import { GlobalLibraryServices } from './Services';

export const GlobalLibrary: FC = () => {
  const { handleSetTabValue, tabValue } = useCatalogTableTabs();

  return (
    <>
      {tabValue === 0 ? (
        <GlobalLibraryServices handleSetTabValue={handleSetTabValue} tabValue={tabValue} />
      ) : (
        <GlobalLibraryProducts handleSetTabValue={handleSetTabValue} tabValue={tabValue} />
      )}
    </>
  );
};
