import { MoreHorizOutlined } from '@mui/icons-material';
import { Card, CardContent, Stack } from '@mui/material';
import { Box, Typography } from '@mui/material';

import { MDButton } from 'components/MDButton';

type TreatmentHistoryCardProps = {
  title: string;
  areasTreated: string;
  lastTreatmentDate: string;
  productUsed: string;
  dosage?: string;
};

export const TreatmentHistoryCard: React.FC<TreatmentHistoryCardProps> = ({
  title,
  areasTreated,
  lastTreatmentDate,
  productUsed,
  dosage,
}) => {
  return (
    <Card sx={{ boxShadow: 'none', bgcolor: theme => theme.palette.background.surfaceSoft }}>
      <CardContent
        sx={{
          p: 1.5,
          '&:last-child': {
            pb: 1.5,
          },
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={1} justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={500}>
            {title}
          </Typography>

          <MDButton size="small" variant="secondary" isIconOnly>
            <MoreHorizOutlined fontSize="medium" />
          </MDButton>
        </Stack>

        <Typography variant="body2" color="text.secondary" fontWeight={400}>
          <Box component="span" color="text.body">
            Areas Treated:
          </Box>{' '}
          {areasTreated}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontWeight={400}>
          <Box component="span" color="text.body">
            Last Treatment Date:
          </Box>{' '}
          {lastTreatmentDate}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontWeight={400}>
          <Box component="span" color="text.body">
            Product Used:
          </Box>{' '}
          {productUsed}
        </Typography>
        {dosage && (
          <Typography variant="body2" color="text.secondary" fontWeight={400}>
            <Box component="span" color="text.body">
              Dosage (if known):
            </Box>{' '}
            {dosage}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
