import { BoxShadowTypes } from 'types/mui-theme';

export const boxShadows: BoxShadowTypes = {
  none: 'none',
  'shadow-xs': '0px 1px 12px 1px #00000008',
  'shadow-sm': '0px 0px 16px 2px #0000000A',
  'shadow-md': '0px 0px 34px 3px #0000000F, 0px 2px 12px -2px #0000000F',
  'shadow-lg': '0px 0px 36px 4px #00000012,  0px 5px 22px -4px #0000000F',
  'shadow-xl': '0px 8px 8px -4px #00000008, 0px 20px 24px -4px #00000014',
  'shadow-2xl': '0px 24px 48px -12px #0000002E',
  'shadow-3xl': '0px 32px 64px -12px #00000024',
};
