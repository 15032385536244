import { Box, styled } from '@mui/material';

import { createTransition } from 'components/NavVertical/helpers';
import { HEADER_CONFIG, SIDE_NAVIGATION } from 'constants/layout-config';

type LayoutWrapperProps = {
  isTablet: boolean;
  isNavMini: boolean;
};

export const MainLayoutStyles = {
  MainLayoutWrapper: styled('div')(() => ({
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
  })),

  ContentWrapper: styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  })) as typeof Box,

  Header: styled('header')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    padding: 16,
    paddingLeft: 20,
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: HEADER_CONFIG.H_DASHBOARD_HEADER,
    [theme.breakpoints.down('md')]: {
      padding: 12,
    },
  })),

  LayoutWrapper: styled('div', {
    shouldForwardProp: prop => prop !== 'isNavMini' && prop !== 'isTablet',
  })<LayoutWrapperProps>(({ theme, isNavMini, isTablet }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: 1,
    paddingLeft: `${SIDE_NAVIGATION.W_DASHBOARD}px`,
    ...(isNavMini && {
      paddingLeft: `${SIDE_NAVIGATION.W_DASHBOARD_MINI}px`,
    }),
    transition: createTransition(theme, 'padding-left'),
    ...(isTablet && {
      paddingLeft: 'unset',
      transition: 'unset',
    }),
  })),
};
