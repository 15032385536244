import { startTransition, useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';

import { getPromptById } from 'apiServices';
import { useRouter, useUserProfile } from 'hooks';
import { useTabs } from 'hooks/useTabs';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

export const usePromptViewState = () => {
  const {
    userRoles: { isRoleAItester },
  } = useUserProfile();

  const { tabValue, setTabValue } = useTabs();

  const { setPrompt, prompt } = useAppStore(
    store => ({
      setPrompt: store.setPrompt,
      prompt: store.prompt,
    }),
    shallow,
  );

  const { params, back } = useRouter();

  const promptId = params?.id;

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) =>
    startTransition(() => {
      setTabValue(newValue);
    });

  const [isLoading, setIsLoading] = useState(true);

  const onFetchPromptHandler = async () => {
    !isLoading && setIsLoading(true);
    try {
      const { data } = await getPromptById(promptId);
      setPrompt(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to fetch prompt, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onFetchPromptHandler();

    return () => {
      setPrompt(null);
    };
  }, []);

  const { handleSetTabValue: handleViewTabValue, tabValue: viewTabValue } = useTabs();

  return { handleViewTabValue, viewTabValue, handleSetTabValue, tabValue, prompt, isLoading, back, isRoleAItester };
};
