import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { boxShadows } from 'styles/theme/base/boxShadows';

const { borderRadius } = borders;

const dialog: {
  defaultProps?: ComponentsProps['MuiDialog'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiDialog'];
  variants?: ComponentsVariants<Theme>['MuiDialog'];
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiBackdrop-root': {
        backgroundColor: theme.shades.overlayPrimaryStrong,
        backdropFilter: theme.backdropBlurs.sm,
      },
    }),
    paper: {
      borderRadius: borderRadius[8],
      boxShadow: boxShadows['shadow-2xl'],
      margin: 24,
    },
    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
