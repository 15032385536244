import { ButtonProps, CSSObject, Tabs, alpha, styled } from '@mui/material';

import { BUTTON_HEIGHT } from 'styles/theme/components/button/root';

export type StyledTabsWrapperOwnerState = {
  styleVariant?: 'pill' | 'folder' | 'table';
  isIconOnly?: boolean;
  size?: ButtonProps['size'];
  isFullWidth?: boolean;
};

export const StyledTabsWrapper = styled(Tabs, {
  shouldForwardProp: prop =>
    prop !== 'styleVariant' && prop !== 'isIconOnly' && prop !== 'isFullWidth' && prop !== 'size',
})<StyledTabsWrapperOwnerState>(({ theme, isFullWidth, styleVariant, isIconOnly, orientation, size = 'medium' }) => {
  const {
    palette,
    borders: { borderRadius, borderWidth },
    typography,
  } = theme;

  const isPill = styleVariant === 'pill';

  const isFolder = styleVariant === 'folder';

  const isTable = styleVariant === 'table';

  const pillStyles = (): CSSObject => {
    return {
      position: 'relative',
      backgroundColor: palette.background.surfaceSoft,
      border: `${borderWidth[1]} solid ${palette.background.surfaceStrong}`,
      borderRadius: borderRadius[10],
      padding: 4,
      minHeight: 'unset',
      width: '100%',
      overflow: 'visible',
      height: BUTTON_HEIGHT[size],
      '.MuiTabs-scroller, .MuiButtonBase-root': { overflow: 'visible !important' },

      '.MuiTabs-flexContainer': {
        height: '100%',
        position: 'relative',
        zIndex: theme.zIndex.fab,
      },

      '.MuiButtonBase-root': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: '1 1 auto',
        textAlign: 'center',
        maxWidth: 'unset !important',
        minHeight: 'unset !important',
        fontSize: typography.size.bodyMedium,
        lineHeight: 'inherit',
        padding: isIconOnly ? 4 : '10px 12px',
        borderRadius: borderRadius[8],
        color: `${palette.secondary.dark} !important`,
        minWidth: 42,
        height: '100%',
        opacity: '1 !important',

        '& .material-icons, .material-icons-round': {
          marginBottom: '0 !important',
          marginRight: 6,
        },

        '& svg': {
          marginBottom: '0 !important',
          marginRight: isIconOnly ? 0 : 6,
        },
      },

      '.Mui-selected': {
        color: `${palette.secondary.darker} !important`,
      },

      '.MuiTabs-indicator': {
        height: '100%',
        borderRadius: borderRadius[8],
        backgroundColor: palette.background.default,
        border: `${borderWidth[1]} solid ${palette.background.surfaceStrong}`,
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeIn,
        }),
        boxShadow: theme.boxShadows['shadow-md'],
        ...(orientation === 'vertical' && { width: '100%' }),
      },
    };
  };

  const folderStyles = (): CSSObject => {
    const zIndex = 10;
    const height = 42;

    return {
      position: 'relative',
      marginBottom: -0.8,
      zIndex,
      minHeight: height,
      height,
      '.MuiButtonBase-root': {
        padding: '12px 14px',
        borderRadius: `${borderRadius[12]} ${borderRadius[12]} 0 0`,
        height,
        minHeight: 'unset',
        border: `${borderWidth[1]} solid transparent`,
        borderBottom: 0,
      },

      '.Mui-selected': {
        backgroundColor: palette.common.white,
        borderBottom: 0,
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
    };
  };

  const tableStyles = (): CSSObject => {
    return {
      padding: 1,
      '.MuiButtonBase-root': {
        padding: '10px 12px',
        borderRadius: borderRadius[8],
      },
      '.MuiTab-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '.MuiBox-root': {
        marginRight: '5px',
      },

      '.Mui-selected': {
        backgroundColor: alpha(palette.primary.light, 0.1),
        color: palette.primary.main,
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
    };
  };

  const defaultStyles = {
    ...(isFullWidth && {
      '& .MuiButtonBase-root': {
        flex: 1,
        padding: '12px 16px',
        height: 42,
        minHeight: 'unset',
      },
      '&': {
        width: '100%',
        minHeight: 42,
      },
    }),
  };

  return {
    ...(isPill && pillStyles()),
    ...(isFolder && folderStyles()),
    ...(isTable && tableStyles()),
    ...defaultStyles,
  };
});
