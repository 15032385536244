import { useEffect, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import { GetPracticesParams, getPractices } from 'apiServices';
import { useBoolean, useEmptyTableState, useTable } from 'hooks';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

import { FiltersInitialState } from './types';

export const FiltersInitialStateValue: FiltersInitialState = {
  city_id: null,
  country_iso_code: null,
  state_iso_code: null,
};

export const usePracticesState = () => {
  const {
    practices: { practices, total },
    setPractices,
  } = useAppStore(
    store => ({
      practices: store.practices,
      setPractices: store.setPractices,
    }),
    shallow,
  );

  const {
    debouncedSearchValue,
    searchValue,
    onChangeSearchValueHandler,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    setPage,
    isFiltersApplied,
    appliedFilters,
    onApplyFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    resetPageHandler,
    setResetFilters,
  } = useTable({
    defaultFilters: FiltersInitialStateValue,
  });

  const [isAddPracticeDialogOpen, openAddPracticeDialog, closeAddPracticeDialog] = useBoolean();

  const [isPending, startTransition] = useTransition();

  const onGetPracticesHandler = async (params?: GetPracticesParams) =>
    startTransition(async () => {
      try {
        const {
          data: { items, page, pages, size, total },
        } = await getPractices({ ...searchParams, ...params });

        setPractices({ page, pages, practices: items, size, total });
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get practices, please try again!' } });
      }
    });

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);

    await onGetPracticesHandler({ page: newPage });
  };

  const searchParams: GetPracticesParams = {
    ...(debouncedSearchValue && {
      search: debouncedSearchValue,
    }),
    page,
    size: rowsPerPage,
    ...(appliedFilters.state_iso_code && {
      state_iso_code: appliedFilters.state_iso_code.iso_code,
    }),
    ...(appliedFilters.country_iso_code && {
      country_iso_code: appliedFilters.country_iso_code.iso_code,
    }),
    ...(appliedFilters.city_id && {
      city_id: appliedFilters.city_id.id,
    }),
  };

  useEffect(() => {
    onGetPracticesHandler();
  }, [rowsPerPage, debouncedSearchValue, appliedFilters]);

  const { isEmptyState, isFilteredEmptyState } = useEmptyTableState({
    debouncedSearchValue,
    isPending,
    total,
    isFiltersApplied,
  });

  return {
    isEmptyState,
    isFilteredEmptyState,
    practices,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    appliedFilters,
    onApplyFilters,
    isAddPracticeDialogOpen,
    openAddPracticeDialog,
    closeAddPracticeDialog,
    onGetPracticesHandler,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    isPending,
    searchValue,
    onChangeSearchValueHandler,
    resetPageHandler,
    setResetFilters,
  };
};
