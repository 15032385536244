import { FC, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { GetPracticesParams, createExpert } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField, RHFUserRoleAutocomplete } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { LABELS } from 'constants/strings';
import { useOptions, useUserRoles, useUserProfile } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateUserFormSchema, createUserValidationSchema, defaultValues } from './form.config';

type AddUserDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
  onGetUsersHandler: (params?: GetPracticesParams) => Promise<void>;
  resetPageHandler: VoidFunction;
};

export const AddUserDialogWindowForm: FC<AddUserDialogWindowFormProps> = ({
  onGetUsersHandler,
  onCloseDialogHandler,
  resetPageHandler,
}) => {
  const {
    userRoles: { isRoleAdmin },
    expertProfile,
  } = useUserProfile();

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CreateUserFormSchema>({
    resolver: yupResolver(createUserValidationSchema) as Resolver<CreateUserFormSchema>,
    mode: 'onTouched',
    defaultValues: {
      ...defaultValues,
      ...(!isRoleAdmin && {
        practiceId: expertProfile?.practice?.id || '',
      }),
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    const { role, email, first_name, last_name, practice_id } = formData;
    try {
      await createExpert({
        practice_id,
        role,
        first_name,
        last_name,
        email,
      });
      onCloseDialogHandler();
      notice(ToastType.SUCCESS, 'User has been successfully created!');

      resetPageHandler();

      await onGetUsersHandler({ page: 1 });
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  const { fetchUserRoles, userRolesState } = useUserRoles();

  const { debouncedPracticeSearchValue, fetchPractices, onChangePracticeInputValueHandler, practicesState } =
    useOptions({
      isImmediateFetchPractices: isRoleAdmin ?? false,
    });

  useEffect(() => {
    fetchUserRoles({ practice_roles: true });
  }, []);

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{ title: 'Add User', onClickCancelButtonHandler: onCloseDialogHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
      }}
    >
      <Scrollbar>
        <Stack spacing={2} width={1} maxHeight={{ xs: 350, sm: 1 }} pb={1}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            gap={2}
            sx={{
              '.MuiStack-root': {
                width: 1,
              },
            }}
          >
            <RHFTextField
              register={register}
              registerName="first_name"
              registerErrors={errors.first_name?.message}
              fullWidth
              required
              label={LABELS.FIRST_NAME}
              placeholder="Enter First Name"
            />

            <RHFTextField
              register={register}
              registerName="last_name"
              registerErrors={errors.last_name?.message}
              fullWidth
              required
              label={LABELS.LAST_NAME}
              placeholder="Enter Last Name"
            />
          </Stack>

          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            gap={2}
            sx={{
              '.MuiStack-root': {
                width: 1,
              },
            }}
          >
            <RHFTextField
              register={register}
              registerName="email"
              registerErrors={errors?.email?.message}
              fullWidth
              required
              placeholder="Enter Email"
              label={LABELS.EMAIL}
            />

            <RHFUserRoleAutocomplete
              options={userRolesState.items}
              loading={userRolesState.isLoading}
              control={control}
              registerName="role"
            />
          </Stack>

          {isRoleAdmin && (
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} width={{ xs: 1, sm: 0.5 }} pr={1}>
              <InfiniteScrollWrapper
                searchQuery={debouncedPracticeSearchValue}
                totalPages={practicesState.totalPages}
                fetchItemsHandler={fetchPractices}
              >
                {({ ref }) => (
                  <RHFAutocompleteField
                    control={control}
                    registerName="practice_id"
                    isRequired
                    label={LABELS.PRACTICE}
                    placeholder="Select Practice"
                    options={practicesState.items}
                    onInputChange={onChangePracticeInputValueHandler}
                    loading={practicesState.isLoading}
                    valueKey="id"
                    labelKey="name"
                    lastElementRef={ref}
                  />
                )}
              </InfiniteScrollWrapper>
            </Stack>
          )}
        </Stack>
      </Scrollbar>
    </FormDialogWindowContent>
  );
};
