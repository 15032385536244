import { FC, memo } from 'react';

import { FileDownloadOutlined, VisibilityOffRounded } from '@mui/icons-material';
import { TableCell, TableRow, Typography } from '@mui/material';

import { PromptItemResponse } from 'apiServices';
import { MDButton } from 'components/MDButton';
import { ThreeDotsTableCell } from 'components/Table';
import { ActionDataInitialState, usePopover, useRouter } from 'hooks';
import { dateToCustomFormat } from 'utils/helpers';

type PromptLibraryTableRowProps = Pick<PromptItemResponse, 'id' | 'text' | 'name' | 'updated_at'> & {
  openDeactivateDialogWindow?: () => void;
  onClickActionOptionHandler: (actionStateData: ActionDataInitialState) => void;
  isHideActionOptions?: boolean;
};

export const PromptLibraryTableRow: FC<PromptLibraryTableRowProps> = memo(
  ({ isHideActionOptions, id, name, updated_at, text, openDeactivateDialogWindow, onClickActionOptionHandler }) => {
    const { navigate } = useRouter();

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      handleClosePopover();
      onClickActionOptionHandler({ actionType: 'delete', id, name });
    };

    const onClickDeactivateButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      handleClosePopover();
      openDeactivateDialogWindow();
    };

    const onClickTableRowHandler = () => {
      navigate(`/prompt-library/${id}`, {
        state: {
          breadCrumbName: name || 'Prompt Name',
          prompt_id: id,
          text: text,
        },
      });
    };

    const promptVersionList = () => {
      navigate(`/prompt-versions-list/${id}`, {
        state: {
          breadCrumbName: name || 'Prompt Name',
          prompt_id: id,
        },
      });
    };

    const formattedUpdatedAt = dateToCustomFormat(updated_at);

    const onClickVersionButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      promptVersionList();
    };

    return (
      <TableRow onClick={onClickTableRowHandler}>
        <TableCell>
          <Typography variant="body2" fontWeight={500}>
            {name}
          </Typography>
        </TableCell>

        {!isHideActionOptions && (
          <TableCell>
            <MDButton variant="contained" color="primary" size="small" onClick={onClickVersionButtonHandler}>
              versions
            </MDButton>
          </TableCell>
        )}

        {/* TODO: when BE will be ready */}
        {/* <TableCell>
          <Chip label="workflow" color="primary" variant="soft" />
        </TableCell>

        <TableCell>
          <Chip label="interaction" color="warning" variant="soft" />
        </TableCell>

        <TableCell>
          <Chip label="assessment" color="success" variant="outlined" />
        </TableCell> */}

        <TableCell>{updated_at && <Typography variant="body2">{formattedUpdatedAt}</Typography>}</TableCell>

        {!isHideActionOptions && (
          <ThreeDotsTableCell
            tableCellSX={{ textAlign: 'center' }}
            open={openPopover}
            onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
            onClose={onCloseThreeDotsMenuHandler}
            onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
            menuItems={[
              { title: 'Export', icon: <FileDownloadOutlined fontSize="small" />, onClick: onClickTableRowHandler },
              {
                title: 'Hide',
                icon: <VisibilityOffRounded fontSize="small" />,
                onClick: onClickDeactivateButtonHandler,
              },
            ]}
          />
        )}
      </TableRow>
    );
  },
);
