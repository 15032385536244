import { memo } from 'react';

import { TableRow, TableCell } from '@mui/material';

type ModelCostTableRowProps = {
  modelName?: string;
  latency?: number;
  cost?: number;
};

export const ModelCostTableRow: React.FC<ModelCostTableRowProps> = memo(({ cost = 0, latency = 0, modelName }) => {
  return (
    <TableRow>
      <TableCell>{modelName}</TableCell>

      <TableCell>{`${latency.toFixed(2)} ms`}</TableCell>

      <TableCell>{`$${cost.toFixed(2)}`}</TableCell>
    </TableRow>
  );
});
