import { FC } from 'react';

import { PlayArrowRounded } from '@mui/icons-material';
import { Box, ButtonGroup } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

import { MDButton } from 'components/MDButton';

import { TableStyles as Styled } from './Table.styles';

export const CustomTablePaginationActions: FC<TablePaginationActionsProps> = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleNextPageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
    onPageChange(event, page + 1);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
    onPageChange(event, page - 1);
  };

  const isNextButtonDisabled = !count || page === Math.ceil(count / rowsPerPage) - 1;
  const isPrevDisabled = page === 0;

  return (
    <Box component="div" sx={Styled.ButtonsWrapperSX}>
      <ButtonGroup
        aria-label="table pagination button group"
        sx={{ borderColor: theme => theme.palette.primary.light }}
        size="small"
      >
        <MDButton
          sx={{
            borderRadius: '40px 0 0 40px',
            ...Styled.ButtonSX,
            ...(isPrevDisabled && Styled.DisabledButtonSX),
          }}
          disabled={isPrevDisabled}
          variant="outlined"
          size="small"
          onClick={handleBackButtonClick}
          aria-label="Previous Page"
        >
          <PlayArrowRounded sx={{ transform: 'rotate(-180deg)' }} />
        </MDButton>
        <MDButton
          sx={{
            borderRadius: '0 40px 40px 0',
            ...Styled.ButtonSX,
            ...(isNextButtonDisabled && Styled.DisabledButtonSX),
          }}
          variant="outlined"
          size="small"
          onClick={handleNextPageButtonClick}
          aria-label="Next Page"
          disabled={isNextButtonDisabled}
        >
          <PlayArrowRounded />
        </MDButton>
      </ButtonGroup>
    </Box>
  );
};
