import { apiClient } from 'lib/api';

import { BASE_AI_MODELS_API_URL, BASE_AI_MODELS_VERSION_API_URL } from '../api.routes';
import { AIModelVersionPostRequest, AIModelVersionResponse, AIModelVersionUpdate } from './types';

export const addAIModelVersion = async (payload: AIModelVersionPostRequest) => {
  return await apiClient.post<AIModelVersionResponse>(BASE_AI_MODELS_VERSION_API_URL, payload);
};

export const getAIModelVersions = async (skip = 0, limit = 10) => {
  return await apiClient.get<AIModelVersionResponse[]>(BASE_AI_MODELS_VERSION_API_URL, {
    params: { skip, limit },
  });
};

export const getAIModelVersionByModelId = async (aiModel: number | string) => {
  return await apiClient.get<AIModelVersionResponse[]>(`${BASE_AI_MODELS_API_URL}/${aiModel}/versions`);
};

export const getAIModelVersionById = async (aiModelVersionId: number | string) => {
  return await apiClient.get<AIModelVersionResponse>(`${BASE_AI_MODELS_VERSION_API_URL}/${aiModelVersionId}`);
};

export const updateAIModelVersion = async (aiModelVersionId: number | string, payload: AIModelVersionUpdate) => {
  return await apiClient.patch<AIModelVersionResponse>(
    `${BASE_AI_MODELS_VERSION_API_URL}/${aiModelVersionId}`,
    payload,
  );
};

export const deleteAIModelVersion = async (aiModelVersionId: number | string) => {
  return await apiClient.delete(`${BASE_AI_MODELS_VERSION_API_URL}/${aiModelVersionId}`);
};
