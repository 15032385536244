import { useEffect, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import { GetPatientsParams, PatientSortFieldName, getPatients } from 'apiServices';
import { useBoolean, useEmptyTableState, useTable, useUserProfile } from 'hooks';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

import { FilterInitialState } from './types';

export const FilterInitialStateValue: FilterInitialState = {
  bioGenderValue: null,
  ethnicGroupValue: null,
  genderValue: null,
};

export const usePatientsState = () => {
  const {
    patients: { patients, total },
    setPatients,
  } = useAppStore(
    store => ({
      patients: store.patients,
      setPatients: store.setPatients,
    }),
    shallow,
  );

  const {
    userRoles: { isRoleAdmin },
  } = useUserProfile();

  const {
    searchValue,
    debouncedSearchValue,
    onChangeSearchValueHandler,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
    order,
    orderBy,
    onSort,
    setResetFilters,
    onApplyFilters,
    openFilterMenu,
    isFiltersMenuOpen,
    closeFiltersMenu,
    appliedFilters,
    isFiltersApplied,
    resetPageHandler,
  } = useTable<PatientSortFieldName, FilterInitialState>({
    defaultOrderBy: 'last_name',
    defaultOrder: 'asc',
    defaultFilters: FilterInitialStateValue,
  });

  const [isPending, startTransition] = useTransition();

  const onGetPatientsHandler = async (params?: GetPatientsParams) =>
    startTransition(async () => {
      try {
        const {
          data: { items, page, pages, size, total },
        } = await getPatients({
          order: [`${orderBy},${order}`, `${orderBy === 'last_name' ? 'first_name' : 'last_name'},${order}`],
          ...searchParams,
          ...params,
        });

        setPatients({ page, pages, patients: items, size, total });
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get patients, please try again!' } });
      }
    });

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);

    await onGetPatientsHandler({ page: newPage });
  };

  const searchParams: GetPatientsParams = {
    ...(debouncedSearchValue && {
      search: debouncedSearchValue,
    }),
    page,
    size: rowsPerPage,
    ...(appliedFilters.bioGenderValue && {
      bio_gender: appliedFilters.bioGenderValue.value,
    }),
    ...(appliedFilters.genderValue && {
      gender: appliedFilters.genderValue.value,
    }),
  };

  useEffect(() => {
    onGetPatientsHandler();
  }, [debouncedSearchValue, rowsPerPage, order, orderBy, appliedFilters]);

  const [isOpenAddPatientDialogWindow, openAddPatientDialogWindow, closeAddPatientDialogWindow] = useBoolean();

  const { isEmptyState, isFilteredEmptyState } = useEmptyTableState({
    debouncedSearchValue,
    isPending,
    total,
    isFiltersApplied,
  });

  return {
    patients,
    isPending,
    page,
    rowsPerPage,
    total,
    onGetPatientsHandler,
    onChangePage,
    onChangeRowsPerPage,
    isFiltersMenuOpen,
    openFilterMenu,
    closeFiltersMenu,
    searchValue,
    onChangeSearchValueHandler,
    appliedFilters,
    onApplyFilters,
    setResetFilters,
    onChangePageHandler,
    setPage,
    onSort,
    orderBy,
    order,
    isOpenAddPatientDialogWindow,
    openAddPatientDialogWindow,
    closeAddPatientDialogWindow,
    isRoleAdmin,
    isEmptyState,
    isFilteredEmptyState,
    resetPageHandler,
  };
};
