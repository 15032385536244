import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const { size } = typography;
const { text } = palette;

const tab: {
  defaultProps?: ComponentsProps['MuiTab'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTab'];
  variants?: ComponentsVariants<Theme>['MuiTab'];
} = {
  styleOverrides: {
    root: {
      padding: '16px 12px',
      minHeight: 44,
      fontSize: size.bodyMedium,
      fontWeight: 500,
      textTransform: 'none',
      color: text.secondary,
    },
  },
};

export default tab;
