import { Box, Stack } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { FormSkeleton } from 'components/Form';
import { StyledListWrapper } from 'components/SideNavMenu/ContentSideMenu.styles';
import { SideNavMenuSkeleton } from 'components/SideNavMenu/SideNavMenu.skeleton';
import { getBorderStyles } from 'styles';

import { PatientProfileHeader } from './components';
import {
  Concerns,
  Consultations,
  Medical,
  PatientSummary,
  Profile,
  Forms,
  WebViewConsultation,
} from './TabContentPages';
import { usePatientViewState } from './usePatientView.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <Medical />;
    case 2:
      return <PatientSummary />;
    case 3:
      return <Concerns />;
    case 4:
      return <Consultations />;
    case 5:
      return <Forms />;
    default:
      return <Profile />;
  }
}
function renderWebViewTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PatientSummary />;
    case 2:
      return <WebViewConsultation />;
    default:
      return <Profile />;
  }
}

export const PatientView: React.FC = () => {
  const {
    isLoading,
    age,
    formatedBirthDate,
    handleSetTabValue,
    tabValue,
    fullName,
    fullNameWidthMiddleName,
    isNoPatient,
    patient,
    onGetPatientHandler,
    isWebView,
    fetchPatient,
  } = usePatientViewState();

  return (
    <Box
      component="section"
      sx={{
        flexDirection: 'column',
        display: 'flex',
        height: 1,
      }}
    >
      {isNoPatient ? (
        <EmptyStateWithAction
          title="Failed to get patient"
          subtitle="Please use the button below to try once again."
          actionButtonProps={{
            title: 'Get patient',
            onClickHandler: onGetPatientHandler,
          }}
        />
      ) : (
        <>
          <PatientProfileHeader
            isLoading={isLoading}
            fetchPatient={fetchPatient}
            handleSetTabValue={handleSetTabValue}
            value={tabValue}
            isWebView={isWebView}
            patientId={patient?.id}
            patientPhotoUrl={patient?.photo_Url}
            patientAvatarUrl={patient?.avatar_url}
            patientInfo={`${formatedBirthDate} (${age})`}
            patientName={patient?.middle_name ? fullNameWidthMiddleName : fullName}
          />

          {isLoading ? (
            <Stack height={1} direction={{ xs: 'column', md: 'row' }}>
              <StyledListWrapper height={1} sx={{ display: 'none' }}>
                <SideNavMenuSkeleton />
              </StyledListWrapper>
              <Stack width={1} sx={theme => getBorderStyles(theme)}>
                <FormSkeleton rows={4} />
                <FormSkeleton rows={5} />
              </Stack>
            </Stack>
          ) : isWebView ? (
            renderWebViewTabValueContent(tabValue)
          ) : (
            renderTabValueContent(tabValue)
          )}
        </>
      )}
    </Box>
  );
};
