import { memo } from 'react';

import { Checkbox, ListItemButton, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';

import { PREVIEW_IMAGE_TYPE_PLACEHOLDER } from '../config';
import { labelStyles, lazyImageStyles, listItemButtonStyles } from './styles';
import { ContentItemProps } from './types';

export const ContentItem: React.FC<ContentItemProps> = memo(
  ({ isNestedItem, type, previewURL, title, id, isChecked, onCheckItemHandler }) => {
    const onChangeCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();
      onCheckItemHandler(id, checked);
    };

    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

    return (
      <ListItemButton
        disableRipple={isNestedItem}
        onClick={onClickHandler}
        component="li"
        sx={listItemButtonStyles(isChecked)}
      >
        <Stack htmlFor={id} component="label" direction="row" sx={labelStyles}>
          <Stack direction="row" gap={1.5} alignItems="center">
            <Checkbox id={id} checked={isChecked} onChange={onChangeCheckboxHandler} onClick={onClickHandler} />
            <LazyImage src={previewURL} alt="content preview image" imageContainerSX={lazyImageStyles} />
            <Typography color="text.secondary" variant="body2" fontWeight={500} fontSize={14}>
              {title}
            </Typography>
          </Stack>

          <Icon
            type={PREVIEW_IMAGE_TYPE_PLACEHOLDER[type]}
            sx={{
              color: 'primary.main',
              svg: {
                width: 24,
                height: 24,
              },
            }}
          />
        </Stack>
      </ListItemButton>
    );
  },
);
