import { useEffect, useMemo, useState } from 'react';

import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import {
  useUserProfile,
  useBoolean,
  useDebounce,
  usePopover,
  useTable,
  useActionDialogManagement,
  useRouter,
} from 'hooks';
import { FiltersInitialState, FiltersInitialStateValue } from 'pages/TagManagement';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

export const usePracticeLibraryWorkflowListState = () => {
  const {
    practiceLibraryWorkflows: { items, total },
    setPracticeLibraryWorkflows,
  } = useAppStore(
    store => ({
      practiceLibraryWorkflows: store.practiceLibraryWorkflows,
      setPracticeLibraryWorkflows: store.setPracticeLibraryWorkflows,
    }),
    shallow,
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
    practiceId,
  } = useUserProfile();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const { order, orderBy, onSort, page, rowsPerPage, onChangeRowsPerPage, setPage, resetPageHandler } = useTable();

  const { navigate } = useRouter();

  const onClickAddWorkflowButtonHandler = () => navigate(ROUTES.createWorkflow);

  const [searchValue, setSearchValue] = useState('');

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isAddTagCategoryDialogOpen, openAddTagCategoryDialogMenu, closeAddTagCategoryDialogMenu] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const {
    getCloseDialogHandler,
    setIsActionPending,
    isActionPending,
    onClickActionOptionHandler,
    actionState,
    dialogStates,
    closeDialog,
  } = useActionDialogManagement();

  const onDeactivateWorkflowHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;

    try {
      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');

      closeDialog('deactivate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateWorkflowHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      notice(ToastType.SUCCESS, 'Tag category has been successfully activated!');

      closeDialog('activate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onGetWorkflowsHandler = async () => {
    !isLoading && setIsLoading(true);

    try {
      if (page !== 1) setPage(1);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get workflows, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSearchInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
  };

  const onApplyFilters = (newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    closeFiltersMenu();
  };

  useEffect(() => {
    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage, order, orderBy]);

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters],
  );

  return {
    dialogStates,
    getCloseDialogHandler,
    searchValue,
    onChangeSearchInputValue,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    onClickActionOptionHandler,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onSort,
    orderBy,
    order,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialogMenu,
    closeAddTagCategoryDialogMenu,
    isViewOnly,
    onClickAddWorkflowButtonHandler,
  };
};
