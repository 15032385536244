import { Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { palette } from 'styles/theme/base/palette';

import { AudioPlayerControlsProps } from './types';

export const AudioPlayerControls: React.FC<AudioPlayerControlsProps> = ({
  currentTime,
  isPlaying,
  isShowTranscript,
  onClickTranscript,
  onTogglePlayPause,
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Stack direction="row" alignItems="center" gap={1}>
      <MDButton size="small" onClick={onTogglePlayPause} isCircular isIconOnly>
        <Icon
          sx={{
            color: palette.background.default,
          }}
          type={isPlaying ? 'pauseFilledIcon' : 'playFilledIcon'}
        />
      </MDButton>

      <Typography variant="body2" fontWeight={500} color="textSecondary">
        {currentTime}
      </Typography>
    </Stack>

    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      color="text.textSecondary"
      onClick={onClickTranscript}
      sx={{
        ':hover': {
          cursor: 'pointer',
          opacity: 0.85,
        },
      }}
    >
      <Typography variant="body2" fontWeight={500} color="textSecondary">
        Transcript
      </Typography>
      <Icon
        type="arrowLeftIcon"
        sx={{
          transform: isShowTranscript ? 'rotate(90deg)' : 'rotate(-90deg)',
          transition: theme => theme.transitions.create('transform', { duration: '0.3s' }),
        }}
      />
    </Stack>
  </Stack>
);
