import { useCallback, useEffect, useMemo, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useInView } from 'react-intersection-observer';

import { ToastType, notice } from 'components/ToastNotification';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';
import {
  useActionDialogManagement,
  useBoolean,
  useFilePicker,
  useRouter,
  useScrollSections,
  useUserProfile,
} from 'hooks';
import { UploadImagePosition } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';
import { generateImageFormData } from 'utils/helpers';

import { SECTION_IDS } from '../config';

type UseAlbumPairsStateProps<TAlbumPair> = {
  fetchAlbumPairApi: (id: string, params?: { include_original: boolean }) => Promise<AxiosResponse<TAlbumPair, any>>;
  activateAlbumPairApi: (id: string) => Promise<AxiosResponse<TAlbumPair, any>>;
  deactivateAlbumPairApi: (id: string) => Promise<AxiosResponse<TAlbumPair, any>>;
  uploadImageApi: (
    pairId: string,
    position: UploadImagePosition,
    payload: FormData,
  ) => Promise<AxiosResponse<TAlbumPair, any>>;
  deleteImageApi: (pairId: string, position: UploadImagePosition) => Promise<AxiosResponse<any, any>>;
  setAlbumPair: (data: TAlbumPair) => void;
  beforeImageURL: string | null;
  afterImageURL: string | null;
};

const SCROLL_SECTIONS = [
  { id: SECTION_IDS.PHOTO, label: 'Photo', priority: 1, icon: 'galleryIcon' },
  { id: SECTION_IDS.INFO, label: 'Info', priority: 2, icon: 'page' },
  { id: SECTION_IDS.TAGS, label: 'Tags', priority: 3, icon: 'tag_managment' },
] as const;

export const useAlbumPairState = <TAlbumPair>({
  activateAlbumPairApi,
  deactivateAlbumPairApi,
  fetchAlbumPairApi,
  setAlbumPair,
  uploadImageApi,
  deleteImageApi,
  afterImageURL,
  beforeImageURL,
}: UseAlbumPairsStateProps<TAlbumPair>) => {
  const { params } = useRouter();

  const pairId = params?.id;

  const [isLoading, setIsLoading] = useState(true);

  const fetchAlbumPairWithoutLoading = useCallback(async () => {
    try {
      const { data } = await fetchAlbumPairApi(pairId);
      setAlbumPair(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to fetch album pair, please try again!' } });
    }
  }, [pairId]);

  const fetchAlbumPair = useCallback(async () => {
    setIsLoading(true);
    await fetchAlbumPairWithoutLoading();
    setIsLoading(false);
  }, [fetchAlbumPairWithoutLoading]);

  const {
    actionState,
    setIsActionPending,
    onClickActionOptionHandler,
    isActionPending,
    dialogStates,
    closeDialog,
    ...otherDialogManagementProps
  } = useActionDialogManagement();

  const onActivateDeactivateAlbumPairHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    try {
      const apiCall = dialogStates.activate ? activateAlbumPairApi : deactivateAlbumPairApi;
      await apiCall(pairId);
      await fetchAlbumPairWithoutLoading();

      closeDialog(dialogStates.activate ? 'activate' : 'deactivate');
      notice(ToastType.SUCCESS, `Album has been ${dialogStates.activate ? 'activated' : 'deactivated'} successfully`);
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: `Failed to ${actionState.actionType} album, please try again!`,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const [isOpenDeleteImageDialogWindow, openDeleteImageDialogWindow, closeDeleteImageDialogWindow] = useBoolean();
  const [isDeletingImage, setIsDeletingImage] = useState(false);

  const onDeleteImageHandler = async ({
    deleteImageApiCall,
    uploadImagePosition,
  }: {
    uploadImagePosition: UploadImagePosition;
    deleteImageApiCall: (pairId: string, position: UploadImagePosition) => Promise<AxiosResponse<any, any>>;
  }) => {
    setIsDeletingImage(true);
    try {
      await deleteImageApiCall(pairId, uploadImagePosition);

      await fetchAlbumPairWithoutLoading();
      closeDeleteImageDialogWindow();
      notice(ToastType.SUCCESS, 'Image has been successfully deleted!');
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to delete image, please try again!' },
      });
    } finally {
      setIsDeletingImage(false);
    }
  };

  const [imagePosition, setImagePosition] = useState<UploadImagePosition | null>(null);

  const onDeleteBeforeImageHandler = async () =>
    await onDeleteImageHandler({ deleteImageApiCall: deleteImageApi, uploadImagePosition: 'left' });

  const onDeleteAfterImageHandler = async () =>
    await onDeleteImageHandler({ deleteImageApiCall: deleteImageApi, uploadImagePosition: 'right' });

  const [isImageUplaoding, setIsImageUploading] = useState(false);

  const onCreateBeforeImageHandler = async () => {
    setIsImageUploading(true);
    try {
      const formData = generateImageFormData({ file, croppedAreaPixels, formDataImageFileName: 'image_file' });
      await uploadImageApi(pairId, 'left', formData);
      await fetchAlbumPairWithoutLoading();

      closeCropFileDialog();
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to upload before image!' } });
    } finally {
      setIsImageUploading(false);
    }
  };

  const onCreateAfterImageHandler = async () => {
    setIsImageUploading(true);
    try {
      const formData = generateImageFormData({
        file: afterFile,
        croppedAreaPixels: afterCroppedAreaPixels,
        formDataImageFileName: 'image_file',
      });

      await uploadImageApi(pairId, 'right', formData);
      await fetchAlbumPairWithoutLoading();

      closeAfterCropFileDialog();
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to upload after image!' } });
    } finally {
      setIsImageUploading(false);
    }
  };

  const {
    openCropFileDialog,
    file,
    onRemoveFileHandler,
    onUploadDropzoneFileHandler,
    isCropFileDialogOpen,
    onFileInputChangeHandler,
    previewURL,
    closeCropFileDialog,
    onCropCompleteHandler,
    croppedAreaPixels,
  } = useFilePicker({
    accept: ACCEPT_INPUT_IMAGE_FILES,
  });

  const {
    openCropFileDialog: openAfterCropFileDialog,
    file: afterFile,
    onRemoveFileHandler: onRemoveAfterFileHandler,
    onUploadDropzoneFileHandler: onUploadAfterDropzoneFileHandler,
    isCropFileDialogOpen: isAfterCropFileDialogOpen,
    onFileInputChangeHandler: onAfterFileInputChangeHandler,
    previewURL: afterPreviewURL,
    closeCropFileDialog: closeAfterCropFileDialog,
    onCropCompleteHandler: onAfterCropCompleteHandler,
    croppedAreaPixels: afterCroppedAreaPixels,
  } = useFilePicker({
    accept: ACCEPT_INPUT_IMAGE_FILES,
  });

  const onCloseCropDialogHandler = () => {
    if (isCropFileDialogOpen) {
      closeCropFileDialog();
      return onRemoveFileHandler();
    }

    closeAfterCropFileDialog();
    onRemoveAfterFileHandler();
  };

  const onClickRemoveBeforeImageHandler = useCallback(() => {
    setImagePosition('left');
    beforeImageURL ? openDeleteImageDialogWindow() : onRemoveFileHandler();
  }, [onRemoveFileHandler, beforeImageURL]);

  const onClickRemoveAfterImageHandler = useCallback(() => {
    setImagePosition('right');
    afterImageURL ? openDeleteImageDialogWindow() : onRemoveAfterFileHandler();
  }, [onRemoveAfterFileHandler, afterImageURL]);

  useEffect(() => {
    if (file) openCropFileDialog();
  }, [file]);

  useEffect(() => {
    if (afterFile) openAfterCropFileDialog();
  }, [afterFile]);

  useEffect(() => {
    fetchAlbumPair();

    return () => setAlbumPair(null);
  }, []);

  const { activeSection, generateSideNavItems, onUpdateActiveSection } = useScrollSections({
    sections: SCROLL_SECTIONS,
  });

  const { ref: infoRef, entry: infoEntry } = useInView({
    onChange: onUpdateActiveSection,
  });

  const { ref: tagsRef, entry: tagsEntry } = useInView({
    onChange: onUpdateActiveSection,
  });

  const { ref: photoRef, entry: photoEntry } = useInView({
    initialInView: true,
    onChange: onUpdateActiveSection,
  });

  const sectionEntries = {
    [SECTION_IDS.PHOTO]: photoEntry,
    [SECTION_IDS.INFO]: infoEntry,
    [SECTION_IDS.TAGS]: tagsEntry,
  };

  const sideNavItems = useMemo(() => generateSideNavItems(sectionEntries), [photoEntry, infoEntry, tagsEntry]);

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
  } = useUserProfile();
  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  return {
    isDeletingImage,
    sideNavItems,
    isLoading,
    onActivateDeactivateAlbumPairHandler,
    photoRef,
    infoRef,
    tagsRef,
    activeSection,
    isOpenDeleteImageDialogWindow,
    isActionPending,
    closeDeleteImageDialogWindow,
    onDeleteBeforeImageHandler,
    onUploadDropzoneFileHandler,
    previewURL,
    onClickRemoveBeforeImageHandler,
    isCropFileDialogOpen,
    onCropCompleteHandler,
    onCreateBeforeImageHandler,
    onCreateAfterImageHandler,
    onFileInputChangeHandler,
    imagePosition,
    onDeleteAfterImageHandler,
    isImageUplaoding,
    onClickRemoveAfterImageHandler,
    onUploadAfterDropzoneFileHandler,
    onAfterFileInputChangeHandler,
    isAfterCropFileDialogOpen,
    afterPreviewURL,
    onAfterCropCompleteHandler,
    onCloseCropDialogHandler,
    dialogStates,
    ...otherDialogManagementProps,
    isViewOnly,
  };
};
