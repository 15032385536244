import { Divider, Stack } from '@mui/material';

import { StickyTabs } from 'components/Widgets';

import { EntityHeaderRootProps } from '../types';

export const EntityHeaderRoot: React.FC<EntityHeaderRootProps> = ({ isWebView, children, isDivider = true, tabs }) => {
  const isNotWebViewTabs = !isWebView && tabs;
  return (
    <>
      {isWebView && tabs && <StickyTabs isWebView={isWebView} {...tabs} />}
      <Stack
        sx={{
          p: 2,
          ...(isNotWebViewTabs && { pb: 0 }),
        }}
      >
        {children}

        {isNotWebViewTabs && <StickyTabs {...tabs} />}
      </Stack>
      {isDivider && <Divider sx={{ mt: isNotWebViewTabs ? '-1px' : 0 }} />}
    </>
  );
};
