import { Box, BoxProps } from '@mui/material';

type PageWrapperProps = BoxProps & {
  children: React.ReactNode;
};

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, ...restProps }) => (
  <Box component="section" height={1} px={{ xs: 2, md: 3 }} display="flex" flexDirection="column" {...restProps}>
    {children}
  </Box>
);
