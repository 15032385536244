import { FC, memo } from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';

import { PracticeListItem } from 'apiServices';
import { ThreeDotsTableCell } from 'components/Table';
import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { usePopover, useRouter } from 'hooks';
import { dateToCustomFormat } from 'utils/helpers';

type PracticesTableRowProps = Pick<PracticeListItem, 'id' | 'status' | 'created_at' | 'name' | 'updated_at'>;

export const PracticesTableRow: FC<PracticesTableRowProps> = memo(({ status, created_at, id, name, updated_at }) => {
  const { navigate } = useRouter();

  const onClickRowHandler = () => navigate(`/practice-management/${id}`);

  const formattedCreatedAtDate = dateToCustomFormat(created_at, MM_DD_YYYY_DATE_FORMAT);

  const formattedUpdatedAtDate = dateToCustomFormat(updated_at, MM_DD_YYYY_DATE_FORMAT);

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleClosePopover();
  };

  const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    handleOpenPopover(event);
  };

  const onClickPaperHandler = (event: React.MouseEvent<HTMLDivElement>) => event?.stopPropagation();

  return (
    <TableRow onClick={onClickRowHandler}>
      <TableCell>
        <Typography variant="body2" fontWeight={500}>
          {name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight={500}>
          {status}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight={500}>
          {formattedCreatedAtDate}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight={500}>
          {formattedUpdatedAtDate || '-'}
        </Typography>
      </TableCell>

      <ThreeDotsTableCell
        open={openPopover}
        onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
        slotProps={{
          paper: { onClick: onClickPaperHandler },
        }}
        onClose={onCloseThreeDotsMenuHandler}
      />
    </TableRow>
  );
});
