import { FC, memo } from 'react';

import { IconButton, Stack, Tooltip, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDAvatar } from 'components/MDAvatar';
import { TypeColor } from 'types';

type RecentlyLoggedInUserItemProps = {
  email: string;
  onRemoveUserHandler?: (email: string) => void;
  onClickUserItemHandler?: (email: string) => void;
};

export const AVATAR_AVAILABLE_COLORS: Extract<
  TypeColor,
  'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
>[] = ['primary', 'info', 'success', 'warning', 'error', 'secondary'];

export const pickAvatarColor = (email: string) => {
  if (!email) return AVATAR_AVAILABLE_COLORS[0];
  const hash = Array.from(email).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return AVATAR_AVAILABLE_COLORS[hash % AVATAR_AVAILABLE_COLORS.length];
};

export const RecentlyLoggedInUserItem: FC<RecentlyLoggedInUserItemProps> = memo(
  ({ email, onRemoveUserHandler, onClickUserItemHandler }) => {
    const onClickDeleteIconHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onRemoveUserHandler?.(email);
    };

    const onSelectUserHandler = () => onClickUserItemHandler?.(email);

    const avatarColor = pickAvatarColor(email);

    return (
      <Stack
        direction="row"
        sx={{
          bgcolor: theme => theme.palette.common.white,
          px: 1.5,
          py: 2,
          borderRadius: theme => theme.borders.borderRadius[12],
          border: theme => `${theme.borders.borderWidth[1]} solid ${theme.palette.background.surfaceStrong}`,
          ':hover': {
            ...(onClickUserItemHandler && {
              cursor: 'pointer',
            }),
            ...(onRemoveUserHandler && {
              '.MuiButtonBase-root': {
                opacity: 1,
              },
            }),
          },
        }}
        gap={1}
        alignItems="center"
        onClick={onSelectUserHandler}
      >
        <Stack gap={1} alignItems="center" direction="row" flex={1}>
          <MDAvatar
            sx={{
              bgcolor: theme => theme.palette[avatarColor].main,
              color: theme => theme.palette.common.white,
            }}
            name={email[0]}
          >
            {email?.[0]}
          </MDAvatar>

          <Typography fontSize={14} fontWeight={500} sx={{ wordBreak: 'break-all' }}>
            {email}
          </Typography>
        </Stack>

        {onRemoveUserHandler && (
          <Tooltip title="Remove" placement="top">
            <IconButton
              sx={{
                opacity: 0,
                ':hover': {
                  backgroundColor: 'unset',
                },
              }}
              onClick={onClickDeleteIconHandler}
            >
              <Icon type="closeIcon" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    );
  },
);
