import { FC, memo, useMemo } from 'react';

import { DomainAddOutlined, PersonOutlineRounded } from '@mui/icons-material';
import { Chip, IconButton, Stack, Typography } from '@mui/material';

import { FeedbackCollectionResponse } from 'apiServices';
import { Icon } from 'components/Icon';
import { MDAvatar } from 'components/MDAvatar';
import { CREATED_AT_DATE_SHORT_FORMAT } from 'constants/dateFormats';
import { ActionDataInitialState } from 'hooks';
import { dateToCustomFormat } from 'utils/helpers';

import { HistoryContentCard } from './HistoryContentCard';
import { RatingItem } from './RatingItem';

type EvaluationHistoryCardProps = Pick<
  FeedbackCollectionResponse,
  | 'id'
  | 'cognito_username'
  | 'coherence_stars'
  | 'accuracy_stars'
  | 'relevancy_stars'
  | 'model_response'
  | 'comment'
  | 'preferred_output'
  | 'created_at'
  | 'ai_model_name'
  | 'used_parameters'
> & {
  onClickActionOptionHandler: (actionStateData: ActionDataInitialState) => void;
  lastCardRef: (node?: Element | null) => void | null;
};

export const EvaluationHistoryCard: FC<EvaluationHistoryCardProps> = memo(
  ({
    id,
    cognito_username,
    accuracy_stars,
    ai_model_name,
    coherence_stars,
    comment,
    created_at,
    model_response,
    preferred_output,
    relevancy_stars,
    used_parameters,
    onClickActionOptionHandler,
    lastCardRef,
  }) => {
    const practice_name = 'practice_name';
    const patient_name = 'patient_name';

    const formatedUsedParameters = useMemo(
      () =>
        used_parameters
          ? Object.entries(used_parameters)
              .map(([key, value]) => `${key}: ${value}`)
              .join(' | ')
          : '',
      [used_parameters],
    );

    const formattedCreatedAt = dateToCustomFormat(created_at, CREATED_AT_DATE_SHORT_FORMAT);

    const onClickDelteButtonHandler = () =>
      onClickActionOptionHandler({ id: id?.toString(), actionType: 'delete', name: '' });

    return (
      <Stack
        p={2}
        spacing={2}
        sx={{
          ':hover': {
            bgcolor: theme => theme.palette.background.surfaceSoft,
            cursor: 'pointer',
          },
          position: 'relative',
        }}
        ref={lastCardRef}
      >
        <Stack direction={{ xs: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1} alignItems="center">
            <MDAvatar avatarSize="sm" />

            <Typography variant="body2" fontWeight={400} fontSize={14}>
              {cognito_username || '-'}
            </Typography>

            <Chip
              variant="soft"
              color="primary"
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <DomainAddOutlined sx={{ width: 20, height: 20 }} />
                  <Typography variant="caption" fontWeight={500}>
                    {patient_name || '-'}
                  </Typography>
                </Stack>
              }
            />
            <Chip
              variant="soft"
              color="primary"
              label={
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <PersonOutlineRounded sx={{ width: 20, height: 20 }} />
                  <Typography variant="caption" fontWeight={500}>
                    {practice_name || '-'}
                  </Typography>
                </Stack>
              }
            />
          </Stack>

          <Stack direction="row" gap={0.5} alignItems="center">
            <Icon type="calendar" sx={{ color: 'text.secondary' }} />
            <Typography variant="body2" lineHeight={1} fontWeight={400} fontSize={14} color="text.secondary">
              {formattedCreatedAt}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" spacing={2.5} width={1}>
          <HistoryContentCard label="Output" content={model_response} iconType="boltIcon" />

          <HistoryContentCard label="General Comments" content={comment} iconType="chatRoundedDotsIcon" />

          <HistoryContentCard label="Expected Response" content={preferred_output} iconType="forwardIcon" />

          <Stack
            gap={2}
            flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
          >
            <RatingItem title="Accuracy" value={accuracy_stars || 0} />
            <RatingItem title="Relevance" value={relevancy_stars || 0} />
            <RatingItem title="Coherence" value={coherence_stars || 0} />
          </Stack>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between" gap={0.5}>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" gap={1}>
            <Typography color="text" variant="button" fontWeight={400}>
              AI Model: {ai_model_name || '-'} #version: -
            </Typography>

            {used_parameters && (
              <Typography color="text" variant="button" fontWeight={400}>
                Parameters: {formatedUsedParameters}
              </Typography>
            )}
          </Stack>

          <IconButton size="small" variant="rounded" onClick={onClickDelteButtonHandler}>
            <Icon type="trashBin" sx={{ svg: { height: 16, width: 16 } }} />
          </IconButton>
        </Stack>
      </Stack>
    );
  },
);
