import { Dialog, Table, TableBody } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { Icon } from 'components/Icon';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { EMPTY_SCREEN_MESSAGES } from 'constants/strings';
import { PageWrapper } from 'layouts';

import { AddPatientDialogWindowForm, FiltersMenuContent, PatientsTableRow } from './components';
import { PATIENTS_TABLE_HEAD } from './table.config';
import { usePatientsState } from './usePatients.state';

export const Patients: React.FC = () => {
  const {
    isPending,
    onGetPatientsHandler,
    page,
    patients,
    total,
    rowsPerPage,
    onChangeRowsPerPage,
    isFiltersMenuOpen,
    openFilterMenu,
    closeFiltersMenu,
    onChangeSearchValueHandler,
    searchValue,
    onApplyFilters,
    appliedFilters,
    onChangePageHandler,
    onSort,
    orderBy,
    order,
    isOpenAddPatientDialogWindow,
    openAddPatientDialogWindow,
    closeAddPatientDialogWindow,
    isRoleAdmin,
    setResetFilters,
    isEmptyState,
    isFilteredEmptyState,
    resetPageHandler,
  } = usePatientsState();

  return (
    <PageWrapper>
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by name, email, phone...',
          onChange: onChangeSearchValueHandler,
        }}
        actionButtonProps={{ buttonTitle: 'Add Patient', onClick: openAddPatientDialogWindow }}
        onOpenFilterMenuHandler={openFilterMenu}
      />

      {isEmptyState ? (
        <EmptyStateWithAction
          title="No Patients Yet"
          subtitle="Click below to add a new one."
          iconType="patientsIcon"
          actionButtonProps={{
            title: 'Add Patient',
            onClickHandler: openAddPatientDialogWindow,
            buttonProps: { startIcon: <Icon type="add" /> },
          }}
        />
      ) : isFilteredEmptyState ? (
        <EmptyStateWithAction
          title={EMPTY_SCREEN_MESSAGES.title}
          subtitle={EMPTY_SCREEN_MESSAGES.subtitle}
          iconType="patientsIcon"
        />
      ) : (
        <>
          <CustomTableContainer>
            <Table size="medium" sx={{ minWidth: 700 }}>
              <CustomTableHeader headLabel={PATIENTS_TABLE_HEAD} onSort={onSort} orderBy={orderBy} order={order} />

              <TableBody>
                {isPending ? (
                  <TableSkeleton cellsAmount={PATIENTS_TABLE_HEAD.length} />
                ) : (
                  patients.map(patient => (
                    <PatientsTableRow
                      key={patient?.id}
                      birth_date={patient?.birth_date}
                      id={patient?.id}
                      first_name={patient?.first_name}
                      last_name={patient?.last_name}
                      middle_name={patient?.middle_name}
                      avatar_url={patient?.avatar_url}
                      email={patient?.email}
                      phone={patient?.phone}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>

          <CustomTablePagination
            count={total}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePageHandler}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </>
      )}

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by Patients"
        handleClosePopover={closeFiltersMenu}
        variant="filter"
      >
        <FiltersMenuContent
          appliedFilters={appliedFilters}
          onResetFilters={setResetFilters}
          onApplyFilters={onApplyFilters}
        />
      </PopoverWrapper>

      <Dialog
        open={isOpenAddPatientDialogWindow}
        onClose={closeAddPatientDialogWindow}
        fullWidth
        slotProps={{
          paper: {
            sx: { maxWidth: 750 },
          },
        }}
      >
        <AddPatientDialogWindowForm
          isPracticeField={isRoleAdmin}
          onCloseDialogHandler={closeAddPatientDialogWindow}
          onGetPatientHandler={onGetPatientsHandler}
          resetPageHandler={resetPageHandler}
        />
      </Dialog>
    </PageWrapper>
  );
};
