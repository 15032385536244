import { useState } from 'react';

import { TableRow, TableCell, TableCellProps, Stack, Tooltip, Box } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDAutocomplete } from 'components/MDAutocomplete';
import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';
import { genericMemo } from 'utils/helpers';

type AddEditMedicalRecordTableRowProps<TOption extends { id: string }> = TableCellProps & {
  options: TOption[];
  option?: TOption | null;
  note?: string;
  onCancelHandler: () => void;
  onSaveHandler: (option: TOption, note?: string) => Promise<void>;
  getOptionLabel: (option: TOption) => string;
};

export const AddEditMedicalRecordTableRow = genericMemo(
  <TOption extends { id: string }>({
    options,
    option,
    note,
    onSaveHandler,
    getOptionLabel,
    onCancelHandler,
    ...restProps
  }: AddEditMedicalRecordTableRowProps<TOption>) => {
    const [selectedOption, setSelectedOption] = useState<TOption | null>(option || null);

    const [noteValue, setNoteValue] = useState(note || '');

    const onChangeNoteInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => setNoteValue(event.target.value);

    const [isLoading, setIsLoading] = useState(false);

    const onClickSaveButtonHandler = async () => {
      setIsLoading(true);

      await onSaveHandler(selectedOption, noteValue);

      setIsLoading(false);
      onClickCancelButtonHandler();
    };

    const onClickCancelButtonHandler = () => {
      onCancelHandler();

      if (selectedOption) setSelectedOption(null);

      if (noteValue) setNoteValue('');
    };

    const isDisabled = (!selectedOption && !noteValue.length) || isLoading;

    return (
      <TableRow>
        <TableCell colSpan={5} {...restProps}>
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <MDAutocomplete
              value={selectedOption}
              inputProps={{ placeholder: 'Select Allergy' }}
              options={options}
              multiple={false}
              freeSolo={false}
              valueKey="id"
              getOptionLabel={getOptionLabel}
            />

            <MDInput value={noteValue} onChange={onChangeNoteInputHandler} fullWidth placeholder="Enter Note" />

            <Stack direction="row" alignItems="center" gap={0.5}>
              <Tooltip title="Save" placement="top">
                <Box>
                  <MDButton
                    size="small"
                    isIconOnly
                    variant="ghost"
                    disabled={isDisabled}
                    onClick={onClickSaveButtonHandler}
                  >
                    <Icon
                      type="checkmarkIcon"
                      sx={{
                        svg: {
                          width: 18,
                          height: 18,
                          ...(!isDisabled && {
                            color: 'primary.main',
                          }),
                        },
                      }}
                    />
                  </MDButton>
                </Box>
              </Tooltip>

              <Tooltip title="Cancel" placement="top">
                <MDButton size="small" isIconOnly variant="ghost" onClick={onClickCancelButtonHandler}>
                  <Icon
                    type="closeIcon"
                    sx={{
                      svg: {
                        width: 12,
                        height: 12,
                      },
                    }}
                  />
                </MDButton>
              </Tooltip>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    );
  },
);
