import { Box, Dialog, Stack, Table, TableBody, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';
import { FilterPopoverContent, TagCategoryListTableRow } from 'pages/TagManagement/components';
import { TAG_LIST_TABLE_HEADERS } from 'pages/TagManagement/config';

import { CreateTagCategoryMenuContent } from './components';
import { usePracticeLibraryTagListState } from './usePracticeLibraryTagList.state';

export const PracticeLibraryTagList: React.FC = () => {
  const {
    onChangeSearchValueHandler,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    items,
    isLoading,
    onGetTagCategoriesHandler,
    onChangeRowsPerPage,
    rowsPerPage,
    total,
    onChangePageHandler,
    page,
    appliedFilters,
    onClickActionOptionHandler,
    onDeactivateTagCategoryHandler,
    dialogStates,
    actionState,
    isActionPending,
    onActivateTagCategoryHandler,
    onApplyFilters,
    isFiltersApplied,
    onSort,
    order,
    orderBy,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialog,
    closeAddTagCategoryDialog,
    isRoleAdmin,
    isViewOnly,
    getCloseDialogHandler,
    resetPageHandler,
    setResetFilters,
  } = usePracticeLibraryTagListState();

  return (
    <PageWrapper component="div">
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by Name',
          onChange: onChangeSearchValueHandler,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={
          !isViewOnly && {
            buttonTitle: 'Add tag category',
            onClick: openAddTagCategoryDialog,
          }
        }
        isFiltersApplied={isFiltersApplied}
      />

      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <colgroup>
            <Box component="col" width={isViewOnly ? '70%' : '50%'} />
            <Box component="col" width="20%" />
            <Box component="col" width="20%" />
            {!isViewOnly && <Box component="col" width="10%" />}
          </colgroup>
          <CustomTableHeader
            headLabel={isViewOnly ? TAG_LIST_TABLE_HEADERS.slice(0, 3) : TAG_LIST_TABLE_HEADERS}
            onSort={onSort}
            order={order}
            orderBy={orderBy}
          />

          <TableBody>
            {isLoading ? (
              <TableSkeleton cellsAmount={isViewOnly ? 3 : 4} />
            ) : (
              items?.map(el => (
                <TagCategoryListTableRow
                  key={el?.id}
                  onClickActivationOptionHandler={onClickActionOptionHandler}
                  tagCategoryName={el?.name}
                  categoryTags={el?.tags}
                  tagCategoryServiceName={el?.service_name}
                  id={el?.id}
                  isPublished={el?.is_published}
                  isActive={el?.is_active}
                  isViewOnly={isViewOnly}
                />
              ))
            )}
            <TableNoData isNotFound={!isLoading && !items?.length} />
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePageHandler}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddTagCategoryDialogOpen}
        onClose={closeAddTagCategoryDialog}
        slotProps={{
          paper: {
            sx: { width: 1 },
          },
        }}
      >
        <CreateTagCategoryMenuContent
          fetchTagCategories={onGetTagCategoriesHandler}
          resetPageHandler={resetPageHandler}
          closeMenuHandler={closeAddTagCategoryDialog}
          isPracticeField={isRoleAdmin}
        />
      </Dialog>

      <BaseDialogWindow
        open={dialogStates.deactivate}
        onClickCancelButtonHandler={getCloseDialogHandler('deactivate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Inactivate Tag Category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate tag category"
        onClickApproveButtonHandler={onDeactivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={dialogStates.activate}
        onClickCancelButtonHandler={getCloseDialogHandler('activate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Activate Tag Category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate tag category"
        onClickApproveButtonHandler={onActivateTagCategoryHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter Tag Categories"
        handleClosePopover={closeFiltersMenu}
        sx={{ mt: 1, px: 2, py: 1 }}
        variant="filter"
      >
        <FilterPopoverContent
          onResetFilters={setResetFilters}
          appliedFilters={appliedFilters}
          onApplyFilters={onApplyFilters}
        />
      </PopoverWrapper>
    </PageWrapper>
  );
};
