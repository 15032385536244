import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, SxProps, TableCell, Theme } from '@mui/material';

import { Icon } from 'components/Icon';
import { MenuPopover, PopoverMenuItem, PopoverMenuItemProps } from 'components/MenuPopover';
import { MenuPopoverProps } from 'components/MenuPopover/types';

type ThreeDotsTableCellProps = MenuPopoverProps & {
  onClickButtonHandler: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  menuItems?: PopoverMenuItemProps[];
  onClickDeleteMenuItemHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
  onClickUpdateMenuItemHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
  tableCellSX?: SxProps<Theme>;
};

export const ThreeDotsTableCell: React.FC<ThreeDotsTableCellProps> = ({
  onClickButtonHandler,
  menuItems = [],
  open,
  onClose,
  onClickDeleteMenuItemHandler,
  tableCellSX,
  ...rest
}) => {
  const menuItemsToMap = [
    ...menuItems,
    onClickDeleteMenuItemHandler && {
      icon: <Icon type="trashBin" />,
      title: 'Delete',
      onClick: onClickDeleteMenuItemHandler,
    },
  ].filter(Boolean);

  const onClickIconButtonHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    if (menuItemsToMap.length) onClickButtonHandler(event);
  };

  return (
    <TableCell sx={{ ...tableCellSX, textAlign: 'center' }}>
      <IconButton variant="rounded" color={open ? 'inherit' : 'default'} onClick={onClickIconButtonHandler}>
        <MoreVertOutlined fontSize="medium" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={onClose}
        disabledArrow
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiPopover-paper': {
            minWidth: 140,
            p: 0,
            overflow: 'hidden',
          },
        }}
        {...rest}
      >
        {menuItemsToMap.map(({ icon, title, ...rest }) => (
          <PopoverMenuItem key={title} title={title} icon={icon} {...rest} />
        ))}
      </MenuPopover>
    </TableCell>
  );
};
