import { Theme, alpha } from '@mui/material';

export const TableStyles = {
  ButtonsWrapperSX: { flexShrink: 0, ml: 1.5, mr: 3 },

  DisabledButtonSX: {
    pointerEvents: 'none',
    cursor: 'default',
    color: (theme: Theme) => alpha(theme.palette.grey[500], 0.8),
  },

  ButtonSX: {
    borderColor: (theme: Theme) => alpha(theme.palette.primary.main, 0.6),
    padding: 0,
    minWidth: '28px !important',
    maxWidth: 28,
    minHeight: '28px !important',
    height: 'unset',
  },

  FormControlLabelSX: {
    pl: 2,
    py: 1.5,
    top: 0,
    position: {
      md: 'absolute',
    },
  },

  SelectIconSX: {
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    position: 'absolute',
    right: 0,
    display: 'block !important',
    top: '50% !important',
    pointerEvents: 'none',
    transform: 'translateY(-50%) rotate(90deg) !important',
    color: (theme: Theme) => `${theme.palette.primary.light} !important`,
  },
};
