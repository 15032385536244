import { apiClient } from 'lib/api';
import { ListOfItems, ImportToPLPayload } from 'types';

import {
  CreatePracticeLibraryTagCategoryPayload,
  CreatePracticeLibraryTagPayload,
  PracticeLibraryTag,
  PracticeLibraryTagCategory,
  PracticeLibraryTagsSearchParams,
  UpdatePracticeLibraryTagCategoryPayload,
  UpdatePracticeLibraryTagPayload,
} from './types';

const BASE_TAG_CATEGORIES_API_URL = 'practice/tag_categories';
const BASE_TAGS_API_URL = 'practice/tags';

export const getPracticeLibraryTagCategories = async (params: PracticeLibraryTagsSearchParams) =>
  await apiClient.get<ListOfItems<PracticeLibraryTagCategory>>(BASE_TAG_CATEGORIES_API_URL, {
    params,
    paramsSerializer: { indexes: null },
  });

export const createPracticeLibraryTagCategory = async (payload: CreatePracticeLibraryTagCategoryPayload) =>
  await apiClient.post<PracticeLibraryTagCategory>(BASE_TAG_CATEGORIES_API_URL, payload);

export const getPracticeLibraryTagCategory = async (categoryId: string) =>
  await apiClient.get<PracticeLibraryTagCategory>(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`);

export const updatePracticeLibraryTagCategory = async (
  categoryId: string,
  payload: UpdatePracticeLibraryTagCategoryPayload,
) => await apiClient.patch<PracticeLibraryTagCategory>(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`, payload);

export const deletePracticeLibraryTagCategory = async (categoryId: string) =>
  await apiClient.delete(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}`);

export const deactivatePracticeLibraryTagCategory = async (categoryId: string) =>
  await apiClient.delete(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}/deactivate`);

export const activatePracticeLibraryTagCategory = async (categoryId: string) =>
  await apiClient.patch<PracticeLibraryTag>(`${BASE_TAG_CATEGORIES_API_URL}/${categoryId}/activate`);

export const importTagCategoryToPL = async (payload: ImportToPLPayload) =>
  await apiClient.post<PracticeLibraryTagCategory>(`${BASE_TAG_CATEGORIES_API_URL}/import`, payload);

export const getPracticeLibraryTags = async (params: PracticeLibraryTagsSearchParams) =>
  await apiClient.get<ListOfItems<PracticeLibraryTag>>(BASE_TAGS_API_URL, {
    params,
    paramsSerializer: { indexes: null },
  });

export const createPracticeLibraryTag = async (payload: CreatePracticeLibraryTagPayload) =>
  await apiClient.post<PracticeLibraryTag>(BASE_TAGS_API_URL, payload);

export const getPracticeLibraryTag = async (tagId: string) =>
  await apiClient.get<PracticeLibraryTag>(`${BASE_TAGS_API_URL}/${tagId}`);

export const updatePracticeLibraryTag = async (tagId: string, payload: UpdatePracticeLibraryTagPayload) =>
  await apiClient.patch<PracticeLibraryTag>(`${BASE_TAGS_API_URL}/${tagId}`, payload);

export const deletePracticeLibraryTag = async (tagId: string) =>
  await apiClient.delete(`${BASE_TAGS_API_URL}/${tagId}`);
