import { Chip, ChipProps, styled } from '@mui/material';

export type MDChipProps = {
  isDot?: boolean;
  isSquare?: boolean;
} & ChipProps;

const Dot = styled('div', {
  shouldForwardProp: prop => prop !== 'color',
})<{ color: ChipProps['color'] }>(({ theme: { palette }, color }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  bgcolor: color === 'default' ? palette.text.primary : palette[color]?.main,
}));

export const MDChip: React.FC<MDChipProps> = ({ color, isDot, isSquare, icon, sx, ...other }) => {
  return (
    <Chip
      color={color}
      sx={{
        ...(isSquare && { borderRadius: theme => theme.borders.borderRadius[6] }),
        ...sx,
      }}
      icon={isDot ? <Dot color={color} /> : (icon ?? null)}
      {...other}
    />
  );
};
