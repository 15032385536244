import { FC, memo, useCallback, useState } from 'react';

import { Badge, Box, alpha } from '@mui/material';
import { FileWithPath } from 'react-dropzone';

import { CropShape, UploadAvatarDialogWindow, ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import { Icon } from 'components/Icon';
import { MDAvatar, MDAvatarProps, MDAvatarSkeleton } from 'components/MDAvatar';
import { ToastType, notice } from 'components/ToastNotification';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';
import { useFilePicker } from 'hooks';
import { useBoolean } from 'hooks/useBoolean';
import { backendErrorHandler } from 'utils/errorHanders';
import { generateImageFormData } from 'utils/helpers';

import getCroppedImg from './helpers';

export type UploadAvatarProps = MDAvatarProps & {
  isDisabled?: boolean;
  onCreateFileHandler: (formData: FormData, croppedImageSrc: string) => Promise<void>;
  onDeleteFileHandler: () => Promise<void>;
  initialPhotoUrl?: string;
  formDataImageName?: string;
  cropShape?: CropShape;
};

export const UploadAvatar: FC<UploadAvatarProps> = memo(
  ({
    src,
    isDisabled,
    avatarSize,
    variant = 'circular',
    onCreateFileHandler,
    initialPhotoUrl,
    onDeleteFileHandler,
    formDataImageName = 'avatar_image',
    cropShape,
    ...rest
  }) => {
    const {
      setCroppedAreaPixels,
      onRemoveFileHandler,
      onUploadDropzoneFileHandler,
      onFileInputChangeHandler,
      previewURL,
      file,
      croppedAreaPixels,
      onCropCompleteHandler,
      isCropFileDialogOpen,
      openCropFileDialog,
      closeCropFileDialog,
    } = useFilePicker({
      accept: ACCEPT_INPUT_IMAGE_FILES,
    });

    const [isUploadFileDialogOpen, openUploadFileDialog, closeUploadFileDialog] = useBoolean();

    const onChangeDropZoneFileHandler = useCallback((fileList: readonly FileWithPath[]) => {
      onUploadDropzoneFileHandler(fileList);
      closeUploadFileDialog();
      openCropFileDialog();
    }, []);

    const onCloseCropFileDialogWindowHandler = useCallback(() => {
      closeCropFileDialog();
      onRemoveFileHandler();
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const onSaveFileHandler = async () => {
      if (!file) return notice(ToastType.ERROR, 'Please upload a file!');

      setIsLoading(true);
      try {
        const formData = generateImageFormData({ file, croppedAreaPixels, formDataImageFileName: formDataImageName });

        const croppedImageSrc = await getCroppedImg({ imageSrc: previewURL, pixelCrop: croppedAreaPixels });

        await onCreateFileHandler(formData, croppedImageSrc);
        notice(ToastType.SUCCESS, 'Photo has been added successfully!');

        onCloseCropFileDialogWindowHandler();
        setCroppedAreaPixels(null);
        setIsLoading(false);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to add photo, please try again!' } });
      } finally {
        setIsLoading(false);
      }
    };

    const onClickUploadAvatarHandler = () => {
      src || initialPhotoUrl ? openCropFileDialog() : openUploadFileDialog();
    };

    const onClickDeleteFileButtonHandler = async () => {
      setIsLoading(true);

      await onDeleteFileHandler();

      setIsLoading(false);
      closeCropFileDialog();
    };

    const onClickUploadNewFileButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      onFileInputChangeHandler(event);
      if (initialPhotoUrl) {
        openCropFileDialog();
      }
    };

    return (
      <>
        {isLoading ? (
          <MDAvatarSkeleton avatarSize={avatarSize} />
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Box
                sx={{
                  ...(isDisabled && {
                    display: 'none',
                  }),
                  height: 28,
                  width: 28,
                  borderRadius: '50%',
                  p: 0.5,
                  bgcolor: theme => theme.palette.grey[200],
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    height: 1,
                    borderRadius: '50%',
                    p: '3px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    bgcolor: theme => alpha(theme.palette.grey[300], 0.5),
                  }}
                >
                  {src ? <Icon type="editPen" /> : <Icon type="uploadIcon" />}
                </Box>
              </Box>
            }
          >
            <MDAvatar
              src={src || null}
              avatarSize={avatarSize}
              variant={variant}
              onClick={onClickUploadAvatarHandler}
              sx={{ ':hover': { cursor: 'pointer' }, bgcolor: theme => alpha(theme.palette.primary.light, 0.1) }}
              isDisabled={isDisabled}
              {...rest}
            />
          </Badge>
        )}

        <UploadAvatarDialogWindow
          open={isUploadFileDialogOpen}
          onClickCloseButtonHandler={closeUploadFileDialog}
          onUploadFileHandler={onChangeDropZoneFileHandler}
        />

        {isCropFileDialogOpen && (
          <ZoomCropDialogWindow
            imageURL={previewURL}
            initialPhotoURL={initialPhotoUrl}
            open={isCropFileDialogOpen}
            onClickCloseButtonHandler={onCloseCropFileDialogWindowHandler}
            onUploadNewFileHandler={onClickUploadNewFileButtonHandler}
            onSaveFileHandler={onSaveFileHandler}
            isLoading={isLoading}
            onCropCompleteHandler={onCropCompleteHandler}
            onDeleteFileHandler={onClickDeleteFileButtonHandler}
            cropShape={cropShape}
          />
        )}
      </>
    );
  },
);
