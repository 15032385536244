import { useState } from 'react';

import { getUserRoles, GetUserRolesParams, UserRolesEnum } from 'apiServices';
import { OptionsInitialState } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';

export const useUserRoles = () => {
  const [userRolesState, setUserRolesState] = useState<Omit<OptionsInitialState<UserRolesEnum>, 'totalPages'>>({
    isLoading: true,
    items: [],
  });

  const fetchUserRoles = async (params?: GetUserRolesParams) => {
    !userRolesState.isLoading && setUserRolesState(prev => ({ ...prev, isLoading: true }));

    try {
      const { data } = await getUserRoles(params);

      setUserRolesState(prev => ({ ...prev, items: data }));
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get user roles, please try again!' } });
    } finally {
      setUserRolesState(prev => ({ ...prev, isLoading: false }));
    }
  };

  return {
    fetchUserRoles,
    userRolesState,
  };
};
