import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';
import { MDButton } from 'components/MDButton';

import { PREVIEW_IMAGE_TYPE_PLACEHOLDER } from '../config';
import { ListViewItemProps } from './types';

export const ListViewItem: React.FC<ListViewItemProps> = memo(
  ({ onClickDeleteButtonHandler, id, previewURL, title, type }) => {
    const handleDeleteButtonClick = () => onClickDeleteButtonHandler(id);
    return (
      <Stack
        sx={{
          ':hover': {
            bgcolor: 'background.surfaceSoft',
            '.delete-button': {
              opacity: 1,
            },
          },
        }}
        p={1.5}
        component="li"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" gap={1.5} alignItems="center">
          <Icon
            type="dragAndDropIcon"
            sx={{
              ':hover': {
                cursor: 'move',
              },
            }}
          />
          <LazyImage
            src={previewURL}
            alt="cover image"
            imageContainerSX={{
              width: 36,
              height: 36,
              borderRadius: theme => theme.borders.borderRadius[8],
            }}
          />
          <Typography variant="body2" color="text.secondary" fontWeight={500}>
            {title}
          </Typography>
        </Stack>

        <Stack direction="row" gap={1.5} alignItems="center">
          <Icon
            sx={{
              color: 'primary.main',
              svg: {
                width: 24,
                height: 24,
              },
            }}
            type={PREVIEW_IMAGE_TYPE_PLACEHOLDER[type]}
          />
          <MDButton
            size="small"
            variant="ghost"
            className="delete-button"
            onClick={handleDeleteButtonClick}
            sx={{
              minWidth: 'unset',
              width: 38,
              opacity: 0,
              transition: ({ transitions }) =>
                transitions.create('opacity', {
                  duration: transitions.duration.shortest,
                  easing: transitions.easing.easeIn,
                }),
              ':hover': {
                opacity: 1,
                backgroundColor: 'common.white',
              },
            }}
          >
            <Icon type="trashBinIcon2" />
          </MDButton>
        </Stack>
      </Stack>
    );
  },
);
