import { FC, memo, useEffect } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';
import { usePatientEnums } from 'hooks';
import { SectionWrapper } from 'layouts';

import { LifestyleContentProps } from './types';
import { LIFESTYLE_ID } from '../constants';
import { EditMedicalFormSchema } from '../validation';

export const LifestyleContent: FC<LifestyleContentProps> = memo(({ contentRef }) => {
  const { control } = useFormContext<EditMedicalFormSchema>();

  const {
    patientAlcoholConsumptionEnumsState,
    fetchPatientAlcoholConsumptionsEnums,
    fetchPatientSmokingEnums,
    patientSmokingEnumsState,
  } = usePatientEnums();

  useEffect(() => {
    fetchPatientAlcoholConsumptionsEnums();
    fetchPatientSmokingEnums();
  }, []);

  return (
    <>
      <SectionWrapper ref={contentRef} id={LIFESTYLE_ID}>
        <Typography mb={3} variant="subtitle1" color="secondary.darker" fontWeight={500}>
          Lifestyle
        </Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} gap={3}>
          <RHFAutocompleteField
            label="Smoking"
            control={control}
            registerName="smoking"
            placeholder="Select Smoking Frequency"
            loading={patientSmokingEnumsState.isLoading}
            options={patientSmokingEnumsState.items}
            valueKey="value"
            labelKey="name"
          />

          <RHFAutocompleteField
            label="Alcohol Consumption"
            control={control}
            registerName="alcohol_consumption"
            placeholder="Select Alcohol Consumption Frequency"
            options={patientAlcoholConsumptionEnumsState.items}
            loading={patientAlcoholConsumptionEnumsState.isLoading}
            valueKey="value"
            labelKey="value"
          />
        </Stack>
      </SectionWrapper>
      <Divider />
    </>
  );
});
