import { TIconType } from 'components/Icon';

import { ContentType } from './ContentTypePickerDialogWindow';

export const PREVIEW_IMAGE_TYPE_PLACEHOLDER: Record<ContentType, TIconType> = {
  image: 'galleryFilledIcon',
  '3d': 'ThreeDfilledIcon',
  video: 'videoFilledIcon',
  text: 'textFilledIcon',
  page: 'pageFilledIcon',
  folder: 'folderFilledIcon',
  beforeAfter: 'beforeAfterWorkflowFilledIcon',
  prompt: 'aiFilledIcon',
  questionnaire: 'questionnaireFilledIcon',
  link: 'linkFilledIcon',
};

export const LIST_OF_ITEMS_PREVIEW_IMAGE_TYPE_PLACEHOLDER: Record<ContentType, TIconType> = {
  ...PREVIEW_IMAGE_TYPE_PLACEHOLDER,
  beforeAfter: 'beforeAfterWorkflowFilledWhiteBgIcon',
};

export const ITEMS: { label: string; type: ContentType }[] = [
  {
    label: 'Image',
    type: 'image',
  },
  {
    label: '3D',
    type: '3d',
  },
  {
    label: 'Video',
    type: 'video',
  },
  {
    label: 'Text',
    type: 'text',
  },
  { label: 'Page', type: 'page' },
  { label: 'Folder', type: 'folder' },
  {
    label: 'Before & After',
    type: 'beforeAfter',
  },
  { label: 'Prompt', type: 'prompt' },
  { label: 'Questionnaire', type: 'questionnaire' },
  { label: 'Link', type: 'link' },
];
