import * as yup from 'yup';

import { PracticeIdValidationSchema } from 'utils/formValidation';

export type Create_ba_album_pair_form_schema = Partial<{
  name: string;
  description: string;
  practice_id: string;
  album_id: string;
}>;

export const create_ba_album_pair_validation_schema = yup
  .object()
  .shape({
    name: yup.string().required().label('Name'),
    album_id: yup.string().required().label('Album'),
    description: yup.string().optional().label('Description'),
    practice_id: PracticeIdValidationSchema,
  })
  .required();

export const create_ba_album_pair_defaultValues: Create_ba_album_pair_form_schema = {
  name: '',
  description: '',
  practice_id: '',
  album_id: '',
};
