import { useCallback, useState } from 'react';

export type onCheckItemHandlerType = (id: string, checked: boolean) => void;
export type onResetChekedIdsType = () => void;
export type onCheckAllIdsHandlerType = (isChecked: boolean, contentItems: string[]) => void;

export const useCheckedIDs = () => {
  const [checkedIds, setCheckedIds] = useState<Set<string>>(new Set([]));

  const onCheckItemHandler = useCallback((id: string, checked: boolean) => {
    setCheckedIds(prevState => {
      const newCheckedIds = new Set(prevState);

      if (checked) {
        newCheckedIds.add(id);
      } else newCheckedIds.delete(id);

      return newCheckedIds;
    });
  }, []);

  const onCheckAllIdsHandler = useCallback((isChecked: boolean, ids: string[]) => {
    setCheckedIds(prevState => {
      const newCheckedIds = new Set(prevState);

      if (isChecked) {
        ids?.forEach(id => newCheckedIds.add(id));
      } else {
        ids?.forEach(id => newCheckedIds.delete(id));
      }

      return newCheckedIds;
    });
  }, []);

  const onResetChekedIds = useCallback(() => setCheckedIds(new Set([])), []);

  const checkedIdsSize = checkedIds.size;
  const isNoIdChecked = !checkedIdsSize;

  return {
    onCheckAllIdsHandler,
    setCheckedIds,
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
    isNoIdChecked,
    checkedIdsSize,
  };
};
