import { Table, TableBody } from '@mui/material';

import { CustomTableContainer, CustomTableHeader, CustomTablePagination, TableToolbar } from 'components/Table';
import { PageWrapper } from 'layouts';
import { useAppStore } from 'store';

import { FormsTableRow } from './components';
import { FORM_TABLE_HEADERS } from './table.config';

export const Forms: React.FC = () => {
  const patient = useAppStore(store => store.patient);

  return (
    <PageWrapper component="div">
      <TableToolbar
        onOpenFilterMenuHandler={() => {}}
        searchInputProps={{
          placeholder: 'Type to Search',
        }}
      />

      <CustomTableContainer>
        <Table size="medium">
          <CustomTableHeader headLabel={FORM_TABLE_HEADERS} />

          <TableBody>
            <FormsTableRow
              title="Precision Injection Planner Input Form"
              provider={`${patient?.first_name || ''} ${patient?.last_name || ''}`}
              lastUpdatedAt={new Date().toISOString()}
              id="1"
            />
          </TableBody>
        </Table>
      </CustomTableContainer>
      <CustomTablePagination
        count={1}
        page={1}
        rowsPerPage={15}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
      />
    </PageWrapper>
  );
};
