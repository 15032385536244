import { FC, useEffect, useMemo, useState } from 'react';

import { Box, FormControlLabel, Stack, Switch } from '@mui/material';

import { PracticeListItem, UserRolesEnum } from 'apiServices';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { MDAutocomplete, UserRoleRenderOption } from 'components/MDAutocomplete';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { UserRoleLabels } from 'constants/roles';
import { useOptions, useUserRoles, useUserProfile } from 'hooks';
import { getElementById, getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialState, FiltersMenuContentProps } from '../types';
import { FiltersInitialStateValue } from '../useUsers.state';

type FiltersValueType<K extends keyof FiltersInitialState> = FiltersInitialState[K];

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ onResetFilters, appliedFilters, onApplyFilters }) => {
  const { fetchPractices, debouncedPracticeSearchValue, onChangePracticeInputValueHandler, practicesState } =
    useOptions({});

  const isRoleAdmin = useUserProfile().userRoles.isRoleAdmin;

  const [filters, setFilters] = useState(appliedFilters);

  const onClickResetFiltersButtonHandler = () => {
    onResetFilters();
    setFilters(FiltersInitialStateValue);
  };

  const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

  const onChangeStateHandler = <K extends keyof FiltersInitialState>(key: K, value: FiltersValueType<K>) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) =>
    onChangeStateHandler('isShowDeactivated', checked);

  const onChagePracticeValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: PracticeListItem) =>
    onChangeStateHandler('practice_id', value?.id);

  const onChageRolesValueHandler = async (_: React.SyntheticEvent<Element, Event>, roles: UserRolesEnum[]) =>
    onChangeStateHandler('roles', roles);

  const { fetchUserRoles, userRolesState } = useUserRoles();

  useEffect(() => {
    fetchUserRoles({ practice_roles: true });
  }, []);

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filters, appliedFilters],
  );

  const isResetButtonDisabled = useMemo(
    () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
    [filters],
  );

  const practiceSelectedValue = useMemo(
    () => getElementById(filters?.practice_id, 'id', practicesState.items),
    [filters?.practice_id, practicesState.items],
  );

  return (
    <Stack position="relative">
      <Stack height={1} spacing={2} py={1}>
        {isRoleAdmin && (
          <InfiniteScrollWrapper
            searchQuery={debouncedPracticeSearchValue}
            totalPages={practicesState.totalPages}
            fetchItemsHandler={fetchPractices}
          >
            {({ ref }) => (
              <MDAutocomplete
                inputProps={{
                  label: 'Practice',
                  placeholder: 'Select Practice',
                }}
                freeSolo={false}
                multiple={false}
                options={practicesState.items}
                loading={practicesState.isLoading}
                onInputChange={onChangePracticeInputValueHandler}
                onChange={onChagePracticeValueHandler}
                value={practiceSelectedValue}
                lastElementRef={ref}
                valueKey="id"
                labelKey="name"
                disabled
              />
            )}
          </InfiniteScrollWrapper>
        )}

        <MDAutocomplete
          inputProps={{
            label: 'Role',
            placeholder: 'Select Role',
          }}
          freeSolo={false}
          multiple
          options={userRolesState.items}
          getOptionLabel={option => UserRoleLabels[option?.value]}
          loading={userRolesState.isLoading}
          onChange={onChageRolesValueHandler}
          value={filters.roles}
          renderOption={UserRoleRenderOption}
          valueKey="value"
        />

        <Box>
          <FormControlLabel
            onChange={onChangeSwitchHandler}
            checked={filters.isShowDeactivated}
            control={<Switch />}
            label="Show deactivated"
            labelPlacement="start"
          />
        </Box>
      </Stack>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
