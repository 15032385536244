import { Box, Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { Icon } from 'components/Icon';
import { genericMemo } from 'utils/helpers';

import { ICustomTableHeaderProps } from './Table.types';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

export const CustomTableHeader = genericMemo(
  <TSortPropertyName,>(props: ICustomTableHeaderProps<TSortPropertyName>) => {
    const {
      order,
      orderBy,
      rowCount = 0,
      headLabel,
      numSelected = 0,
      onSort,
      onSelectAllRows,
      onClickAddColumnButtonHandler,
      sx,
    } = props;

    const onClickTableSortHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
      onSort?.(event.currentTarget.id as unknown as TSortPropertyName);

    const onChangeCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
      onSelectAllRows && onSelectAllRows(event.target.checked);

    const isChecked = rowCount > 0 && numSelected === rowCount;

    const indeterminate = numSelected > 0 && numSelected < rowCount;

    return (
      <TableHead sx={sx}>
        <TableRow sx={{ 'th:first-of-type': { pl: 2 } }}>
          {onSelectAllRows && (
            <TableCell padding="checkbox">
              <Checkbox indeterminate={indeterminate} checked={isChecked} onChange={onChangeCheckboxHandler} />
            </TableCell>
          )}
          {headLabel.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.align || 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                width: headCell.width,
                minWidth: headCell.minWidth,
                ...(headCell?.isHidden && {
                  display: 'none',
                }),
              }}
            >
              {onSort && !headCell?.disableSorting ? (
                <TableSortLabel
                  id={headCell.id}
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={onClickTableSortHandler}
                  sx={{
                    textTransform: 'capitalize',
                    '& .MuiTableSortLabel-icon': {
                      width: 14,
                      height: 14,
                    },
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}

          {onClickAddColumnButtonHandler && (
            <TableCell sx={{ textAlign: 'center' }}>
              <IconButton variant="circle" size="small" onClick={onClickAddColumnButtonHandler}>
                <Icon type="add" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  },
);
