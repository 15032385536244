import { StyledListWrapper } from './ContentSideMenu.styles';
import { SideNavMenuSkeleton } from './SideNavMenu.skeleton';
import { ListWrapperProps } from './types';

export const ListWrapper: React.FC<ListWrapperProps> = ({ isLoading, children, isBorder = true, sx, ...rest }) => (
  <StyledListWrapper
    component="ul"
    sx={{
      ...(!isBorder && {
        border: 0,
      }),
      ...sx,
    }}
    {...rest}
  >
    {isLoading ? <SideNavMenuSkeleton /> : children}
  </StyledListWrapper>
);
