import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { fetchUserAttributes, getCurrentUser, signIn, signOut, signUp, verifyTOTPSetup } from 'aws-amplify/auth';
import { AxiosError } from 'axios';

import { getExpertProfile } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useRouter, useUserProfile } from 'hooks';
import { getAuthSession } from 'lib/AWS_Cognito';
import { resetAllSlices } from 'store';
import { USER_ROLES } from 'types/enums';
import { awsCognitoErrorHandler, backendErrorHandler } from 'utils/errorHanders';

type SignInCognitoProps = {
  email: string;
  password: string;
  onTOTPRequiedHandler?: () => void;
  onSuccessfulSignInHandler?: () => void;
};

export type CognitoUserContextValue = {
  getCognitoCurrentUser: () => Promise<void>;
  signOutCognito: () => Promise<boolean>;
  getExpertProfileHandler: () => Promise<void>;
  signInCognito: (props: SignInCognitoProps) => Promise<void>;
  signUpCognito: (email: string, password: string) => Promise<void>;
  verifyTOTP: (TOTPcode: string) => Promise<void>;
};

const Context = createContext<CognitoUserContextValue>({
  getCognitoCurrentUser: async () => {},
  getExpertProfileHandler: async () => {},
  signOutCognito: async () => {
    return true;
  },
  signInCognito: async () => null,
  signUpCognito: async () => {},
  verifyTOTP: async () => null,
});

export const CognitoAuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);

  const { navigate } = useRouter();

  const getCognitoCurrentUser = useCallback(async () => {
    try {
      const currentAuthSession = await getAuthSession();

      if (currentAuthSession?.tokens) {
        const user = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();

        setCognitoProfile({
          ...user,
          userAttributes,
          roles: currentAuthSession.tokens?.accessToken?.payload?.['cognito:groups'] as USER_ROLES[],
        });
      } else {
        signOutCognito();
      }
    } catch (error) {
      awsCognitoErrorHandler({
        error,
        async customErrorHandler(err) {
          notice(ToastType.ERROR, err?.message || 'Failed to get user, please try again!');
          await signOutCognito();
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getCognitoCurrentUser();
  }, []);

  const { setCognitoProfile, setUserProfile } = useUserProfile();

  const getExpertProfileHandler = useCallback(async (): Promise<void> => {
    try {
      const { tokens } = await getAuthSession();
      const userRoles = (tokens?.accessToken?.payload?.['cognito:groups'] as USER_ROLES[]) || [];

      const route = userRoles.includes(USER_ROLES.ROLE_AI_TESTER) ? ROUTES.promptLibrary : ROUTES.patients;

      const isUserWithProfile =
        !userRoles.includes(USER_ROLES.ROLE_ADMIN) && !userRoles.includes(USER_ROLES.ROLE_AI_TESTER);

      if (isUserWithProfile) {
        const { data } = await getExpertProfile();
        setUserProfile(data);
      }

      navigate(route, { replace: true });
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to get expert profile, please try again!',
          customErrorHandler: async err => {
            if (err instanceof AxiosError) {
              const errorStatus = err?.response?.status;

              switch (errorStatus) {
                case 404:
                  notice(ToastType.ERROR, 'Expert profile not found!');
                  break;
              }

              await signOutCognito();
            }
          },
        },
      });
    }
  }, []);

  const signInCognito = useCallback(
    async ({ email, password, onTOTPRequiedHandler, onSuccessfulSignInHandler }: SignInCognitoProps) => {
      try {
        const { isSignedIn, nextStep } = await signIn({ username: email, password });

        if (!isSignedIn && nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') return onTOTPRequiedHandler();

        if (isSignedIn && nextStep.signInStep === 'DONE') {
          await getCognitoCurrentUser();
          await getExpertProfileHandler();
          onSuccessfulSignInHandler?.();
        }
      } catch (error) {
        console.error(error);
        awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign in.' });
      }
    },
    [],
  );

  const signUpCognito = useCallback(async (email: string, password: string) => {
    try {
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign up.' });
    }
  }, []);

  const signOutCognito = useCallback(async () => {
    try {
      await signOut();
      resetAllSlices();
      setCognitoProfile(null);
      return true;
    } catch (error) {
      console.error(error);
      awsCognitoErrorHandler({ error, customErrorMessage: 'Failed to sign out.' });
      return false;
    }
  }, []);

  const verifyTOTP = useCallback(async (code: string) => {
    try {
      await verifyTOTPSetup({ code });
      await getCognitoCurrentUser();
    } catch (err) {
      throw err;
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      getCognitoCurrentUser,
      signOutCognito,
      signInCognito,
      signUpCognito,
      verifyTOTP,
      getExpertProfileHandler,
    }),
    [getExpertProfileHandler, getCognitoCurrentUser, signInCognito, signOutCognito, signUpCognito, verifyTOTP],
  );

  return (
    <Context.Provider value={contextValue}>
      {isLoading ? (
        <Stack
          sx={{
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: 1,
            height: 1,
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        children
      )}
    </Context.Provider>
  );
};

export const useCognitoAuthContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCognitoAuthContext should be used inside the CognitoUserContextProvider.');
  }
  return context;
};
