import { FC, memo, useEffect } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';
import { usePatientEnums } from 'hooks';
import { SectionWrapper } from 'layouts';

import { EditMedicalFormSchema } from '../validation';
import { GeneralContentProps } from './types';
import { GENERAL_ID } from '../constants';

export const GeneralContent: FC<GeneralContentProps> = memo(({ contentRef }) => {
  const { control } = useFormContext<EditMedicalFormSchema>();

  const {
    fetchPatientBioSexesEnums,
    patientBioSexesEnumsState,
    fetchPatientBloodTypesEnums,
    patientBloodTypesEnumsState,
    fetchPatientPainTolerancesEnums,
    patientPainTolerancesEnumsState,
  } = usePatientEnums();

  useEffect(() => {
    fetchPatientBioSexesEnums();
    fetchPatientBloodTypesEnums();
    fetchPatientPainTolerancesEnums();
  }, []);

  return (
    <>
      <SectionWrapper ref={contentRef} id={GENERAL_ID}>
        <Stack gap={3}>
          <Typography variant="subtitle1" color="secondary.darker" fontWeight={500}>
            General
          </Typography>

          <Stack gap={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={3}>
              <RHFAutocompleteField
                label="Biological Sex"
                control={control}
                registerName="biological_sex"
                placeholder="Select Biological Sex"
                options={patientBioSexesEnumsState.items}
                loading={patientBioSexesEnumsState.isLoading}
                valueKey="value"
                labelKey="value"
              />

              <RHFAutocompleteField
                label="Blood Type"
                control={control}
                registerName="blood_type"
                placeholder="Select Blood Type"
                options={patientBloodTypesEnumsState.items}
                loading={patientBloodTypesEnumsState.isLoading}
                valueKey="value"
                labelKey="value"
              />
            </Stack>

            <Stack gap={3} width="calc(50% - 16px)">
              <RHFAutocompleteField
                label="Pain Tolerance"
                control={control}
                registerName="pain_tolerance"
                placeholder="Select Pain Tolerance"
                options={patientPainTolerancesEnumsState.items}
                loading={patientPainTolerancesEnumsState.isLoading}
                valueKey="value"
                labelKey="value"
                multiple
              />
            </Stack>
          </Stack>
        </Stack>
      </SectionWrapper>
      <Divider />
    </>
  );
});
