import { FC, memo } from 'react';

import { Box, Stack, TableCell, TableRow, Tooltip } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

type MedicalRecordRowProps = {
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  name: string;
  id: string;
  code?: string;
  note?: string;
  createdAt: string;
};

export const MedicalRecordRow: FC<MedicalRecordRowProps> = memo(
  ({ id, createdAt, name, onClickDelete, onClickEdit, code, note }) => {
    const onClickEditButtonHandler = () => onClickEdit(id);

    const onClickDeleteButtonHandler = () => onClickDelete(id);

    return (
      <TableRow>
        <TableCell>{name}</TableCell>

        {code && <TableCell>{code}</TableCell>}

        <TableCell>{note || 'No notes aded'}</TableCell>

        <TableCell>{createdAt}</TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Save" placement="top">
              <Box>
                <MDButton size="small" isIconOnly variant="ghost" onClick={onClickEditButtonHandler}>
                  <Icon
                    type="checkmarkIcon"
                    sx={{
                      svg: {
                        width: 18,
                        height: 18,
                        color: 'primary.main',
                      },
                    }}
                  />
                </MDButton>
              </Box>
            </Tooltip>

            <Tooltip title="Cancel" placement="top">
              <MDButton size="small" isIconOnly variant="ghost" onClick={onClickDeleteButtonHandler}>
                <Icon type="trashBin" />
              </MDButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    );
  },
);
