import { Dialog, Stack, Table, TableBody, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';
import {
  FiltersMenuContent,
  PRODUCTS_SERVICES_TABLE_HEADERS,
  ProductsServicesListTableRow,
  TableTabs,
  TableTabsProps,
} from 'pages/Catalog';

import { AddProductDialogWindowForm } from './AddProductDialogWindowForm';
import { useGLProductsState } from './useGLProducts.state';

export const GlobalLibraryProducts: React.FC<TableTabsProps> = ({ handleSetTabValue, tabValue }) => {
  const {
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onDeleteServiceHandler,
    onCopyItemHandler,
    closeAddItemDialog,
    isAddItemDialogOpen,
    onGetProductsHandler,
    openAddItemDialog,
    isRolePracticeUser,
    dialogStates,
    getCloseDialogHandler,
  } = useGLProductsState();

  return (
    <PageWrapper>
      <TableToolbar
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchValueHandler,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        filterButtonShown={isRoleAdmin}
        actionButtonProps={
          isRoleAdmin && {
            buttonTitle: 'Add product',
            onClick: openAddItemDialog,
          }
        }
        isFiltersApplied={isFiltersApplied}
        tabs={<TableTabs tabValue={tabValue} handleSetTabValue={handleSetTabValue} />}
      />

      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <CustomTableHeader headLabel={PRODUCTS_SERVICES_TABLE_HEADERS} />

          <TableBody>
            {isLoading ? (
              <TableSkeleton cellsAmount={3} />
            ) : (
              items.map(el => (
                <ProductsServicesListTableRow
                  key={el?.id}
                  onClickActivationOptionHandler={onClickActionOptionHandler}
                  description={el?.description}
                  title={el?.title}
                  id={el?.id}
                  isActive={el?.is_active}
                  isThreeDotsButton={isRoleAdmin}
                  library="gl"
                  type="product"
                  isViewOnly={isRolePracticeUser}
                />
              ))
            )}
            <TableNoData isNotFound={!isLoading && !items?.length} />
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddItemDialogOpen}
        onClose={closeAddItemDialog}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddProductDialogWindowForm
          onCloseDialogHandler={closeAddItemDialog}
          onGetProductsHandler={onGetProductsHandler}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('copy')}
        open={dialogStates.copy}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to copy{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            to practice library?
          </Typography>
        }
        title="Copying product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Copy product"
        onClickApproveButtonHandler={onCopyItemHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={getCloseDialogHandler('delete')}
        open={dialogStates.delete}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}?
            </Typography>
          </Typography>
        }
        title="Deleting product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete product"
        onClickApproveButtonHandler={onDeleteServiceHandler}
      />

      <BaseDialogWindow
        open={dialogStates.deactivate}
        onClickCancelButtonHandler={getCloseDialogHandler('deactivate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              product?
            </Typography>
          </Stack>
        }
        title="Inactivate product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate product"
        onClickApproveButtonHandler={onDeactivateServiceHandler}
      />

      <BaseDialogWindow
        open={dialogStates.activate}
        onClickCancelButtonHandler={getCloseDialogHandler('activate')}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              product?
            </Typography>
          </Stack>
        }
        title="Activate product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate product"
        onClickApproveButtonHandler={onActivateServiceHandler}
      />

      {isRoleAdmin && (
        <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
          <FiltersMenuContent
            onResetFilters={() => {}}
            appliedFilters={appliedFilters}
            onApplyFilters={onApplyFilters}
          />
        </PopoverWrapper>
      )}
    </PageWrapper>
  );
};
