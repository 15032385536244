import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme, alpha } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';

const { error } = palette;
const { borderRadius } = borders;

const textField: {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants<Theme>['MuiTextField'];
} = {
  styleOverrides: {
    root: {
      '& .MuiInputBase-root': {
        height: 42,
      },

      '& .MuiInputBase-sizeSmall': {
        height: 38,
      },

      '.MuiInputBase-multiline': {
        height: 'auto',
      },

      backgroundColor: 'transparent',
      borderRadius: borderRadius[8],
      '& .Mui-error.MuiInputBase-root': {
        backgroundColor: alpha(error.main, 0.1),
      },
      '.MuiInputBase-root': {
        paddingRight: 0,
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: 14,
      },
      '& .MuiInputBase-adornedStart': {
        paddingLeft: 14,
      },
    },
  },
};

export default textField;
