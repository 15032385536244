import { Stack, styled } from '@mui/material';

export const StyledListWrapper = styled(Stack)(({ theme: { zIndex, palette, breakpoints, spacing } }) => ({
  position: 'sticky',
  top: 0,
  margin: 0,
  padding: spacing(1.5),
  gap: spacing(0.5),
  width: '100%',
  flexGrow: 0,
  alignSelf: 'flex-start',
  flexBasis: 'auto',
  zIndex: zIndex.fab,
  background: palette.background.default,
  [breakpoints.up('md')]: {
    flexBasis: 228,
    display: 'flex',
  },
})) as typeof Stack;
