import * as yup from 'yup';

import { LABELS } from 'constants/strings';
import { Shape } from 'types/RHF-types';

import { GAINED_WEIGHT_NAME, LOST_WEIGHT_NAME } from './components/Form';
import { QuestionnaireField } from './types';

export type QuestionnaireFormSchema = Partial<Omit<QuestionnaireField, 'medicalConditions' | 'allergies'>>;

export const questionnaireFormValidationSchema = yup.object().shape<Shape<QuestionnaireFormSchema>>({
  // Patient Information
  birthDate: yup.string().required().label(LABELS.DATE_OF_BIRTH),
  genderIndentity: yup.string().notRequired().label(LABELS.GENDER).nullable(),
  ethnicity: yup.string().notRequired().label(LABELS.ETHNICITY).nullable(),
  previousAestheticTreatments: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .nullable()
    .notRequired()
    .label('Previous Aesthetic Treatments'),
  skinType: yup.string().notRequired().label('Skin Type (Fitzpatrick Scale)'),
  sunExposureHistory: yup.string().notRequired().label('Sun Exposure History'),
  smokingStatus: yup.string().notRequired().label('Smoking Status'),

  // Patient Concerns and Goals
  primaryConcerns: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .nullable()
    .notRequired()
    .label('Primary Concerns'),
  secondaryConcerns: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .nullable()
    .label('Secondary Concerns'),
  desiredOutcome: yup.string().notRequired().label('Desired Outcome'),
  budgetConstraints: yup.string().notRequired().label('Budget Constraints'),

  // Lifestyle Factors
  ocupation: yup.string().notRequired().label(LABELS.OCCUPATION),
  facialExpressions: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .nullable()
    .notRequired()
    .label('Facial Expressions'),
  sleepHabits: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .nullable()
    .notRequired()
    .label('Sleep Habits'),

  recentWeightChanges: yup.string().notRequired().nullable().label('Recent Weight Changes'),
  weightChangeAmount: yup
    .number()
    .notRequired()
    .positive()
    .when('recentWeightChanges', {
      is: GAINED_WEIGHT_NAME,
      then: schema => schema.required().label('Weight Gained'),
      otherwise: schema => schema,
    })
    .when('recentWeightChanges', {
      is: LOST_WEIGHT_NAME,
      then: schema => schema.required().label('Weight Lost'),
      otherwise: schema => schema,
    })
    .label('Weight Change Amount')
    .transform(value => (Number.isNaN(value) ? null : value)),

  hormonalStatus: yup.string().notRequired().nullable().label('Hormonal Status'),

  // Physical Examination
  facialScanPerformed: yup.string().notRequired().label('3D Facial Scan Performed'),
  skinElasticity: yup.string().notRequired().label('Skin Elasticity'),
  facialMuscleStrength: yup.string().notRequired().label('Facial Muscle Strength'),
  facialMuscleAsymmetryDetails: yup.string().nullable().notRequired().label('Facial Muscle Asymmetry Details'),
  boneStructure: yup.string().nullable().notRequired().label('Bone Structure'),
  anatomicalNotes: yup.string().nullable().notRequired().label('Specific Anatomical Notes'),
  patientSatisfaction: yup.string().nullable().notRequired().label('Patient Satisfaction with Previous Treatments'),

  // Additional Information
  additionalInformation: yup.string().notRequired().label('Additional Information'),
});

export const defaultValues: Required<QuestionnaireFormSchema> = {
  // Patient Information
  birthDate: null,
  genderIndentity: '',
  ethnicity: '',
  previousAestheticTreatments: [],
  skinType: '',
  sunExposureHistory: '',
  smokingStatus: '',

  // Patient Concerns and Goals
  primaryConcerns: [],
  secondaryConcerns: [],
  desiredOutcome: '',
  budgetConstraints: '',

  // Lifestyle Factors
  ocupation: '',
  facialExpressions: [],
  otherFacialExpressions: '',
  sleepHabits: [],
  recentWeightChanges: '',
  weightChangeAmount: null,
  hormonalStatus: '',

  // Physical Examination
  facialScanPerformed: '',
  skinElasticity: '',
  facialMuscleStrength: '',
  facialMuscleAsymmetryDetails: '',
  boneStructure: '',
  anatomicalNotes: '',

  // Treatment History
  previousBotulinumToxinTreatments: '',
  botulinumToxinAreasTreated: '',
  botulinumToxinLastTreatmentDate: null,
  botulinumToxinProductUsed: '',
  botulinumToxinDosage: null,
  previousDermalFillerTreatments: '',
  dermalFillerAreasTreated: '',
  dermalFillerLastTreatmentDate: null,
  dermalFillerProductsUsed: '',
  dermalFillerVolumeInjected: null,
  patientSatisfaction: '',

  // Additional Information
  additionalInformation: '',
};

export const addTreatmentValidationSchema = yup.object().shape({
  areasTreated: yup.string().nullable().label('Areas Treated'),
  lastTreatmentDate: yup.string().nullable().label('Last Treatment Date'),
  productUsed: yup.string().nullable().label('Product Used'),
  dosage: yup
    .number()
    .notRequired()
    .nullable()
    .label('Dosage')
    .positive()
    .transform(value => (Number.isNaN(value) ? null : value)),
});

export type AddTreatmentValidationSchema = Partial<{
  areasTreated: string;
  lastTreatmentDate: string | null;
  productUsed: string;
  dosage: number | null;
}>;

export const defaultTreatmentValues: AddTreatmentValidationSchema = {
  areasTreated: '',
  lastTreatmentDate: null,
  productUsed: '',
  dosage: null,
};
