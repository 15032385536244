import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CardContent, CardHeader, Divider, FormControlLabel, Grid2, Stack, Switch } from '@mui/material';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { getPLService, updatePLService } from 'apiServices';
import { FormActions } from 'components/Form';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions, useRouter, useUserProfile } from 'hooks';
import { EditCatalogPLItemFormSchema, ProfileSkeleton, editCatalogPLItemValidationSchema } from 'pages/Catalog';
import { useAppStore } from 'store';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

export const PracticeLibraryServiceProfile: FC = () => {
  const { params } = useRouter();

  const { debouncedPracticeSearchValue, fetchPractices, onChangePracticeInputValueHandler, practicesState } =
    useOptions({});
  const {
    userRoles: { isRoleAdmin },
  } = useUserProfile();

  const serviceId = params?.id;

  const {
    userRoles: { isRolePracticeUser },
  } = useUserProfile();

  const { PLService, setPLService } = useAppStore(
    store => ({
      PLService: store.PLService,
      setPLService: store.setPLService,
    }),
    shallow,
  );

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { isDirty, isValid, isSubmitting, errors },
  } = useForm<EditCatalogPLItemFormSchema>({
    resolver: yupResolver(editCatalogPLItemValidationSchema) as Resolver<EditCatalogPLItemFormSchema>,
    mode: 'onTouched',
    values: {
      title: PLService?.title,
      description: PLService?.description || '',
      is_preferred: PLService?.is_preferred,
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ title, description, is_preferred }) => {
    try {
      const { data } = await updatePLService(serviceId, { title, is_preferred, ...(description && { description }) });

      notice(ToastType.SUCCESS, 'Service has been successfully edited!');

      setPLService(data);
    } catch (error) {
      formErrorHandler({ error, config: { formError: { setError } } });
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  const onGetPLServiceHandler = async () => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getPLService(serviceId);

      setPLService(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get service, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetPLServiceHandler();

    return () => {
      setPLService(null);
    };
  }, []);

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <Box component="section">
      <Stack component="form" onSubmit={onFormSubmitHandler}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <CardHeader
              title="Edit service"
              slotProps={{
                title: {
                  sx: {
                    fontSize: 20,
                    fontWeight: 700,
                  },
                },
              }}
              sx={{ pb: 0 }}
            />
            <Divider sx={{ my: 2 }} />

            <CardContent sx={{ pt: 0 }}>
              {isLoading ? (
                <ProfileSkeleton isSwitcher />
              ) : (
                <Stack spacing={2}>
                  <Box>
                    <Controller
                      name="is_preferred"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={<Switch disabled={isRolePracticeUser} checked={value} onChange={onChange} />}
                          label="Is preffered"
                          labelPlacement="start"
                        />
                      )}
                    />
                  </Box>

                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="title"
                    label="Name"
                    required
                    placeholder="Enter name"
                    disabled={isRolePracticeUser}
                  />

                  <RHFTextField
                    register={register}
                    registerErrors={errors?.description?.message}
                    registerName="description"
                    label="Description"
                    placeholder="Enter description"
                    multiline
                    minRows={5}
                    maxRows={8}
                    disabled={isRolePracticeUser}
                  />

                  {isRoleAdmin && (
                    <InfiniteScrollWrapper
                      searchQuery={debouncedPracticeSearchValue}
                      totalPages={practicesState.totalPages}
                      fetchItemsHandler={fetchPractices}
                    >
                      {({ ref }) => (
                        <RHFAutocompleteField
                          control={control}
                          registerName="practice_id"
                          isRequired
                          label="Practice"
                          placeholder="Select Practice"
                          options={practicesState.items}
                          onInputChange={onChangePracticeInputValueHandler}
                          loading={practicesState.isLoading}
                          valueKey="id"
                          labelKey="name"
                          lastElementRef={ref}
                        />
                      )}
                    </InfiniteScrollWrapper>
                  )}
                </Stack>
              )}
            </CardContent>
          </Grid2>

          {!isLoading && (
            <Grid2
              size={12}
              sx={{
                '.MuiPaper-root': {
                  boxShadow: 'none',
                  border: 'none',
                },
              }}
            >
              <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
            </Grid2>
          )}
        </Grid2>
      </Stack>
    </Box>
  );
};
