import { Badge, Tab, TabsProps } from '@mui/material';

import { StyledTabsWrapper, StyledTabsWrapperOwnerState } from './MDTabs.styles';

export type MDTabsProps = TabsProps & {
  tabsData: string[];
  bagesValues?: number[];
  handleSetTabValue: (event: React.SyntheticEvent<Element, Event>, newValue: number) => void;
} & StyledTabsWrapperOwnerState;

export const MDTabs: React.FC<MDTabsProps> = ({
  styleVariant,
  handleSetTabValue,
  bagesValues,
  tabsData,
  orientation,
  value,
  sx,
  ...other
}) => (
  <StyledTabsWrapper
    orientation={orientation}
    value={value}
    styleVariant={styleVariant}
    onChange={handleSetTabValue}
    sx={{
      '& .Mui-disabled': {
        display: 'none',
      },
      ...sx,
    }}
    {...other}
  >
    {tabsData?.map((el, index) => (
      <Tab
        key={el}
        sx={{ maxWidth: 'unset' }}
        label={
          <Badge
            badgeContent={bagesValues ? bagesValues[index] : 0}
            color="error"
            sx={{ '.MuiBadge-badge': { right: -6, top: -1 } }}
          >
            {el}
          </Badge>
        }
      />
    ))}
  </StyledTabsWrapper>
);
