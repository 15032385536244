import { memo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TableRow, TableCell, Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import {
  ContactPerson,
  createPracticeContactPerson,
  CreatePracticeContactPersonPayload,
  updatePracticeContactPerson,
} from 'apiServices';
import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField } from 'components/HookForm';
import { MDButton } from 'components/MDButton';
import { notice, ToastType } from 'components/ToastNotification';
import { LABELS } from 'constants/strings';
import { PatientEnumsInitialState } from 'hooks';
import { BaseEnum } from 'types';
import { formErrorHandler } from 'utils/errorHanders';

import { CONTACT_PERSON_DEFAULT_VALUES, ContactPersonFormSchema, contactPersonValidationSchema } from './validation';

type AddEditContactTableRowProps = {
  contact: ContactPerson | null;
  practiceId: string;
  onClickCancleButtonHandler: () => void;
  fetchPracticeProfile: () => Promise<void>;
  patientPersonTitlesEnumsState: PatientEnumsInitialState<BaseEnum>;
};

export const AddEditContactTableRow: React.FC<AddEditContactTableRowProps> = memo(
  ({ patientPersonTitlesEnumsState, contact, practiceId, fetchPracticeProfile, onClickCancleButtonHandler }) => {
    const {
      register,
      control,
      formState: { isDirty, isSubmitting, isValid, errors },
      handleSubmit,
      setError,
    } = useForm<ContactPersonFormSchema>({
      resolver: yupResolver(contactPersonValidationSchema) as Resolver<ContactPersonFormSchema>,
      mode: 'onTouched',
      values: contact,
      defaultValues: CONTACT_PERSON_DEFAULT_VALUES,
    });

    const onFormSubmitHandler = handleSubmit(
      async ({ first_name, last_name, email, middle_name, phone, position, title }) => {
        try {
          const payload: CreatePracticeContactPersonPayload = {
            first_name,
            last_name,
            ...(email && { email }),
            ...(middle_name && { middle_name }),
            ...(phone && { phone: `+${phone}` }),
            ...(position && { position }),
            ...(title && { title }),
          };
          if (contact) {
            await updatePracticeContactPerson(practiceId, contact?.id, payload);
          } else {
            await createPracticeContactPerson(practiceId, payload);
          }

          await fetchPracticeProfile();

          onClickCancleButtonHandler();

          notice(ToastType.SUCCESS, `Contact address has been successfully ${contact ? 'updated' : 'created'}`);
        } catch (error) {
          formErrorHandler({
            error,
            customErrorMessage: contact
              ? 'Failed to update contact address, please try again!'
              : `Failed to ${contact ? 'update' : 'add'} contact address, please try again!`,
            config: { formError: { setError } },
          });
        }
      },
    );

    const isSaveButtonDisabled = contact ? !isDirty || isSubmitting || !isValid : isSubmitting || !isValid;

    return (
      <TableRow>
        <TableCell colSpan={8} sx={{ p: '0 !important' }}>
          <Stack component="form" onSubmit={onFormSubmitHandler} alignItems="flex-start" gap={2} width={1}>
            <Stack width={1} gap={2} p={2} pb={0}>
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" gap={2} width={1}>
                <RHFTextField
                  fullWidth
                  register={register}
                  registerErrors={errors.first_name?.message}
                  registerName="first_name"
                  label={LABELS.FIRST_NAME}
                  placeholder="Enter First Name"
                  required
                />

                <RHFTextField
                  fullWidth
                  register={register}
                  registerErrors={errors.middle_name?.message}
                  registerName="middle_name"
                  label={LABELS.MIDDLE_NAME}
                  placeholder="Enter Middle Name"
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" gap={2} width={1}>
                <RHFTextField
                  fullWidth
                  register={register}
                  registerErrors={errors.last_name?.message}
                  registerName="last_name"
                  label={LABELS.LAST_NAME}
                  placeholder="Enter Last Name"
                  required
                />

                <RHFTextField
                  fullWidth
                  register={register}
                  registerErrors={errors.position?.message}
                  registerName="position"
                  label={LABELS.POSITION}
                  placeholder="Enter Position"
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" gap={2} width={1}>
                <RHFTextField
                  fullWidth
                  register={register}
                  registerName="email"
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                  label={LABELS.EMAIL}
                  placeholder="Enter Email"
                />

                <RHFPhoneTextField
                  control={control}
                  registerErrors={errors}
                  registerName="phone"
                  MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
                />
              </Stack>

              <Stack width={{ xs: 1, sm: 'calc(50% - 8px)' }}>
                <RHFAutocompleteField
                  control={control}
                  registerName="title"
                  label={LABELS.TITLE}
                  placeholder="Select Title"
                  options={patientPersonTitlesEnumsState.items}
                  loading={patientPersonTitlesEnumsState.isLoading}
                  valueKey="value"
                  labelKey="value"
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              gap={1.5}
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
              p={1.5}
              width={1}
              borderTop={theme => `${theme.borders.borderWidth[1]} solid ${theme.palette.divider}`}
            >
              <MDButton variant="secondary" disabled={isSubmitting} onClick={onClickCancleButtonHandler}>
                Cancel
              </MDButton>
              <MDButton type="submit" isLoading={isSubmitting} disabled={isSaveButtonDisabled}>
                Save
              </MDButton>
            </Stack>
          </Stack>
        </TableCell>
      </TableRow>
    );
  },
);
