import { BASE_PRACTICE_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import {
  GetPracticeContactPersonsParams,
  ContactPerson,
  CreatePracticeContactPersonPayload,
  UpdatePracticeContactPersonPayload,
} from './types';

export const getPracticeContactPersons = async (practiceId: string, params: GetPracticeContactPersonsParams) =>
  await apiClient.get<ListOfItems<ContactPerson>>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, { params });

export const createPracticeContactPerson = async (practiceId: string, payload: CreatePracticeContactPersonPayload) =>
  await apiClient.post<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons`, payload);

export const getPracticeContactPerson = async (practiceId: string, personId: string) =>
  await apiClient.get<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);

export const deletePracticeContactPerson = async (practiceId: string, personId: string): Promise<void> => {
  await apiClient.delete(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`);
};

export const updatePracticeContactPerson = async (
  practiceId: string,
  personId: string,
  payload: UpdatePracticeContactPersonPayload,
) =>
  await apiClient.patch<ContactPerson>(`${BASE_PRACTICE_API_URL}/${practiceId}/contact_persons/${personId}`, payload);
