import { memo, useEffect, useState } from 'react';

import { Dialog } from '@mui/material';
import { Area } from 'react-easy-crop';

import { GLbeforeAfterAlbumPair, PLbeforeAfterAlbumPair } from 'apiServices';
import { ZoomCropDialogWindow } from 'components/BaseDialogWindow';
import getCroppedImg from 'components/UploadAvatar/helpers';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';
import { useFilePicker } from 'hooks';
import { NullableField } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';
import { generateImageFormData } from 'utils/helpers/utilities';

import { AddBeforeAfterImagesForm } from './AddBeforeAfterImagesForm';
import { AddAlbumImagesDialogWindowProps } from './types';

export const AddBeforeAfterImagesDialogWindow = memo(
  <T extends GLbeforeAfterAlbumPair | PLbeforeAfterAlbumPair>({
    isOpenAddAllbumDialogWindow,
    closeAddAlbumDialogWindow,
    onFetchAlbumsHandler,
    createBAimagePairAPIrequest,
    uploadImageAPIrequest,
    isRoleAdmin,
    albumId,
  }: AddAlbumImagesDialogWindowProps<T>) => {
    const [croppedImageSrc, setCroppedImageSrc] = useState<NullableField>(null);
    const [croppedAfterImageSrc, setCroppedAfterImageSrc] = useState<NullableField>(null);

    const onSaveCroppedFileHandler = async ({
      setSrc,
      closeCropFileDialog,
      croppedAreaPixels,
      previewURL,
    }: {
      setSrc: React.Dispatch<React.SetStateAction<string>>;
      croppedAreaPixels: Area;
      closeCropFileDialog: () => void;
      previewURL: string;
    }) => {
      const croppedImageSrc = await getCroppedImg({ imageSrc: previewURL, pixelCrop: croppedAreaPixels });
      setSrc(croppedImageSrc);
      closeCropFileDialog();
    };

    const onClickSaveFileHandler = () =>
      onSaveCroppedFileHandler({ setSrc: setCroppedImageSrc, closeCropFileDialog, croppedAreaPixels, previewURL });

    const onClickSaveAfterFileHandler = () =>
      onSaveCroppedFileHandler({
        setSrc: setCroppedAfterImageSrc,
        closeCropFileDialog: closeAfterCropFileDialog,
        croppedAreaPixels: croppedAfterAreaPixels,
        previewURL: afterPreviewURL,
      });

    const removeFile = ({
      setSrc,
      removeFileHandler,
    }: {
      setSrc: React.Dispatch<React.SetStateAction<string>>;
      removeFileHandler: () => void;
    }) => {
      setSrc(null);
      removeFileHandler();
    };

    const onClickRemoveFileHandler = () =>
      removeFile({ setSrc: setCroppedImageSrc, removeFileHandler: onRemoveFileHandler });

    const onClickRemoveAfterFileHandler = () =>
      removeFile({ setSrc: setCroppedAfterImageSrc, removeFileHandler: onRemoveAfterFileHandler });

    const onCreateBeforeImageHandler = async (pairId: string) => {
      try {
        const formData = generateImageFormData({ file, croppedAreaPixels, formDataImageFileName: 'image_file' });
        await uploadImageAPIrequest(pairId, 'left', formData);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to upload before image!' } });
      }
    };

    const onCreateAfterImageHandler = async (pairId: string) => {
      try {
        const formData = generateImageFormData({ file, croppedAreaPixels, formDataImageFileName: 'image_file' });
        await uploadImageAPIrequest(pairId, 'right', formData);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to upload after image!' } });
      }
    };

    const {
      openCropFileDialog,
      file,
      onRemoveFileHandler,
      onUploadDropzoneFileHandler,
      isCropFileDialogOpen,
      onFileInputChangeHandler,
      previewURL,
      closeCropFileDialog,
      onCropCompleteHandler,
      croppedAreaPixels,
    } = useFilePicker({
      accept: ACCEPT_INPUT_IMAGE_FILES,
    });

    const {
      onRemoveFileHandler: onRemoveAfterFileHandler,
      onUploadDropzoneFileHandler: onUploadDropzoneAfterFileHandler,
      onFileInputChangeHandler: onUploadAnotherAfterFileHandler,
      previewURL: afterPreviewURL,
      file: afterFile,
      isCropFileDialogOpen: isCropAfterFileDialogOpen,
      openCropFileDialog: openCropAfterFileDialog,
      closeCropFileDialog: closeAfterCropFileDialog,
      onCropCompleteHandler: onCropAfterCompleteHandler,
      croppedAreaPixels: croppedAfterAreaPixels,
    } = useFilePicker({ accept: ACCEPT_INPUT_IMAGE_FILES });

    useEffect(() => {
      if (file) openCropFileDialog();
    }, [file]);

    useEffect(() => {
      if (afterFile) openCropAfterFileDialog();
    }, [afterFile]);

    const isZoomCropFileDialogOpen = isCropFileDialogOpen || isCropAfterFileDialogOpen;

    const onClickCloseDialogHandler = () => {
      closeAddAlbumDialogWindow();
      onClickRemoveFileHandler();
      onClickRemoveAfterFileHandler();
    };

    return (
      <>
        <Dialog
          open={isOpenAddAllbumDialogWindow}
          onClose={onClickCloseDialogHandler}
          PaperProps={{
            sx: { width: isZoomCropFileDialogOpen ? 0 : 1, maxWidth: 750 },
          }}
          hideBackdrop={isZoomCropFileDialogOpen}
        >
          <AddBeforeAfterImagesForm
            closeAddAlbumDialogWindow={onClickCloseDialogHandler}
            onFetchAlbumsHandler={onFetchAlbumsHandler}
            afterImageData={{
              onSelectFileHandler: onUploadDropzoneAfterFileHandler,
              previewURL: croppedAfterImageSrc,
              onRemoveFileHandler: onClickRemoveAfterFileHandler,
              onSelectAnotherFileHandler: onUploadAnotherAfterFileHandler,
            }}
            beforeImageData={{
              onSelectFileHandler: onUploadDropzoneFileHandler,
              previewURL: croppedImageSrc,
              onRemoveFileHandler: onClickRemoveFileHandler,
              onSelectAnotherFileHandler: onFileInputChangeHandler,
            }}
            createBAimagePairAPIrequest={createBAimagePairAPIrequest}
            onCreateAfterImageHandler={onCreateAfterImageHandler}
            onCreateBeforeImageHandler={onCreateBeforeImageHandler}
            isRoleAdmin={isRoleAdmin}
            albumId={albumId}
          />
        </Dialog>

        {isCropFileDialogOpen && (
          <ZoomCropDialogWindow
            imageURL={previewURL}
            open={isCropFileDialogOpen}
            onClickCloseButtonHandler={closeCropFileDialog}
            onUploadNewFileHandler={onFileInputChangeHandler}
            onSaveFileHandler={onClickSaveFileHandler}
            onCropCompleteHandler={onCropCompleteHandler}
            cropShape="rect"
          />
        )}

        {isCropAfterFileDialogOpen && (
          <ZoomCropDialogWindow
            imageURL={afterPreviewURL}
            open={isCropAfterFileDialogOpen}
            onClickCloseButtonHandler={closeAfterCropFileDialog}
            onUploadNewFileHandler={onUploadAnotherAfterFileHandler}
            onSaveFileHandler={onClickSaveAfterFileHandler}
            onCropCompleteHandler={onCropAfterCompleteHandler}
            cropShape="rect"
          />
        )}
      </>
    );
  },
);
