import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const { size } = typography;
const { text } = palette;

const dialogContentText = {
  styleOverrides: {
    root: {
      fontSize: size.bodyLarge,
      color: text.primary,
    },
  },
};

export default dialogContentText;
