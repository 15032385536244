import { DialogActions, Divider, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

import { DialogFooterProps } from './types';

export const DialogFooter: React.FC<DialogFooterProps> = ({
  onClickCloseButtonHandler,
  onClickApplyButtonHandler,
  onResetSelectedItems,
}) => {
  return (
    <>
      <Divider />
      <DialogActions
        sx={theme => ({
          justifyContent: 'space-between',
          flexDirection: 'column',
          gap: 1,
          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
          },
        })}
      >
        <MDButton
          variant="secondary"
          startIcon={
            <Icon
              type="arrowLeftIcon"
              sx={{
                svg: {
                  width: 18,
                  height: 18,
                },
                path: {
                  strokeWidth: 1.5,
                },
              }}
            />
          }
          onClick={onClickCloseButtonHandler}
        >
          Back
        </MDButton>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 1, sm: 1.5 }}>
          <MDButton
            variant="ghost"
            startIcon={
              <Icon
                type="refreshIcon"
                sx={{
                  svg: {
                    width: 18,
                    height: 18,
                  },
                }}
              />
            }
            onClick={onResetSelectedItems}
          >
            Clear Selection
          </MDButton>
          <MDButton
            startIcon={
              <Icon
                type="checkmarkIcon"
                sx={{
                  svg: {
                    width: 18,
                    height: 18,
                  },
                }}
              />
            }
            onClick={onClickApplyButtonHandler}
          >
            Done
          </MDButton>
        </Stack>
      </DialogActions>
    </>
  );
};
