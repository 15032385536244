import { useCallback } from 'react';

import { shallow } from 'zustand/shallow';

import { useAppStore } from 'store';

export const usePatientStore = () => {
  const { patient, fetchPatient, setPatient } = useAppStore(
    store => ({
      patient: store.patient,
      fetchPatient: store.fetchPatient,
      setPatient: store.setPatient,
    }),
    shallow,
  );

  const memoizedFetchPatient = useCallback(fetchPatient, []);

  return { patient, setPatient, fetchPatient: memoizedFetchPatient };
};
