import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import { palette } from '../base/palette';

const { background, text } = palette;

const slider: {
  defaultProps?: ComponentsProps['MuiSlider'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiSlider'];
  variants?: ComponentsVariants<Theme>['MuiSlider'];
} = {
  defaultProps: {
    valueLabelDisplay: 'auto',
  },
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: text.disabled,
      },
    },
    rail: {
      opacity: 0.32,
      backgroundColor: background.surfaceStrong,
    },
    markLabel: {
      fontSize: 13,
      color: text.disabled,
    },
    valueLabel: {
      borderRadius: 6,
      zIndex: 10,
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
      fontWeight: 600,
      '&:before': {
        borderBottomRightRadius: 2,
      },
    },
    thumb: {
      width: 12,
      height: 12,
      border: `2px solid`,
      boxShadow: 'none',
      backgroundColor: background.default,
      '&.Mui-focusVisible': {
        boxShadow: 'none',
      },

      '&.Mui-active': {
        boxShadow: '0px 0px 0px 10px rgba(37, 112, 235, 0.16)',
      },
      '&:after': {
        width: 12,
        height: 12,
      },
    },
  },
};

export default slider;
