import { FC } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

import { MenuPopover } from './MenuPopover';
import { PopoverWrapperProps } from './types';

export const PopoverWrapper: FC<PopoverWrapperProps> = ({
  title,
  titleDescription,
  variant = 'form',
  sx,
  handleClosePopover,
  children,
  ...other
}) => {
  return (
    <MenuPopover
      paperSx={{
        width: 1,
        maxWidth: variant === 'filter' ? 290 : 'auto',
        mt: 0.5,
        px: 2,
        py: variant === 'filter' ? 1 : 0,
      }}
      disabledArrow
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={sx}
      {...other}
      onClose={handleClosePopover}
    >
      <Stack py={variant === 'form' ? 1.5 : 0}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
          <Typography variant="h6" whiteSpace="initial" fontSize="size.bodyLarge" fontWeight="fontWeightBold">
            {title}
          </Typography>
          <MDButton onClick={handleClosePopover} variant="tertiary" size="small" isIconOnly>
            <Icon
              type="closeIcon"
              sx={{
                svg: {
                  width: 12,
                  height: 12,
                },
              }}
            />
          </MDButton>
        </Stack>

        {titleDescription && (
          <Typography
            variant="body2"
            color="text"
            fontWeight="fontWeightMedium"
            sx={{
              wordBreak: 'break-all',
              whiteSpace: 'initial',
            }}
          >
            {titleDescription}
          </Typography>
        )}
      </Stack>
      <Divider
        sx={{
          mb: 1,
          ...(variant === 'filter' && {
            my: 0.5,
          }),
        }}
      />
      {children}
    </MenuPopover>
  );
};
