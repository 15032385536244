import { BoxProps } from '@mui/material';

import { MainLayoutStyles as Styled } from './MainLayout.styles';

export const ContentWrapper: React.FC<BoxProps> = props => {
  const { children, sx, ...other } = props;

  return (
    <Styled.ContentWrapper component="main" sx={sx} {...other}>
      {children}
    </Styled.ContentWrapper>
  );
};
