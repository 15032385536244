import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import root from './root';
import { palette } from '../../base/palette';

const button: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants<Theme>['MuiButton'];
} = {
  defaultProps: { variant: 'contained', disableElevation: true },
  styleOverrides: {
    root,
  },
  variants: [
    {
      props: { variant: 'tertiary' },
      style: {
        backgroundColor: palette.background.surfaceSoft,
        ':hover': {
          backgroundColor: palette.background.surfaceMedium,
        },
      },
    },
    {
      props: { variant: 'secondary' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        border: `${theme.borders.borderWidth[1]} solid ${palette.secondary.dark}`,
        ':hover': {
          backgroundColor: theme.palette.background.surfaceMedium,
          borderColor: theme.palette.text.primary,
        },
      }),
    },
    {
      props: { variant: 'ghost' },
      style: {
        backgroundColor: 'transparent',
        ':hover': {
          backgroundColor: palette.background.surfaceMedium,
        },
      },
    },
  ],
};

export default button;
