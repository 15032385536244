import { memo, useMemo, useState } from 'react';

import { Checkbox, Collapse, List, ListItemButton, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';

import { ContentItem } from './ContentItem';
import { lazyImageStyles, listItemButtonStyles } from './styles';
import { AccordionContentItemProps } from './types';

export const AccordionContentItem: React.FC<AccordionContentItemProps> = memo(
  ({ onCheckAllItemsHandler, checkedIds, id, title, onCheckItemHandler, previewURL, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const isIndeterminate = useMemo(() => content.some(item => checkedIds.has(item.id)), [checkedIds, content]);

    const onChangeAccordionHandler = (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      setIsExpanded(prevState => !prevState);
    };

    const contentIds = useMemo(() => content.map(item => item.id), [content]);

    const onChangeCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.stopPropagation();
      onCheckAllItemsHandler(checked, contentIds);
    };

    const onClickCheckboxHandler = (e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation();

    return (
      <ListItemButton
        onClick={onChangeAccordionHandler}
        component="li"
        disableGutters
        sx={{
          py: 0,
          color: 'text.secondary',
          flexDirection: 'column',
          ...listItemButtonStyles(isIndeterminate),
        }}
      >
        <Stack
          width={1}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          gap={1}
          px={{ xs: 1, sm: 2 }}
          py={1.5}
        >
          <Stack direction="row" gap={1.5} alignItems="center">
            <Checkbox
              id={id}
              checked={isIndeterminate}
              onChange={onChangeCheckboxHandler}
              onClick={onClickCheckboxHandler}
            />

            <LazyImage src={previewURL} alt="content preview image" imageContainerSX={lazyImageStyles} />
            <Typography variant="body2" fontWeight={500}>
              {title}
            </Typography>
          </Stack>

          <Stack direction="row" gap={1.5} alignItems="center">
            <Typography variant="body2" fontWeight={500}>
              {`${content.length} item(s)`}
            </Typography>
            <Icon
              type="beforeAfterGroupedWorkflowFilledIcon"
              sx={{
                color: 'primary.main',
                svg: {
                  width: 24,
                  height: 24,
                },
              }}
            />
            <Icon
              type="arrowLeftIcon"
              sx={{
                svg: {
                  width: 18,
                  height: 18,
                  transition: ({ transitions }) =>
                    transitions.create('all', {
                      duration: transitions.duration.shortest,
                    }),
                  rotate: isExpanded ? '90deg' : '-90deg',
                },
                path: {
                  strokeWidth: 1.5,
                },
              }}
            />
          </Stack>
        </Stack>

        <Collapse
          in={isExpanded}
          unmountOnExit
          sx={{
            width: 1,
          }}
        >
          <List
            sx={{
              width: 1,
              '& li': {
                px: 4,
              },
            }}
          >
            {content?.map(item => (
              <ContentItem
                key={item.id}
                onCheckItemHandler={onCheckItemHandler}
                id={item.id}
                title={item.title}
                previewURL={item.previewURL}
                isChecked={checkedIds.has(item.id)}
                type={item.type}
                isNestedItem
              />
            ))}
          </List>
        </Collapse>
      </ListItemButton>
    );
  },
);
