import Grid2 from '@mui/material/Grid2';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';

import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';
import {
  BUDGET_CONSTRAINTS_OPTIONS,
  DESIRED_OUTCOME_OPTIONS,
  PRIMARY_CONCERNS_OPTIONS,
  SECONDARY_CONCERNS_OPTIONS,
} from '../../options';

export const PatientConcernsAndGoals: React.FC = () => {
  const { control } = useFormContext<QuestionnaireFormSchema>();

  return (
    <FormSectionWrapper>
      <Grid2 spacing={2} container>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            options={PRIMARY_CONCERNS_OPTIONS}
            label="Primary Concerns"
            control={control}
            multiple
            registerName="primaryConcerns"
            placeholder="Select Primary Concerns"
            valueKey="value"
            labelKey="value"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Secondary Concerns"
            control={control}
            multiple
            registerName="secondaryConcerns"
            placeholder="Select Secondary Concerns"
            options={SECONDARY_CONCERNS_OPTIONS}
            valueKey="value"
            labelKey="value"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Desired Outcome"
            control={control}
            registerName="desiredOutcome"
            placeholder="Select Desired Outcome"
            options={DESIRED_OUTCOME_OPTIONS}
            valueKey="value"
            labelKey="value"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Budget Constraints"
            control={control}
            registerName="budgetConstraints"
            placeholder="Select Budget Constraints"
            options={BUDGET_CONSTRAINTS_OPTIONS}
            valueKey="value"
            labelKey="value"
          />
        </Grid2>
      </Grid2>
    </FormSectionWrapper>
  );
};
