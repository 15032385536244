import { FC, memo } from 'react';

import { IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { getActivateMenuItem, getEditMenuItem } from 'components/MenuPopover';
import { ThreeDotsTableCell } from 'components/Table';
import { ActionDataInitialState, usePopover, useRouter } from 'hooks';
import { LibraryType } from 'types';

type ProductsServicesListTableRowProps = {
  isThreeDotsButton?: boolean;
  onClickActivationOptionHandler: (activationStateData: ActionDataInitialState) => void;
  title: string;
  isActive: boolean;
  id: string;
  practiceId?: string;
  practiceName?: string;
  description?: string | null;
  library: LibraryType;
  type: 'service' | 'product';
  isViewOnly: boolean;
};

export const ProductsServicesListTableRow: FC<ProductsServicesListTableRowProps> = memo(
  ({
    description,
    id,
    onClickActivationOptionHandler,
    isActive,
    title,
    isThreeDotsButton,
    library,
    type,
    isViewOnly,
    practiceName,
  }) => {
    const path = `${library}-${type}`;

    const { navigate } = useRouter();

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name: title,
        actionType: 'delete',
      });
    };

    const onClickTableRowHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      navigate(`/catalog/${path}/${id}`);
    };

    // TODO after BE implementation
    // const onClickTagItemHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    //   event.stopPropagation();
    //   navigate(`/catalog/${path}?tag=${event.currentTarget.id}`);
    // };

    const onClickCopyButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onClickActivationOptionHandler({
        id,
        name: title,
        actionType: 'copy',
      });
    };

    const onClickActivationButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name: title,
        actionType: isActive ? 'deactivate' : 'activate',
      });
    };

    const renderOptions = () => {
      if (isViewOnly) return null;

      if (!isThreeDotsButton)
        return (
          <TableCell sx={{ textAlign: 'end' }}>
            <IconButton onClick={onClickCopyButtonHandler}>
              <Icon type="copy" sx={{ svg: { width: 16, height: 16 }, color: theme => theme.palette.grey[700] }} />
            </IconButton>
          </TableCell>
        );

      return (
        <ThreeDotsTableCell
          open={openPopover}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          onClose={onCloseThreeDotsMenuHandler}
          onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
          tableCellSX={{ textAlign: 'end' }}
          menuItems={[
            getEditMenuItem(onClickTableRowHandler),
            getActivateMenuItem(isActive, onClickActivationButtonHandler),
          ]}
        />
      );
    };

    return (
      <TableRow
        onClick={onClickTableRowHandler}
        sx={{
          ...(!isActive && { opacity: '40%' }),
        }}
      >
        <TableCell>
          <Typography variant="body2">{title}</Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" noWrap maxWidth={500}>
            {description}
          </Typography>
        </TableCell>

        {practiceName && (
          <TableCell>
            <Typography variant="body2" noWrap maxWidth={500}>
              {practiceName}
            </Typography>
          </TableCell>
        )}

        {/* TODO after BE implementation */}
        {/* <TableCell>
          <HoverMenuPopover
            renderItem={bindedState => (
              <Box {...bindedState}>
                <Chip variant="soft" color="primary" label={'13 tags'} />
              </Box>
            )}
          >
            <Stack direction="row" component="ul" flexWrap="wrap" ml={-1} alignItems="center" maxWidth={203} p={1}>
              {MOCKED_TAGS.map((el, index) => {
                return (
                  <Stack
                    sx={{ ':hover': { cursor: 'pointer' } }}
                    id={el}
                    onClick={onClickTagItemHandler}
                    component="li"
                    pl={1}
                    pb={1}
                    key={el}
                  >
                    <Tag size="small" label={el} itemIndex={index} />
                  </Stack>
                );
              })}
            </Stack>
          </HoverMenuPopover>
        </TableCell> */}

        {/* TODO after BE implementation */}
        {/* <TableCell>
          <LinkedContentStatistic />
        </TableCell> */}

        {renderOptions()}
      </TableRow>
    );
  },
);
