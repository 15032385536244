import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { BUTTON_HEIGHT } from 'styles/theme/components/button/root';

import { ButtonOwnerState } from './types';

export const ButtonRoot = styled(Button, { shouldForwardProp: propName => propName !== 'ownerState' })<{
  ownerState: ButtonOwnerState;
}>(({ theme, ownerState, size }) => {
  const { borders } = theme;
  const { isCircular, isIconOnly } = ownerState;

  const { borderRadius } = borders;

  const circularStyles = () => ({
    borderRadius: borderRadius.full,
  });

  const iconOnlyStyles = () => {
    return {
      padding: 10,
      width: BUTTON_HEIGHT[size],
      flexShrink: 0,

      '& .material-icons': {
        marginTop: 0,
      },

      '& svg': {
        width: 14,
        height: 14,
      },

      '&:hover, &:focus, &:active': {
        transform: 'none',
      },
    };
  };

  return {
    ...(isCircular && circularStyles()),
    ...(isIconOnly && iconOnlyStyles()),
  };
});
