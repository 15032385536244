import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import { palette } from 'styles/theme/base/palette';

const formHelperText: {
  defaultProps?: ComponentsProps['MuiFormHelperText'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiFormHelperText'];
  variants?: ComponentsVariants<Theme>['MuiFormHelperText'];
} = {
  defaultProps: { component: 'div' },
  styleOverrides: {
    root: {
      whiteSpace: 'initial',
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 0,
      backgroundSize: 12,
      marginTop: 0,
      '&.Mui-error': {
        color: palette.error.main,
      },
    },
  },
};

export default formHelperText;
