import { Table, TableBody } from '@mui/material';

import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';
import { WorkflowTableRow } from 'pages/Workflows/components';
import { WORKFLOW_TABLE_HEADERS } from 'pages/Workflows/config';

import { useGlobalLibraryWorkflowListState } from './useGlobalLibraryWorkflowList.state';

export const GlobalLibraryWorkflowList: React.FC = () => {
  const {
    items,
    rowsPerPage,
    page,
    total,
    onClickAddWorkflowButtonHandler,
    onClickActionOptionHandler,
    onChangePage,
    onChangeRowsPerPage,
  } = useGlobalLibraryWorkflowListState();

  return (
    <PageWrapper>
      <TableToolbar
        searchInputProps={{
          placeholder: 'Type to search',
          onChange: () => {},
        }}
        onOpenFilterMenuHandler={() => {}}
        actionButtonProps={{
          buttonTitle: 'Add Workflow',
          onClick: onClickAddWorkflowButtonHandler,
        }}
      />

      <CustomTableContainer>
        <Table size="medium" sx={{ minWidth: 600 }}>
          <CustomTableHeader headLabel={WORKFLOW_TABLE_HEADERS} />

          <TableBody>
            {false ? (
              <TableSkeleton cellsAmount={WORKFLOW_TABLE_HEADERS.length} />
            ) : (
              items?.map(el => (
                <WorkflowTableRow
                  key={el.id}
                  name={el.name}
                  id={el.id}
                  isPublished={el.isPublished}
                  isActive={el.isActive}
                  onClickActivationOptionHandler={onClickActionOptionHandler}
                  phase={el.phase}
                  libraryType="gl"
                />
              ))
            )}
            <TableNoData isNotFound={!items.length} />
          </TableBody>
        </Table>
      </CustomTableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </PageWrapper>
  );
};
