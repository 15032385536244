import * as yup from 'yup';

import { AIModelPostRequest } from 'apiServices/ml/types';

export const AddAIModelValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required').max(100, 'Name must be at most 100 characters'),
  parameters: yup
    .array(
      yup.object().shape({
        param_name: yup.string().required('Parameter name is required'),
        param_type: yup
          .string()
          .oneOf(['string', 'float', 'int'], 'Invalid parameter type')
          .required('Parameter type is required'),
      }),
    )
    .optional(),
});

export const defaultAIModelValues: AIModelPostRequest = {
  name: 'AI Model Name',
  parameters: [
    {
      param_name: 'Parameter name',
      param_type: 'string',
    },
  ],
};
