import * as yup from 'yup';

import { CreatePracticeContactPersonPayload } from 'apiServices';
import { LABELS } from 'constants/strings';
import { EmailLazyValidationSchema, PhoneValidationSchema } from 'utils/formValidation';

export type ContactPersonFormSchema = Omit<CreatePracticeContactPersonPayload, 'id'>;

export const contactPersonValidationSchema: yup.ObjectSchema<ContactPersonFormSchema> = yup
  .object<ContactPersonFormSchema>()
  .shape({
    first_name: yup.string().required().label(LABELS.FIRST_NAME),
    middle_name: yup.string().notRequired().label(LABELS.MIDDLE_NAME),
    last_name: yup.string().required().label(LABELS.LAST_NAME),
    position: yup.string().notRequired().label(LABELS.POSITION),
    title: yup.string().notRequired().label(LABELS.TITLE),
    email: EmailLazyValidationSchema,
    phone: PhoneValidationSchema,
  })
  .required();

export const CONTACT_PERSON_DEFAULT_VALUES: ContactPersonFormSchema = {
  first_name: '',
  last_name: '',
  title: '',
  position: '',
  middle_name: '',
  email: '',
  phone: '',
};
