import { FieldValues } from 'react-hook-form';

import { UserRolesEnum } from 'apiServices';
import { UserRoleRenderOption } from 'components/MDAutocomplete';
import { UserRoleLabels } from 'constants/roles';

import { RHFAutocompleteField } from './RHFAutocomplete';
import { RHFAutocompleteFieldProps } from './types';

type RHFUserRoleAutocompleteProps<
  TField extends FieldValues,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  RHFAutocompleteFieldProps<TField, UserRolesEnum, Multiple, DisableClearable, FreeSolo>,
  'renderOption' | 'register' | 'registerOptions' | 'registerErrors' | 'valueKey'
> & {
  valueKey?: keyof UserRolesEnum;
};

export const RHFUserRoleAutocomplete = <
  TField extends FieldValues,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  control,
  registerName,
  loading,
  options,
  freeSolo = false as FreeSolo,
  valueKey = 'value',
  getOptionLabel,
  ...otherProps
}: RHFUserRoleAutocompleteProps<TField, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <RHFAutocompleteField
      control={control}
      registerName={registerName}
      isRequired
      label="Role"
      placeholder="Select Role"
      options={options}
      loading={loading}
      renderOption={UserRoleRenderOption}
      freeSolo={freeSolo}
      getOptionLabel={getOptionLabel ?? (option => UserRoleLabels[(option as UserRolesEnum).value] || '')}
      valueKey={valueKey}
      {...otherProps}
    />
  );
};
