import * as yup from 'yup';

import { emailRegExp, webSiteURLRegExp } from 'constants/regex';
import { LABELS } from 'constants/strings';

export const EmailValidationSchema = yup
  .string()
  .required()
  .email('Email must be a valid email address')
  .matches(emailRegExp, 'Please enter a valid email')
  .label(LABELS.EMAIL);

export const EmailLazyValidationSchema = yup.lazy((value: string) => {
  return value?.length ? EmailValidationSchema : yup.string().optional().label(LABELS.EMAIL).nullable();
});

export const WebsiteLazyValidationSchema = yup.lazy((value: string) => {
  return value?.length
    ? yup
        .string()
        .required()
        .matches(webSiteURLRegExp, 'Please enter a valid website url. For example: https://example.com')
        .label(LABELS.WEBSITE)
    : yup.string().optional().label(LABELS.WEBSITE).nullable();
});

export const PhoneValidationSchema = yup.string().optional().label('Phone number').trim().nullable();

export type TOT_MFA_verification_code_schema = {
  TOTPverificationCode: string;
};

export const TOTP_MFA_verification_code_validation_schema: yup.ObjectSchema<TOT_MFA_verification_code_schema> = yup
  .object({
    TOTPverificationCode: yup.string().required().label('TOTP verification code').min(6).max(6),
  })
  .required();

export const PracticeIdValidationSchema = yup
  .string()
  .label(LABELS.PRACTICE)
  .nullable()
  .when('$isPracticeIdRequired', {
    is: true,
    then: schema => schema.required(),
    otherwise: schema => schema.optional(),
  });
