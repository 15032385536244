import { useEffect } from 'react';

import Grid2 from '@mui/material/Grid2';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { LABELS } from 'constants/strings';

import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';
import {
  FACIAL_EXPRESSIONS_OPTIONS,
  HORMONAL_STATUS_OPTIONS,
  RECENT_WEIGHT_CHANGES_OPTIONS,
  SLEEP_HABITS_OPTIONS,
} from '../../options';

export const NO_CHANGE_NAME = RECENT_WEIGHT_CHANGES_OPTIONS[0].value;
export const LOST_WEIGHT_NAME = RECENT_WEIGHT_CHANGES_OPTIONS[1].value;
export const GAINED_WEIGHT_NAME = RECENT_WEIGHT_CHANGES_OPTIONS[2].value;

export const LifeStyleFactors: React.FC = () => {
  const {
    formState: { errors },
    register,
    control,
    watch,
    setValue,
  } = useFormContext<QuestionnaireFormSchema>();

  const recentWeightChange = watch('recentWeightChanges');

  useEffect(() => {
    if (recentWeightChange === NO_CHANGE_NAME) setValue('weightChangeAmount', null);
  }, [recentWeightChange]);

  return (
    <FormSectionWrapper>
      <Grid2 spacing={2} container>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFTextField
            label={LABELS.OCCUPATION}
            register={register}
            registerName="ocupation"
            registerErrors={errors.ocupation?.message}
            fullWidth
            placeholder="Enter Occupation"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            options={FACIAL_EXPRESSIONS_OPTIONS}
            label="Facial expressions (select all that apply)"
            placeholder="Select Facial Expressions"
            control={control}
            registerName="facialExpressions"
            valueKey="value"
            labelKey="value"
            multiple
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Sleeping habits"
            control={control}
            registerName="sleepHabits"
            placeholder="Select Sleeping Habits"
            options={SLEEP_HABITS_OPTIONS}
            valueKey="value"
            labelKey="value"
            multiple
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Hormonal status (if applicable)"
            control={control}
            registerName="hormonalStatus"
            options={HORMONAL_STATUS_OPTIONS}
            valueKey="value"
            labelKey="name"
            freeSolo={false}
            multiple={false}
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Recent weight changes"
            control={control}
            registerName="recentWeightChanges"
            placeholder="Select Recent Weight Changes"
            options={RECENT_WEIGHT_CHANGES_OPTIONS}
            valueKey="value"
            labelKey="value"
          />
        </Grid2>

        {(recentWeightChange === LOST_WEIGHT_NAME || recentWeightChange === GAINED_WEIGHT_NAME) && (
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <RHFTextField
              register={register}
              label={`Weight ${recentWeightChange === LOST_WEIGHT_NAME ? 'Lost' : 'Gained'} (lbs)`}
              name="weightChangeAmount"
              placeholder="Enter amount in lbs"
              type="number"
              required
              registerName="weightChangeAmount"
              registerErrors={errors.weightChangeAmount?.message}
            />
          </Grid2>
        )}
      </Grid2>
    </FormSectionWrapper>
  );
};
