import { Icon } from 'components/Icon';
import { getActivateMenuItem, getCopyMenuItem, getDeleteMenuItem, getEditMenuItem } from 'components/MenuPopover';
import { ActionDataInitialState } from 'hooks';

import { BACardMenuItem } from './types';

export type MenuItemKey = 'view' | 'add' | 'edit' | 'clone' | 'activateToggle' | 'delete';

export type ActionHandler<TParams> = (params?: TParams) => void;

interface MenuItemsProps {
  isActive: boolean;
  id: string;
  name: string;
  handlers?: Partial<Record<MenuItemKey, ActionHandler<ActionDataInitialState>>>;
}

const MENU_ITEM_ORDER: MenuItemKey[] = ['add', 'edit', 'activateToggle', 'clone', 'delete'];

export const getBACardMenuItems = ({ isActive, id, name, handlers = {} }: MenuItemsProps): BACardMenuItem[] => {
  const menuItems: Record<MenuItemKey, BACardMenuItem> = {
    view: {
      icon: <Icon type="eyeOpened" />,
      title: 'View',
      onClick: () => handlers.view?.(),
    },
    add: {
      icon: <Icon type="add" />,
      title: 'Add before & after photos',
      onClick: () => handlers.add?.(),
    },
    edit: getEditMenuItem(() => handlers.edit?.()),

    clone: getCopyMenuItem(() => handlers.clone?.({ id, name, actionType: 'copy' })),

    activateToggle: getActivateMenuItem(isActive, () =>
      handlers.activateToggle?.({
        id,
        name,
        actionType: isActive ? 'deactivate' : 'activate',
      }),
    ),
    delete: getDeleteMenuItem(() => handlers.delete?.({ id, name, actionType: 'delete' })),
  };

  return MENU_ITEM_ORDER.filter(key => key in handlers).map(key => menuItems[key]);
};
