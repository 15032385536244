import { Table, TableBody } from '@mui/material';

import { PopoverWrapper } from 'components/MenuPopover';
import {
  CustomTableContainer,
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableToolbar,
} from 'components/Table';
import { PageWrapper } from 'layouts';

import { ConsultationTableRow, FiltersMenuContent } from './components';
import { CONSULTATIONS_TABLE_HEADERS } from './table.config';
import { useConsultationsState } from './useConsultations.state';

export const Consultations: React.FC = () => {
  const isLoading = false;

  const {
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    page,
    total,
    rowsPerPage,
    onChangePageHandler,
    onChangeRowsPerPage,
  } = useConsultationsState();

  return (
    <>
      <PageWrapper component="div">
        <TableToolbar
          onOpenFilterMenuHandler={openFilterMenu}
          searchInputProps={{
            placeholder: 'Type to Search',
          }}
        />

        <CustomTableContainer>
          <Table size="medium" sx={{ minWidth: 600 }}>
            <CustomTableHeader headLabel={CONSULTATIONS_TABLE_HEADERS} onClickAddColumnButtonHandler={() => {}} />

            <TableBody>
              {isLoading ? <TableSkeleton cellsAmount={3} /> : <ConsultationTableRow id="123" />}
              <TableNoData isNotFound={!isLoading && !1} />
            </TableBody>
          </Table>
        </CustomTableContainer>

        <CustomTablePagination
          count={total}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePageHandler}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </PageWrapper>

      <PopoverWrapper
        open={isFiltersMenuOpen}
        handleClosePopover={closeFiltersMenu}
        title="Filter by Consultations"
        variant="filter"
      >
        <FiltersMenuContent appliedFilters={{ isShowDeactivated: false }} onApplyFilters={() => {}} />
      </PopoverWrapper>
    </>
  );
};
