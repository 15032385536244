import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { Icon } from 'components/Icon';
import { palette } from 'styles/theme/base/palette';

const tableSortLabel: {
  defaultProps?: ComponentsProps['MuiTableSortLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableSortLabel'];
} = {
  defaultProps: {
    IconComponent: props => (
      <Icon
        {...props}
        type="arrowLeftIcon"
        sx={{
          transform: 'rotate(-90deg)',
          svg: { color: `${palette.text.secondary} !important` },
        }}
      />
    ),
  },
  styleOverrides: {
    root: {
      color: 'inherit !important',
    },
  },
};

export default tableSortLabel;
