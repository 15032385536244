import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFTextField } from 'components/HookForm';

import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';

export const AdditionalInformation: React.FC = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<QuestionnaireFormSchema>();

  return (
    <FormSectionWrapper>
      <Grid container>
        <Grid item xs={12}>
          <RHFTextField
            register={register}
            label="Additional Information"
            placeholder="Enter Additional Information"
            registerName="additionalInformation"
            registerErrors={errors.additionalInformation?.message}
            multiline
            minRows={5}
            maxRows={10}
          />
        </Grid>
      </Grid>
    </FormSectionWrapper>
  );
};
