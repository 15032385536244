import { BASE_PATIENTS_API_URL } from 'apiServices/api.routes';
import { apiClient } from 'lib/api';
import { ListOfItems } from 'types';

import {
  CreatePatientPayload,
  GetPatientByIdParams,
  GetPatientsParams,
  Patient,
  PatientListItem,
  UpdatePatientPayload,
} from './types';

export const getPatients = async (params: GetPatientsParams) =>
  await apiClient.get<ListOfItems<PatientListItem>>(BASE_PATIENTS_API_URL, {
    params,
    paramsSerializer: { indexes: null },
  });

export const getPatientsByPracticeId = async (practiceId: string) => {
  return await apiClient.get<ListOfItems<Patient>>(BASE_PATIENTS_API_URL, {
    params: { search: practiceId },
  });
};

export const createPatient = async (payload: CreatePatientPayload) =>
  await apiClient.post<Patient>(BASE_PATIENTS_API_URL, payload);

export const getPatient = async (patientId: string, params?: GetPatientByIdParams) =>
  await apiClient.get<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}`, { params });

export const updatePatient = async (patientId: string, payload: UpdatePatientPayload) =>
  await apiClient.patch<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}`, payload);

export const deletePatient = async (patientId: string) =>
  await apiClient.delete(`${BASE_PATIENTS_API_URL}/${patientId}`);

export const uploadPatientAvatar = async (patientId: string, payload: FormData) =>
  await apiClient.post<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/avatar`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deletePatientAvatar = async (patientId: string) =>
  await apiClient.delete<Patient>(`${BASE_PATIENTS_API_URL}/${patientId}/avatar`);
