import * as yup from 'yup';

import { emailRegExp } from 'constants/regex';
import { LABELS } from 'constants/strings';

export type UserEmailSchema = Partial<{
  email: string;
}>;

export const userEmailValidationSchema: yup.ObjectSchema<UserEmailSchema> = yup
  .object({
    email: yup.string().required().matches(emailRegExp, 'Please enter a valid email').label(LABELS.EMAIL),
  })
  .required();

export type VerificationCodeSchema = Partial<{
  verificationCode: string;
}>;

export const verificationCodeValidationSchema = yup.object().shape({
  verificationCode: yup
    .string()
    .required()
    .label('Verification code')
    .matches(/^[0-9]{6}$/, 'Verification code must be a 6-digit number'),
});
