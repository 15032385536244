import { Box, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import { MDButton } from 'components/MDButton';
import { MDInputPassword } from 'components/MDInput';
import { ValidationChecklist } from 'components/ValidationChecklist';

import { useChangePassword } from './useChangePassword';

export const ChangePassword: React.FC = () => {
  const {
    isLoading,
    passwordsState,
    onChangeCurrentPasswordHandler,
    onChangeNewPasswordHandler,
    onChangeConfirmPasswordHandler,
    isValid,
    rules,
    onFormSubmitHandler,
  } = useChangePassword();

  return (
    <>
      <CardHeader titleTypographyProps={{ component: 'h3' }} title="Change password" />
      <CardContent sx={{ height: 1 }}>
        <Box component="form" onSubmit={onFormSubmitHandler}>
          <Stack spacing={2}>
            <MDInputPassword
              value={passwordsState.currentPassword}
              onChange={onChangeCurrentPasswordHandler}
              label="Current password"
              fullWidth
            />

            <MDInputPassword
              value={passwordsState.newPassword}
              onChange={onChangeNewPasswordHandler}
              label="New password"
              fullWidth
            />

            <MDInputPassword
              value={passwordsState.confirmPassword}
              onChange={onChangeConfirmPasswordHandler}
              label="Confirm password"
              fullWidth
            />
          </Stack>

          <Box mt={2} mb={1}>
            <Typography variant="h3" fontSize={20}>
              Password requirements
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body2" color="text.secondary">
              Please follow this guide for a strong password:
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
            <Box component="ul" m={0} pl={1} mb={{ xs: 1.5, sm: 0 }}>
              <ValidationChecklist validationRules={rules} />
            </Box>
            <Box ml="auto" sx={{ width: { xs: 1, sm: 'auto' } }}>
              <MDButton fullWidth disabled={isLoading || !isValid} isLoading={isLoading} size="small" type="submit">
                Update password
              </MDButton>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </>
  );
};
