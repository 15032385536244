import { FC, PropsWithChildren } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { useStorageListener, useUserProfile } from 'hooks';
import { AppStore, useAppStore } from 'store';

export const PrivateRouter: FC<PropsWithChildren> = ({ children }) => {
  const { cognitoUser, setCognitoProfile } = useUserProfile();
  const navigate = useNavigate();

  const recentlyLoggedInUsers = useAppStore(store => store.recentlyLoggedInUsers);

  useStorageListener<{ state: AppStore }>('store', parsedNewState => {
    if (!parsedNewState?.state?.cognitoUser?.userId) {
      setCognitoProfile(null);
      navigate(ROUTES.signIn);
    }
  });

  if (cognitoUser) return <>{children}</>;

  const authRoute = recentlyLoggedInUsers?.length ? ROUTES.recentlyLoggedInUsers : ROUTES.signIn;

  return <Navigate to={authRoute} replace />;
};
