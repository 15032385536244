import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';

import { updatePatient } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useResponsive, useRouter, useScrollSections } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';
import { dateToCustomFormat } from 'utils/helpers';

import { EditPatientFormSchema, editPatientValidationSchema } from './components';
import { DEMOGRAPHICS_ID, CONTACT_ID } from './constants';
import { usePatientStore } from '../../usePatientStore';

const SCROLL_SECTIONS = [
  { id: DEMOGRAPHICS_ID, label: 'Demographics', priority: 1 },
  { id: CONTACT_ID, label: 'Contact', priority: 2 },
] as const;

export const useProfileState = () => {
  const { params } = useRouter();

  const isWebView = useResponsive('down', 431);

  const patientId = params?.id;

  const { patient, fetchPatient } = usePatientStore();

  const formMethods = useForm<EditPatientFormSchema>({
    resolver: yupResolver(editPatientValidationSchema) as Resolver<EditPatientFormSchema>,
    mode: 'onTouched',
    disabled: isWebView,
    values: {
      birth_date: patient?.birth_date,
      ethnicity: patient?.ethnicity || null,
      gender_identity: patient?.gender_identity || null,
      first_name: patient?.first_name,
      last_name: patient?.last_name,
      middle_name: patient?.middle_name || '',
      occupation: patient?.occupation || '',
      title: patient?.title || null,
      ssn: patient?.ssn ?? null,
      email: patient?.email || null,
      phone: patient?.phone || null,
    },
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const isSubmitButtonDisabled = isSubmitting || !isDirty || !isValid;

  const onFormSubmitHandler = handleSubmit(
    async ({
      birth_date,
      first_name,
      last_name,
      middle_name,
      ssn,
      title,
      ethnicity,
      gender_identity,
      occupation,
      email,
      phone,
    }) => {
      try {
        await updatePatient(patientId, {
          birth_date: dateToCustomFormat(birth_date),
          first_name,
          last_name,
          middle_name: middle_name || null,
          ssn,
          title,
          ethnicity,
          gender_identity,
          occupation: occupation || null,
          email: email || null,
          phone: phone?.length === 1 || !phone ? null : `+${phone}`,
        });

        await fetchPatient(patientId);

        notice(ToastType.SUCCESS, 'Patient profile has been successfully updated!');
        reset();
      } catch (error) {
        formErrorHandler({
          error,
          config: { formError: { setError: setError } },
          customErrorMessage: 'Failed to update patient profile, please try again!',
        });
      }
    },
  );

  const { isSkipObserver, activeSection, generateSideNavItems, onUpdateActiveSection } = useScrollSections({
    sections: SCROLL_SECTIONS,
  });

  const { ref: demographicsRef, entry: demographicsEntry } = useInView({
    initialInView: true,
    onChange: onUpdateActiveSection,
    skip: isSkipObserver,
  });

  const { ref: contactRef, entry: contactEntry } = useInView({
    threshold: 1,
    onChange: onUpdateActiveSection,
    skip: isSkipObserver,
  });

  const sectionEntries = {
    [DEMOGRAPHICS_ID]: demographicsEntry,
    [CONTACT_ID]: contactEntry,
  };

  const sidenavItems = useMemo(() => generateSideNavItems(sectionEntries), [contactEntry, demographicsEntry]);

  return {
    activeSection,
    formMethods,
    sidenavItems,
    demographicsRef,
    contactRef,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    patientId,
    fetchPatient,
    isDirty,
  };
};
