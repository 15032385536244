import * as yup from 'yup';

import { CreateGlobalLibraryTagCategoryPayload } from 'apiServices';
import { Shape } from 'types/RHF-types';
import { PracticeIdValidationSchema } from 'utils/formValidation';

export type CreateTagCategoryFormSchema = Pick<
  CreateGlobalLibraryTagCategoryPayload,
  'name' | 'service_name' | 'practice_id' | 'is_published'
>;

export const createTagCategoryValidationSchema = yup
  .object()
  .shape<Shape<CreateTagCategoryFormSchema>>({
    name: yup.string().required().label('Tag Category Name').trim(),
    service_name: yup.string().optional().label('Tag Category Service Name').nullable().trim(),
    is_published: yup.boolean().optional().label('Is Published Tag').nullable(),
    practice_id: PracticeIdValidationSchema,
  })
  .required();

export const createTagCategoryDefaultValues: CreateTagCategoryFormSchema = {
  name: '',
  service_name: '',
  practice_id: '',
  is_published: false,
};

export type CreateTagFormSchema = {
  name: string;
};

export const createTagValidationSchema = yup
  .object()
  .shape<Shape<CreateTagFormSchema>>({
    name: yup.string().required().label('Tag Name').trim(),
  })
  .required();

export const createTagDefaultValues: CreateTagFormSchema = {
  name: '',
};
