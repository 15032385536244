import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';
import Fade from '@mui/material/Fade';

import borders from '../base/borders';
import { palette } from '../base/palette';
import typography from '../base/typography';
import pxToRem from '../functions/pxToRem';

const { text, background } = palette;
const { size, fontWeightMedium } = typography;
const { borderRadius } = borders;

const tooltip: {
  defaultProps?: ComponentsProps['MuiTooltip'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTooltip'];
  variants?: ComponentsVariants<Theme>['MuiTooltip'];
} = {
  defaultProps: {
    arrow: true,
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(200),
      backgroundColor: text.primary,
      color: background.default,
      fontSize: size.bodySmall,
      fontWeight: fontWeightMedium,
      textAlign: 'center',
      borderRadius: borderRadius[8],
      marginBottom: '8px !important',
      padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(5)}`,
    },

    arrow: {
      color: text.primary,
      '&::before': {
        borderRadius: 1,
      },
    },
  },
};

export default tooltip;
