import { useEffect, useState, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import { GetExpertsParams, deleteExpert, getExperts } from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useBoolean, useEmptyTableState, useTable } from 'hooks';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

import { FiltersInitialState } from './types';

export const FiltersInitialStateValue: FiltersInitialState = {
  isShowDeactivated: false,
  practice_id: null,
  roles: [],
};

export const useUsersState = () => {
  const {
    experts: { items, total },
    setExperts,
    expertProfileId,
    activateExpert,
    deactivateExpert,
  } = useAppStore(
    store => ({
      experts: store.experts,
      setExperts: store.setExperts,
      expertProfileId: store.expertProfile?.id,
      activateExpert: store.activateExpert,
      deactivateExpert: store.deactivateExpert,
    }),
    shallow,
  );

  const {
    closeFiltersMenu,
    openFilterMenu,
    appliedFilters,
    debouncedSearchValue,
    searchValue,
    page,
    rowsPerPage,
    isFiltersMenuOpen,
    isFiltersApplied,
    onApplyFilters,
    onChangeRowsPerPage,
    onChangeSearchValueHandler,
    setPage,
    resetPageHandler,
    setResetFilters,
  } = useTable({ defaultFilters: FiltersInitialStateValue });

  const [isOpenAddUserDialogWindow, openAddUserDialogWindow, closeAddUserDialogWindow] = useBoolean();

  const [isPending, startTransition] = useTransition();

  const onGetUsersHandler = async (params?: GetExpertsParams) =>
    startTransition(async () => {
      const roles = appliedFilters.roles?.map(role => role?.value) || [];

      try {
        const { data } = await getExperts({
          ...(debouncedSearchValue && {
            search: debouncedSearchValue,
          }),
          ...(appliedFilters.practice_id && {
            practice_id: appliedFilters.practice_id,
          }),
          is_active: appliedFilters.isShowDeactivated ? false : true,
          page,
          size: rowsPerPage,
          role: roles,
          ...params,
        });
        setExperts(data);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get users, please try again!' } });
      }
    });

  const onChangePageHandler = async (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetUsersHandler({ page: newPage });
  };

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [selectedItem, setSelectedItem] = useState({ name: '', id: '' });

  const onClickDeleteMenuItemHandler = (data: { name: string; id: string }) => {
    openDeleteDialogWindow();
    setSelectedItem(data);
  };

  const {
    getCloseDialogHandler,
    actionState,
    closeDialog,
    dialogStates,
    isActionPending,
    onClickActionOptionHandler,
    setIsActionPending,
  } = useActionDialogManagement();

  const onDeactivateUserHandler = async () => {
    setIsActionPending(true);

    const { id } = actionState;

    const resp = await deactivateExpert(id);

    setIsActionPending(false);

    if (resp) {
      closeDialog('deactivate');
      await onGetUsersHandler({ page: 1 });
      resetPageHandler();
    }
  };

  const onActivateUserHandler = async () => {
    setIsActionPending(true);

    const { id } = actionState;

    const resp = await activateExpert(id);

    setIsActionPending(false);

    if (resp) {
      closeDialog('activate');
      await onGetUsersHandler({ page: 1 });
      resetPageHandler();
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteUserHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsDeleting(true);
    const { id } = selectedItem;
    try {
      await deleteExpert(id);

      notice(ToastType.SUCCESS, 'User has been successfully deleted!');

      await onGetUsersHandler({ page: 1 });

      resetPageHandler();

      closeDeleteDialogWindow();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to delete user, please try again!',
        },
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const { isEmptyState, isFilteredEmptyState } = useEmptyTableState({
    debouncedSearchValue,
    isPending,
    total,
    isFiltersApplied,
  });

  useEffect(() => {
    onGetUsersHandler();
  }, [debouncedSearchValue, rowsPerPage, appliedFilters]);

  return {
    items,
    isPending,
    onGetUsersHandler,
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    total,
    onChangePageHandler,
    onApplyFilters,
    appliedFilters,
    onClickDeleteMenuItemHandler,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    selectedItem,
    onDeleteUserHandler,
    isDeleting,
    onActivateUserHandler,
    onDeactivateUserHandler,
    onClickActionOptionHandler,
    actionState,
    getCloseDialogHandler,
    dialogStates,
    isActionPending,
    expertProfileId,
    isOpenAddUserDialogWindow,
    openAddUserDialogWindow,
    closeAddUserDialogWindow,
    resetPageHandler,
    setResetFilters,
    isEmptyState,
    isFilteredEmptyState,
  };
};
