import * as yup from 'yup';

import { CreatePromptPayload } from 'apiServices';

export const createPromptValidationSchema: yup.ObjectSchema<CreatePromptPayload> = yup
  .object()
  .shape({
    name: yup.string().required().label('Name').max(250),
    text: yup.string().required().label('Text'),
    comment: yup.string().optional(),
  })
  .required();

export const defaultPromptValues: CreatePromptPayload = {
  name: '',
  text: '',
  comment: '',
};
