import { alpha, styled } from '@mui/material';

export const EditorContainer = styled('div')(({ theme: { palette, borders } }) => ({
  width: '100%',
  minHeight: '150px',
  '& .tag': {
    margin: 2,
    userSelect: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    height: 28,
    padding: 6,
    backgroundColor: alpha(palette.success.main, 0.25),
    color: alpha(palette.success.main, 0.9),
    borderRadius: borders.borderRadius[8],
    border: 0,
    ':hover': {
      backgroundColor: alpha(palette.success.main, 0.35),
    },
  },

  '& .tag-close': {
    cursor: 'pointer',
    marginLeft: '6px',
    width: '14px',
    height: '14px',
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
  },

  '& .tag-close::before': {
    content: '"×"',
    lineHeight: 1,
    fontSize: '17px',
    textAlign: 'center',
    width: 14,
    height: 14,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type EditorInputProps = {
  isError?: boolean;
  placeholder?: string;
};

export const EditorInput = styled('div')<EditorInputProps>(({ theme, isError, placeholder }) => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  width: '100%',
  minHeight: 100,
  height: 255,
  padding: '9px 12px',
  color: theme.palette.text.primary,
  fontSize: 14,
  borderRadius: theme.borders.borderRadius[8],
  outline: 'none',
  background: 'transparent',
  overflowY: 'auto',
  '&:empty:before': {
    content: `"${placeholder || 'Enter text'}"`,
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    display: 'block',
  },

  '&:hover:not(:focus) + .input-border': {
    borderColor: isError ? theme.palette.error.main : theme.palette.secondary.dark,
  },

  '&:focus + .input-border': {
    borderColor: isError ? theme.palette.error.main : theme.palette.primary.main,
    borderWidth: 2,
  },
  ...(isError && {
    '.input-border': {
      borderColor: theme.palette.error.main,
    },
  }),
}));

export const InputBorder = styled('fieldset')<Pick<EditorInputProps, 'isError'>>(({ theme, isError }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
  borderStyle: 'solid',
  borderWidth: 1,
  overflow: 'hidden',
  borderColor: theme.palette.secondary.main,
  borderRadius: theme.borders.borderRadius[8],
  ...(isError && {
    borderColor: theme.palette.error.main,
  }),
  '&:hover': {
    borderColor: theme.palette.secondary.dark,
  },
  '&:focus-within': {
    '&:hover': {
      borderColor: 'inherit',
    },
  },
}));
