import { CheckRounded } from '@mui/icons-material';
import { Box, Divider, Stack, SxProps, Theme } from '@mui/material';

import { MDButton, MDButtonProps } from 'components/MDButton';

type FormMenuPopoverButtonProps = MDButtonProps & {
  sx?: SxProps<Theme>;
  buttonTitle?: string;
};

export const FormMenuPopoverButton: React.FC<FormMenuPopoverButtonProps> = ({
  sx,
  buttonTitle = 'Save',
  ...buttonProps
}) => {
  return (
    <Stack sx={sx} py={1.5}>
      <Divider />
      <Box mt={1.5} display="flex" justifyContent="flex-end">
        <MDButton startIcon={<CheckRounded sx={{ fontSize: '16px !important' }} />} {...buttonProps}>
          {buttonTitle}
        </MDButton>
      </Box>
    </Stack>
  );
};
