import { shallow } from 'zustand/shallow';

import { useTabs } from 'hooks/useTabs';
import { useAppStore } from 'store';

export const useTagManagementState = () => {
  const { setTagManagementActiveLibraryTab, tagManagementActiveLibraryTab } = useAppStore(
    store => ({
      tagManagementActiveLibraryTab: store.tagManagementActiveLibraryTab,
      setTagManagementActiveLibraryTab: store.setTagManagementActiveLibraryTab,
    }),
    shallow,
  );

  const { setTabValue, tabValue } = useTabs(tagManagementActiveLibraryTab === 'gl' ? 0 : 1);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setTagManagementActiveLibraryTab(newValue === 0 ? 'gl' : 'pl');
  };

  return { handleSetTabValue, tabValue };
};
