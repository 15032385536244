import { useEffect } from 'react';

import { STORE_NAME } from 'store';

export const useStorageListener = <T = unknown>(key: string = STORE_NAME, callback: (parsedState: T) => void) => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        try {
          const parsedState = event.newValue ? JSON.parse(event.newValue) : null;
          callback(parsedState);
        } catch (error) {
          console.error('Failed to parse storage event:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key, callback]);
};
