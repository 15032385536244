import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const { text } = palette;
const { size } = typography;

const inputLabel = {
  styleOverrides: {
    root: {
      fontSize: size.bodyMedium,
      color: text.primary,
      lineHeight: 1,
      top: -4,
      '&.MuiInputLabel-shrink': {
        lineHeight: 1.5,
        fontSize: size.bodyLarge,
        top: 0,

        '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
          fontSize: '0.85em',
        },
      },
    },

    sizeSmall: {
      fontSize: size.bodySmall,
      lineHeight: 1.625,

      '&.MuiInputLabel-shrink': {
        lineHeight: 1.6,
        fontSize: size.bodyMedium,
        top: 0,

        '~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend': {
          fontSize: '0.72em',
        },
      },

      top: 1.5,
    },
  },
};

export default inputLabel;
