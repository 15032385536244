import { Stack } from '@mui/material';

import { getEditMenuItem } from 'components/MenuPopover';
import { ContentCard } from 'components/Widgets';
import { useResponsive } from 'hooks';

const htmlContent = `<div class="section">
      <h2>Subjective:</h2>
      <ul>
        <li>Deep forehead wrinkles</li>
        <li>Age spots</li>
        <li>Rough skin texture due to sun damage</li>
        <li>Concern about skin cancer recurrence</li>
      </ul>
    </div>
    <div class="section">
      <h2>Objective:</h2>
      <ul>
        <li>Deep forehead wrinkles</li>
        <li>Age spots</li>
        <li>Rough skin texture due to sun damage</li>
        <li>Concern about skin cancer recurrence</li>
      </ul>
    </div>
    <div class="section">
      <h2>Assessment</h2>
      <ul>
        <li>Aging-related wrinkles and hyperpigmentation likely due to prolonged sun exposure.</li>
        <li>No immediate contraindications for injectable treatments but must consider anticoagulant use (Warfarin).</li>
      </ul>
    </div>
    <div class="section">
      <h2>Plan</h2>
      <ul>
        <li>Botox injections to reduce forehead wrinkles, ensuring careful technique due to anticoagulation.</li>
        <li>Laser treatment or chemical peel for age spots (pending further assessment of sun damage history).</li>
        <li>Skin care regimen adjustment with medical-grade sunscreen and antioxidant-rich serum.</li>
        <li>Follow-up appointment in four weeks to assess initial treatment response.</li>
      </ul>
    </div>
  `;

export const DetailsTabContent: React.FC = () => {
  const isWebView = useResponsive('down', 431);

  return (
    <Stack width={1} gap={2} p={{ xs: 2, sm: 3 }}>
      <ContentCard
        title="Summary"
        isCollapseButton={isWebView}
        content="Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing client concerns such as wrinkle reduction, brow lifting, or symmetry correction.
Functionality: Preserve or enhance facial expressions without creating a sense of stiffness or limiting mobility.
Safety of Procedure: Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or infection.
Comfortable Recovery: Ensure a swift and smooth recovery process, with clear guidance to help clients return to their routine quickly.
Emotional Confidence: Instill confidence in clients regarding their decision, providing psychological support before and after the procedure.
Personalized Approach: Develop a tailored surgical plan considering individual anatomy, age, gender, and personal preferences.
Long-Lasting Results: Deliver outcomes that are durable over time, accounting for the natural aging process."
        menuItems={[getEditMenuItem(() => {})]}
      />

      <ContentCard
        title="SOAP"
        isCollapseButton={isWebView}
        isHTMLcontent
        content={htmlContent}
        menuItems={[getEditMenuItem(() => {})]}
      />

      <ContentCard
        title="Follow-up Queston"
        isCollapseButton={isWebView}
        content="Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing client concerns such as wrinkle reduction, brow lifting, or symmetry correction.
Functionality: Preserve or enhance facial expressions without creating a sense of stiffness or limiting mobility.
Safety of Procedure: Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or infection.
Comfortable Recovery: Ensure a swift and smooth recovery process, with clear guidance to help clients return to their routine quickly.
Emotional Confidence: Instill confidence in clients regarding their decision, providing psychological support before and after the procedure.
Personalized Approach: Develop a tailored surgical plan considering individual anatomy, age, gender, and personal preferences.
Long-Lasting Results: Deliver outcomes that are durable over time, accounting for the natural aging process."
        menuItems={[getEditMenuItem(() => {})]}
      />
    </Stack>
  );
};
