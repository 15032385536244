import { Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { MDInput } from 'components/MDInput';

import { TranscriptionActionsProps } from './types';

export const TranscriptionActionsToolbar: React.FC<TranscriptionActionsProps> = ({ onClickEditButtonHandler }) => {
  return (
    <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between" gap={4}>
      <MDInput
        placeholder="Search Transcript"
        fullWidth
        slotProps={{
          input: { startAdornment: <Icon type="searchIcon" sx={{ color: 'secondary.main' }} /> },
        }}
      />

      <MDButton onClick={onClickEditButtonHandler} variant="tertiary" startIcon={<Icon type="editPen" />}>
        Edit
      </MDButton>
    </Stack>
  );
};
