import { FC, useCallback, useState } from 'react';

import Stack from '@mui/material/Stack';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { useBoolean, useRouter } from 'hooks';

import { AddItemCard, Header, PhaseCard } from './components';
import { CardItem, Phase, StepContentItem } from './types';

const initialPhasesStateValue: Phase[] = [];

export const WorkflowBuilder: FC = () => {
  const [isAddPhaseMode, onClickAddPhaseButtonHandler, onClickCancelAddPhaseButtonHandler] = useBoolean();

  const { state } = useRouter();

  const [phases, setPhases] = useState<Phase[]>(state?.phase?.length ? state.phase : initialPhasesStateValue);

  const onClickSavePhaseButtonHandler = useCallback(
    (phase: Phase) => setPhases(prevState => prevState.concat(phase)),
    [],
  );

  const onClickSaveStepButtonHandler = useCallback((phaseId: string, newStepData: CardItem) => {
    setPhases(prevPhases =>
      prevPhases.map(phase =>
        phase.id === phaseId
          ? {
              ...phase,
              steps: [...(phase?.steps || []), { ...newStepData, content: [] }],
            }
          : phase,
      ),
    );
  }, []);

  const onAddContentToStepHandler = useCallback((phaseId: string, stepId: string, stepContent: StepContentItem[]) => {
    if (!stepContent.length) return;

    setPhases(prevPhases =>
      prevPhases.map(phase => {
        if (phase.id !== phaseId) return phase;

        return {
          ...phase,
          steps: phase.steps.map(step =>
            step.id === stepId ? { ...step, content: step?.content?.concat(stepContent) } : step,
          ),
        };
      }),
    );
  }, []);

  return (
    <section>
      <Header />
      <Stack mb={2}>
        {phases.length ? (
          <Stack gap={2}>
            {phases.map(phase => (
              <PhaseCard
                key={phase.id}
                id={phase.id}
                steps={phase.steps}
                name={phase.name}
                onSaveStepHandler={onClickSaveStepButtonHandler}
                onAddContentToStepHandler={onAddContentToStepHandler}
              />
            ))}
          </Stack>
        ) : null}

        {isAddPhaseMode ? (
          <AddItemCard
            onCancelHandler={onClickCancelAddPhaseButtonHandler}
            onSaveHandler={onClickSavePhaseButtonHandler}
            label="Phase Name"
            placeholder="Enter Phase Name"
          />
        ) : (
          <Stack mt={2} justifyContent="center" alignItems="center">
            <MDButton variant="ghost" onClick={onClickAddPhaseButtonHandler} startIcon={<Icon type="add" />}>
              Add Phase
            </MDButton>
          </Stack>
        )}
      </Stack>
    </section>
  );
};
