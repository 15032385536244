import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, alpha } from '@mui/material';
import { useForm } from 'react-hook-form';

import { GLbeforeAfterAlbum, PLbeforeAfterAlbum } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { MDButton } from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { UploadFileButton } from 'components/UploadFileButton';
import { ACCEPT_INPUT_IMAGE_FILES } from 'constants/fileExtensions';
import { useOptions } from 'hooks';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateBAalbumFormSchema, createBAalbumValidationSchema, defaultValues } from './form.config';
import { AddAlbumDialogWindowFormProps } from './types';

export const AddAlbumDialogWindowForm = <T extends PLbeforeAfterAlbum | GLbeforeAfterAlbum>({
  onFetchAlbumsHandler,
  onSelectFileHandler,
  previewURL,
  onRemoveFileHandler,
  createAlbumAPIrequest,
  isRoleAdmin,
  closeAddAlbumDialogWindow,
  onCreateAlbumCoverHandler,
}: AddAlbumDialogWindowFormProps<T>) => {
  const { fetchPractices, debouncedPracticeSearchValue, practicesState, onChangePracticeInputValueHandler } =
    useOptions({
      isImmediateFetchPractices: isRoleAdmin ?? false,
    });

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CreateBAalbumFormSchema>({
    resolver: yupResolver(createBAalbumValidationSchema),
    mode: 'onTouched',
    defaultValues,
    context: { isPracticeIdRequired: isRoleAdmin },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    const { name, description, practice_id } = formData;
    try {
      const { data } = await createAlbumAPIrequest({
        name,
        ...(description && { description }),
        ...(practice_id && { practice_id }),
        is_active: true,
      });
      if (previewURL) {
        await onCreateAlbumCoverHandler(data.id);
        onRemoveFileHandler();
      }

      notice(ToastType.SUCCESS, 'Album has been successfully created!');
      closeAddAlbumDialogWindow();

      await onFetchAlbumsHandler({ page: 1 });
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to create album, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  return (
    <>
      <FormDialogWindowContent
        onFormSubmitHandler={onFormSubmitHandler}
        headerProps={{ title: 'Add Album', onClickCancelButtonHandler: closeAddAlbumDialogWindow }}
        actionProps={{
          approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting },
          cancelButtonProps: { children: 'Cancel' },
        }}
      >
        <Scrollbar sx={{ height: 1, maxHeight: 380 }}>
          <Stack
            sx={{
              '.MuiStack-root': {
                width: 1,
              },
            }}
            spacing={2}
            mb="1px"
          >
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2.5} alignItems="center">
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  flexShrink: 0,
                  borderRadius: theme => theme.borders.borderRadius[10],
                  border: theme => `${theme.borders.borderWidth[1]} solid ${theme.palette.grey[300]}`,
                  bgcolor: theme => alpha(theme.palette.primary.light, 0.1),
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                }}
              >
                {previewURL ? (
                  <Box
                    component="img"
                    alt="album cover"
                    src={previewURL}
                    width={1}
                    height={1}
                    sx={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Icon type="galleryIcon" />
                )}
              </Box>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <UploadFileButton
                  onChangeHandler={onSelectFileHandler}
                  inputProps={{ accept: ACCEPT_INPUT_IMAGE_FILES }}
                >
                  Upload cover
                </UploadFileButton>
                <MDButton variant="secondary" disabled={!previewURL} onClick={onRemoveFileHandler}>
                  Remove
                </MDButton>
              </Stack>
            </Stack>
            <RHFTextField
              register={register}
              registerName="name"
              registerErrors={errors.name?.message}
              fullWidth
              required
              label="Name"
              placeholder="Enter name"
            />

            <RHFTextField
              register={register}
              registerName="description"
              registerErrors={errors.description?.message}
              fullWidth
              placeholder="Enter description"
              label="Description"
              multiline
              maxRows={8}
              minRows={5}
            />

            {isRoleAdmin && (
              <InfiniteScrollWrapper
                searchQuery={debouncedPracticeSearchValue}
                totalPages={practicesState.totalPages}
                fetchItemsHandler={fetchPractices}
              >
                {({ ref }) => (
                  <RHFAutocompleteField
                    control={control}
                    registerName="practice_id"
                    isRequired
                    label="Practice"
                    placeholder="Select Practice"
                    options={practicesState.items}
                    onInputChange={onChangePracticeInputValueHandler}
                    loading={practicesState.isLoading}
                    valueKey="id"
                    labelKey="name"
                    lastElementRef={ref}
                  />
                )}
              </InfiniteScrollWrapper>
            )}
          </Stack>
        </Scrollbar>
      </FormDialogWindowContent>
    </>
  );
};
