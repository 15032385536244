import { useMemo } from 'react';

import { Icon } from 'components/Icon';
import { ROUTES } from 'constants/routes';

import { useUserProfile } from './useUserProfile';

export interface NavItem {
  title: string;
  path: { path: string; isDeepActive?: boolean };
  pathes?: string[];
  icon?: string | React.JSX.Element;
  children?: { title: string; path: string }[];
}

interface ISideNavLinkRoutesConfig {
  subheader: string;
  items: NavItem[];
}

const PROMPT_LIBRARY_NAV_ITEM: NavItem = {
  title: 'Prompt library',
  icon: <Icon type="page" />,
  path: { path: ROUTES.promptLibrary },
};

export const useSideNavRoutes = () => {
  const {
    practiceId,
    userRoles: { isRoleAdmin, isRoleAItester },
  } = useUserProfile();

  const sideNavRoutes: ISideNavLinkRoutesConfig[] = useMemo(() => {
    if (isRoleAItester) {
      return [
        {
          subheader: '',
          items: [PROMPT_LIBRARY_NAV_ITEM],
        },
      ];
    }

    const practiceManagementPath = isRoleAdmin
      ? ROUTES.practiceManagement
      : ROUTES.practiceManagementProfile.replace(':id', practiceId);

    return [
      {
        subheader: '',
        items: [
          {
            title: 'Patients',
            icon: <Icon type="patientsIcon" />,
            path: { path: ROUTES.patients },
          },
          {
            title: 'Practice Management',
            icon: <Icon type="practices" />,
            path: { path: practiceManagementPath, isDeepActive: true },
          },

          {
            title: 'Tag management',
            icon: <Icon type="tag_managment" />,
            path: { path: ROUTES.tagManagement },
          },

          {
            title: 'Catalog',
            icon: <Icon type="catalog" />,
            path: { path: ROUTES.catalog },
          },

          PROMPT_LIBRARY_NAV_ITEM,

          {
            title: 'Before & After Galleries',
            icon: <Icon type="beforeAfterBoldIcon" />,
            path: { path: ROUTES.beforeAfter },
          },

          {
            title: 'Workflows',
            icon: <Icon type="workflowIcon" />,
            path: { path: ROUTES.workflows },
          },

          {
            title: 'Users',
            icon: <Icon type="users" />,
            path: { path: ROUTES.users },
          },
        ],
      },
    ];
  }, [practiceId, isRoleAdmin]);

  return sideNavRoutes;
};
