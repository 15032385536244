import * as yup from 'yup';

import { CreatePracticePayload } from 'apiServices';
import { Shape } from 'types/RHF-types';

export type CreatePracticeFormSchema = Omit<CreatePracticePayload, 'practice_summary' | 'is_active'> & {
  practice_summary: string;
};

export const createPracticeValidationSchema = yup
  .object()
  .shape<Shape<CreatePracticePayload>>({
    name: yup.string().required().label('Practice Name'),
    practice_summary: yup.string().optional().label('Practice Summary'),
  })
  .required();

export const practiceFormDefaultValues: CreatePracticeFormSchema = {
  name: '',
  practice_summary: '',
};
