import { Divider, Grid2, Stack } from '@mui/material';

import { ChangePassword, MFAProviders } from './components';

export const Security: React.FC = () => {
  return (
    <Grid2 container>
      <Grid2 size={12}>
        <Stack gap={2} divider={<Divider />}>
          <ChangePassword />
          <MFAProviders />
        </Stack>
      </Grid2>
    </Grid2>
  );
};
