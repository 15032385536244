import { TableHeaderConfig } from 'types';

export const CONCERNS_TABLE_HEADERS: TableHeaderConfig[] = [
  { id: 'added', label: 'Date Added' },
  { id: 'aesthetic_issue', label: 'Aesthetic Issue' },
  { id: 'anatomical_area', label: 'Anatomical Area' },
  { id: 'severity', label: 'Severity' },
  { id: 'priority', label: 'Priority' },
  { id: 'provider', label: 'Provider' },
];
