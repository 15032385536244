import { StateCreator } from 'zustand';

import workflow_image1 from 'assets/illustrations/workflow_image1.png';
import workflow_image2 from 'assets/illustrations/workflow_image2.png';

import { Workflow, WorkflowBuilderInitialState, WorkflowBuilderSliceType } from './types';
import { sliceResetFns } from '../../Store';

const GL_ITEMS: Workflow[] = [
  {
    id: '1',
    name: 'GL Workflow 1',
    isActive: true,
    isPublished: false,
    phase: [
      {
        id: '1',
        name: 'Phase 1',
        steps: [
          {
            content: [{ id: '1', title: 'Content 1', type: 'image', previewURL: workflow_image1 }],
            id: '1',
            name: 'Step 1',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    name: 'GL Workflow 2',
    isActive: false,
    isPublished: true,
    phase: [],
  },
];

const PL_ITEMS: Workflow[] = [
  {
    id: '1',
    name: 'PL Workflow 1',
    isActive: true,
    isPublished: true,
    phase: [
      {
        id: '1',
        name: 'Phase 1',
        steps: [
          {
            content: [{ id: '1', title: 'Content 1', type: 'image', previewURL: workflow_image2 }],
            id: '1',
            name: 'Step 1',
          },
        ],
      },
      {
        id: '2',
        name: 'Phase 2',
        steps: [],
      },
    ],
  },
  {
    id: '2',
    name: 'PL Workflow 2',
    isActive: false,
    isPublished: true,
    phase: [],
  },
];

const initialState: WorkflowBuilderInitialState = {
  globalLibraryWorkflows: {
    page: null,
    items: GL_ITEMS,
    total: null,
    size: null,
    pages: null,
  },
  practiceLibraryWorkflows: {
    page: null,
    items: PL_ITEMS,
    total: null,
    size: null,
    pages: null,
  },
  selectedWorkflow: null,
  workflowActiveLibraryTab: 'gl',
};

export const WorkflowBuilderSlice: StateCreator<
  WorkflowBuilderSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  WorkflowBuilderSliceType
> = (set, get) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,

    setGlobalLibraryWorkflows: data => {
      set(state => {
        state.globalLibraryWorkflows = data;
      });
    },
    setPracticeLibraryWorkflows: data => {
      set(state => {
        state.practiceLibraryWorkflows = data;
      });
    },
    setWorkflowActiveLibraryTab: tab => {
      set(state => {
        state.workflowActiveLibraryTab = tab;
      });
    },
  };
};
