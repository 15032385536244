import { useCallback, useEffect, useState } from 'react';

import { FileWithPath } from 'react-dropzone';
import { Area } from 'react-easy-crop/types';

import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES, UNSUPPORTED_FILE_TYPE_TITLE } from 'constants/strings';

import { useBoolean } from './useBoolean';

type UseFilePickerProps = {
  accept?: string;
};

const getAllowedFormats = (acceptableFileFormats: string) =>
  acceptableFileFormats
    .split(',')
    .map(el => el.trim().toUpperCase().replace('.', ''))
    .join(', ') + '.';

export const useFilePicker = ({ accept }: UseFilePickerProps) => {
  const [file, setFile] = useState<FileWithPath | null>(null);

  const [previewURL, setPreviewURL] = useState('');

  const onUploadDropzoneFileHandler = useCallback((files: readonly FileWithPath[]) => {
    const file = files?.[0];
    setFile(file);
    setPreviewURL(URL.createObjectURL(file));
  }, []);

  const onRemoveFileHandler = useCallback(() => {
    setFile(null);
    setPreviewURL('');
  }, []);

  const [isFileError, setIsFileError] = useState(false);

  const onFileInputChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      isFileError && setIsFileError(false);

      const file = event.target.files?.[0];

      if (!file) return;

      if (accept) {
        const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

        const acceptedExtensions = accept.split(',');

        if (!acceptedExtensions.includes(fileExtension)) {
          setIsFileError(true);

          const allowedFileFormats = getAllowedFormats(accept);

          notice(ToastType.ERROR, `${ERROR_MESSAGES.unsupportedFileFormat} ${allowedFileFormats}`, {
            title: UNSUPPORTED_FILE_TYPE_TITLE,
          });

          event.target.value = '';

          return;
        }
      }

      setPreviewURL(URL.createObjectURL(file));
      setFile(file);

      event.target.value = '';
    },
    [accept, isFileError],
  );

  useEffect(() => {
    return () => {
      if (previewURL) URL.revokeObjectURL(previewURL);
    };
  }, [previewURL]);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropCompleteHandler = useCallback(
    (_: Area, croppedAreaPixels: Area) => setCroppedAreaPixels(croppedAreaPixels),
    [],
  );

  const [isCropFileDialogOpen, openCropFileDialog, closeCropFileDialog] = useBoolean();

  return {
    file,
    previewURL,
    isFileError,
    setIsFileError,
    setFile,
    setPreviewURL,
    onFileInputChangeHandler,
    onUploadDropzoneFileHandler,
    onRemoveFileHandler,
    croppedAreaPixels,
    onCropCompleteHandler,
    setCroppedAreaPixels,
    isCropFileDialogOpen,
    openCropFileDialog,
    closeCropFileDialog,
  };
};
