import { StateCreator } from 'zustand';

import { PromptLibraryInitialState, PromptLibrarySliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: PromptLibraryInitialState = {
  prompts: {
    items: [],
    pagination: {
      page: null,
      total: null,
      size: null,
    },
  },
};

export const PromptLibrarySlice: StateCreator<
  PromptLibrarySliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PromptLibrarySliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setPrompts: prompts => {
      set(state => {
        state.prompts = prompts;
      });
    },
  };
};
