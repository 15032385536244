import React, { FC, useEffect, useState } from 'react';

import { CachedRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { verifyExpertProfile } from 'apiServices';
import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { MDButton } from 'components/MDButton';
import { MDInput, MDInputPassword } from 'components/MDInput';
import { ToastType, notice } from 'components/ToastNotification';
import { ValidationChecklist, resetPasswordValidationData } from 'components/ValidationChecklist';
import { WHITESPACE_REGEX_IN_URL } from 'constants/regex';
import { ROUTES } from 'constants/routes';
import { useCognitoAuthContext } from 'context';
import { useBoolean, useRouter, useUserProfile } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';
import { hideEmail } from 'utils/helpers';

import { BaseType, STEPS_FLOW } from '../types';
import { StepHeader } from './StepHeader';

const initialState = {
  confirmationCode: '',
  newPassword: '',
  confirmPassword: '',
};

export const SetupCredentials: FC<BaseType> = ({ handleNextStep }) => {
  const { navigate } = useRouter();

  const { signOutCognito } = useCognitoAuthContext();

  const cognitoUser = useUserProfile().cognitoUser;

  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const [URLsearchParams] = useSearchParams();

  const emailSearchParam = encodeURI(URLsearchParams.get('email')).replace(WHITESPACE_REGEX_IN_URL, '+') || '';
  const confrimCodeSearchParam = URLsearchParams.get('confirmation_code');
  const expiredAt = encodeURI(URLsearchParams.get('expire_at')).replace(WHITESPACE_REGEX_IN_URL, '+');

  useEffect(() => {
    if (cognitoUser) {
      openDialog();
    } else {
      checkIsLinkValid();
    }
  }, []);

  const checkIsLinkValid = () => {
    if (expiredAt) {
      const expiredAtTime = new Date(expiredAt).getTime();
      const currentDateTime = new Date().getTime();
      if (currentDateTime > expiredAtTime) {
        notice(
          ToastType.WARNING,
          'The invitation link has been expired! Plase contact your practice administrator for a new link',
        );
        navigate(ROUTES.signIn, { replace: true });
      }
    }
  };

  const onClickKeepSessionButtonHandler = () => {
    navigate(ROUTES.home);
    closeDialog();
  };

  const [isLoading, setIsLoading] = useState(false);

  const onClickNewSessionButtonHandler = async () => {
    setIsLoading(true);
    await signOutCognito();
    closeDialog();
    setIsLoading(false);
    checkIsLinkValid();
  };

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onFormSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsFormSubmitting(true);
      await verifyExpertProfile({
        confirmationCode: confrimCodeSearchParam,
        confirmationPin: Number(setupAccountState.confirmationCode),
        email: emailSearchParam,
        newPassword: setupAccountState.confirmPassword,
      });

      handleNextStep(STEPS_FLOW.COMPLETED);
    } catch (error: any) {
      console.error(error);

      const errorStatus = error?.response?.status;
      const responseMessage = error.response.data?.detail;

      if (errorStatus === 400 && responseMessage === 'Email is already verified.')
        return handleNextStep(STEPS_FLOW.EMAIL_ALREADY_VERIFIED);

      if (
        errorStatus === 400 &&
        (responseMessage === '400: Confirmation code has expired.' ||
          responseMessage === '400: Invalid confirmation code or pin.')
      ) {
        setStateValue({ fieldName: 'confirmationCode', value: '' });
        return notice(ToastType.ERROR, error.response.data?.detail);
      }

      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to set a password, please try again!' } });
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const [setupAccountState, setSetupAccountState] = useState(initialState);

  const setStateValue = ({ fieldName, value }: { fieldName: keyof typeof initialState; value: string }) =>
    setSetupAccountState(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));

  const onChangeNewPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue({ fieldName: 'newPassword', value: event.target.value });
  };

  const onChangeCodeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length <= 6) {
      setStateValue({ fieldName: 'confirmationCode', value });
    }
  };

  const onChangeConfirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateValue({ fieldName: 'confirmPassword', value: event.target.value });
  };

  const { isValid, rules } = resetPasswordValidationData(
    setupAccountState.confirmationCode,
    setupAccountState.newPassword,
    setupAccountState.confirmPassword,
  );

  const hiddenEmail = hideEmail(emailSearchParam);

  return (
    <Stack spacing={2} textAlign={{ xs: 'center', lg: 'start' }}>
      <StepHeader
        subtitle={`Enter the 6-digit verification code that was sent to ${hiddenEmail} and create a password`}
      />
      <Box height={1} component="form" onSubmit={onFormSubmitHandler}>
        <Stack spacing={2} mt="28px !important">
          <MDInput
            label="Verification code"
            placeholder="Enter code"
            onChange={onChangeCodeHandler}
            fullWidth
            name="code"
            type="number"
            inputProps={{
              autoComplete: 'off',
            }}
            InputProps={{
              autoComplete: 'new-password',
            }}
          />

          <MDInputPassword
            value={setupAccountState.newPassword}
            onChange={onChangeNewPasswordHandler}
            label="Password"
            placeholder="Enter your password"
            inputProps={{
              autoComplete: 'off',
            }}
            InputProps={{
              autoComplete: 'new-password',
            }}
          />

          <MDInputPassword
            value={setupAccountState.confirmPassword}
            onChange={onChangeConfirmPasswordHandler}
            label="Confirm Password"
            placeholder="Confirm new password"
            inputProps={{
              autoComplete: 'off',
            }}
            InputProps={{
              autoComplete: 'new-password',
            }}
          />

          <ValidationChecklist validationRules={rules} />

          <MDButton fullWidth disabled={!isValid || isFormSubmitting} isLoading={isFormSubmitting} type="submit">
            Set password
          </MDButton>
        </Stack>
      </Box>

      <BaseDialogWindow
        open={isDialogOpen}
        title="You are already logged in"
        onClickCancelButtonHandler={onClickKeepSessionButtonHandler}
        description="Would you like to start a new session or keep the current session?"
        cancelButtonTitle="New session"
        approveButtonTitle="Keep session"
        onClickApproveButtonHandler={onClickKeepSessionButtonHandler}
        cancelButtonProps={{
          startIcon: <CachedRounded fontSize="medium" />,
          fullWidth: true,
          variant: 'secondary',
          sx: { width: 1 },
          isLoading,
          disabled: isLoading,
          onClick: onClickNewSessionButtonHandler,
        }}
        approveButtonProps={{ disabled: isLoading, fullWidth: true, sx: { width: 1 } }}
        sx={{
          '.MuiDialogContent-root': { textAlign: 'center' },
        }}
        isDivider={false}
      />
    </Stack>
  );
};
