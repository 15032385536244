import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import {
  GlobalLibraryTagsSearchParams,
  TagListSortFieldName,
  UpdateGlobalLibraryTagCategoryPayload,
  createGlobalLibraryTag,
  deleteGlobalLibraryTag,
  deleteGlobalLibraryTagCategory,
  getGlobalLibraryTagCategory,
  getGlobalLibraryTags,
  updateGlobalLibraryTag,
  updateGlobalLibraryTagCategory,
} from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useTable } from 'hooks';
import {
  CreateTagCategoryFormSchema,
  createTagCategoryValidationSchema,
  useEditTagCategoryState,
} from 'pages/TagManagement';
import { useAppStore } from 'store';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

export const useGLtagCategoryProfile = () => {
  const {
    globalLibraryTagCategoryData: {
      category,
      tags: { items: tags, total },
    },
    setGlobalLibraryTagCategory,
    setGlobalLibraryTags,
  } = useAppStore(
    store => ({
      globalLibraryTagCategoryData: store.globalLibraryTagCategoryData,
      setGlobalLibraryTagCategory: store.setGlobalLibraryTagCategory,
      setGlobalLibraryTags: store.setGlobalLibraryTags,
    }),
    shallow,
  );

  const isTagCategoryPublished = category?.is_published;
  const isTagCategoryActive = category?.is_active;

  const {
    register,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<CreateTagCategoryFormSchema>({
    resolver: yupResolver(createTagCategoryValidationSchema) as Resolver<CreateTagCategoryFormSchema>,
    mode: 'onTouched',
    values: {
      name: category?.name || '',
      service_name: category?.service_name || '',
      is_published: category?.is_published || false,
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ name, service_name, is_published }) => {
    try {
      const { data } = await updateGlobalLibraryTagCategory(categoryId, {
        is_active: category?.is_active,
        is_core: category?.is_core,
        is_published,
        name,
        ...(service_name && { service_name }),
      });
      setGlobalLibraryTagCategory(data);

      notice(ToastType.SUCCESS, 'Tag category has been successfully updated!');
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to update tag category, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onActivate = async (id: string) => {
    await updateGlobalLibraryTag(id, { is_active: true, category_id: categoryId });

    notice(ToastType.SUCCESS, 'Tag has been successfully activated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const onDeactivate = async (id: string) => {
    await deleteGlobalLibraryTag(id);

    notice(ToastType.SUCCESS, 'Tag has been successfully inactivated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const updateTagCategory = async (
    updateData: Partial<UpdateGlobalLibraryTagCategoryPayload>,
    successMessage: string,
  ) => {
    await updateGlobalLibraryTagCategory(categoryId, {
      is_active: category?.is_active,
      is_core: category?.is_core,
      name: category?.name,
      service_name: category?.service_name,
      is_published: category?.is_published,
      ...updateData,
    });

    await getTagCategory();

    notice(ToastType.SUCCESS, successMessage);
  };

  const onActivateTagCategory = async () =>
    await updateTagCategory({ is_active: true }, 'Tag category has been successfully activated!');

  const onDeactivateTagCategory = async () => {
    try {
      await deleteGlobalLibraryTagCategory(categoryId);
      await getTagCategory();
      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');
    } catch (error) {
      console.error('Error deactivating tag category:', error);
      notice(ToastType.ERROR, 'Failed to deactivate the tag category. Please try again.');
    }
  };

  const onPublishTagCategory = async () =>
    await updateTagCategory({ is_published: true }, 'Tag category has been successfully published!');

  const onUnpublishTagCategory = async () =>
    await updateTagCategory({ is_published: false }, 'Tag category has been successfully unpublished!');

  const { order, orderBy, onSort, page, rowsPerPage, onChangeRowsPerPage, setPage, resetPageHandler } =
    useTable<TagListSortFieldName>({ defaultOrderBy: 'name', defaultOrder: 'asc' });

  const {
    categoryId,
    searchValue,
    debouncedSearchValue,
    appliedFilters,
    selectedTagId,
    closeAddTagMenu,
    setSelectedTagId,
    onChangeSearchInputValue,
    closeEditTagMenu,
    tagCategoryActionState,
    userRoles: { isRoleAdmin },
    ...restEditState
  } = useEditTagCategoryState({
    onActivate,
    onDeactivate,
    onActivateTagCategory,
    onDeactivateTagCategory,
    onPublishTagCategory,
    onUnpublishTagCategory,
  });

  const [isLoading, setIsLoading] = useState(true);

  const getTagCategory = async () => {
    try {
      const { data } = await getGlobalLibraryTagCategory(categoryId);

      setGlobalLibraryTagCategory(data);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get tag category, please try again!' } });
    }
  };

  const onGetTagCategoryHandler = async () => {
    !isLoading && setIsLoading(true);

    await getTagCategory();
    setIsLoading(false);
  };

  const [isLoadingTags, setIsLoadingTags] = useState(true);

  const onGetTagsByCategoryHandler = async ({
    isResetPage,
    params,
  }: {
    isResetPage?: boolean;
    params?: GlobalLibraryTagsSearchParams;
  }) => {
    !isLoading && setIsLoadingTags(true);

    try {
      const { data } = await getGlobalLibraryTags({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        is_active: !appliedFilters.isShowDeactivated,
        order: [`${orderBy},${order}`],
        tag_category_id: categoryId,
        size: rowsPerPage,
        page,
        ...params,
      });

      setGlobalLibraryTags(data);

      if (isResetPage && page !== 1) {
        setPage(1);
      }
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get tags by category, please try again!' },
      });
    } finally {
      setIsLoadingTags(false);
    }
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagsByCategoryHandler({ params: { page: newPage } });
  };

  useEffect(() => {
    onGetTagCategoryHandler();
  }, []);

  useEffect(() => {
    if (categoryId) onGetTagsByCategoryHandler({});
  }, [debouncedSearchValue, appliedFilters, order, orderBy]);

  const createTagHandler = async (name: string) => {
    await createGlobalLibraryTag({
      is_active: true,
      name,
      category_id: categoryId,
    });

    closeAddTagMenu();

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const editTagHandler = async (name: string, is_active: boolean) => {
    await updateGlobalLibraryTag(selectedTagId, {
      is_active,
      name,
      category_id: categoryId,
    });

    closeEditTagMenu();
    setSelectedTagId('');
    await onGetTagsByCategoryHandler({});
  };

  return {
    category,
    searchValue,
    onChangeSearchInputValue,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
    closeAddTagMenu,
    selectedTagId,
    closeEditTagMenu,
    appliedFilters,
    isDirty,
    tagCategoryActionState,
    isTagCategoryPublished,
    isTagCategoryActive,
    isRoleAdmin,
    ...restEditState,
  };
};
