import { StrictMode } from 'react';

import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'App/App';
import { AWSconfig } from 'lib/AWS_Cognito';

Amplify.configure(AWSconfig);

const root = createRoot(document.getElementById('root'));

root.render(
  // <StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </StrictMode>,
);
