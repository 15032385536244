import { FC, memo, useEffect } from 'react';

import { Divider, InputAdornment, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField, RHFPhoneTextField, RHFTextField, RHFUserRoleAutocomplete } from 'components/HookForm';
import { Icon } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';
import { LABELS } from 'constants/strings';
import { usePatientEnums, useUserRoles } from 'hooks';
import { SectionWrapper } from 'layouts';

import { EditUserFormSchema } from '../form.config';

type UserFormProps = {
  isRoleField: boolean;
};

export const UserForm: FC<UserFormProps> = memo(({ isRoleField }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EditUserFormSchema>();

  const { fetchUserRoles, userRolesState } = useUserRoles();

  const { fetchPatientPersonTitlesEnums, patientPersonTitlesEnumsState } = usePatientEnums();

  useEffect(() => {
    fetchPatientPersonTitlesEnums();
    fetchUserRoles({ practice_roles: true });
  }, []);

  return (
    <Stack width={1} height={1} overflow="auto">
      <Scrollbar sx={{ height: 1 }}>
        <Stack height={1}>
          <SectionWrapper>
            <Stack spacing={2.5}>
              <Stack direction={{ xs: 'column', md: 'row' }} gap={2} alignItems="flex-start">
                <RHFTextField
                  register={register}
                  registerName="first_name"
                  registerErrors={errors.first_name?.message}
                  fullWidth
                  required
                  label={LABELS.FIRST_NAME}
                  placeholder="Enter First Name"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFTextField
                  register={register}
                  registerName="middle_name"
                  registerErrors={errors?.middle_name?.message}
                  fullWidth
                  label={LABELS.MIDDLE_NAME}
                  placeholder="Enter Middle Name"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFTextField
                  register={register}
                  registerName="last_name"
                  registerErrors={errors?.last_name?.message}
                  fullWidth
                  required
                  label={LABELS.LAST_NAME}
                  placeholder="Enter Last Name"
                  fieldWrapperProps={{ width: 1 }}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} gap={2} alignItems="flex-start">
                <RHFTextField
                  register={register}
                  registerName="position"
                  registerErrors={errors?.position?.message}
                  fullWidth
                  label={LABELS.POSITION}
                  placeholder="Enter Position"
                  fieldWrapperProps={{ width: 1 }}
                />

                <RHFAutocompleteField
                  control={control}
                  registerName="title"
                  placeholder="Select Title"
                  label={LABELS.TITLE}
                  options={patientPersonTitlesEnumsState.items}
                  loading={patientPersonTitlesEnumsState.isLoading}
                  valueKey="value"
                  labelKey="value"
                />

                <RHFUserRoleAutocomplete
                  control={control}
                  isRequired
                  registerName="role"
                  options={userRolesState.items}
                  disabled={!isRoleField}
                  loading={userRolesState.isLoading}
                />
              </Stack>
            </Stack>
          </SectionWrapper>

          <Divider />
        </Stack>

        <SectionWrapper>
          <Stack spacing={2}>
            <RHFTextField
              register={register}
              registerName="email"
              registerErrors={errors?.email?.message}
              fullWidth
              required
              label={LABELS.EMAIL}
              placeholder="Enter Email"
              fieldWrapperProps={{ width: { xs: 1, md: '66%' } }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 0 }}>
                      <Icon type="email" />
                    </InputAdornment>
                  ),
                },
              }}
            />

            <Stack
              maxWidth={{
                xs: 1,
                md: '66%',
              }}
              sx={{
                '& .country-list': {
                  bottom: 0,
                  top: 'unset !important',
                },
              }}
            >
              <RHFPhoneTextField
                control={control}
                registerErrors={errors}
                registerName="phone"
                MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
              />
            </Stack>
          </Stack>
        </SectionWrapper>
      </Scrollbar>
    </Stack>
  );
});
