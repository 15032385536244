import { FC } from 'react';

import { CheckRounded } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Stack, Tooltip } from '@mui/material';

import { Icon } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';
import { removeSpanRegExp } from 'constants/regex';
import { useCopyToClipboard } from 'hooks';

type PromptContentProps = {
  promptText: string;
  isLoadingPromptText: boolean;
};

const removeSpanTags = (text: string) => text.replace(removeSpanRegExp, '');

export const PromptContent: FC<PromptContentProps> = ({ promptText = '', isLoadingPromptText }) => {
  const { copyToClipboard, isCopied } = useCopyToClipboard({});

  const plainPromptText = removeSpanTags(promptText);

  const onClickCopyHandler = () => copyToClipboard(plainPromptText);

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} flexGrow={1} width={1} height={1} p={2}>
      {isLoadingPromptText ? (
        <Skeleton variant="rounded" width="100%" sx={{ flexGrow: 1, height: { xs: 250, lg: '100%' } }} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            border: ({ borders, palette }) => `${borders.borderWidth[1]} solid ${palette.secondary.main}`,
            borderRadius: theme => theme.borders.borderRadius[8],
            flexGrow: 1,
            alignItems: 'flex-start',
            position: 'relative',
            overflowY: 'hidden',
            height: 1,
            minHeight: 300,
          }}
        >
          {isCopied ? (
            <CheckRounded
              color="success"
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: theme => theme.zIndex.fab,
              }}
            />
          ) : promptText ? (
            <Tooltip title="Copy" placement="top">
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  zIndex: theme => theme.zIndex.fab,
                }}
                onClick={onClickCopyHandler}
              >
                <Icon type="copy" sx={{ svg: { width: 16, height: 16 } }} />
              </IconButton>
            </Tooltip>
          ) : null}

          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              overflowY: 'auto',
              p: 1.5,
            }}
          >
            <Scrollbar sx={{ height: 1 }}>
              <Box
                sx={{
                  textAlign: 'left',
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'pre-wrap',
                  ...(!promptText && {
                    color: theme => theme.palette.secondary.dark,
                    fontWeight: 500,
                  }),
                  '& span': {
                    color: theme => theme.palette.success.main,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: promptText || 'Start testing to get the result' }}
              />
            </Scrollbar>
          </Box>
        </Box>
      )}
    </Stack>
  );
};
