import { useEffect } from 'react';

import { Grid2, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';

import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';
import { BONE_STRUCTURE_OPTIONS, FACIAL_MUSCLE_STRENGTH_OPTIONS, SKIN_ELASTICITY_OPTIONS } from '../../options';

export const PhysicalExamination: React.FC = () => {
  const {
    formState: { errors },
    register,
    control,
    watch,
    setValue,
  } = useFormContext<QuestionnaireFormSchema>();

  const watchFacialMuscleStrength = watch('facialMuscleStrength');

  useEffect(() => {
    if (watchFacialMuscleStrength !== FACIAL_MUSCLE_STRENGTH_OPTIONS[1].value) {
      setValue('facialMuscleAsymmetryDetails', '');
    }
  }, [watchFacialMuscleStrength]);

  return (
    <FormSectionWrapper>
      <Grid2 spacing={2} container>
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            options={SKIN_ELASTICITY_OPTIONS}
            label="Skin Elasticity"
            placeholder="Select Skin Elasticity"
            control={control}
            registerName="skinElasticity"
            valueKey="value"
            labelKey="value"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFAutocompleteField
            label="Bone Structure"
            control={control}
            registerName="boneStructure"
            options={BONE_STRUCTURE_OPTIONS}
            valueKey="value"
            labelKey="name"
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Stack gap={2}>
            <RHFAutocompleteField
              label="Facial Muscle Strength"
              control={control}
              registerName="facialMuscleStrength"
              placeholder="Select Facial Muscle Strength"
              options={FACIAL_MUSCLE_STRENGTH_OPTIONS}
              valueKey="value"
              labelKey="value"
            />

            {watchFacialMuscleStrength === FACIAL_MUSCLE_STRENGTH_OPTIONS[1].value && (
              <RHFTextField
                label="Facial Muscle Asymmetry Details"
                register={register}
                registerName="facialMuscleAsymmetryDetails"
                placeholder="Enter Facial Muscle Asymmetry Details"
                multiline
                minRows={5}
                maxRows={8}
                registerErrors={errors.facialMuscleAsymmetryDetails?.message}
              />
            )}
          </Stack>
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <RHFTextField
            label="Specific anatomical notes"
            register={register}
            registerName="anatomicalNotes"
            placeholder="Enter Specific Anatomical Notes"
            registerErrors={errors.anatomicalNotes?.message}
            multiline
            minRows={5}
            maxRows={8}
          />
        </Grid2>
      </Grid2>
    </FormSectionWrapper>
  );
};
