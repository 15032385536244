import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { MDChip } from 'components/MDChip';
import { useRouter } from 'hooks';

type ConsultationItemProps = {
  id: string;
};

export const ConsultationItem: React.FC<ConsultationItemProps> = memo(({ id }) => {
  const { navigate, params } = useRouter();

  const onClickItemHandler = () => {
    if (!params?.id) return;

    navigate(`/patients/${params.id}/consultation/${id}`);
  };

  return (
    <Stack
      onClick={onClickItemHandler}
      component="li"
      p={2}
      pl={0}
      gap={0.5}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
        borderBottom: ({ borders, palette }) => `${borders.borderWidth[1]} solid ${palette.divider}`,
      }}
      ml={2}
    >
      <Typography variant="body2" fontWeight={500}>
        Initial Consultation
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <Typography variant="body2" fontWeight={500} color="textSecondary">
          Doctor: Dr. John Doe
        </Typography>
        <MDChip size="small" variant="soft" color="primary" isSquare label="On-going" />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="body2" color="textSecondary">
          May 5, 2024 2:05 - 2:25 PM
        </Typography>
        <Typography variant="body2" color="textBody">
          2:05 – 2:25 PM
        </Typography>
      </Stack>
    </Stack>
  );
});
