import { FC, memo, useMemo, useState } from 'react';

import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialStateValue } from '../config';
import { AppliedFilters, FiltersInitialState } from '../types';

type FilterPopoverProps = {
  appliedFilters: AppliedFilters;
  onApplyFilters: (newFilters: FiltersInitialState) => void;
  onResetFilters: () => void;
  isHideShowDeactived?: boolean;
  isGL?: boolean;
};

export const FilterPopoverContent: FC<FilterPopoverProps> = memo(
  ({ isHideShowDeactived, appliedFilters, onApplyFilters, onResetFilters, isGL = false }) => {
    const [filters, setFilters] = useState(appliedFilters);

    const onClickResetFiltersButtonHandler = () => {
      onResetFilters();
      setFilters(FiltersInitialStateValue);
    };

    const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

    const filterEntries = Object.entries(filters);

    const isApplyButtonDisabled = useMemo(
      () => getFilterApplyButtonStatus({ filterEntries, appliedFilters }),
      [appliedFilters, filters],
    );

    const isResetButtonDisabled = useMemo(
      () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
      [filters],
    );

    const onChangeDeactivatedCheckboxHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isDeactivated: checked }));
    };

    const onChangeActivatedCheckboxHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isActivated: checked }));
    };

    const onChangePublishedCheckboxHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isPublished: checked }));
    };

    const onChangeUnpublishedCheckboxHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      setFilters(prevState => ({ ...prevState, isUnpublished: checked }));
    };

    return (
      <>
        <Stack py={1}>
          {isHideShowDeactived ? null : (
            <Stack spacing={1.5}>
              {isGL && isHideShowDeactived ? null : (
                <Stack spacing={0.5}>
                  <Typography variant="button" fontWeight={500}>
                    By Status
                  </Typography>

                  <Box>
                    <FormControlLabel
                      onChange={onChangeActivatedCheckboxHandler}
                      checked={filters.isActivated}
                      control={<Checkbox />}
                      label="Activated"
                      labelPlacement="end"
                    />
                  </Box>

                  <FormControlLabel
                    onChange={onChangeDeactivatedCheckboxHandler}
                    checked={filters.isDeactivated}
                    control={<Checkbox />}
                    label="Deactivated"
                    labelPlacement="end"
                  />
                </Stack>
              )}

              <Stack spacing={0.5}>
                <Typography variant="button" fontWeight={500}>
                  By Mode
                </Typography>

                <Box>
                  <FormControlLabel
                    onChange={onChangePublishedCheckboxHandler}
                    checked={filters.isPublished}
                    control={<Checkbox />}
                    label="Published"
                    labelPlacement="end"
                  />
                </Box>

                <Box>
                  <FormControlLabel
                    onChange={onChangeUnpublishedCheckboxHandler}
                    checked={filters.isUnpublished}
                    control={<Checkbox />}
                    label="Unpublished"
                    labelPlacement="end"
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Stack>

        <FilterMenuPopoverButtons
          isClearButtonDisabled={isResetButtonDisabled}
          onClickClearButtonHandler={onClickResetFiltersButtonHandler}
          isApplyButtonDisabled={isApplyButtonDisabled}
          onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
        />
      </>
    );
  },
);
