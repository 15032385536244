import { memo, useState } from 'react';

import { Box, Card, CardContent, Collapse, Stack, styled, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';
import { ActionMenuItem, ActionMenuList } from 'components/MenuPopover';
import { usePopover } from 'hooks';

type ContentCardProps = {
  title: string;
  content: string;
  isHTMLcontent?: boolean;
  menuItems?: ActionMenuItem[];
  isCollapseButton?: boolean;
};

export const ContentCard: React.FC<ContentCardProps> = memo(
  ({ isCollapseButton = true, isHTMLcontent, title, content, menuItems }) => {
    const [isShowContent, setIsShowContent] = useState(true);

    const onClickCollapseButtonHandler = () => setIsShowContent(prev => !prev);

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const handleDotsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => handleOpenPopover(event);

    return (
      <Card sx={{ bgcolor: theme => theme.palette.background.surfaceSoft }}>
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" fontSize="size.bodyLarge" fontWeight={600}>
              {title}
            </Typography>

            {(menuItems?.length || isCollapseButton) && (
              <Stack direction="row" gap={1}>
                {menuItems?.length > 0 && (
                  <MDButton size="small" isIconOnly variant="secondary" onClick={handleDotsMenuClick}>
                    <Icon type="menuHorizontalDotsIcon" />
                  </MDButton>
                )}

                {isCollapseButton && (
                  <MDButton size="small" isIconOnly variant="secondary" onClick={onClickCollapseButtonHandler}>
                    <Icon
                      type="arrowLeftIcon"
                      sx={{
                        transform: isShowContent ? 'rotate(90deg)' : 'rotate(-90deg)',
                        transition: theme => theme.transitions.create('transform', { duration: '0.3s' }),
                      }}
                    />
                  </MDButton>
                )}
              </Stack>
            )}
          </Stack>

          <Collapse in={isShowContent} unmountOnExit>
            {isHTMLcontent ? (
              <HTMLcontent dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <Box mt={2}>{content}</Box>
            )}
          </Collapse>
        </CardContent>

        <ActionMenuList onClose={handleClosePopover} open={openPopover} menuItems={menuItems} />
      </Card>
    );
  },
);

const HTMLcontent = styled(Box)(({ theme }) => ({
  fontWeight: '500 !important',
  fontSize: '16px !important',
  color: theme.palette.text.secondary,
  '& *': {
    margin: 0,
    marginTop: '16px',
    padding: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    lineHeight: 'normal',
  },

  '& ul': {
    paddingLeft: '25px',
  },
  '& li': {
    margin: '5px 0',
  },
  '& .section': {
    marginBottom: '16px',
  },
}));
