import * as yup from 'yup';

import { CreateExpertPayload } from 'apiServices';
import { USER_ROLES } from 'types/enums';
import { Shape } from 'types/RHF-types';
import { EmailValidationSchema } from 'utils/formValidation';

export type CreateUserFormSchema = CreateExpertPayload;

export const createUserValidationSchema = yup
  .object()
  .shape<Shape<CreateUserFormSchema>>({
    role: yup.string<USER_ROLES>().required().label('User role'),
    practice_id: yup.string().required().label('Practice'),
    first_name: yup.string().required().label('First name'),
    last_name: yup.string().required().label('Last name'),
    email: EmailValidationSchema,
  })
  .required();

export const defaultValues: CreateUserFormSchema = {
  role: null,
  first_name: '',
  last_name: '',
  email: '',
  practice_id: '',
};
