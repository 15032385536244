import { useCallback, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useDebounce, useRouter, useUserProfile, useActionDialogManagement, useBoolean, usePopover } from 'hooks';
import { TagCategoryTagInitialFilters } from 'pages/TagManagement';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

const FiltersInitialStateValue: TagCategoryTagInitialFilters = {
  isShowDeactivated: false,
};

type UseEditTagCategoryStateProps = {
  onDeactivate: (id: string) => Promise<void>;
  onActivate: (id: string) => Promise<void>;
  onActivateTagCategory: () => Promise<void>;
  onDeactivateTagCategory: () => Promise<void>;
  onPublishTagCategory: () => Promise<void>;
  onUnpublishTagCategory: () => Promise<void>;
};

export const useEditTagCategoryState = ({
  onDeactivate,
  onActivate,
  onActivateTagCategory,
  onDeactivateTagCategory,
  onPublishTagCategory,
  onUnpublishTagCategory,
}: UseEditTagCategoryStateProps) => {
  const tagCategoryActionState = useActionDialogManagement();

  const handleTagCategoryAction = async (
    action: () => Promise<void>,
    closeDialog: () => void,
    errorMessage: string,
  ) => {
    tagCategoryActionState.setIsActionPending(true);

    try {
      await action();
      closeDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: errorMessage },
      });
    } finally {
      tagCategoryActionState.setIsActionPending(false);
    }
  };

  const onActivateTagCategoryHandler = () =>
    handleTagCategoryAction(
      onActivateTagCategory,
      tagCategoryActionState.getCloseDialogHandler('activate'),
      'Failed to activate tag category, please try again!',
    );

  const onDeactivateTagCategoryHandler = () =>
    handleTagCategoryAction(
      onDeactivateTagCategory,
      tagCategoryActionState.getCloseDialogHandler('deactivate'),
      'Failed to deactivate tag category, please try again!',
    );

  const onPublishTagCategoryHandler = () =>
    handleTagCategoryAction(
      onPublishTagCategory,
      tagCategoryActionState.getCloseDialogHandler('publish'),
      'Failed to publish tag category, please try again!',
    );

  const onUnpublishTagCategoryHandler = () =>
    handleTagCategoryAction(
      onUnpublishTagCategory,
      tagCategoryActionState.getCloseDialogHandler('unpublish'),
      'Failed to unpublish tag category, please try again!',
    );

  const {
    getCloseDialogHandler,
    onClickActionOptionHandler,
    dialogStates,
    closeDialog,
    actionState,
    isActionPending,
    setIsActionPending,
  } = useActionDialogManagement();

  const { params } = useRouter();

  const categoryId = params?.id;

  const [isAddTagMenuOpen, openAddTagMenu, closeAddTagMenu] = useBoolean();
  const [isEditTagMenuOpen, openEditTagMenu, closeEditTagMenu] = useBoolean();

  const [selectedTagId, setSelectedTagId] = useState('');

  const onClickEditOptionMenuHandler = useCallback((tagId: string) => {
    openEditTagMenu();
    setSelectedTagId(tagId);
  }, []);

  const onDeactivateTagHandler = async () => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await onDeactivate(id);

      closeDialog('deactivate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateTagHandler = async () => {
    setIsActionPending(true);
    const { id } = actionState;

    try {
      await onActivate(id);

      closeDialog('activate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const [searchParams] = useSearchParams();

  const urlTagParam = searchParams.get('tag');

  const [searchValue, setSearchValue] = useState(urlTagParam || '');

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const onChangeSearchInputValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }, []);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [appliedFilters, setAppliedFilters] = useState<TagCategoryTagInitialFilters>(FiltersInitialStateValue);

  const onApplyFilters = useCallback((newFilters: TagCategoryTagInitialFilters) => {
    setAppliedFilters(newFilters);
    closeFiltersMenu();
  }, []);

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters],
  );

  const { userRoles } = useUserProfile();

  return {
    isFiltersApplied,
    onApplyFilters,
    appliedFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    categoryId,
    debouncedSearchValue,
    onChangeSearchInputValue,
    searchValue,
    urlTagParam,
    onClickActionOptionHandler,
    dialogStates,
    getCloseDialogHandler,
    actionState,
    isActionPending,
    setIsActionPending,
    selectedTagId,
    onClickEditOptionMenuHandler,
    setSelectedTagId,
    isEditTagMenuOpen,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    closeEditTagMenu,
    openEditTagMenu,
    onDeactivateTagHandler,
    onActivateTagHandler,
    tagCategoryActionState,
    onActivateTagCategoryHandler,
    onDeactivateTagCategoryHandler,
    onPublishTagCategoryHandler,
    onUnpublishTagCategoryHandler,
    userRoles,
  };
};
