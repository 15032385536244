import { apiClient } from 'lib/api';

import { GetUserRolesParams, PatientEnum, PatientEnums, UserRolesEnum } from './types';

const BASE_PATIENT_ENUM_API_URL = '/patient/enums';

export const getPatientEnums = async () => await apiClient.get<PatientEnums>(BASE_PATIENT_ENUM_API_URL);

export const getPatientGenderEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/genders`);

export const getPatientBiologicalSexesEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/biological_sexes`);

export const getPatientEthnicitiesEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/ethnicities`);

export const getPatientPainTolerancesEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/pain_tolerances`);

export const getPatientPersonTitlesEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/person_titles`);

export const getPatientBloodTypesEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/blood_types`);

export const getPatientSmokingEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/smoking`);

export const getPatientAlcoholConsumptionsEnums = async () =>
  await apiClient.get<PatientEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/alcohol_consumption`);

export const getUserRoles = async (params?: GetUserRolesParams) =>
  await apiClient.get<UserRolesEnum[]>(`${BASE_PATIENT_ENUM_API_URL}/roles`, { params });
