import { FC } from 'react';

import { Stack, Typography, alpha } from '@mui/material';

import { useCopyToClipboard } from 'hooks';

import { Icon } from '../Icon';

interface Props {
  label: string;
}

export const ClipboardLabel: FC<Props> = props => {
  const { label } = props;

  const { copyToClipboard, isCopied } = useCopyToClipboard({});

  const onClickCopy = async () => copyToClipboard(label);

  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme => theme.borders.borderRadius[6],
        px: 1,
        py: '6px',
        bgcolor: theme => alpha(theme.palette.primary.light, 0.1),
        ':hover': {
          cursor: 'pointer',
        },
      }}
      onClick={onClickCopy}
    >
      <Typography color="primary.light" fontSize={12} fontWeight={400}>
        {label}
      </Typography>

      <Icon
        sx={{
          svg: {
            width: 14,
            height: 14,
            color: ({ palette }) => palette.primary.light,
          },
        }}
        type={isCopied ? 'checkmarkIcon' : 'copy'}
      />
    </Stack>
  );
};
