import { useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';

import { GLServicesSearchParams, importToPLService } from 'apiServices';
import {
  activateGLService,
  deactivateGLService,
  deleteGLService,
  getGLServices,
  updateGLService,
} from 'apiServices/dictionary/servicesGlobalLibrary.api';
import { useUserProfile } from 'hooks/useUserProfile';
import { CATALOG_SERVICE_MESSAGES } from 'pages/Catalog/constants';
import { useCatalogTableState } from 'pages/Catalog/useCatalogTable.state';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

export const useGLServicesState = () => {
  const {
    GLServices: { items, total },
    setGLServices,
  } = useAppStore(
    store => ({
      GLServices: store.catalogGLData.GLServices,
      setGLServices: store.setGLServices,
    }),
    shallow,
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeUser },
    practiceId,
  } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const onGetServicesHandler = async (params?: GLServicesSearchParams) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGLServices({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        page,
        ...params,
      });

      setGLServices({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (params?.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get services, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    setPage,
    resetPageHandler,
    page,
    rowsPerPage,
    debouncedSearchValue,
    appliedFilters,
    isAddItemDialogOpen,
    openAddItemDialog,
    closeAddItemDialog,
    ...catalogTableStateMethods
  } = useCatalogTableState({
    fetchItems: onGetServicesHandler,
    updateItem: updateGLService,
    deleteItem: deleteGLService,
    copyItem: importToPLService,
    practiceId,
    messages: {
      activationError: CATALOG_SERVICE_MESSAGES.activateFailed,
      activationSuccess: CATALOG_SERVICE_MESSAGES.activateSuccess,
      deactivationError: CATALOG_SERVICE_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_SERVICE_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_SERVICE_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_SERVICE_MESSAGES.deleteSuccess,
      copyError: CATALOG_SERVICE_MESSAGES.copyFailed,
      copySuccess: CATALOG_SERVICE_MESSAGES.copySuccess,
    },
  });

  useEffect(() => {
    onGetServicesHandler({});
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    items,
    isLoading,
    total,
    rowsPerPage,
    page,
    appliedFilters,
    isAddServiceDialogOpen: isAddItemDialogOpen,
    openAddServiceDialog: openAddItemDialog,
    closeAddServiceDialog: closeAddItemDialog,
    onGetServicesHandler,
    isRoleAdmin,
    isRolePracticeUser,
    ...catalogTableStateMethods,
  };
};
