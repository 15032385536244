import { FC } from 'react';

import { styled } from '@mui/material';

import { MDButton, MDButtonProps } from 'components/MDButton';

type FileInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'formAction'>;
type UploadFileButtonProps = MDButtonProps & {
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  // inputProps?: FileInputProps;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
// as React.FC<React.InputHTMLAttributes<HTMLInputElement>>;

export const UploadFileButton: FC<UploadFileButtonProps> = ({ onChangeHandler, children, inputProps, ...props }) => {
  return (
    <MDButton component="label" role={undefined} {...props}>
      {children}
      {/* <input type="file" id="uploadInput" onChange={onChangeHandler} {...inputProps} /> */}
      <VisuallyHiddenInput id="uploadInput" type="file" onChange={onChangeHandler} {...inputProps} />
    </MDButton>
  );
};
