import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { PracticeLibraryTagsSearchParams, createPracticeLibraryTagCategory } from 'apiServices';
import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFSwitch, RHFTextField } from 'components/HookForm';
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper';
import { ToastType, notice } from 'components/ToastNotification';
import { useOptions } from 'hooks';
import {
  CreateTagCategoryFormSchema,
  createTagCategoryDefaultValues,
  createTagCategoryValidationSchema,
} from 'pages/TagManagement';
import { formErrorHandler } from 'utils/errorHanders';

type CreateTagCategoryMenuContentProps = {
  closeMenuHandler: VoidFunction;
  resetPageHandler: VoidFunction;
  fetchTagCategories: (params: PracticeLibraryTagsSearchParams) => Promise<void>;
  isPracticeField: boolean;
};

export const CreateTagCategoryMenuContent: FC<CreateTagCategoryMenuContentProps> = ({
  closeMenuHandler,
  fetchTagCategories,
  resetPageHandler,
  isPracticeField,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateTagCategoryFormSchema>({
    resolver: yupResolver(createTagCategoryValidationSchema) as Resolver<CreateTagCategoryFormSchema>,
    mode: 'onTouched',
    defaultValues: createTagCategoryDefaultValues,
    context: { isPracticeIdRequired: isPracticeField },
  });

  const { debouncedPracticeSearchValue, fetchPractices, practicesState, onChangePracticeInputValueHandler } =
    useOptions({
      isImmediateFetchPractices: isPracticeField,
    });

  const onSubmitFormHandler = handleSubmit(async ({ name, service_name, practice_id, is_published }) => {
    try {
      await createPracticeLibraryTagCategory({
        is_active: true,
        is_published,
        name,
        ...(service_name && {
          service_name,
        }),
        ...(practice_id && {
          practice_id,
        }),
      });

      closeMenuHandler();

      await fetchTagCategories({ page: 1 });
      resetPageHandler();

      notice(ToastType.SUCCESS, 'Tag category has been successfully created!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to create tag category, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onSubmitFormHandler}
      headerProps={{ title: 'Add Tag Category', onClickCancelButtonHandler: closeMenuHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitting || !isValid, isLoading: isSubmitting },
      }}
    >
      <Stack spacing={2}>
        <RHFTextField
          fullWidth
          register={register}
          label="Tag category name"
          registerErrors={errors?.name?.message}
          registerName="name"
          required
          placeholder="Enter Name"
        />
        <RHFSwitch control={control} registerName="is_published" label="Published" />

        {isPracticeField && (
          <InfiniteScrollWrapper
            searchQuery={debouncedPracticeSearchValue}
            totalPages={practicesState.totalPages}
            fetchItemsHandler={fetchPractices}
          >
            {({ ref }) => (
              <RHFAutocompleteField
                control={control}
                registerName="practice_id"
                isRequired
                label="Practice"
                placeholder="Select Practice"
                options={practicesState.items}
                onInputChange={onChangePracticeInputValueHandler}
                loading={practicesState.isLoading}
                valueKey="id"
                labelKey="name"
                lastElementRef={ref}
              />
            )}
          </InfiniteScrollWrapper>
        )}
      </Stack>
    </FormDialogWindowContent>
  );
};
