import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material';

import borders from 'styles/theme/base/borders';
import { boxShadows } from 'styles/theme/base/boxShadows';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';
import pxToRem from 'styles/theme/functions/pxToRem';

const { size } = typography;
const { text, background } = palette;
const { borderRadius } = borders;

const menu: {
  defaultProps?: ComponentsProps['MuiMenu'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiMenu'];
  variants?: ComponentsVariants<Theme>['MuiMenu'];
} = {
  defaultProps: {
    disableAutoFocusItem: true,
  },

  styleOverrides: {
    paper: {
      minWidth: pxToRem(160),
      boxShadow: boxShadows['shadow-md'],
      padding: 0,
      fontSize: size.bodyMedium,
      color: text.primary,
      textAlign: 'left',
      backgroundColor: background.default,
      borderRadius: borderRadius[8],
    },
  },
};

export default menu;
