import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import {
  activate_pl_ba_album,
  activate_pl_ba_album_pair,
  deactivate_pl_ba_album,
  deactivate_pl_ba_album_pair,
  delete_pl_ba_album_cover,
  delete_pl_ba_album_pair,
  get_pl_ba_album,
  get_pl_ba_album_pairs,
  update_pl_ba_album,
  upload_pl_ba_album_cover,
} from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useRouter } from 'hooks';
import { CreateBAalbumFormSchema, createBAalbumValidationSchema } from 'pages/BeforeAfter/components';
import { useAppStore } from 'store';
import { formErrorHandler } from 'utils/errorHanders';

import { useAlbumProfileState } from '../useAlbumProfile.state';

export const usePLBeforeAfterAlbumProfileState = () => {
  const { params } = useRouter();

  const albumId = params?.albumId;

  const { b_a_pl_album, b_a_pl_album_pairs, set_next_b_a_pl_album_pairs, set_b_a_pl_album, set_b_a_pl_album_pairs } =
    useAppStore(
      store => ({
        b_a_pl_album: store.b_a_pl_album,
        b_a_pl_album_pairs: store.b_a_pl_album_pairs,
        set_b_a_pl_album: store.set_b_a_pl_album,
        set_b_a_pl_album_pairs: store.set_b_a_pl_album_pairs,
        set_next_b_a_pl_album_pairs: store.set_next_b_a_pl_album_pairs,
      }),
      shallow,
    );

  const formMethods = useForm<CreateBAalbumFormSchema>({
    resolver: yupResolver(createBAalbumValidationSchema),
    mode: 'onTouched',
    values: { name: b_a_pl_album?.name, description: b_a_pl_album?.description || '' },
  });

  const { handleSubmit, setError } = formMethods;

  const onFormSubmitHandler = handleSubmit(async ({ name, description }) => {
    try {
      const { data } = await update_pl_ba_album(albumId, { name, ...(description && { description }) });

      notice(ToastType.SUCCESS, 'Album has been successfully edited!');

      set_b_a_pl_album({
        ...data,
        ...(b_a_pl_album.cover_thumb_url && {
          coverThumbUrl: b_a_pl_album.cover_thumb_url,
        }),
      });
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to edit album, please try again!',
      });
    }
  });

  const albumProfileStateMethods = useAlbumProfileState({
    setAlbum: set_b_a_pl_album,
    fetchAlbumApi: get_pl_ba_album,
    totalPages: b_a_pl_album_pairs.pages,
    activateAlbumApi: activate_pl_ba_album,
    deactivateAlbumApi: deactivate_pl_ba_album,
    deleteAlbumCoverApi: delete_pl_ba_album_cover,
    uploadAlbumCoverApi: upload_pl_ba_album_cover,
    setAlbumPairs: set_b_a_pl_album_pairs,
    fetchAlbumPairsApi: get_pl_ba_album_pairs,
    setNextAlbumPairs: set_next_b_a_pl_album_pairs,
    deleteAlbumPairApi: delete_pl_ba_album_pair,
    activateAlbumPairApi: activate_pl_ba_album_pair,
    deactivateAlbumPairApi: deactivate_pl_ba_album_pair,
    libraryType: 'pl',
  });

  return {
    b_a_pl_album,
    b_a_pl_album_pairs,
    onFormSubmitHandler,
    albumId,
    formMethods,
    ...albumProfileStateMethods,
  };
};
