import { useEffect, useMemo, useState } from 'react';

import { AxiosResponse } from 'axios';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useBoolean, useDebounce, usePopover, useTable } from 'hooks';
import { BaseFilterState } from 'types';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied } from 'utils/helpers';

interface BaseTableStateParams<TItem> {
  fetchItems: (params?: { page?: number; [key: string]: any }) => Promise<void>;
  updateItem: (id: string, payload: { is_active: boolean }) => Promise<AxiosResponse<TItem, any>>;
  deleteItem: (id: string) => Promise<AxiosResponse<any, any>>;
  messages: {
    activationSuccess?: string;
    activationError?: string;
    deactivationSuccess?: string;
    deactivationError?: string;
    deletionSuccess?: string;
    deletionError?: string;
  };
}

interface CopySpecificParams<TItem> {
  copyItem: (payload: {
    source_id: string;
    practice_id: string;
    alternative_name?: string;
  }) => Promise<AxiosResponse<TItem, any>>;
  practiceId: string;
  messages: {
    copySuccess: string;
    copyError: string;
  };
}

type UseCatalogTableStateParams<TItem> = BaseTableStateParams<TItem> &
  (
    | CopySpecificParams<TItem>
    | {
        copyItem?: never;
        practiceId?: never;
        messages: {
          copySuccess?: never;
          copyError?: never;
        };
      }
  );

const FiltersInitialStateValue: BaseFilterState = {
  isShowDeactivated: false,
};

export const useCatalogTableState = <TItem>({
  fetchItems,
  deleteItem,
  updateItem,
  copyItem,
  messages,
  practiceId: practice_id,
}: UseCatalogTableStateParams<TItem>) => {
  const {
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    setPage,
    closeFiltersMenu,
    openFilterMenu,
    searchValue,
    debouncedSearchValue,
    onChangeSearchValueHandler,
    appliedFilters,
    isFiltersApplied,
    isFiltersMenuOpen,
    onApplyFilters,
    resetPageHandler,
    setResetFilters,
  } = useTable({ defaultFilters: FiltersInitialStateValue });

  const [isAddItemDialogOpen, openAddItemDialog, closeAddItemDialog] = useBoolean();

  const {
    actionState,
    isActionPending,
    dialogStates,
    getCloseDialogHandler,
    getOpenDialogHandler,
    closeDialog,
    setIsActionPending,
    onClickActionOptionHandler,
  } = useActionDialogManagement();

  const [categoryName, setCategoryName] = useState<string>(actionState.name);

  useEffect(() => {
    setCategoryName(actionState.name);
  }, [actionState.name]);

  const handleChangeCategoryName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCategoryName(event.target.value);
  };

  const onDeactivateServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await updateItem(id, { is_active: false });

      notice(ToastType.SUCCESS, messages?.deactivationSuccess);

      closeDialog('deactivate');

      await fetchItems({ page: 1 });
      resetPageHandler();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.deactivationError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await updateItem(id, { is_active: true });

      notice(ToastType.SUCCESS, messages?.activationSuccess);
      closeDialog('activate');

      await fetchItems({ page: 1 });
      resetPageHandler();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.activationError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onDeleteServiceHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deleteItem(id);

      notice(ToastType.SUCCESS, messages?.deletionSuccess);

      closeDialog('delete');

      await fetchItems({ page: 1 });

      resetPageHandler();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.deletionError,
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await fetchItems({ page: newPage });
  };

  const onCopyItemHandler = async () => {
    if (!copyItem && !practice_id) return;

    setIsActionPending(true);
    try {
      await copyItem({ source_id: actionState.id, practice_id, alternative_name: categoryName });

      closeDialog('copy');
      notice(ToastType.SUCCESS, messages?.copySuccess || 'Item copied successfully!');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: messages?.copyError || 'Failed to copy item, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  return {
    searchValue,
    onChangeSearchValueHandler,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    onDeleteServiceHandler,
    debouncedSearchValue,
    setPage,
    isAddItemDialogOpen,
    openAddItemDialog,
    closeAddItemDialog,
    resetPageHandler,
    onCopyItemHandler,
    handleChangeCategoryName,
    categoryName,
    dialogStates,
    getCloseDialogHandler,
    getOpenDialogHandler,
    setResetFilters,
  };
};
