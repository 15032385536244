import { Stack, Typography } from '@mui/material';

import { EmptyStateWithAction } from 'components/EmptyState';
import { Icon } from 'components/Icon';
import { WebViewTableToolbar } from 'components/Widgets';
import { PageWrapper } from 'layouts';

import { ConsultationItem } from './components/ConsultationItem';

export const WebViewConsultation: React.FC = () => {
  return (
    <PageWrapper component="div" px={0}>
      <WebViewTableToolbar
        placeholder="Search"
        value=""
        onChangeInputHandler={function () {}}
        onClickFilterButtonHandler={function () {}}
      />
      <Stack component="ul">
        <ConsultationItem id="1" />
        <ConsultationItem id="2" />
      </Stack>

      <EmptyStateWithAction
        title="No Consultations Yet"
        iconType="listViewIcon"
        subtitle={
          <Typography variant="body1" fontWeight={500} color="text.secondary" textAlign="center">
            Tap
            <Icon type="startRecordingIcon" sx={{ display: 'inline-flex', verticalAlign: 'middle', mx: 0.5 }} />
            to start a patient consultation and begin consultation history
          </Typography>
        }
      />
    </PageWrapper>
  );
};
