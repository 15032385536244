export const PREVIOUS_AESTHETIC_TREATMENTS_OPTIONS = [
  { name: 'none', value: 'None' },
  { name: 'botox', value: 'Botox' },
  { name: 'dermal_fillers', value: 'Dermal Fillers' },
  { name: 'chemical_peels', value: 'Chemical Peels' },
  { name: 'laser_treatments', value: 'Laser Treatments' },
];

export const PRIMARY_CONCERNS_OPTIONS = [
  { name: 'forehead_lines', value: 'Forehead Lines' },
  { name: 'glabellar_lines', value: 'Glabellar Lines (11s)' },
  { name: 'crows_feet', value: "Crow's Feet" },
  { name: 'nasolabial_folds', value: 'Nasolabial Folds' },
  { name: 'marionette_lines', value: 'Marionette Lines' },
  { name: 'lip_volume', value: 'Lip Volume' },
  { name: 'cheek_volume', value: 'Cheek Volume' },
  { name: 'jaw_definition', value: 'Jaw Definition' },
  { name: 'neck_lines', value: 'Neck Lines' },
  { name: 'chemical_peels', value: 'Chemical Peels' },
];

export const SECONDARY_CONCERNS_OPTIONS = [
  { name: 'under_eye_hollows', value: 'Under-Eye Hollows' },
  { name: 'chin_projection', value: 'Chin Projection' },
  { name: 'facial_asymmetry', value: 'Facial Asymmetry' },
  { name: 'skin_texture', value: 'Skin Texture' },
  { name: 'nose_shape', value: 'Nose Shape' },
];

export const DESIRED_OUTCOME_OPTIONS = [
  { name: 'subtle_natural', value: 'Subtle, Natural-Looking Results' },
  { name: 'moderate_improvement', value: 'Moderate Improvement' },
  { name: 'dramatic_change', value: 'Dramatic Change' },
];

export const BUDGET_CONSTRAINTS_OPTIONS = [
  { name: 'limited_budget', value: 'Limited Budget' },
  { name: 'moderate_budget', value: 'Moderate Budget' },
  { name: 'comprehensive_treatment', value: 'Open to Comprehensive Treatment' },
];

export const FACIAL_EXPRESSIONS_OPTIONS = [
  { name: 'furrows_brow', value: 'Frequently Furrows Brow' },
  { name: 'raises_eyebrows', value: 'Often Raises Eyebrows' },
  { name: 'squints_frequently', value: 'Squints Frequently' },
  { name: 'purses_lips', value: 'Purses Lips Often' },
  { name: 'other', value: 'Other' },
];

export const SLEEP_HABITS_OPTIONS = [
  { name: 'sleeps_on_back', value: 'Sleeps on Back' },
  { name: 'sleeps_on_side_left', value: 'Sleeps on Side (Left)' },
  { name: 'sleeps_on_side_right', value: 'Sleeps on Side (Right)' },
  { name: 'sleeps_on_stomach', value: 'Sleeps on Stomach' },
];

export const RECENT_WEIGHT_CHANGES_OPTIONS = [
  { name: 'no_significant_changes', value: 'No Significant Changes' },
  { name: 'lost_weight', value: 'Lost Weight' },
  { name: 'gained_weight', value: 'Gained Weight' },
];

export const HORMONAL_STATUS_OPTIONS = [
  { name: 'premenopausal', value: 'Premenopausal' },
  { name: 'perimenopausal', value: 'Perimenopausal' },
  { name: 'postmenopausal', value: 'Postmenopausal' },
  { name: 'hormone_replacement', value: 'On Hormone Replacement Therapy' },
];

export const SKIN_ELASTICITY_OPTIONS = [
  { name: 'excellent', value: 'Excellent' },
  { name: 'good', value: 'Good' },
  { name: 'moderate_loss', value: 'Moderate Loss' },
  { name: 'significant_loss', value: 'Significant Loss' },
];

export const FACIAL_MUSCLE_STRENGTH_OPTIONS = [
  { name: 'symmetrical', value: 'Symmetrical' },
  { name: 'asymmetrical', value: 'Asymmetrical' },
];

export const BONE_STRUCTURE_OPTIONS = [
  { name: 'symmetrical', value: 'Symmetrical' },
  { name: 'slight_asymmetry', value: 'Slight Asymmetry' },
  { name: 'noticeable_asymmetry', value: 'Noticeable Asymmetry' },
];

export const PREVIOUS_BOTULINUM_TOXIN_TREATMENTS_OPTIONS = [
  { name: 'none', value: 'None' },
  { name: 'areas_treated', value: 'Areas Treated' },
  { name: 'last_treatment_date', value: 'Last Treatment Date' },
  { name: 'product_used', value: 'Product Used' },
  { name: 'dosage', value: 'Dosage (if known)' },
];

export const PREVIOUS_DERMAL_FILLER_TREATMENTS_OPTIONS = [
  { name: 'none', value: 'None' },
  { name: 'areas_treated', value: 'Areas Treated' },
  { name: 'last_treatment_date', value: 'Last Treatment Date' },
  { name: 'products_used', value: 'Product(s) Used' },
  { name: 'volume_injected', value: 'Volume Injected (if known)' },
];

export const PATIENT_SATISFACTION_OPTIONS = [
  { name: 'very_satisfied', value: 'Very Satisfied' },
  { name: 'satisfied', value: 'Satisfied' },
  { name: 'neutral', value: 'Neutral' },
  { name: 'dissatisfied', value: 'Dissatisfied' },
  { name: 'very_dissatisfied', value: 'Very Dissatisfied' },
];

export const SKIN_TYPE_OPTIONS = [
  { value: 'type_1', name: 'Type I - Very fair, always burns, never tans' },
  { value: 'type_2', name: 'Type II - Fair, burns easily, tans minimally' },
  { value: 'type_3', name: 'Type III - Medium, sometimes burns, gradually tans' },
  { value: 'type_4', name: 'Type IV - Olive, rarely burns, tans well' },
  { value: 'type_5', name: 'Type V - Brown, very rarely burns, tans darkly' },
  { value: 'type_6', name: 'Type VI - Dark brown/black, never burns, deeply pigmented' },
];

export const SUN_EXPOSURE_OPTIONS = [
  { value: 'low', name: 'Low - Minimal sun exposure' },
  { value: 'moderate', name: 'Moderate - Regular sun exposure' },
  { value: 'high', name: 'High - Frequent/prolonged sun exposure' },
  { value: 'very_high', name: 'Very High - Intense sun exposure, outdoor lifestyle' },
];
