import { useEffect, useState, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import { PLServicesSearchParams, updatePLService, getPLServices, deletePLService } from 'apiServices';
import { useUserProfile } from 'hooks';
import { CATALOG_SERVICE_MESSAGES } from 'pages/Catalog/constants';
import { useCatalogTableState } from 'pages/Catalog/useCatalogTable.state';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';

export const usePLServicesState = () => {
  const {
    PLServices: { items, total },
    setPLServices,
  } = useAppStore(
    store => ({
      PLServices: store.catalogPLData.PLServices,
      setPLServices: store.setPLServices,
    }),
    shallow,
  );

  const {
    userRoles: { isRolePracticeUser, isRoleAdmin },
  } = useUserProfile();

  const [isPending, startTransition] = useTransition();

  const onGetServicesHandler = async (params: Partial<PLServicesSearchParams>) =>
    startTransition(async () => {
      try {
        const { data } = await getPLServices({
          ...(debouncedSearchValue && { search: debouncedSearchValue }),
          is_active: !appliedFilters.isShowDeactivated,
          size: rowsPerPage,
          page,
          ...params,
        });

        setPLServices({
          items: data.items,
          page: data.page,
          pages: data.pages,
          size: data.size,
          total: data.total,
        });
      } catch (error) {
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to get practice library services, please try again!' },
        });
      }
    });

  const {
    page,
    appliedFilters,
    rowsPerPage,
    isAddItemDialogOpen,
    debouncedSearchValue,
    setPage,
    openAddItemDialog,
    closeAddItemDialog,
    ...catalogTableStateMethods
  } = useCatalogTableState({
    fetchItems: onGetServicesHandler,
    deleteItem: deletePLService,
    updateItem: updatePLService,
    messages: {
      activationError: CATALOG_SERVICE_MESSAGES.activateFailed,
      activationSuccess: CATALOG_SERVICE_MESSAGES.activateSuccess,
      deactivationError: CATALOG_SERVICE_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_SERVICE_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_SERVICE_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_SERVICE_MESSAGES.deleteSuccess,
    },
  });

  useEffect(() => {
    onGetServicesHandler({});
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    items,
    isLoading: isPending,
    total,
    rowsPerPage,
    page,
    appliedFilters,
    isAddServiceDialogOpen: isAddItemDialogOpen,
    openAddServiceDialog: openAddItemDialog,
    closeAddServiceDialog: closeAddItemDialog,
    onGetServicesHandler,
    isRolePracticeUser,
    isRoleAdmin,
    ...catalogTableStateMethods,
  };
};
