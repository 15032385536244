export enum ROUTES {
  home = '/',
  signIn = '/authentification/sign-in',
  setupAccount = '/setup-account',
  forgotPassword = '/authentification/forgot-password',
  resetPassword = '/authentification/reset-password',
  recentlyLoggedInUsers = '/authentification/recently-logged-in-users',
  recentlyLoggedInUsersSignIn = '/authentification/recently-logged-in-users/sign-in',
  profile = '/profile',
  profileSecurity = '/profile/security',
  practiceManagement = '/practice-management',
  practiceManagementProfile = '/practice-management/:id',
  users = '/users',
  usersProfile = '/users/:id',
  patients = '/patients',
  patientProfile = '/patients/:id',
  patientConcernProfile = '/patients/:id/concern/:concernId',
  patientConsultationProfile = '/patients/:id/consultation/:consultationId',
  patientFormsProfile = '/patients/:id/forms/:formId',
  tagManagement = '/tag-management',
  tagManagementGLTagCategoryProfile = '/tag-management/gl/:id',
  tagManagementPLTagCategoryProfile = '/tag-management/pl/:id',
  catalog = '/catalog',
  catalogGLServiceProfile = '/catalog/gl-service/:id',
  catalogPLServiceProfile = '/catalog/pl-service/:id',
  catalogGLProductProfile = '/catalog/gl-product/:id',
  catalogPLProductProfile = '/catalog/pl-product/:id',
  promptLibrary = '/prompt-library',
  viewPrompt = '/prompt-library/:id',
  updatePrompt = '/prompt-library/update-prompt/:id',
  addAIModel = '/prompt-library/add-ai-model',
  updateAIModel = '/prompt-library/update-ai-model/:id',
  addAIModelVersion = '/prompt-library/add-ai-model-version',
  modelList = '/prompt-library/ai-model-list',
  aiModelVersions = '/ai-models/:model_name/:model_id/versions',
  updateAIModelVersion = '/update-ai-models/:model_name/:version_id/versions',
  beforeAfter = '/before-and-after-galleries',
  promptVersionList = '/prompt-versions-list/:prompt_id',
  promptVersionUpdate = '/prompt-version-form/:version_id',
  promptVersionAdd = '/prompt-add-version-form',
  beforeAfterGLProfile = '/before-and-after-galleries/gl/:albumId',
  beforeAfterPLProfile = '/before-and-after-galleries/pl/:albumId',
  beforeAfterPhotosGLProfile = '/before-and-after-galleries/gl/:albumId/:id',
  beforeAfterPhotosPLProfile = '/before-and-after-galleries/pl/:albumId/:id',
  workflows = '/workflows',
  createWorkflow = '/workflows/create',
  workflowsGLprofile = '/workflows/gl/:id',
  workflowsPLprofile = '/workflows/pl/:id',
  notFound = '*',
}
