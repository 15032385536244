import { Box, Stack } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyStateWithAction } from 'components/EmptyState';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { getActivateMenuItem } from 'components/MenuPopover';
import { EntityHeader } from 'components/Widgets';

import { FormContentSkeleton, UserForm } from './components';
import { useUserProfileState } from './useUserProfile.state';

export const UserProfile: React.FC = () => {
  const {
    user,
    formMethods,
    isSubmitting,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isLoading,
    onGetUserHandler,
    isNoUser,
    isHideRoleField,
    isDirty,
    dialogStates,
    getOpenDialogHandler,
    getCloseDialogHandler,
    isActionPending,
    onActivateUserHandler,
    onDeactivateUserHandler,
    name,
    onUploadAvatarHandler,
    onDeleteAvatarHandler,
    avatarSrc,
    isCanEditAvatar,
  } = useUserProfileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
      }}
    >
      {isNoUser ? (
        <EmptyStateWithAction
          title="Failed to get user"
          subtitle="Please use the button below to try once again."
          actionButtonProps={{
            title: 'Get user',
            onClickHandler: onGetUserHandler,
          }}
        />
      ) : (
        <Stack
          width={1}
          sx={{
            ...(!isLoading && {
              height: 1,
            }),
          }}
        >
          <EntityHeader
            title={name}
            role={user?.role}
            activeStatusProps={{
              isActive: user?.is_active,
            }}
            avatarProps={{
              src: avatarSrc,
              isDisabled: !isCanEditAvatar,
              initialPhotoUrl: user?.photo_url,
              onCreateFileHandler: onUploadAvatarHandler,
              onDeleteFileHandler: onDeleteAvatarHandler,
            }}
            actionMenuItems={[
              getActivateMenuItem(
                user?.is_active,
                user?.is_active ? getOpenDialogHandler('deactivate') : getOpenDialogHandler('activate'),
              ),
            ]}
            isLoading={isLoading}
            skeletonProps={{
              isActiveStatus: true,
              isActionButton: true,
            }}
          />
          {isLoading ? (
            <FormContentSkeleton rows={7} />
          ) : (
            <>
              <FormProvider
                formProps={{ sx: { position: 'relative', flex: 1, height: 1 } }}
                methods={formMethods}
                onSubmit={onFormSubmitHandler}
              >
                <UserForm isRoleField={!isHideRoleField} />
                <FormActions isDisabled={isSubmitButtonDisabled} isLoading={isSubmitting} isDirtyForm={isDirty} />
              </FormProvider>
            </>
          )}
        </Stack>
      )}

      <BaseDialogWindow
        open={dialogStates.deactivate}
        onClickCancelButtonHandler={getCloseDialogHandler('deactivate')}
        description="Are you sure you want to inactivate this user?"
        title="Inactivate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate user"
        onClickApproveButtonHandler={onDeactivateUserHandler}
      />

      <BaseDialogWindow
        open={dialogStates.activate}
        onClickCancelButtonHandler={getCloseDialogHandler('activate')}
        description="Are you sure you want to activate this user?"
        title="Activate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate user"
        onClickApproveButtonHandler={onActivateUserHandler}
      />
    </Box>
  );
};
