import { FC, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialState, FiltersMenuContentProps } from '../types';
import { FiltersInitialStateValue } from '../usePractices.state';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ appliedFilters, setAppliedFilters }) => {
  const [filters, setFilters] = useState<FiltersInitialState>(appliedFilters);

  const onClickResetFiltersButtonHandler = () => setFilters(FiltersInitialStateValue);

  const onClickApplyFiltersButtonHandler = async () => setAppliedFilters(filters);

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filters, appliedFilters],
  );

  const isResetButtonDisabled = useMemo(
    () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
    [filters],
  );

  return (
    <Stack position="relative">
      <Scrollbar style={{ maxHeight: 410 }}></Scrollbar>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
