import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';

import { RHFCommonProps } from './types';

type RHFSwitchProps<TField extends FieldValues> = SwitchProps &
  RHFCommonProps<TField> & {
    label: string;
  };

export const RHFSwitch = <TField extends FieldValues>(props: RHFSwitchProps<TField>) => {
  const { registerName, label, control, ...switchProps } = props;

  return (
    <Controller
      name={registerName}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={<Switch checked={value} onChange={onChange} color="primary" {...switchProps} />}
          label={label}
        />
      )}
    />
  );
};
