import { useCallback, useState } from 'react';

import {
  CreateFeedbackPayload,
  PromptText,
  RunPromptResponse,
  createFeedback,
  getPromptText,
  runPrompt,
} from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useRouter, useUserProfile } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

export type SubmitFeedbackHandlerProps = Pick<
  CreateFeedbackPayload,
  'coherence_stars' | 'relevancy_stars' | 'accuracy_stars' | 'comment' | 'preferred_output'
>;

export const useTestingTabContentState = () => {
  const { cognitoUser } = useUserProfile();

  const { params } = useRouter();

  const [isLoadingPromptText, setIsLoadingPromptText] = useState(false);

  const [userPromptText, setUserPromptText] = useState<PromptText | null>(null);

  const getUserPromptText = useCallback(async (patientId: string, practiceId: string) => {
    setIsLoadingPromptText(true);
    try {
      const { data } = await getPromptText(params?.id, patientId, practiceId);

      setUserPromptText(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get prompt text' } });
    } finally {
      setIsLoadingPromptText(false);
    }
  }, []);

  const [promptResponse, setPromptResponse] = useState<RunPromptResponse | null>(null);

  const [isFeedback, setIsFeedback] = useState(false);

  const executePromptHandler = useCallback(
    async ({
      patientOpensearchIndexName,
      practiceOpensearchIndexName,
    }: {
      practiceOpensearchIndexName: string | null;
      patientOpensearchIndexName: string | null;
    }) => {
      try {
        const { data } = await runPrompt(
          params.id,
          practiceOpensearchIndexName || '',
          patientOpensearchIndexName || '',
        );

        setPromptResponse(data);

        setUserPromptText(null);

        setIsFeedback(true);
      } catch (error) {
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to get prompt result, please try again!' },
        });
      }
    },
    [],
  );

  const submitFeedbackHandler = useCallback(
    async (payload: SubmitFeedbackHandlerProps) => {
      try {
        await createFeedback({
          account_id: null,
          // TODO: clarify with user name
          cognito_username: 'test.admin@aesthetics360.com',
          prompt_id: params?.id,
          model_response: promptResponse?.result || '',
          ...payload,
        });

        notice(ToastType.SUCCESS, 'Feedback has been sent successfully!');

        setIsFeedback(false);

        setPromptResponse(null);
      } catch (error) {
        backendErrorHandler({ error, config: { customErrorMessage: 'Failed to submit feedback, please try again!' } });
      }
    },
    [promptResponse, cognitoUser],
  );
  return {
    isLoadingPromptText,
    userPromptText,
    getUserPromptText,
    promptResponse,
    isFeedback,
    executePromptHandler,
    submitFeedbackHandler,
  };
};
