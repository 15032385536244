import { useEffect, useState, useTransition } from 'react';

import { shallow } from 'zustand/shallow';

import {
  GlobalLibrarySearchParams,
  TagListSortFieldName,
  deletePracticeLibraryTagCategory,
  getPracticeLibraryTagCategories,
  updatePracticeLibraryTagCategory,
} from 'apiServices';
import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean, useTable, useUserProfile } from 'hooks';
import { useActionDialogManagement } from 'hooks/useActionDialogManagement';
import { FiltersInitialState } from 'pages/TagManagement';
import { FiltersInitialStateValue } from 'pages/TagManagement/config';
import { useAppStore } from 'store';
import { backendErrorHandler } from 'utils/errorHanders';
import { resolveFilterCondition } from 'utils/helpers';

export const usePracticeLibraryTagListState = () => {
  const {
    practiceLibraryTagCategories: { items, total },
    setPracticeLibraryTagCategories,
  } = useAppStore(
    store => ({
      practiceLibraryTagCategories: store.practiceLibraryTagCategories,
      setPracticeLibraryTagCategories: store.setPracticeLibraryTagCategories,
    }),
    shallow,
  );

  const {
    isFiltersApplied,
    isFiltersMenuOpen,
    openFilterMenu,
    closeFiltersMenu,
    appliedFilters,
    onApplyFilters,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    setPage,
    resetPageHandler,
    onSort,
    order,
    orderBy,
    debouncedSearchValue,
    onChangeSearchValueHandler,
    searchValue,
    setResetFilters,
  } = useTable<TagListSortFieldName, FiltersInitialState>({
    defaultOrderBy: 'name',
    defaultOrder: 'asc',
    defaultFilters: FiltersInitialStateValue,
  });

  const [isAddTagCategoryDialogOpen, openAddTagCategoryDialog, closeAddTagCategoryDialog] = useBoolean();

  const [isPending, startTransition] = useTransition();

  const onGetTagCategoriesHandler = async (params?: GlobalLibrarySearchParams) =>
    startTransition(async () => {
      try {
        const { data } = await getPracticeLibraryTagCategories({
          ...(debouncedSearchValue && { search: debouncedSearchValue }),
          size: rowsPerPage,
          order: [`${orderBy},${order}`],
          page,
          is_active: resolveFilterCondition(appliedFilters.isActivated, appliedFilters.isDeactivated),
          is_published: resolveFilterCondition(appliedFilters.isPublished, appliedFilters.isUnpublished),
          ...params,
        });

        setPracticeLibraryTagCategories(data);
      } catch (error) {
        backendErrorHandler({
          error,
          config: { customErrorMessage: 'Failed to get tag categories, please try again!' },
        });
      }
    });

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagCategoriesHandler({ page: newPage });
  };

  const {
    getCloseDialogHandler,
    setIsActionPending,
    isActionPending,
    dialogStates,
    closeDialog,
    onClickActionOptionHandler,
    actionState,
  } = useActionDialogManagement();

  const onDeactivateTagCategoryHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await deletePracticeLibraryTagCategory(id);

      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');

      await onGetTagCategoriesHandler({ page: 1 });

      resetPageHandler();

      closeDialog('deactivate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateTagCategoryHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await updatePracticeLibraryTagCategory(id, { is_active: true });

      notice(ToastType.SUCCESS, 'Tag category has been successfully activated!');

      await onGetTagCategoriesHandler({ page: 1 });

      resetPageHandler();

      closeDialog('activate');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  useEffect(() => {
    onGetTagCategoriesHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage, order, orderBy]);

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
  } = useUserProfile();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  return {
    searchValue,
    onChangeSearchValueHandler,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialog,
    closeAddTagCategoryDialog,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading: isPending,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onGetTagCategoriesHandler,
    onChangePageHandler,
    page,
    appliedFilters,
    onActivateTagCategoryHandler,
    isActionPending,
    onDeactivateTagCategoryHandler,
    onClickActionOptionHandler,
    dialogStates,
    getCloseDialogHandler,
    actionState,
    onApplyFilters,
    onSort,
    order,
    orderBy,
    isFiltersApplied,
    isRoleAdmin,
    isViewOnly,
    resetPageHandler,
    setResetFilters,
  };
};
