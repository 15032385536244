import { PracticeListItem } from 'apiServices';
import { TableHeaderConfig } from 'types';

export const PRACTICES_SERVICES_TABLE_HEAD: TableHeaderConfig<PracticeListItem>[] = [
  { id: 'practiceName', label: 'Practice Name' },
  { id: 'status', label: 'Status' },
  { id: 'createdAt', label: 'Creation Date' },
  { id: 'updatedAt', label: 'Last Update' },
  { id: 'actions' },
];
