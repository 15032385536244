import * as yup from 'yup';

import { CreatePatientPayload } from 'apiServices';
import { LABELS } from 'constants/strings';
import { EmailLazyValidationSchema, PhoneValidationSchema } from 'utils/formValidation';

export type CreatePatientFormSchema = CreatePatientPayload;

export const createPatientValidationSchema = yup
  .object({
    first_name: yup.string().required().label(LABELS.FIRST_NAME),
    middle_name: yup.string().optional().label(LABELS.MIDDLE_NAME).nullable(),
    last_name: yup.string().required().label(LABELS.LAST_NAME),
    phone: PhoneValidationSchema,
    email: EmailLazyValidationSchema,
    patient_summary: yup.string().optional().label('Patient Summary').nullable(),
    birth_date: yup.string().required().label(LABELS.DATE_OF_BIRTH),
    practice_id: yup.string().required().label(LABELS.PRACTICE),
  })
  .required();

export const patientFormDefaultValues: CreatePatientFormSchema = {
  birth_date: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  phone: '',
  practice_id: '',
  patient_summary: '',
};
