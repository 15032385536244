import { LibraryTabbedPanel } from 'components/Widgets';

import { TABS } from './config';
import { GlobalLibraryBeforeAfterList } from './pages/GlobalLibrary';
import { PracticeLibraryBeforeAfterList } from './pages/PracticeLibrary';
import { useBeforeAfterState } from './useBeforeAfter.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibraryBeforeAfterList />;

    default:
      return <GlobalLibraryBeforeAfterList />;
  }
}

export const BeforeAfter: React.FC = () => {
  const { handleSetTabValue, tabValue } = useBeforeAfterState();

  return (
    <section>
      <LibraryTabbedPanel value={tabValue} handleSetTabValue={handleSetTabValue} tabsData={TABS} />
      {renderTabValueContent(tabValue)}
    </section>
  );
};
