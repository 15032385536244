import { useState, useRef, useCallback } from 'react';

import { ScrollableSideNavMenuItem } from 'components/SideNavMenu';

import { useResponsive } from './useResponsive';

type SectionItem = Omit<ScrollableSideNavMenuItem, 'onClick'> & { priority: number };

type UseActiveSectionManagerProps = {
  sections: readonly SectionItem[];
};

export const useScrollSections = ({ sections }: UseActiveSectionManagerProps) => {
  const [activeSection, setActiveSection] = useState<string | null>(sections[0].id);

  const isSkipObserver = useResponsive('down', 'md');

  const visibleSectionsRef = useRef(new Set<string>());

  const onUpdateActiveSection = useCallback(
    (inView: boolean, entry: IntersectionObserverEntry) => {
      const sectionId = entry.target.id;
      if (inView) {
        visibleSectionsRef.current.add(sectionId);
      } else {
        visibleSectionsRef.current.delete(sectionId);
      }

      const active = sections.find(section => visibleSectionsRef.current.has(section.id));
      setActiveSection(prev => (active?.id !== prev ? active?.id : prev));
    },
    [sections],
  );

  const scrollToRef = useCallback((refEntry: IntersectionObserverEntry | null) => {
    if (refEntry && refEntry.target) refEntry.target.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const generateSideNavItems = useCallback(
    (sectionRefs: Record<string, IntersectionObserverEntry>): ScrollableSideNavMenuItem[] =>
      sections.map(({ icon, id, label }) => ({
        id,
        label,
        icon,
        onClick: () => scrollToRef(sectionRefs[id]),
      })),

    [sections],
  );

  return { isSkipObserver, generateSideNavItems, activeSection, onUpdateActiveSection, scrollToRef };
};
