import { StateCreator } from 'zustand';

import { BeforeAfterPhotosInitialState, BeforeAfterPhotosSliceType } from './types';
import { sliceResetFns } from '../../Store';

const initialState: BeforeAfterPhotosInitialState = {
  ba_gl_image_pair: null,
  ba_pl_image_pair: null,
};

export const BeforeAfterPhotosSlice: StateCreator<
  BeforeAfterPhotosSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  BeforeAfterPhotosSliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    set_ba_gl_image_pair: data => {
      set(state => {
        state.ba_gl_image_pair = data;
      });
    },
    set_ba_pl_image_pair: data => {
      set(state => {
        state.ba_pl_image_pair = data;
      });
    },
  };
};
