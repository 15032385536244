export const StylesSx = {
  searchInput: {
    '& .MuiInputBase-input': {
      p: '9px 6px',
    },
  },
  filterButton: {
    lineHeight: 'initial',
    minWidth: 'unset',
    width: 46,
    p: 0,
  },
  filterIcon: {
    alignItems: 'center',
    svg: {
      path: {
        strokeWidth: 1.5,
      },
      width: 18,
      height: 18,
    },
  },
  appliedFilterDot: {
    flexShrink: 0,
    width: 6,
    height: 6,
    borderRadius: '50%',
    bgcolor: 'success.light',
  },
  actionButton: {
    lineHeight: 'initial',
    svg: {
      width: 20,
      height: 20,
      path: {
        strokeWidth: 2,
      },
    },
  },
};
