import { FC } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { ListWrapper } from './ListWrapper';
import { SideNavMenuItem } from './SideNavMenuItem';
import { SideNavMenuProps } from './types';

export const SideNavMenu: FC<SideNavMenuProps> = ({ sidenavItems, sx }) => {
  const location = useLocation();

  const renderSidenavItems = sidenavItems?.map(({ icon, label, href, isDisabled }) => {
    const isActive = location.pathname === href;

    return (
      <SideNavMenuItem
        key={label}
        label={label}
        isDisabled={isDisabled}
        isActive={isActive}
        icon={icon}
        component={NavLink}
        to={href}
      />
    );
  });

  return <ListWrapper sx={sx}>{renderSidenavItems}</ListWrapper>;
};
