import { memo } from 'react';

import { TableRow, TableCell, Box, Tooltip } from '@mui/material';

import { ContactPerson } from 'apiServices';
import { Icon } from 'components/Icon';
import { MDButton } from 'components/MDButton';

type PracticeContactTableRowProps = ContactPerson & {
  onClickEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const PracticeContactTableRow: React.FC<PracticeContactTableRowProps> = memo(
  ({ email, first_name, id, onClickEdit, last_name, middle_name, position, phone, title }) => {
    return (
      <TableRow>
        <TableCell>{first_name}</TableCell>

        <TableCell>{middle_name}</TableCell>

        <TableCell>{last_name}</TableCell>

        <TableCell>{position}</TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>{phone}</TableCell>

        <TableCell>{title}</TableCell>

        <TableCell>
          <Tooltip title="Edit" placement="top">
            <Box>
              <MDButton size="small" isIconOnly variant="ghost" id={id} onClick={onClickEdit}>
                <Icon
                  type="editPen"
                  sx={{
                    svg: {
                      width: 16,
                      height: 16,
                    },
                  }}
                />
              </MDButton>
            </Box>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  },
);
