import Stack from '@mui/material/Stack';

import { create_pl_ba_album_pair, upload_image_pl_ba_album_pair } from 'apiServices';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { getActivateMenuItem } from 'components/MenuPopover';
import { EntityHeader } from 'components/Widgets';
import {
  ActivateDeactivateDialogWindow,
  AddBeforeAfterImagesDialogWindow,
  AlbumProfileFormSection,
  BACardItem,
  BeforeAfterPhotos,
} from 'pages/BeforeAfter';

import beforeAfterAlbumFallback from 'assets/icons/before_after_album_fallback.svg';

import { usePLBeforeAfterAlbumProfileState } from './usePLBeforeAfterAlbumProfile.state';

export const PracticeLibraryBAProfile: React.FC = () => {
  const {
    b_a_pl_album,
    b_a_pl_album_pairs,
    isLoading,
    isActionPending,
    formMethods,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    isAlbumPairLoading,
    ref,
    isFetchMoreLoading,
    onDeleteAvatarHandler,
    onUploadAvatarHandler,
    onFormSubmitHandler,
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
    getMenuItems,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    fetchAlbumPairs,
    albumId,
    albumPairActionMethods,
    deleteAlbumPairHandler,
    isViewOnly,
    onActivateDeactivateAlbumPairHandler,
    dialogStates,
    getOpenDialogHandler,
    getCloseDialogHandler,
  } = usePLBeforeAfterAlbumProfileState();

  return (
    <Stack component="section" height={1}>
      <EntityHeader
        isLoading={isLoading}
        skeletonProps={{
          avatarVariant: 'rounded',
          isActiveStatus: true,
          isActionButton: true,
        }}
        title={b_a_pl_album?.name}
        subtitle={b_a_pl_album?.description}
        activeStatusProps={{
          isActive: b_a_pl_album?.is_active,
        }}
        actionMenuItems={[
          getActivateMenuItem(
            b_a_pl_album?.is_active,
            getOpenDialogHandler(b_a_pl_album?.is_active ? 'deactivate' : 'activate'),
          ),
        ]}
        avatarProps={{
          src: b_a_pl_album?.cover_thumb_url,
          fallbackSrc: beforeAfterAlbumFallback,
          initialPhotoUrl: b_a_pl_album?.cover_url,
          onCreateFileHandler: onUploadAvatarHandler,
          onDeleteFileHandler: onDeleteAvatarHandler,
          formDataImageName: 'cover_image',
          cropShape: 'rect',
          variant: 'rounded',
          isDisabled: isViewOnly,
        }}
      />

      <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
        <AlbumProfileFormSection isLoading={isLoading} isViewOnly={isViewOnly} />
        <FormActions
          isDisabled={formMethods.formState.isSubmitting}
          isLoading={formMethods.formState.isSubmitting}
          isDirtyForm={formMethods.formState.isDirty}
          sx={{
            borderRadius: 0,
            ...(formMethods.formState.isDirty && {
              borderBottom: ({ borders, palette }) => `${borders.borderWidth[1]} solid ${palette.divider}`,
            }),
          }}
        />
      </FormProvider>

      <BeforeAfterPhotos
        data={b_a_pl_album_pairs.items}
        totalItems={b_a_pl_album_pairs.total}
        isLoading={isAlbumPairLoading}
        isLoadingNext={isFetchMoreLoading}
        checkedImagePairIdSize={checkedIds.size}
        onResetImagePairChekedIds={onResetChekedIds}
        openAddAlbumImagesDialogWindow={!isViewOnly && openAddAlbumImagesDialogWindow}
        albumPairActionMethods={albumPairActionMethods}
        deleteAlbumPairHandler={deleteAlbumPairHandler}
        deactiveActivateAlbumPairHandler={onActivateDeactivateAlbumPairHandler}
        renderItem={(item, index) => {
          return (
            <BACardItem
              id={item?.id}
              title={item?.name}
              coverRightThumbURL={item?.image_right_thumb_url}
              coverLeftThumbURL={item?.image_left_thumb_url}
              isActive={item?.is_active}
              onChangeCheckboxHandler={!isViewOnly && onCheckItemHandler}
              checkedIds={checkedIds}
              getMenuItems={getMenuItems}
              onCardClickHandler={() => {}}
              lastAlbumCardRef={b_a_pl_album_pairs.items.length - 1 === index ? ref : null}
            />
          );
        }}
      />

      {isOpenAddAlbumImagesDialogWindow && (
        <AddBeforeAfterImagesDialogWindow
          isOpenAddAllbumDialogWindow={isOpenAddAlbumImagesDialogWindow}
          closeAddAlbumDialogWindow={closeAddAlbumImagesDialogWindow}
          onFetchAlbumsHandler={fetchAlbumPairs}
          uploadImageAPIrequest={upload_image_pl_ba_album_pair}
          createBAimagePairAPIrequest={create_pl_ba_album_pair}
          albumId={albumId}
        />
      )}

      <ActivateDeactivateDialogWindow
        title="Album"
        actionType={dialogStates.activate ? 'deactivate' : 'activate'}
        name={b_a_pl_album?.name}
        isOpen={dialogStates.deactivate || dialogStates.activate}
        isActionPending={isActionPending}
        onClose={getCloseDialogHandler(dialogStates.deactivate ? 'deactivate' : 'activate')}
        onApprove={dialogStates.deactivate ? onDeactivateAlbumHandler : onActivateAlbumHandler}
      />
    </Stack>
  );
};
