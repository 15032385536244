import Stack from '@mui/material/Stack';

import { Icon } from 'components/Icon';
import { MDChip } from 'components/MDChip';
import { EntityHeaderActions, EntityHeaderRoot } from 'components/Widgets';
import { dateToCustomFormat } from 'utils/helpers';

import { DetailsTabContent } from './GeneralTabContent';
import { RelatedConcernsTabContent } from './RelatedConcernsTabContent';
import { TranscriptTabContent } from './TranscriptTabContent';
import {
  PATIENT_CONCERN_PROFILE_TAB_VALUES,
  usePatientConsultationProfileState,
} from './usePatientConsultationProfile.state';
import { DetailItem } from '../../components';

const renderTabContent = (tabValue: number) => {
  switch (tabValue) {
    case 1:
      return <TranscriptTabContent />;
    case 2:
      return <RelatedConcernsTabContent />;
    default:
      return <DetailsTabContent />;
  }
};

export const PatientConsultationProfile: React.FC = () => {
  const { handleSetTabValue, tabValue, isWebView } = usePatientConsultationProfileState();

  const formattedDate = dateToCustomFormat('12-20-2024');

  const tabsData = isWebView ? PATIENT_CONCERN_PROFILE_TAB_VALUES.slice(0, 2) : PATIENT_CONCERN_PROFILE_TAB_VALUES;

  return (
    <Stack component="section" height={1}>
      <EntityHeaderRoot
        tabs={{
          tabsData,
          value: tabValue,
          handleSetTabValue,
          ...(!isWebView && {
            tabWrapperProps: { maxWidth: 400 },
          }),
        }}
        isWebView={isWebView}
      >
        {!isWebView && <EntityHeaderActions actionMenuItems={[{ title: 'Delete', onClick: () => {} }]} />}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 1, sm: 1.5 }}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
        >
          <Stack justifyContent="flex-start" maxWidth={350} width={1}>
            <DetailItem
              icon={<Icon type="consultationStatusIcon" />}
              label="Status"
              value={<MDChip variant="soft" size="small" isSquare label="Finished" color="success" />}
            />
            <DetailItem icon={<Icon type="calendar" />} label="Date" value="April 12, 2024 4:15-4:30PM" />
          </Stack>
          <Stack maxWidth={350} width={1}>
            <DetailItem icon={<Icon type="userIcon" />} label="Provider" value="Dr. Michael Lee" />
            <DetailItem icon={<Icon type="settingsIcon" />} label="Workflow" value="Follow-up Visit" />
          </Stack>
        </Stack>
      </EntityHeaderRoot>
      {renderTabContent(tabValue)}
    </Stack>
  );
};
