import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const tableCell = {
  styleOverrides: {
    root: {
      borderBottom: 'none',
      padding: 8,
      '&:first-of-type': { paddingLeft: 16 },
      fontSize: typography.size.bodyMedium,
      fontWeight: 500,
    },
    head: {
      color: palette.text.secondary,
      fontSize: typography.size.bodySmall,
      fontWeight: 500,
    },
    stickyHeader: {
      backgroundColor: palette.background.default,
    },
    paddingCheckbox: {
      paddingLeft: '8px',
    },
  },
};

export default tableCell;
