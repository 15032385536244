import { useCallback, useEffect, useState } from 'react';

import { shallow } from 'zustand/shallow';

import { updatePractice, UpdatePracticePayload } from 'apiServices';
import { useRouter, useTabs, useUserProfile } from 'hooks';
import { useAppStore } from 'store';

export const usePracticeProfileState = () => {
  const { params } = useRouter();

  const practiceId = params?.id;

  const { getPractice, practice, setPractice } = useAppStore(
    store => ({
      getPractice: store.fetchPractice,
      practice: store.practice,
      setPractice: store.setPractice,
    }),
    shallow,
  );

  const fetchPractice = useCallback(() => getPractice(practiceId), [practiceId]);

  const updateAndFetchPracticeHandler = useCallback(
    async (paylaod: UpdatePracticePayload) => {
      await updatePractice(practiceId, paylaod);

      await fetchPractice();
    },
    [practiceId],
  );

  const [isLoading, setIsLoading] = useState(true);

  const onGetPracticeHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchPractice();
    setIsLoading(false);
  };

  useEffect(() => {
    onGetPracticeHandler();

    return () => {
      setPractice(null);
    };
  }, []);

  const isNoPractice = !isLoading && !practice;

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
  } = useUserProfile();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const { handleSetTabValue, tabValue } = useTabs();

  return {
    practice,
    isLoading,
    onGetPracticeHandler,
    isNoPractice,
    tabValue,
    isViewOnly,
    handleSetTabValue,
    updateAndFetchPracticeHandler,
    fetchPractice,
  };
};
