import { ContactPerson } from 'apiServices';
import { LABELS } from 'constants/strings';
import { TableHeaderConfig } from 'types';

export const CONTACT_RECORD_TABLE_HEAD: TableHeaderConfig<ContactPerson>[] = [
  { id: 'first_name', label: LABELS.FIRST_NAME, disableSorting: true },
  { id: 'middle_name', label: LABELS.MIDDLE_NAME, disableSorting: true },
  { id: 'last_name', label: LABELS.LAST_NAME, disableSorting: true },
  { id: 'position', label: LABELS.POSITION, disableSorting: true },
  { id: 'email', label: LABELS.EMAIL, disableSorting: true },
  { id: 'phone', label: LABELS.PHONE, disableSorting: true },
  { id: 'title', label: LABELS.TITLE, disableSorting: true },
  { id: 'actions', label: LABELS.ACTIONS, disableSorting: true },
];
