import { Grid2, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { RHFAutocompleteField } from 'components/HookForm';
import { usePopover } from 'hooks';
import { SectionWrapper } from 'layouts';

import { AddTreatmentForm } from './AddTreatmentForm';
import { FormSectionWrapper } from './FormSectionWrapper';
import { QuestionnaireFormSchema } from '../../form.config';
import { PATIENT_SATISFACTION_OPTIONS } from '../../options';
import { TreatmentHistoryCard } from '../TreatmentHistoryCard';

export const TreatmentHistory: React.FC = () => {
  const { control } = useFormContext<QuestionnaireFormSchema>();

  const [anchor, openPopoverHandler, closePopoverHandler] = usePopover();

  const [previousDermalAnchor, openPopoverHandlerDermal, closePopoverHandlerDermal] = usePopover();

  return (
    <SectionWrapper>
      <Grid2 spacing={2} container>
        <Grid2 size={12}>
          <Typography mb={1} variant="subtitle1" fontWeight={500}>
            Previous botulinum toxin treatments
          </Typography>

          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                lg: 10,
              }}
              display="flex"
              alignItems="center"
            >
              {/* <AddMedicalRecordButton
                title="Add Botulinum Toxin Treatment"
                isOpen={!!anchor}
                onClick={openPopoverHandler}
              /> */}
              <AddTreatmentForm unit="units" open={anchor} onClose={closePopoverHandler} />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TreatmentHistoryCard
                title="Treatment Record"
                areasTreated="Face, Neck"
                lastTreatmentDate="Apr-14-2024"
                productUsed="Hyaluronic Acid"
                dosage="2.0 units"
              />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TreatmentHistoryCard
                title="Treatment Record"
                areasTreated="Face, Neck"
                lastTreatmentDate="Apr-14-2024"
                productUsed="Hyaluronic Acid"
                dosage="2.0 units"
              />
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <Typography mb={1} variant="subtitle1" fontWeight={500}>
            Previous dermal filler treatments
          </Typography>

          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                lg: 10,
              }}
              display="flex"
              alignItems="center"
            >
              {/* <AddMedicalRecordButton
                title="Add Dermal Filler Treatment"
                isOpen={!!previousDermalAnchor}
                onClick={openPopoverHandlerDermal}
              /> */}
              <AddTreatmentForm unit="mL" open={previousDermalAnchor} onClose={closePopoverHandlerDermal} />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <TreatmentHistoryCard
                title="Treatment Summary"
                areasTreated="Lips, Cheeks"
                lastTreatmentDate="Apr-28-2024"
                productUsed="Juvederm Ultra"
                dosage="1.5 mL"
              />
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={6}>
          <RHFAutocompleteField
            control={control}
            registerName="patientSatisfaction"
            options={PATIENT_SATISFACTION_OPTIONS}
            valueKey="value"
            labelKey="value"
            label="Patient Satisfaction With Previous Treatments"
            placeholder="Select Patient Satisfaction With Previous Treatments"
          />
        </Grid2>
      </Grid2>
    </SectionWrapper>
  );
};
