import { PlayArrowRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import { CustomTablePaginationActions } from './CustomTablePaginationActions';
import { TableStyles as Styled } from './Table.styles';
import { TCustomTablePaginationProps } from './Table.types';

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [20, 30, 40];

export const CustomTablePagination: React.FC<TCustomTablePaginationProps> = props => {
  const { dense, onChangeDense, rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS, sx, count, ...other } = props;

  return (
    <Box>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        ActionsComponent={CustomTablePaginationActions}
        sx={{
          mt: '-1px',
        }}
        slotProps={{
          select: {
            IconComponent: props => <PlayArrowRounded {...props} fontSize="medium" sx={Styled.SelectIconSX} />,
          },
        }}
        count={count || 0}
        {...other}
      />
    </Box>
  );
};
