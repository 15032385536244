import { useCallback, useState } from 'react';

type UsePopoverResponse = [HTMLElement, VoidFunction, VoidFunction] & {
  openPopover: HTMLElement;
  handleClosePopover: VoidFunction;
  handleOpenPopover: (event: React.MouseEvent<HTMLElement>) => void;
};

export const usePopover = (): UsePopoverResponse => {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setOpenPopover(event.currentTarget),
    [],
  );

  const handleClosePopover = useCallback(() => setOpenPopover(null), []);

  const result = [openPopover, handleOpenPopover, handleClosePopover] as UsePopoverResponse;

  result.openPopover = result[0];
  result.handleOpenPopover = result[1];
  result.handleClosePopover = result[2];

  return result;
};
