import borders from 'styles/theme/base/borders';
import { palette } from 'styles/theme/base/palette';
import typography from 'styles/theme/base/typography';

const { secondary, text } = palette;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      fontSize: size.bodyMedium,
      borderRadius: borderRadius[8],

      '&:hover .MuiOutlinedInput-notchedOutline:not(.Mui-focused .MuiOutlinedInput-notchedOutline):not(.Mui-error .MuiOutlinedInput-notchedOutline)':
        {
          borderColor: secondary.main,
        },
    },

    notchedOutline: {
      borderColor: secondary.main,
    },

    input: {
      color: text.primary,
      padding: '9px 12px',
      backgroundColor: 'transparent',
      textOverflow: 'ellipsis',
    },

    inputSizeSmall: {
      fontSize: size.bodySmall,
      padding: '5px 12px',
      '::placeholder': {
        transform: 'translateY(1px)',
      },
    },

    multiline: {
      color: text.primary,
      padding: 0,
    },
  },
};

export default inputOutlined;
