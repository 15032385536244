import { Stack, Typography } from '@mui/material';

type ConcernDetailsItemProps = {
  icon: React.ReactNode;
  label: string;
  value: React.ReactNode;
};

export const DetailItem: React.FC<ConcernDetailsItemProps> = ({ icon, label, value }) => {
  return (
    <Stack alignItems="center" gap={2} direction="row" py="10px">
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        maxWidth={105}
        width={1}
        sx={{
          '& svg': {
            color: theme => theme.palette.secondary.main,
          },
        }}
      >
        {icon}
        <Typography variant="button" color="textBody" fontWeight={400}>
          {label}
        </Typography>
      </Stack>

      {typeof value === 'string' ? (
        <Typography variant="button" color="textSecondary" fontWeight={500}>
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};
