import { forwardRef } from 'react';

import { Stack, StackProps } from '@mui/material';

export const SectionWrapper = forwardRef<HTMLDivElement, StackProps>(({ children, ...props }, ref) => {
  return (
    <Stack p={{ xs: 2, sm: 2.5, md: 3, lg: 3.5 }} {...props} ref={ref}>
      {children}
    </Stack>
  );
});
